import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const InstallmentLazy = lazy(() => lazyRetry(() => import('./Installment')));

export type InstallmentProps = BaseProps & {
    defaultBirthdate?: string;
    goToSummary?: () => void;
};

const Installment = (props: InstallmentProps): JSX.Element => (
    <LazyComponent>
        <InstallmentLazy {...props} />
    </LazyComponent>
);

export default Installment;
