import { injectComponent } from '@mediashop/app/component-injector';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';

export type BackgroundColor =
    | 'none'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'default'
    | 'white-true'
    | 'white'
    | 'black'
    | 'black-true'
    | 'info'
    | 'warning'
    | 'success'
    | 'error';

type OverflowDirection = 'none' | 'both';

type Props = BasePropsWithChildren & {
    activeMobile?: boolean;
    activeTablet?: boolean;
    activeDesktop?: boolean;
    overflowDirection?: OverflowDirection;
    backgroundColor?: BackgroundColor;
    blobBackground?: boolean;
};

/**
 * Helper Component to allow Components to adaptively take the whole page-width on different device-types.
 *
 * @param {any} children - if true the container has full-width on mobile
 * @param {string} className - extends the className of the component
 * @param {bool} activeMobile - if true the container has full-width on mobile
 * @param {bool} activeTablet - if true the container has full-width on tablet
 * @param {bool} activeDesktop - if true the container has full-width on desktop
 * @param {string} overflowDirection - if set the container overflows to this direction
 * @param {string} backgroundColor - sets the background-color of the component to one
 *                                   of the theme-colors.
 */
function ResponsiveFullPageWidthWrapper({ children }: Props) {
    return <div>{children}</div>;
}

export default injectComponent('pattern.atom.ResponsiveFullPageWidthWrapper', ResponsiveFullPageWidthWrapper);
