import { useAppSelector } from '../../store/hooks/hooks';
import ProductVariantIdParser from './ProductVariantIdParser';
import ProductMapper from './ProductMapper';
import { BloomreachProductBundle, ProductBundle, ProductBundleLoading } from './types/ProductBundle';

interface ExpectedProps {
    productId?: string;
    productVariantId?: string;
    product?: BloomreachProductBundle | ProductBundle | ProductBundleLoading;
}

/**
 * Enhance dereferenced data from bloomreach for a component with product-data.
 * This mutates the props-object.
 */
export const enhance = (props: ExpectedProps): void => {
    let productId = props.productId;
    let variantId: number | undefined;

    if (props.productVariantId) {
        productId = ProductVariantIdParser.getProductId(props.productVariantId) ?? productId;
        variantId = ProductVariantIdParser.getVariantId(props.productVariantId);

        // ProductId from props and from productVariantId should probably be the same
        if (props.productId && props.productId !== productId) {
            console.warn(
                'ProductBundlePropEnhancer: Mismatch between productId and productVariantId',
                props.productId,
                productId
            );
        }
    }

    if (!productId) {
        return;
    }

    const entity = useAppSelector((state) => state.product.products[productId!]);
    if (!entity || entity.status !== 'succeeded' || !entity.product) {
        props.product = {
            ...props.product,
            name: '',
            status: entity?.status ?? 'unloaded',
        };
        return;
    }

    const { product } = entity;
    const activeVariant = ProductMapper.getActiveVariantData(product, variantId);

    props.product = {
        ...props.product,
        ...product,
        status: entity.status,
        activeVariant,
    };
};

export default {
    enhance,
};
