import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const BloomreachVideoLazy = lazy(() => lazyRetry(() => import('./BloomreachVideo')));

export type BloomreachVideoProps = BaseProps & {
    videoUrl: string;
};

const BloomreachVideo = (props: BloomreachVideoProps): JSX.Element => (
    <LazyComponent>
        <BloomreachVideoLazy {...props} />
    </LazyComponent>
);

export default BloomreachVideo;
