import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { generateBreadcrumbsSchema } from '../../ProductDetails/Seo/schema-generators';
import useSiteName from '@mediashop/app/hooks/useSiteName';
import { FlatBreadcrumbIndex } from '../../../../utils/buildBreadcrumbsIndex';
import { DetailedProduct } from '@mediashop/app/api/types/ClientProductDetailed';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { BreadcrumbList } from 'schema-dts';

type Props = {
    breadcrumbIndex: FlatBreadcrumbIndex;
    product: DetailedProduct;
};

export const BreadcrumbSeo = ({ breadcrumbIndex, product }: Props): JSX.Element => {
    const {
        location: { href },
    } = useShopContext();
    const shopName = useSiteName();

    const breadcrumbsProps = {
        href,
        breadcrumbIndex,
        product,
        shopName,
    };

    const breadcrumbSchema = generateBreadcrumbsSchema(breadcrumbsProps);

    return (
        <Helmet
            script={
                /** Schema tags */
                [helmetJsonLdProp<BreadcrumbList>(breadcrumbSchema)]
            }
        />
    );
};
