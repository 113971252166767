import { useCallback } from 'react';

/**
 * Returns a callback which can be used to scroll an id into view
 */
export default function useScrollIntoViewCallback(
    elementId?: string,
    scrollIntoViewOptions: boolean | ScrollIntoViewOptions = { behavior: 'smooth' }
): () => void {
    return useCallback(() => {
        if (!elementId) {
            return;
        }

        const sanitizedId = elementId.replace('#', '');
        document?.getElementById(sanitizedId)?.scrollIntoView(scrollIntoViewOptions);
    }, [elementId]);
}
