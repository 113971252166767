import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ExistingAccountModalMolecule = lazy(() => lazyRetry(() => import('./ExistingAccountModal')));

export type ExistingAccountModalProps = BaseProps & {
    isOpen: boolean;
    onAccept: () => void;
    onClose: () => void;
};

const ExistingAccountModal = (props: ExistingAccountModalProps): JSX.Element => (
    <LazyComponent>
        <ExistingAccountModalMolecule {...props} />
    </LazyComponent>
);

export default ExistingAccountModal;
