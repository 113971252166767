import { BaseProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { FormattedMessage } from 'react-intl';

const componentName = 'burger-menu-button';

type BurgerMenuButtonProps = BaseProps & {
    onClick: () => void;
    isBurgerMenuOpen: boolean;
};

const BurgerMenuButton = ({ onClick, isBurgerMenuOpen }: BurgerMenuButtonProps) => {
    return (
        <div className={componentName} onClick={onClick}>
            <Icon name={isBurgerMenuOpen ? 'BurgerMenuClose' : 'BurgerMenuOpen'} />
            <span className={`${componentName}__text`}>
                <FormattedMessage id="mainNavigation.burgerMenuButtonText" />
            </span>
        </div>
    );
};

export default injectComponent('pattern.organism.MainNavigationCustom.BurgerMenu.BurgerMenuButton', BurgerMenuButton);
