import { Children, ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
    children: ReactNode;
};

/**
 * Display all items in this container inline.
 */
export default function ContainerInline({ children }: Props): JSX.Element {
    return (
        <div className={classNames('container-inline', 'container-wrapper')}>
            {Children.map(children, (child) => (
                <div className="container-inline__item">{child}</div>
            ))}
        </div>
    );
}
