import { BaseProps } from '@mediashop/app/bloomreach/types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useCountdown } from './useCountdown';

type Props = BaseProps & {
    date: number;
    textColor?: string;
};

const componentName = 'special-deal-countdown';

export function SpecialDealCountdown({ date, textColor }: Props): JSX.Element | null {
    const countdown = useCountdown(date);

    if (!countdown) {
        return SKIP_RENDER;
    }

    return (
        <div className={componentName} style={{ color: textColor ? textColor : undefined }}>
            {countdown.isEnd ? (
                <div className={`${componentName}__expired`}>
                    <FormattedMessage id="specialDeal.specialDealOver" />
                </div>
            ) : (
                <div className={`${componentName}__digits`}>
                    {countdown.days > 0 ? (
                        <span className={`${componentName}__time`} data-testid={`${componentName}-days`}>
                            {countdown.days}
                            <sup>
                                <FormattedMessage id="specialDeal.days" values={{ count: countdown.days }} />
                            </sup>
                        </span>
                    ) : (
                        SKIP_RENDER
                    )}
                    <span className={`${componentName}__time`} data-testid={`${componentName}-hours`}>
                        <FormattedNumber value={countdown.hours} minimumIntegerDigits={2} />
                        <sup>
                            <FormattedMessage id="specialDeal.hours" />
                        </sup>
                    </span>
                    <span className={`${componentName}__time`} data-testid={`${componentName}-minutes`}>
                        <FormattedNumber value={countdown.minutes} minimumIntegerDigits={2} />
                        <sup>
                            <FormattedMessage id="specialDeal.minutes" />
                        </sup>
                    </span>
                    <span className={`${componentName}__time`} data-testid={`${componentName}-seconds`}>
                        <FormattedNumber value={countdown.seconds} minimumIntegerDigits={2} />
                        <sup>
                            <FormattedMessage id="specialDeal.seconds" />
                        </sup>
                    </span>
                </div>
            )}
        </div>
    );
}
