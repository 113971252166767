/**
 * Filters the given list of shipping countries based on the provided currency.
 */

export default function filterShippingCountriesByCurrency(shippingCountries: string[], currency: string): string[] {
    if (currency === 'CHF') {
        return shippingCountries.filter((country) => ['CH', 'LI'].includes(country));
    }
    return shippingCountries.filter((country) => !['CH', 'LI'].includes(country));
}
