import classNames from 'classnames';
import parseHtml from 'html-react-parser';
import { Category } from '@mediashop/app/api/types/ClientCategory';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { CATEGORY_BELOW_THE_FOLD_CONTAINER_ID } from './constants';

const componentName = 'category-below-the-fold-text';

type BelowTheFoldTextType = BaseProps & {
    category?: Category;
};

export function BelowTheFoldText({ category, className }: BelowTheFoldTextType): JSX.Element | null {
    const belowTheFoldText = category?.custom?.categoryTextBelowTheFold;

    return belowTheFoldText ? (
        <div id={CATEGORY_BELOW_THE_FOLD_CONTAINER_ID} className={classNames(className, componentName)}>
            {parseHtml(belowTheFoldText)}
        </div>
    ) : (
        SKIP_RENDER
    );
}
