import VoucherValidityIndicator from '../VoucherValidityIndicator';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useState } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import Icon from '../Icon';
import classNames from 'classnames';
import { VoucherRowDiscountType } from '@mediashop/app/api/types/Discount';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { Price } from '../../molecule/Price';

const componentName = 'modal-discount';

export type ModalDiscountProps = BaseProps & {
    discount: VoucherRowDiscountType;
    showValidity?: boolean;
    alwaysShowLegalText?: boolean;
    isActive?: boolean;
    onClick?: () => void;
};

// eslint-disable-next-line complexity
function ModalDiscount({
    discount: { code, legalText, minimumOrderValue, state, type, value },
    showValidity = false,
    alwaysShowLegalText = false,
    isActive,
    onClick,
}: ModalDiscountProps) {
    const [expanded, setExpanded] = useState(false);

    const onClickExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    return (
        <div
            className={classNames(`${componentName}__details`, { [`${componentName}__details--active`]: isActive })}
            onClick={onClick ? onClick : undefined}
            data-testid="TESTID_MODALDISCOUNT"
        >
            {showValidity && (
                <VoucherValidityIndicator
                    className={`${componentName}__validity`}
                    discountState={state}
                    iconPosition="start"
                />
            )}
            <div className={`${componentName}__info`}>
                <span className={`${componentName}__info-code`}>{code}</span>
                {type?.type === 'relative' || type?.type === 'absolute' || type?.type === 'absoluteCart' ? (
                    <div className={`${componentName}__info-details`}>
                        <div className={`${componentName}__info-details-savings`}>
                            {type.type === 'relative' ? (
                                <FormattedMessage
                                    id="voucher.modal.savings"
                                    values={{
                                        amount: (
                                            <>
                                                <FormattedNumber value={type?.permyriad / 100} />%
                                            </>
                                        ),
                                    }}
                                />
                            ) : (
                                SKIP_RENDER
                            )}
                            {type.type === 'absolute' || type.type === 'absoluteCart' ? (
                                <FormattedMessage
                                    id="voucher.modal.savings"
                                    values={{
                                        amount: <Price price={type.money ?? value} />,
                                    }}
                                />
                            ) : (
                                SKIP_RENDER
                            )}
                        </div>

                        {minimumOrderValue && (
                            <div>
                                <FormattedMessage
                                    id="voucher.modal.minimalOrderAmount"
                                    values={{ amount: <Price price={minimumOrderValue} /> }}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    SKIP_RENDER
                )}
            </div>
            {!alwaysShowLegalText && legalText && (
                <div className={`${componentName}__show-more-container`}>
                    <div
                        className={`${componentName}__show-more`}
                        onClick={onClickExpand}
                        data-testid="TESTID_SHOWMORE"
                    >
                        {expanded ? <Icon name="ChevronUp" /> : <Icon name="ChevronDown" />}
                        <FormattedMessage
                            id={expanded ? 'voucher.modal.dontShowLegalText' : 'voucher.modal.displayLegalText'}
                        />
                    </div>
                </div>
            )}
            {(expanded || alwaysShowLegalText) && legalText ? (
                <div className={`${componentName}__legal`}>{legalText}</div>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}

export default injectComponent('pattern.molecule.ModalDiscount', ModalDiscount);
