import { useEffect, useState } from 'react';
import { X_PROJECT_HEADER } from '../../constants/api';
import { useProject } from '../useProject';

/**
 * This route points to our Express server.
 */
const API_RESOURCE = '/transformers/pdf-to-image';

type HookProps = {
    assetUrl: string;
};

export const useDownloadAssetPreview = ({ assetUrl }: HookProps) => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    const [inlineImage, setInlineImage] = useState<string>();

    const projectKey = useProject().key;

    const useEffectFn = () => {
        if (assetUrl) {
            setLoading(true);

            fetch(API_RESOURCE, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    assetUrl,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    [X_PROJECT_HEADER]: projectKey,
                },
            })
                .then((response) => response.blob())
                .then((blob) => {
                    setInlineImage(window.URL.createObjectURL(new Blob([blob])));
                })
                .catch(setError)
                .finally(() => setLoading(false));
        }
    };

    useEffect(useEffectFn, [projectKey, assetUrl]);

    return { isLoading, error, inlineImage };
};
