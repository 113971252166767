import { AddressForm } from '../../api/types/Address';

class CustomValidation {
    private static isFutureDate(dateString: string): boolean {
        const today = new Date().getTime();
        const dateStringArray = dateString.split('-');

        const dateString2 = new Date(
            Number(dateStringArray[0]),
            Number(dateStringArray[1]) - 1,
            Number(dateStringArray[2])
        ).getTime();

        return today - dateString2 < 0;
    }

    private static isTooFarInPast(dateString: string): boolean {
        const dateStringArray = dateString.split('-');

        return Number(dateStringArray[0]) < 1915;
    }

    static isTooYoung(dateString: string): boolean {
        const currentDate = new Date();
        const birthDate = new Date(dateString);
        let age = currentDate.getUTCFullYear() - birthDate.getUTCFullYear();
        const monthDifference = currentDate.getUTCMonth() - birthDate.getUTCMonth();
        if (monthDifference < 0 || (monthDifference === 0 && currentDate.getUTCDate() < birthDate.getUTCDate())) {
            age--;
        }
        // check the age between 0 and 18 because of the error message
        return age >= 0 && age < 18;
    }

    /*
     * "mm/dd/yyyy"
     * "yyyy-mm-dd"
     */
    static isValidDate(dateString: string): boolean {
        // First check for the pattern
        if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) {
            return false;
        }

        // Parse the date parts to integers
        const parts = dateString.split('-');
        const day = parseInt(parts[2], 10);
        const month = parseInt(parts[1], 10);
        const year = parseInt(parts[0], 10);

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month === 0 || month > 12) {
            return false;
        }

        const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }

        // Check the day is in the Future
        if (this.isFutureDate(dateString)) {
            return false;
        }

        // Check the day is too far in the Past
        if (this.isTooFarInPast(dateString)) {
            return false;
        }

        if (this.isTooYoung(dateString)) {
            return false;
        }

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    }

    static isValidPostalCode(postalCode: string): boolean {
        if (postalCode.includes('_')) {
            return false;
        }
        return true;
    }

    static isValidPhoneNumber(phoneNumber: string): boolean {
        const phoneNumberPattern = /\+[0-9 /]{9,20}/g;

        if (!phoneNumber.startsWith('+')) {
            return false;
        }

        if (!phoneNumber.match(phoneNumberPattern)) {
            return false;
        }
        return true;
    }

    static checkValidity(formData: AddressForm): boolean {
        const invalidFields: string[] = [];

        Object.entries(formData).forEach(([inputName, value]) => {
            // Date of birth validation
            if (inputName.includes('birthdate') && !this.isValidDate(value)) {
                invalidFields.push(inputName);
            }
            if (inputName.includes('postalCode') && !this.isValidPostalCode(value)) {
                invalidFields.push(inputName);
            }
            if (inputName.includes('phone') && !this.isValidPhoneNumber(value)) {
                invalidFields.push(inputName);
            }
        });

        return invalidFields.length === 0;
    }
}

export default CustomValidation;
