import { BrPageContext } from '@bloomreach/react-sdk';
import { useContext } from 'react';

export const useBrxPageContext = () => {
    const pageContext = useContext(BrPageContext);

    if (!pageContext) {
        throw new Error('useBrxPageContext must be called inside a bloomreach page.');
    }

    return pageContext;
};
