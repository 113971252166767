import { mapShippingCountryToKnownCategoryCountry } from '../helper/mapCategoryCountry';
import { useProject } from './useProject';
import { useShopContext } from './useShopContext';

/**
 * Hook that mappes the current shipping country to a known category country.
 */
export const useMappedCountry = (): string => {
    const { categoryCountries } = useProject();
    const { country: shippingCountry } = useShopContext();
    return mapShippingCountryToKnownCategoryCountry(shippingCountry, categoryCountries);
};
