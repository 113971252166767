import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Hook that return query-params as object.
 */
export const useQueryParams = (): Record<string, string> => {
    const { search } = useLocation();

    return useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);
};
