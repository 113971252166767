import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';

type Props = BasePropsWithChildren & {
    id?: string;
    activeMobile?: boolean;
    activeTablet?: boolean;
    activeDesktop?: boolean;
};

function ContentWrapper({
    id,
    children,
    className,
    activeMobile = true,
    activeTablet = true,
    activeDesktop = true,
}: Props) {
    const componentName = 'content-width-wrapper';
    return (
        <div
            id={id}
            className={classNames(
                className,
                activeMobile ? `${componentName}--mobile` : '',
                activeTablet ? `${componentName}--tablet` : '',
                activeDesktop ? `${componentName}--desktop` : '',
                activeDesktop ? `${componentName}--wide` : ''
            )}
        >
            <div className={`${componentName}--holder`}>{children}</div>
        </div>
    );
}

export default injectComponent('pattern.atom.ContentWrapper', ContentWrapper);
