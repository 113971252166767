import { useEffect, useState } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { FormattedMessage } from 'react-intl';
import { convertSecondsToHours } from '@mediashop/catalog-base/utils/convertSecondsToHours';

type Props = BaseProps & {
    date: number;
    customFontColor?: string;
    customBackgroundColor?: string;
};

const componentName = 'deal-of-the-day-countdown';

export function Countdown({ date, customFontColor, customBackgroundColor }: Props): JSX.Element {
    const [countdown, setCountdown] = useState(convertSecondsToHours(date));

    useEffect(() => {
        const timer = setTimeout(() => {
            setCountdown(convertSecondsToHours(date));
        }, 1000);

        return () => clearTimeout(timer);
    });
    return (
        <div
            className={`${componentName}`}
            style={{
                background: customBackgroundColor ? customBackgroundColor : undefined,
                color: customFontColor ? customFontColor : undefined,
            }}
        >
            {countdown.isEnd ? (
                <FormattedMessage id="deal.dealOver" />
            ) : (
                <div>
                    <span>
                        <FormattedMessage id="deal.countdownEnd" />
                    </span>
                    <span>&nbsp;</span>
                    <span className={`${componentName}__time`}>{countdown.days}</span>
                    <sup>
                        <FormattedMessage id="deal.days" />
                    </sup>
                    <span>&nbsp;&nbsp;</span>
                    <span className={`${componentName}__time`}>{countdown.hours}</span>
                    <sup>
                        <FormattedMessage id="deal.hours" />
                    </sup>
                    <span>&nbsp;&nbsp;</span>
                    <span className={`${componentName}__time`}>{countdown.minutes}</span>
                    <sup>
                        <FormattedMessage id="deal.minutes" />
                    </sup>
                    <span>&nbsp;&nbsp;</span>
                    <span className={`${componentName}__time`}>{countdown.seconds}</span>
                    <sup>
                        <FormattedMessage id="deal.seconds" />
                    </sup>
                </div>
            )}
        </div>
    );
}
