import { BaseProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import classNames from 'classnames';
import Icon from '../../atom/Icon';

const componentName = 'delivery-info';

type DeliveryInfoProps = BaseProps & {
    shippingTime?: string;
};

function DeliveryInfo({ className, shippingTime }: DeliveryInfoProps) {
    if (typeof shippingTime !== 'string') {
        return SKIP_RENDER;
    }

    return (
        <div className={classNames(componentName, className)}>
            <Icon name="Delivery" className={`${componentName}__icon`} />
            <div>{shippingTime}</div>
        </div>
    );
}

export default injectComponent('pattern.molecule.DeliveryInfo', DeliveryInfo);
