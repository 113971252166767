import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import {
    AddressField,
    AddressFieldColumnsConfiguration,
    AddressFieldSetLayout,
    SelectField,
} from '@mediashop/app/api/types/AddressConfiguration';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { AddressForm } from '@mediashop/app/api/types/Address';
import DynamicFormikField from '../DynamicFormikField';
import { FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import LoqateAddressField from '../LoqateAddressField';
import { useCart } from '@mediashop/app/hooks/useCart';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

type DynamicFormikFieldsetProps = BaseProps & {
    formik: FormikProps<Record<string, string>>;
    name?: string;
    labelId?: string;
    layout?: AddressFieldSetLayout;
    columns?: AddressFieldColumnsConfiguration;
    fields: AddressField[];
    initialFormData?: AddressForm;
    type?: string;
};

/**
 * Filters billing countries, so Switzerland and Liechtenstein are only available in a CHF cart and filtered out in EUR carts.
 * @param currencyCode
 * @param filteredFields
 * @returns
 */
function filterSelectableCountries(currencyCode: string | undefined, filteredFields: AddressField[]) {
    const billingCountries = filteredFields.find((field) => field.name === 'billing_country');
    const shippingCountries = filteredFields.find((field) => field.name === 'shipping_country');

    if (currencyCode === 'CHF') {
        billingCountries &&
            ((billingCountries as SelectField).values = (billingCountries as SelectField).values.filter((country) =>
                ['CH', 'LI'].includes(country.value)
            ));
        shippingCountries &&
            ((shippingCountries as SelectField).values = (shippingCountries as SelectField).values.filter((country) =>
                ['CH', 'LI'].includes(country.value)
            ));
    } else if (currencyCode === 'EUR') {
        billingCountries &&
            ((billingCountries as SelectField).values = (billingCountries as SelectField).values.filter(
                (country) => !['CH', 'LI'].includes(country.value)
            ));
        shippingCountries &&
            ((shippingCountries as SelectField).values = (shippingCountries as SelectField).values.filter(
                (country) => !['CH', 'LI'].includes(country.value)
            ));
    }

    return filteredFields;
}

function DynamicFormikFieldset({
    formik,
    labelId,
    layout = 'stack',
    fields,
    columns,
    className,
    type,
}: DynamicFormikFieldsetProps) {
    function doesConditionMatch(field: AddressField) {
        if (field.validationCondition) {
            return formik.values[field.validationCondition.name] !== field.validationCondition.value;
        }
        if (field.validationConditions) {
            const returnArray = field.validationConditions.map(
                (validation) => formik.values[validation.name] === validation.value
            );
            return !returnArray.every((entry) => entry);
        }
        return false;
    }

    const { cart } = useCart();
    const cartCurrencyCode = cart?.productTotal?.currencyCode;

    function filterFields(fields: AddressField[]) {
        const filteredFields = fields.filter((field) => !doesConditionMatch(field));
        return filterSelectableCountries(cartCurrencyCode, filteredFields);
    }

    const filteredFields = filterFields(fields);
    if (isEmpty(filteredFields)) {
        return null;
    }

    if (type === 'address') {
        return (
            <div
                className={classNames(
                    { [`${className}__fieldset`]: className },
                    'dynamic-fieldset',
                    `dynamic-fieldset--${layout}`
                )}
            >
                <LoqateAddressField
                    labelId={labelId}
                    data={filteredFields}
                    formik={formik}
                    columns={columns}
                    doesConditionMatch={doesConditionMatch}
                />
            </div>
        );
    }

    return (
        <div
            className={classNames(
                { [`${className}__fieldset`]: className },
                'dynamic-fieldset',
                `dynamic-fieldset--${layout}`
            )}
        >
            <div className="dynamic-fieldset__title">{labelId ? <FormattedMessage id={labelId} /> : SKIP_RENDER}</div>
            <div
                className={classNames(
                    'dynamic-fieldset__fields',
                    `dynamic-fieldset__fields--cols-mobile-${columns?.mobile}`,
                    `dynamic-fieldset__fields--cols-tablet-${columns?.tablet}`,
                    `dynamic-fieldset__fields--cols-desktop-${columns?.desktop}`
                )}
            >
                {filteredFields &&
                    filteredFields.map((field) => {
                        if (doesConditionMatch(field)) {
                            return null;
                        }

                        return <DynamicFormikField key={field.name} formik={formik} {...field} />;
                    })}
            </div>
        </div>
    );
}

export default injectComponent('pattern.molecule.DynamicFormik.DynamicFormikFieldset', DynamicFormikFieldset);
