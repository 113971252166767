import * as React from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxLink } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { Cart } from '@mediashop/app/api/types/ClientCart';

const CheckoutLoginMolecule = React.lazy(() => lazyRetry(() => import('./CheckoutLogin')));

export type CheckoutLoginProps = BaseProps & {
    cart?: Cart;
    handleNextStepPayment: () => void;
    isCountryCompatible: boolean;
    passwordForgotten: BrxLink;
    refId?: React.ForwardedRef<{ onSubmit: () => void }>;
};

const CheckoutLogin = (props: CheckoutLoginProps): JSX.Element => (
    <LazyComponent>
        <CheckoutLoginMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.Checkout.CheckoutLogin', CheckoutLogin, 'catalog-base');
