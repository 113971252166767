import { useEffect, useState } from 'react';
import useIsPreview from './useIsPreview';
import { useAppSelector } from '../store/hooks/hooks';
import { useNavigate } from './useNavigate';

export const useRestrictedAccess = () => {
    const navigate = useNavigate();
    const { isSignedIn } = useAppSelector((state) => state.customer);

    const isBloomreachPreview = useIsPreview();

    const [isAllowed, setAllowed] = useState(false);

    useEffect(() => {
        if (isSignedIn === false && !isBloomreachPreview) {
            navigate('/');
        } else if (isSignedIn) {
            setAllowed(true);
        }
    }, [navigate, isBloomreachPreview, isSignedIn]);

    return isAllowed;
};
