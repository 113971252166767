import { ReactComponent as Account } from './assets/icons/account.svg';
import { ReactComponent as ArrowDown } from './assets/icons/arrow-down.svg';
import { ReactComponent as ArrowLeft } from './assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from './assets/icons/arrow-right.svg';
import { ReactComponent as ArrowRightLight } from './assets/icons/arrow-right-light.svg';
import { ReactComponent as ArrowUp } from './assets/icons/arrow-up.svg';
import { ReactComponent as Cart } from './assets/icons/cart.svg';
import { ReactComponent as Check } from './assets/icons/check.svg';
import { ReactComponent as ChevronUp } from './assets/icons/chevron_up.svg';
import { ReactComponent as ChevronDown } from './assets/icons/chevron_down.svg';
import { ReactComponent as ChevronRight } from './assets/icons/chevron_right.svg';
import { ReactComponent as ChevronLeft } from './assets/icons/chevron_left.svg';
import { ReactComponent as Close } from './assets/icons/close.svg';
import { ReactComponent as Deliver } from './assets/icons/deliver.svg';
import { ReactComponent as Delivery } from './assets/icons/delivery.svg';
import { ReactComponent as Info } from './assets/icons/info.svg';
import { ReactComponent as ListBackground } from './assets/icons/icon-background.svg';
import { ReactComponent as Menu } from './assets/icons/menu.svg';
import { ReactComponent as Search } from './assets/icons/search.svg';
import { ReactComponent as Star } from './assets/icons/star.svg';
import { ReactComponent as Play } from './assets/icons/play.svg';
import { ReactComponent as Twitter } from './assets/icons/twitter.svg';
import { ReactComponent as Facebook } from './assets/icons/facebook.svg';
import { ReactComponent as WhatsApp } from './assets/icons/whatsapp.svg';
import { ReactComponent as Share } from './assets/icons/share.svg';
import { ReactComponent as Attention } from './assets/icons/exclamation-triangle.svg';
import { ReactComponent as EyeShow } from './assets/icons/eye-show.svg';
import { ReactComponent as EyeHide } from './assets/icons/eye-hide.svg';
import { ReactComponent as worldFlag } from './assets/icons/World_Flag.svg';
import { ReactComponent as Trash } from './assets/icons/trash.svg';
import { ReactComponent as AccountFilled } from './assets/icons/account-filled.svg';
import { ReactComponent as Pencil } from './assets/icons/pencil.svg';
import { ReactComponent as Home } from './assets/icons/home.svg';
import { ReactComponent as HalfStar } from './assets/icons/star-half.svg';
import { ReactComponent as XCircle } from './assets/icons/x-circle.svg';
import { ReactComponent as CheckCircle } from './assets/icons/check-circle.svg';
import { ReactComponent as PlayButton } from './assets/icons/play_button.svg';
import { ReactComponent as PauseButton } from './assets/icons/pause-button.svg';
import { ReactComponent as Card } from './assets/icons/card-icon.svg';
import { ReactComponent as Help } from './assets/icons/help-circle.svg';
import { ReactComponent as CheckRounded } from './assets/icons/check-rounded.svg';
import { ReactComponent as XIcon } from './assets/icons/x-icon.svg';

/** Flags */
import deFlag from './assets/images/flags/de.png';
import atFlag from './assets/images/flags/at.png';
import chFlag from './assets/images/flags/ch.png';
import nlFlag from './assets/images/flags/nl.png';
import bgFlag from './assets/images/flags/bg.png';
import beFlag from './assets/images/flags/be.png';
import frFlag from './assets/images/flags/fr.png';
import liFlag from './assets/images/flags/li.png';
import cyFlag from './assets/images/flags/cy.png';
import czFlag from './assets/images/flags/cz.png';
import dkFlag from './assets/images/flags/dk.png';
import eeFlag from './assets/images/flags/ee.png';
import esFlag from './assets/images/flags/es.png';
import euFlag from './assets/images/flags/eu.png';
import fiFlag from './assets/images/flags/fi.png';
import grFlag from './assets/images/flags/gr.png';
import hrFlag from './assets/images/flags/hr.png';
import huFlag from './assets/images/flags/hu.png';
import ieFlag from './assets/images/flags/ie.png';
import itFlag from './assets/images/flags/it.png';
import ltFlag from './assets/images/flags/lt.png';
import luFlag from './assets/images/flags/lu.png';
import lvFlag from './assets/images/flags/lv.png';
import mkFlag from './assets/images/flags/mk.png';
import mtFlag from './assets/images/flags/mt.png';
import plFlag from './assets/images/flags/pl.png';
import ptFlag from './assets/images/flags/pt.png';
import roFlag from './assets/images/flags/ro.png';
import seFlag from './assets/images/flags/se.png';
import siFlag from './assets/images/flags/si.png';
import skFlag from './assets/images/flags/sk.png';
import usFlag from './assets/images/flags/usa.png';
//import smFlag from './assets/icons/flags/sm.png';

export default {
    Account,
    AccountFilled,
    ArrowDown,
    ArrowLeft,
    ArrowRight,
    ArrowRightLight,
    ArrowUp,
    Attention,
    Card,
    Cart,
    Check,
    CheckCircle,
    CheckRounded,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
    Close,
    Deliver,
    Delivery,
    EyeHide,
    EyeShow,
    Facebook,
    HalfStar,
    Help,
    Home,
    Info,
    ListBackground,
    Menu,
    Pencil,
    Play,
    PlayButton,
    PauseButton,
    Search,
    Share,
    Star,
    Trash,
    Twitter,
    WhatsApp,
    XCircle,
    XIcon,
    /* Flags */
    atFlag,
    beFlag,
    bgFlag,
    chFlag,
    cyFlag,
    czFlag,
    deFlag,
    dkFlag,
    eeFlag,
    esFlag,
    euFlag,
    fiFlag,
    frFlag,
    grFlag,
    hrFlag,
    huFlag,
    ieFlag,
    itFlag,
    liFlag,
    ltFlag,
    luFlag,
    lvFlag,
    mkFlag,
    mtFlag,
    nlFlag,
    plFlag,
    ptFlag,
    roFlag,
    seFlag,
    siFlag,
    skFlag,
    usFlag,
    worldFlag,
};
