import BadgeNew from '@mediashop/catalog-base/pattern/atom/Badge';
import { BadgeObject } from '@mediashop/catalog-base/types/badges';

type WkoUpsellProductBadgesProps = {
    topBadges: BadgeObject[];
    componentName: string;
};

export default function WkoUpsellProductBadges({ topBadges, componentName }: WkoUpsellProductBadgesProps): JSX.Element {
    return (
        <div className={`${componentName}__badge-wrapper`}>
            <div className={`${componentName}__badge-container ${componentName}__badge-container--top`}>
                {topBadges
                    .filter((badge) => badge.type !== 'XPLUSYGRATISTOP')
                    .map((badge) => (
                        <BadgeNew {...badge.badgeProps} key={badge.badgeProps.text} />
                    ))}
            </div>
        </div>
    );
}
