import * as React from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { useIntl } from 'react-intl';
import { BaseProps } from '@mediashop/app/bloomreach/types';

type Props = BaseProps & {
    style?: string;
    index: number;
    isActive?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    backgroundColor?: string;
};

/**
 * Simple navigation for your slider. Or anything you want.
 * Provide the indexes which should be marked as active via `activeIndices`.
 */
function SliderNavigationItem({
    index,
    style = 'dot',
    backgroundColor = 'default',
    isActive,
    className = '',
    onClick,
}: Props) {
    const intl = useIntl();

    return (
        <button
            key={index}
            tabIndex={index === 0 ? 0 : -1}
            type="button"
            aria-label={
                isActive
                    ? intl.formatMessage({ id: 'slider.activePagination' })
                    : intl.formatMessage({ id: 'slider.inactivePagination' })
            }
            onClick={(event) => onClick && onClick(event)}
            className={classNames(
                className,
                'slider-navigation-item',
                `slider-navigation-item--${style}`,
                `slider-navigation-item--bg-${backgroundColor}`,
                { 'slider-navigation-item--active': isActive }
            )}
        />
    );
}

export default injectComponent('pattern.molecule.SliderNavigationItem', SliderNavigationItem);
