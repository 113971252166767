import { injectComponent } from '@mediashop/app/component-injector';
import { lazy, ReactElement } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const InfoBoxLazy = lazy(() => lazyRetry(() => import('./InfoBox')));

export type InfoBoxProps = BaseProps & {
    text: string;
};

const InfoBox = (props: InfoBoxProps): ReactElement => (
    <LazyComponent>
        <InfoBoxLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.InfoBox', InfoBox);
