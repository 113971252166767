import React from 'react';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';

type ShowMoreButtonProps = BasePropsWithChildren & {
    onClick: () => void;
};

const ShowMoreButton = React.forwardRef<HTMLButtonElement, ShowMoreButtonProps>(({ children, onClick }, ref) => {
    const componentName = 'show-more-button';

    return (
        <button className={`${componentName}`} onClick={onClick} ref={ref}>
            {children}
        </button>
    );
});

ShowMoreButton.displayName = 'ShowMoreButton';

export default ShowMoreButton;
