import { BaseProps } from '@mediashop/app/bloomreach/types';
import Icon from '@mediashop/base/pattern/atom/Icon';

const componentName = 'review-stars';

const allStars = [1, 2, 3, 4, 5];

type Props = BaseProps & {
    allStarsProduct?: number;
    starId?: string;
};

function Stars({ starId = '', allStarsProduct }: Props): JSX.Element {
    const all = allStarsProduct ?? 0;
    const starIds = starId ?? 0;
    return (
        <div className={componentName}>
            {allStars?.map((stars, index) => (
                <Icon
                    name="Star"
                    key={index + starIds}
                    className={stars <= all ? `${componentName}__stars` : `${componentName}__stars-normal`}
                />
            ))}
        </div>
    );
}

export default Stars;
