import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { PaymentMethodName } from '@mediashop/app/domain/Payment';

const PaymentMethodErrorLazy = lazy(() => lazyRetry(() => import('./PaymentMethodError')));

export type PaymentMethodErrorProps = BaseProps & {
    unavailablePaymentMethodCodes: PaymentMethodName[];
};

const PaymentMethodError = (props: PaymentMethodErrorProps): JSX.Element => (
    <LazyComponent>
        <PaymentMethodErrorLazy {...props} />
    </LazyComponent>
);

export default PaymentMethodError;
