import { lazy } from 'react';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';

const CheckoutSuccessSectionLazy = lazy(() => lazyRetry(() => import('./CheckoutSuccessSection')));

export type CheckoutSuccessSectionProps = BasePropsWithChildren & {
    headline: string;
    feedbackText?: string;
    showFeedback?: boolean;
};

const CheckoutSuccessSection = (props: CheckoutSuccessSectionProps): JSX.Element => (
    <LazyComponent>
        <CheckoutSuccessSectionLazy {...props} />
    </LazyComponent>
);

export default CheckoutSuccessSection;
