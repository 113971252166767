import { AddressConfiguration, SelectField } from '../../api/types/AddressConfiguration';

const forms = ['billing', 'billingOnlyAddress', 'shipping', 'registration', 'registrationWithoutEmail', 'personalinfo'];

export const addShippingCountriesToAdresses = (
    addressesConfig: AddressConfiguration,
    shippingCountries: string[]
): AddressConfiguration => {
    const shippingCountriesData = shippingCountries.map((country) => ({
        labelId: `country.${country}`,
        value: country,
    }));

    return Object.entries(addressesConfig).reduce((configuration, [key, value]) => {
        if (forms.includes(key)) {
            value.fieldsets.forEach((fieldset) => {
                const countrySelect = fieldset.fields.find(
                    (field) => field.name.includes('country') && field.type === 'select'
                ) as SelectField;

                if (countrySelect) {
                    countrySelect.values = shippingCountriesData;
                }
            });
        }

        return { ...configuration, [key]: value };
    }, {} as AddressConfiguration);
};
