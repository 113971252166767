import { useContext } from 'react';
import { BloomreachContext } from '@mediashop/app/bloomreach/bloomify';

/**
 * Determine whether we are currently in the preview of bloomreach.
 */
export default function useIsPreview(): boolean {
    const brContext = useContext(BloomreachContext);
    return brContext.page.isPreview();
}
