import { Variant, VariantAsset } from '@mediashop/app/api/types/ClientProduct';
import { DetailedProduct } from '@mediashop/app/api/types/ClientProductDetailed';
import { injectComponent } from '@mediashop/app/component-injector';
import { lazy } from 'react';
import { BaseProps, BrxRelationType } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const StickyAddToCartBannerLazy = lazy(() => lazyRetry(() => import('./StickyAddToCartBanner')));

export type StickyAddToCartBannerProps = BaseProps & {
    addToCartErrorMessage?: string;
    dontShowPreCheckoutFlyout: boolean;
    handleAddToCartClick: (quantity: number) => Promise<void>;
    isInProgress: boolean;
    product?: DetailedProduct;
    productRelationType?: BrxRelationType;
    variant: Variant;
    variantImage: VariantAsset;
};

const StickyAddToCartBanner = (props: StickyAddToCartBannerProps): JSX.Element => (
    <LazyComponent>
        <StickyAddToCartBannerLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.StickyAddToCartBanner', StickyAddToCartBanner, 'catalog-base');
