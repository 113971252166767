import classNames from 'classnames';
import Badge from '@mediashop/catalog-base/pattern/atom/Badge';
import CroppedImage from '@mediashop/base/pattern/atom/CroppedImage';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';
import { ProductWithActiveVariant } from '@mediashop/app/api/types/ClientProduct';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { useChannelSettings } from '@mediashop/app/bloomreach/useChannelSettings';
import { BadgeObject } from '../../../../types/badges';

const componentName = 'product-image';
const imageContainer = `${componentName}__image-container`;
const badgeWrapper = `${componentName}__badge-wrapper`;
const badgeContainer = `${componentName}__badge-container`;

const selectProductImage = (product: ProductWithActiveVariant, property: 'imageUri' | 'secondaryImageUri'): string => {
    if (product.activeVariant?.price && product.activeVariant?.[property]) {
        return product.activeVariant[property]!;
    }

    const activeVariants = product.variants.filter((variant) => variant.price);
    const hasMultipleVariants = product.variants.length > 1;
    const hasOnlyOneActiveVariant = activeVariants.length === 1;

    if (hasMultipleVariants && hasOnlyOneActiveVariant && activeVariants[0][property]) {
        return activeVariants[0][property] ?? product[property];
    }

    return product[property];
};

type ProductImageProps = BaseProps & {
    imageVariant?: CroppedImageVariant;
    product: ProductWithActiveVariant;
    roundedImages?: boolean;
    topBadges: BadgeObject[];
    bottomBadges: BadgeObject[];
};

function ProductImage({
    imageVariant,
    product,
    roundedImages,
    topBadges,
    bottomBadges,
}: ProductImageProps): JSX.Element {
    const { productTileEffect } = useChannelSettings();
    return (
        <div
            className={classNames(componentName, {
                [`${componentName}--hover-effect-zoom`]: productTileEffect === 'Zoom',
                [`${componentName}--hover-effect-flip`]:
                    productTileEffect === 'Flip' && Boolean(selectProductImage(product, 'secondaryImageUri')),
            })}
        >
            <div>
                <div
                    className={classNames(imageContainer, {
                        [`${imageContainer}--hover-effect-flip`]: productTileEffect === 'Flip',
                        [`${imageContainer}__image-rounded`]: roundedImages,
                    })}
                >
                    {product && (
                        <>
                            <CroppedImage
                                altText={product.activeVariant?.attributes.name ?? product?.name}
                                className={`${imageContainer}__image ${imageContainer}__image--initial-opacity-1`}
                                src={selectProductImage(product, 'imageUri')}
                                variant={imageVariant ?? CroppedImageVariant.ProductDefault}
                            />

                            <CroppedImage
                                altText={product.activeVariant?.attributes.name ?? product?.name}
                                className={`${imageContainer}__image ${imageContainer}__image--initial-opacity-0`}
                                src={selectProductImage(product, 'secondaryImageUri')}
                                variant={imageVariant ?? CroppedImageVariant.ProductDefault}
                            />
                        </>
                    )}
                </div>
                <div className={badgeWrapper}>
                    <div className={`${badgeContainer} ${badgeContainer}--top`}>
                        {topBadges
                            .filter((badge) => badge.type !== 'XPLUSYGRATISTOP')
                            .map((badge) => (
                                <Badge {...badge.badgeProps} key={badge.badgeProps.text} isTopBadge />
                            ))}
                    </div>
                    <div className={`${badgeContainer} ${badgeContainer}--bottom`}>
                        {bottomBadges.map((badge) => {
                            let badgeProps = { ...badge.badgeProps };
                            if (badge.type === 'GRATISZUBEHOER') {
                                badgeProps = {
                                    ...badgeProps,
                                    size: 'small',
                                    text: badge.badgeProps.text,
                                };
                            }
                            return <Badge {...badgeProps} key={badge.badgeProps.text} />;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductImage;
