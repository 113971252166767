import { lazy } from 'react';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const AvailableContentSpaceLazy = lazy(() => lazyRetry(() => import('./AvailableContentSpace')));

export type AvailableContentSpaceProps = BasePropsWithChildren & {
    contentSpace: string;
};

const AvailableContentSpace = (props: AvailableContentSpaceProps): JSX.Element => (
    <LazyComponent>
        <AvailableContentSpaceLazy {...props} />
    </LazyComponent>
);

export default AvailableContentSpace;
