import { useMutation } from '@tanstack/react-query';
import noop from 'lodash/noop';
import { useCartApiClient } from '../../api/hooks/useCartApiClient';
import { useAppDispatch } from '../../store/hooks/hooks';
import { cartReceived } from '../../store/reducer/cart';
import { Cart } from '../../api/types/ClientCart';

type UseSetAddressExpressCheckoutProps = {
    onSuccess?: () => void;
};

/**
 * Hook that calls the SetAddressExpressCheckout api to update cart with express checkout customer
 * @returns useMutation result
 */
export const useSetAddressExpressCheckout = ({ onSuccess = noop }: UseSetAddressExpressCheckoutProps = {}) => {
    const dispatch = useAppDispatch();
    const apiClient = useCartApiClient();

    const setAddress = () => apiClient.setAddressExpressCheckout();

    const onSuccessInternal = (cart: Cart) => {
        dispatch(cartReceived({ cart }));

        onSuccess();
    };

    return useMutation({
        mutationFn: setAddress,
        onSuccess: onSuccessInternal,
    });
};
