import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { createRef, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';

export type BadgeSizeProps = 'small' | 'medium' | 'large' | 'xlarge';

export interface BadgeProps {
    text?: string;
    textElement?: ReactNode;
    size?: BadgeSizeProps;
    style?: string;
    cornerPosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
    isTopBadge?: boolean;
}

type Props = BaseProps & BadgeProps;

/**
 * Display a badge for PLP and PDP.
 * Multiple sizes and colors available.
 */
function Badge({
    text = '',
    textElement,
    size = 'medium',
    style = 'topseller',
    cornerPosition = 'bottom-right',
    isTopBadge = false,
}: Props) {
    const componentName = 'catalog-badge';
    const device = useDeviceType();
    const [divWidth, setDivWidth] = useState(0);
    const spanRef = createRef<HTMLSpanElement>();

    useEffect(() => {
        if (spanRef.current) {
            const padding = 22;
            if (isTopBadge) {
                setDivWidth(spanRef.current.offsetWidth + padding);
            }

            const { width, height } = spanRef.current.getBoundingClientRect();

            if (height > 18 && size === 'small') {
                setDivWidth(width + padding);
            }
        }
    }, [device, spanRef, size]);

    return (
        <div
            className={classNames(
                componentName,
                `${componentName}--size-${size}`,
                `${componentName}--${style}`,
                `${componentName}--corner-${cornerPosition}`
            )}
            style={divWidth ? { width: `${divWidth}px` } : { display: 'inline' }}
        >
            <span ref={spanRef}>{textElement ?? text}</span>
        </div>
    );
}

export default injectComponent('pattern.atom.Badge', Badge, 'catalog-base');
