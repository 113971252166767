import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CardLinkLazy = lazy(() => lazyRetry(() => import('./CardLink')));

export type CardLinkingProps = BaseProps;

const CardLinking = (props: CardLinkingProps): JSX.Element => (
    <LazyComponent>
        <CardLinkLazy {...props} />
    </LazyComponent>
);

export default CardLinking;
