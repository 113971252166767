import { ExchangeRatesSubset } from '../../../types/exchangeRates';

export const getExchangeRate = (
    currency?: string,
    exchangeRates?: ExchangeRatesSubset,
    emarsysAccountCurrency: string | undefined = 'EUR'
): number => {
    if (
        exchangeRates &&
        currency &&
        Object.keys(exchangeRates).includes(currency) &&
        currency !== emarsysAccountCurrency
    ) {
        const exchangeCurrency = emarsysAccountCurrency === 'EUR' ? currency : emarsysAccountCurrency;
        const exchangeRate = exchangeRates[exchangeCurrency];

        // Invert exchange rate if target is not EUR (as we just get exchanges rates from EUR)
        return emarsysAccountCurrency === 'EUR' ? exchangeRate : 1 / exchangeRate;
    }

    return 1;
};
