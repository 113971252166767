import { useState } from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import Headlines from '@mediashop/base/pattern/atom/Headlines';
import Slider from '@mediashop/base/pattern/molecule/Slider';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import SwiperCore from 'swiper';
import { SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import HeroTeaser, { HeroTeaserProps } from '../HeroTeaser';
import { BackgroundProps, BaseProps, BrxAnchor } from '@mediashop/app/bloomreach/types';
import { LinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';

type HeroTeaserSliderItem = {
    headlineAtTheRightSide: string;
    textAtTheRightSide: string;
    heroTeaser: HeroTeaserProps;
};

type HeroTeaserSliderProps = BaseProps & {
    anchor?: BrxAnchor;
    backgroundColor: BackgroundProps;
    sliderItemFirst: HeroTeaserSliderItem;
    sliderItemList: HeroTeaserSliderItem[];
    textRightSide: {
        text: string;
        link: string;
    };
    displayTime: number;
};

/**
 * Component with a background of photo including title information and checkout button.
 * Different design for mobile and desktop base on the slider for pictures
 */
function HeroTeaserSlider({
    anchor,
    backgroundColor,
    sliderItemList,
    textRightSide,
    displayTime,
}: HeroTeaserSliderProps) {
    const componentName = 'hero-teaser-slider';

    const [heroSlider, setHeroSlider] = useState<SwiperCore>();
    const [slideToIndex, setSlideToIndex] = useState<number>(0);

    const displayTimeInMilliseconds = displayTime > 0 ? displayTime * 1000 : 5000;

    const handleSliderChange = (swiper: SwiperClass) => {
        if (!swiper.destroyed) {
            setSlideToIndex(swiper.realIndex);
        }
    };

    const handlePaginationClick = (index: number) => {
        setSlideToIndex(index);
        heroSlider?.slideToLoop(index);
    };

    return (
        <BackgroundColorWrapper backgroundColor={backgroundColor}>
            <ContentWrapper id={anchor?.id}>
                <div
                    className={classNames(
                        componentName,
                        `background--${backgroundColor}`,
                        `${componentName}--image-position-left`
                    )}
                >
                    <div className={`${componentName}__left-slider`}>
                        <Slider
                            slidesPerView={1}
                            loop
                            className={`${componentName}__left-slider`}
                            showArrows={false}
                            showPagination={false}
                            autoplay={{
                                delay: displayTimeInMilliseconds,
                            }}
                            onSwiper={setHeroSlider}
                            onSlideChange={handleSliderChange}
                            isPlaying
                        >
                            {sliderItemList.map((item, key) => (
                                <SwiperSlide key={`${componentName}-${key}`}>
                                    <HeroTeaser
                                        desktopImage={item.heroTeaser?.desktopImage}
                                        mobileImage={item.heroTeaser?.mobileImage}
                                        ctaBox={item.heroTeaser?.ctaBox}
                                    />
                                </SwiperSlide>
                            ))}
                        </Slider>
                    </div>
                    <div className={`${componentName}__right-items`}>
                        {sliderItemList.map((item, index) => (
                            <div
                                key={index}
                                className={classNames(
                                    `${componentName}__text-content-container`,
                                    slideToIndex === index ? `${componentName}__item-active` : ''
                                )}
                            >
                                <div
                                    className={`${componentName}__text-wrapper`}
                                    onClick={() => handlePaginationClick(index)}
                                >
                                    {item?.headlineAtTheRightSide && (
                                        <Headlines
                                            textStyle="normalCase"
                                            headlineStyle="h3"
                                            headline={item?.headlineAtTheRightSide}
                                            subheadline={item?.textAtTheRightSide}
                                        />
                                    )}
                                    <LinkOpenInTab
                                        link={item.heroTeaser?.ctaBox.buttonLink}
                                        tab="sameTab"
                                        className={`${componentName}__link`}
                                    >
                                        {item.heroTeaser?.ctaBox.buttonText}
                                    </LinkOpenInTab>
                                </div>
                            </div>
                        ))}
                        {textRightSide && (
                            <div className={`${componentName}__text-content-container`}>
                                <div className={`${componentName}__text-wrapper`}>
                                    <LinkOpenInTab
                                        link={textRightSide.link}
                                        tab="sameTab"
                                        className={`${componentName}__link`}
                                    >
                                        {textRightSide.text}
                                    </LinkOpenInTab>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.HeroTeaserSlider', HeroTeaserSlider);
