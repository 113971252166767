import { Location } from 'react-router-dom';
import { MainNavigationVerticalBrandNavigationItems } from '.';

export const markChildItemActive = (
    extendedNavigation: MainNavigationVerticalBrandNavigationItems[],
    childItem: MainNavigationVerticalBrandNavigationItems,
    cleanedPathname: string,
    location: Location
) => {
    const foundChildItems = extendedNavigation.find((item) =>
        item.childItems.some((child) => child.link === childItem.link)
    )?.childItems;

    let currentHeadline: MainNavigationVerticalBrandNavigationItems | undefined;
    const isMatchUnderHeadline = foundChildItems?.some((item) => {
        if (item.isHeadline) {
            currentHeadline = item;
        }
        return item.link === childItem.link && Boolean(currentHeadline);
    });

    if (isMatchUnderHeadline) {
        return `${cleanedPathname}${location.hash}` === childItem.link;
    }

    return !location.hash && cleanedPathname === childItem.link?.split('#')[0];
};
