import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';

export type ProductAttributeValueProps = BaseProps & {
    value: boolean | number | string | ArrayLike<unknown>;
};

import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ProductAttributeValueMolecule = lazy(() => lazyRetry(() => import('./ProductAttributeValue')));

const ProductAttributeValue = (props: ProductAttributeValueProps): JSX.Element => (
    <LazyComponent>
        <ProductAttributeValueMolecule {...props} />
    </LazyComponent>
);

export default ProductAttributeValue;
