import { Helmet } from 'react-helmet-async';
import { SKIP_RENDER } from '../../constants/semanticConstants';

interface GoogleSiteVerificationProps {
    content?: string;
}

/**
 * Include google site verification tag
 * @returns JSX.Element | null
 */
export default function GoogleSiteVerification({ content }: GoogleSiteVerificationProps) {
    return content ? (
        <Helmet>
            <meta name="google-site-verification" content={content} />
        </Helmet>
    ) : (
        SKIP_RENDER
    );
}
