import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy } from 'react';
import { OptionItemBaseProps } from './OptionItem';

const OptionItemLazy = lazy(() => lazyRetry(() => import('./OptionItem')));

const OptionItem = (props: OptionItemBaseProps): JSX.Element => (
    <LazyComponent>
        <OptionItemLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.OptionSelector.OptionItem', OptionItem);
