import { Category } from '../../api/types/ClientCategory';
import { Product } from '../../api/types/ClientProduct';
import { EMPTY_STRING } from '../../constants/semanticConstants';
import { mapShippingCountryToKnowCategoryCountries } from '../../helper/mapCategoryCountry';
import { ValueOf } from '../../helper/utility';
import { StoreProducts } from '../../store/reducer/product';
import { PageTypes } from './pageTypeMapper';

type NameProperty = 'name' | 'emarsysName';

type CategoryIndex = Record<string, { name: string; parent: string; level: number; id: string }>;

function buildCategoryIndex(
    parent: Category,
    children: Category[],
    level = 0,
    nameProperty: NameProperty = 'name'
): CategoryIndex {
    let index: CategoryIndex = {};

    for (const child of children) {
        index[child.id] = { name: child[nameProperty] ?? child.name, parent: parent.id, level, id: child.id };
        const subIndex = buildCategoryIndex(child, child?.children ?? [], level + 1, nameProperty);

        index = { ...index, ...subIndex };
    }
    return index;
}

export const getProductCategory = (
    mainCategory: Category | undefined,
    product: Product,
    shippingCountry: string
): { productCategory?: string; productSubcategory?: string } => {
    if (mainCategory) {
        const childrenPerSelectedCountry = mainCategory.children.filter(
            (cat) => cat.name === mapShippingCountryToKnowCategoryCountries(shippingCountry)
        );
        const categoryIndex = buildCategoryIndex(mainCategory, childrenPerSelectedCountry);

        // Get first category that is included in category tree and has a name
        const applicableCategory = product.categories
            .filter((cat) => Object.keys(categoryIndex).includes(cat.id))
            .find((cat) => cat.name);

        if (applicableCategory) {
            if (categoryIndex[applicableCategory.id]?.level === 1) {
                return { productCategory: applicableCategory.name };
            } else if (categoryIndex[applicableCategory.id]?.level === 2) {
                return {
                    productCategory: categoryIndex[categoryIndex[applicableCategory.id].parent].name,
                    productSubcategory: applicableCategory.name,
                };
            }
        }
    }

    return { productCategory: undefined };
};

export const getProductCategories = (
    mainCategory: Category | undefined,
    product: Product,
    shippingCountry: string
): string[][] => {
    if (!mainCategory) {
        return [];
    }

    const childrenPerSelectedCountry = mainCategory.children.filter(
        (cat) => cat.name === mapShippingCountryToKnowCategoryCountries(shippingCountry)
    );
    const categoryIndex = buildCategoryIndex(mainCategory, childrenPerSelectedCountry);

    // Get all categories that are included in category tree and has a name
    const applicableCategories = product.categories
        .filter((cat) => Object.keys(categoryIndex).includes(cat.id))
        .filter((cat) => cat.name);
    const applicableCategoryIds = applicableCategories.map((cat) => cat.id);

    let productCategories = applicableCategories.filter((cat) => categoryIndex[cat.id]?.level === 1);
    const productSubcategories = applicableCategories.filter((cat) => categoryIndex[cat.id]?.level === 2);

    const filteredSubcategories = productSubcategories.map((subcategory) => {
        const { id, name, parent } = categoryIndex[subcategory.id];

        // We don´t want to return the parent category when the product is also in a subcategory
        if (productCategories.find((category) => category.id === parent)) {
            productCategories = productCategories.filter((category) => category.id !== parent);
        }

        return {
            id,
            category: categoryIndex[parent].name,
            subcategory: name,
        };
    });

    const allCategories = [
        ...productCategories.map(({ id }) => ({ id, category: categoryIndex[id].name, subcategory: undefined })),
        ...filteredSubcategories,
    ].sort(
        // eslint-disable-next-line id-length
        (a, b) => applicableCategoryIds.indexOf(a.id) - applicableCategoryIds.indexOf(b.id)
    );

    return allCategories.map((category) =>
        [category.category, category.subcategory].filter((cat): cat is string => Boolean(cat))
    );
};

export const getCurrentCategoryForAnalytics = (
    mainCategory: Category,
    selectedCategoryId: string,
    nameProperty: NameProperty = 'name'
): { category?: string; subcategory?: string } => {
    const categoryIndex = buildCategoryIndex(mainCategory, mainCategory?.children, 0, nameProperty);

    if (categoryIndex[selectedCategoryId]?.level === 1) {
        return { category: categoryIndex[selectedCategoryId].name };
    } else if (categoryIndex[selectedCategoryId]?.level === 2) {
        return {
            category: categoryIndex[categoryIndex[selectedCategoryId].parent].name,
            subcategory: categoryIndex[selectedCategoryId].name,
        };
    }

    return { category: undefined };
};

export const getProductCategoryAndSubcategoryForDataLayer = ({
    country,
    mainCategory,
    pageType,
    pathName,
    products,
    selectedCategoryId,
}: {
    country: string;
    mainCategory?: Category;
    pageType: ValueOf<typeof PageTypes>;
    pathName: string;
    products: StoreProducts;
    selectedCategoryId: string;
}): {
    productCategory: string;
    productSubcategory: string;
} => {
    let productCategory: string | undefined;
    let productSubcategory: string | undefined;

    if (pageType === PageTypes.CATEGORY) {
        if (mainCategory && selectedCategoryId) {
            ({ category: productCategory, subcategory: productSubcategory } = getCurrentCategoryForAnalytics(
                mainCategory,
                selectedCategoryId
            ));
        }
    } else if (pageType === PageTypes.PRODUCT) {
        const slug = pathName.replace(/\/$/, '').split('/').pop();

        const storeProduct = Object.values(products).find((product) => product?.product?.slug === slug);

        if (mainCategory && storeProduct?.product) {
            ({ productCategory, productSubcategory } = getProductCategory(mainCategory, storeProduct.product, country));
        }
    }

    // The dataLayer either expects an empty string or null, but never undefined
    return {
        productCategory: productCategory ?? EMPTY_STRING,
        productSubcategory: productSubcategory ?? EMPTY_STRING,
    };
};
