import { useQuery } from '@tanstack/react-query';
import { Variant } from '../../api/types/ClientProduct';
import { NeededPriceType } from '../../api/types/PriceType';
import { QueryKeys } from '../../constants/queryKeys';
import { useLoader } from '../../store/loader';
import { useShopContext } from '../useShopContext';

const NEEDED_PRICE_TYPE_UPSELL: NeededPriceType = 'UPSELL';

/**
 * Fetch cheaper-set- (upsell-) price for the given variant.
 * @returns cheaper-set price
 */
export const useCheaperSetPrice = ({ variant }: { variant?: Variant }) => {
    const { productClient } = useLoader();
    const { country } = useShopContext();

    const fetchCheaperSetPrice = async (sku: string) => {
        const { actualPrice, originalListPrice } = await productClient.getProductPrices(sku, NEEDED_PRICE_TYPE_UPSELL);
        return { actualPrice, originalListPrice };
    };

    const variantSku = variant?.sku || '';

    const { data, ...props } = useQuery({
        queryKey: [QueryKeys.FETCH_CHEAPER_SET_PRICE, variantSku, country],
        queryFn: () => fetchCheaperSetPrice(variantSku),
        enabled: variant?.mightHaveCheaperSets || false,
    });

    const { actualPrice, originalListPrice } = data ?? {};

    const isValidCheaperSetPrice =
        (actualPrice?.centAmount && originalListPrice?.centAmount === undefined) ||
        (actualPrice?.centAmount &&
            originalListPrice?.centAmount &&
            actualPrice?.centAmount < originalListPrice?.centAmount);

    return {
        cheaperSetPrice: isValidCheaperSetPrice ? actualPrice : undefined,
        ...props,
    };
};
