import { useCallback } from 'react';
import analytics from '@mediashop/app/analytics';
import { useCategory } from '../../hooks/useCategory';
import { useProduct } from '../../hooks/useProduct';
import { useCategories } from '../../queries/categories/useCategories';
import { useShopContext } from '../../hooks/useShopContext';
import { useCart } from '../../hooks/useCart';

/**
 * Hook that handles analytics event when the page changes
 * @returns A function that can be used to trigger the analytics event
 */
export const usePageViewed = () => {
    const { cart } = useCart();
    const { data: categories = [] } = useCategories();
    const { country } = useShopContext();
    const { products } = useProduct();
    const { selectedCategoryId } = useCategory();

    const dispatchPageViewed = useCallback(
        ({ pageTitle, pageType, pageUri }) =>
            analytics.dispatchPageViewed({
                cart,
                country,
                mainCategory: categories[0],
                pageUri,
                pageTitle,
                pageType,
                products,
                selectedCategoryId,
            }),
        [cart, categories, country, products, selectedCategoryId]
    );

    return dispatchPageViewed;
};
