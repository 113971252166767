import { ReactNode, lazy, type MouseEvent as ReactMouseEvent, type KeyboardEvent } from 'react';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ModalMolecule = lazy(() => lazyRetry(() => import('./Modal')));

export type ModalProps = BasePropsWithChildren & {
    isOpen: boolean;
    footer?: ReactNode;
    onRequestClose?: (event: ReactMouseEvent<Element, MouseEvent> | KeyboardEvent<Element>) => void;
    onAfterOpen?: () => void;
    onAfterClose?: () => void;
    showCloseButton?: boolean;
    background?: 'white' | 'white-true';
    bodyOpenClassName?: string;
    overlayClassName?: string;
    widthClassName?: string;
    modalBodyClassName?: string;
};

const Modal = (props: ModalProps): JSX.Element => (
    <LazyComponent>
        <ModalMolecule {...props} />
    </LazyComponent>
);

export default Modal;
