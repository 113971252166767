import { useAppSelector } from '../store/hooks/hooks';
import { PartnerState } from '../store/reducer/partner';
import { shallowEqual } from 'react-redux';

/**
 * Hook that returns partner data from store.
 * @returns partner
 */
export const usePartner = (equalityCheck = false): PartnerState => {
    return useAppSelector((state) => state.partner, {
        equalityFn: equalityCheck ? shallowEqual : undefined,
    });
};
