import { lazy, RefObject } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { RegistrationForm } from '@mediashop/app/api/types/Address';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const LoginMolecule = lazy(() => lazyRetry(() => import('./Login')));

export type LoginSubmitHandle = {
    submit: () => void;
};

export type LoginProps = BaseProps & {
    refId: RefObject<LoginSubmitHandle>;
    defaultValues?: Record<string, any>;
    selectedBillingCountry: string;
    validAddress: boolean;
    onChange: ({ values, validated }: { values: RegistrationForm; validated: boolean }) => void;
};

const Login = (props: LoginProps) => (
    <LazyComponent>
        <LoginMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.Login', Login);
