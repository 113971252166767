import { RefObject, useMemo } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { RegistrationForm } from '@mediashop/app/api/types/Address';
import DynamicFormik from '@mediashop/base/pattern/molecule/DynamicFormik';
import { FormattedMessage } from 'react-intl';
import { useAddressConfig } from '@mediashop/app/queries/addresses/useAddressConfig';

const componentName = 'register-addresses';

export type RegisterFormSubmitHandle = {
    submit: () => void;
};

type RegisterAddressesProps = BaseProps & {
    refId: RefObject<RegisterFormSubmitHandle>;
    defaultValues?: Record<string, any>;
    selectedBillingCountry: string;
    validAddress: boolean;
    onChange: ({ values, validated }: { values: RegistrationForm; validated: boolean }) => void;
};

function RegisterAddresses({
    className,
    refId,
    defaultValues,
    selectedBillingCountry,
    onChange,
}: RegisterAddressesProps) {
    const addressConfig = useAddressConfig(selectedBillingCountry);

    const memoizedForm = useMemo(
        () => (
            <DynamicFormik
                refId={refId}
                defaultValues={defaultValues}
                formFieldSets={addressConfig?.registration?.fieldsets}
                errorFocus
                onChange={onChange}
            />
        ),
        [addressConfig]
    );

    return (
        <div className={`${componentName} registration__panel ${className}`}>
            <div className={`${componentName}__hint`}>
                <FormattedMessage id="address.requiredField" />
            </div>
            {memoizedForm}
        </div>
    );
}

export default injectComponent('pattern.molecule.Register.RegisterAddresses', RegisterAddresses);
