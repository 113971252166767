import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import {
    BackgroundProps,
    BaseProps,
    BrxCTAButton,
    BrxHeadlines,
    BrxImageWithAltText,
} from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const BackgroundImageWithTextMolecule = lazy(() => lazyRetry(() => import('./BackgroundImageWithText')));

export type BackgroundImageWithTextProps = BaseProps & {
    headlines: BrxHeadlines;
    image: BrxImageWithAltText;
    mobileImage?: BrxImageWithAltText;
    background?: BackgroundProps;
    callToActionButton?: BrxCTAButton;
    ctaColorHex?: string;
    fontColorHex?: string;
};

const BackgroundImageWithText = (props: BackgroundImageWithTextProps): JSX.Element => (
    <LazyComponent>
        <BackgroundImageWithTextMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.BackgroundImageWithText', BackgroundImageWithText);
