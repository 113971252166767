import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BackgroundProps, BaseProps, BrxImageWithAltText, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const TextWithImageMolecule = lazy(() => lazyRetry(() => import('./TextWithImage')));

export type TextWithImageProps = BaseProps & {
    background: BackgroundProps;
    backgroundEven: BackgroundProps;
    backgroundOdd: BackgroundProps;
    headlines: BrxHeadlines;
    imageWithTitleAndContent: {
        headlines: BrxHeadlines;
        content: string;
        badge: string;
        imageWithAltText: BrxImageWithAltText;
    }[];
};

const TextWithImage = (props: TextWithImageProps): JSX.Element => (
    <LazyComponent>
        <TextWithImageMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.TextWithImage', TextWithImage);
