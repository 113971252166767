import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import Headlines from '../../atom/Headlines';
import AccordionItem from './AccordionItem';
import { BackgroundProps, BaseProps, BrxAnchor, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import ContentWrapper from '../../atom/ContentWrapper';
import BackgroundColorWrapper from '../../atom/BackgroundColorWrapper';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import AvailableContentSpace from '@mediashop/catalog-base/pattern/molecule/AvailableContentSpace';

export type AccordionProps = BaseProps & {
    anchor?: BrxAnchor;
    items: Array<any>;
    background: BackgroundProps;
    backgroundTitle: BackgroundProps;
    backgroundContent: BackgroundProps;
    headlines: BrxHeadlines;
    separatorColorHex?: string;
    itemHeadlineFontColorHex?: string;
    itemSubheadlineFontColorHex?: string;
    itemHeadlineStyle?: string;
    availableContentSpace: string;
};

function Accordion({
    anchor,
    items,
    background = 'primary',
    backgroundTitle = 'primary',
    backgroundContent = 'primary',
    headlines,
    separatorColorHex,
    itemHeadlineFontColorHex,
    itemSubheadlineFontColorHex,
    itemHeadlineStyle = 'h3',
    availableContentSpace,
}: AccordionProps): JSX.Element {
    const componentName = 'accordion';

    if (!backgroundContent) {
        backgroundContent = 'default';
    }

    return (
        <BackgroundColorWrapper backgroundColor={background}>
            <ContentWrapper id={anchor?.id}>
                <AvailableContentSpace contentSpace={availableContentSpace}>
                    <div className={classNames(componentName, `${componentName}__background--${backgroundTitle}`)}>
                        {headlines?.headline ? (
                            <Headlines {...headlines} className={`${componentName}__headlines`} />
                        ) : (
                            SKIP_RENDER
                        )}

                        <div className={classNames(`${componentName}__container`)}>
                            {items.map((item, key) => (
                                <AccordionItem
                                    key={key}
                                    itemKey={key}
                                    item={item}
                                    backgroundTitle={backgroundTitle}
                                    backgroundContent={backgroundContent}
                                    separatorColorHex={separatorColorHex}
                                    itemHeadlineFontColorHex={itemHeadlineFontColorHex}
                                    itemSubheadlineFontColorHex={itemSubheadlineFontColorHex}
                                    itemHeadlineStyle={itemHeadlineStyle}
                                />
                            ))}
                        </div>
                    </div>
                </AvailableContentSpace>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.Accordion', Accordion);
