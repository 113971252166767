import { dot } from 'dot-object';

import enMessages from './en';
import deMessages from './de';
import deInformalMessages from './de_informal';
import chMessages from './ch';
import atMessages from './at';
import czMessages from './cs';
import liMessages from './li';
import huMessages from './hu';
import nlMessages from './nl';
import skMessages from './sk';
import roMessages from './ro';
import frMessages from './fr';

export default {
    'en-US': dot(enMessages),
    'de-DE': dot(deMessages),
    'de-DE_informal': dot(deInformalMessages),
    'de-AT': dot(atMessages),
    'de-CH': dot(chMessages),
    'de-LI': dot(liMessages),
    'hu-HU': dot(huMessages),
    'cs-CZ': dot(czMessages),
    'nl-NL': dot(nlMessages),
    'nl-BE': dot(nlMessages),
    'sk-SK': dot(skMessages),
    'ro-RO': dot(roMessages),
    'fr-CH': dot(frMessages),
    'fr-FR': dot(frMessages),
};
