import { useQuery, useQueryClient } from '@tanstack/react-query';
import { queries } from '..';
import { useCartApiClient } from '../../api/hooks/useCartApiClient';
import { useAppDispatch } from '../../store/hooks/hooks';
import { installmentPlanReset } from '../../store/reducer/cart';

export const usePayment = ({ enabled = true } = {}) => {
    const dispatch = useAppDispatch();
    const apiClient = useCartApiClient();
    const queryClient = useQueryClient();

    const invalidatePayment = () => {
        queryClient.invalidateQueries({ queryKey: queries.payment._def });
    };

    const queryFn = () => {
        dispatch(installmentPlanReset());

        return apiClient.getPayment();
    };

    const query = useQuery({
        ...queries.payment.get,
        queryFn,
        enabled,
    });

    return { ...query, invalidatePayment };
};
