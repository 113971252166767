import { injectComponent } from '@mediashop/app/component-injector';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import Icon from '../Icon';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { DiscountStateType } from '@mediashop/app/api/types/Discount';

const DEFAULT_DISCOUNT_STATE_MESSAGE_ID = 'voucher.codes.notActive';

const discountStateMessageIds: Record<DiscountStateType, string> = {
    NotActive: 'voucher.codes.notActive',
    NotValid: 'voucher.codes.notValid',
    DoesNotMatchCart: 'voucher.codes.doesNotMatchCart',
    MaxApplicationReached: 'voucher.codes.maxApplicationReached',
    ApplicationStoppedByPreviousDiscount: 'voucher.codes.applicationStoppedByPreviousDiscount',
    MatchesCart: 'voucher.codes.matchesCart',
};

type Props = BasePropsWithChildren & {
    discountState: DiscountStateType;
    discountCode?: string;
    showModal?: boolean;
    setShowModal?: (boolean) => void;
    iconPosition?: 'start' | 'end';
};

/**
 * Display a tooltip for Vouchers.
 */
function VoucherValidityIndicator({
    discountState,
    discountCode,
    iconPosition = 'end',
    className,
    showModal = false,
    setShowModal = noop,
}: Props) {
    const componentName = 'voucher-validity-indicator';
    const intl = useIntl();

    const isVoucherValid = discountState === 'MatchesCart';

    const iconToShowContainer = (
        <span className={`${componentName}__icon-container`} onClick={() => setShowModal(!showModal)}>
            {isVoucherValid ? <Icon name="CheckCircle" /> : <Icon name="Info" />}
        </span>
    );

    return (
        <div
            className={classNames(
                className,
                `${componentName}__label`,
                isVoucherValid ? `${componentName}__voucher-valid` : `${componentName}__voucher-invalid`
            )}
        >
            {iconPosition === 'start' && iconToShowContainer}
            {discountCode ? (
                <>
                    {intl.formatMessage({ id: 'cart.voucher' })}
                    <span className={`${componentName}__code`} onClick={() => setShowModal(!showModal)}>
                        <u>{discountCode}</u>
                    </span>
                </>
            ) : (
                intl.formatMessage({ id: discountStateMessageIds[discountState] ?? DEFAULT_DISCOUNT_STATE_MESSAGE_ID })
            )}
            {iconPosition === 'end' && iconToShowContainer}
        </div>
    );
}

export default injectComponent('pattern.atom.VoucherValidityIndicator', VoucherValidityIndicator);
