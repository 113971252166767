import * as React from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { ComponentInjector } from '@mediashop/app';
import { BaseProps } from '@mediashop/app/bloomreach/types';

interface BlockProps extends BaseProps {
    name: string;
    children: React.ReactNode;
    [x: string]: any;
}

/**
 * Display a replaceable Block Component.
 */
function Block({ name, children, ...props }: BlockProps): JSX.Element | null {
    const Component = ComponentInjector.tryGet(name);

    if (!Component) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /** @ts-ignore */
        return children;
    }

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /** @ts-ignore */
        <Component id={name} {...props}>
            {children}
        </Component>
    );
}

export default injectComponent('pattern.atom.Block', Block);
