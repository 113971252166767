import { useAppSelector } from '../store/hooks/hooks';
import { RoutesState } from '../store/reducer/routes';

/**
 * Return a route from the route-reducer.
 * @param routeName
 */
export default function useRoute(routeName: keyof RoutesState): string {
    return useAppSelector((state) => state.routes[routeName]);
}
