import { Address as AddressType, AddressForm } from '@mediashop/app/api/types/Address';
import { Cart } from '@mediashop/app/api/types/ClientCart';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import * as React from 'react';

export type CheckoutAddressesProps = BaseProps & {
    address: AddressType;
    cart?: Cart;
    isLoading?: boolean;
    onChange?: (address: AddressType) => void;
    onEditClick: () => void;
    onNextClick: (formValues: AddressForm) => void;
    refId?: React.ForwardedRef<{ onSubmit: () => void }>;
};

const CheckoutAddressesReadOnlyMolecule = React.lazy(() => lazyRetry(() => import('./AddressesReadOnly')));

const CheckoutAddressesReadOnly = (props: CheckoutAddressesProps): JSX.Element => (
    <LazyComponent>
        <CheckoutAddressesReadOnlyMolecule {...props} />
    </LazyComponent>
);

export default injectComponent(
    'pattern.organism.Checkout.CheckoutLogin.CheckoutAddressesReadOnly',
    CheckoutAddressesReadOnly,
    'catalog-base'
);
