import { BreakpointName } from '../config/breakpoints';
import { useAppSelector } from '../store/hooks/hooks';

/**
 * A hook that returns the current device type.
 * Breakpoints can be configured in config/breakpoints.ts
 */
export default function useDeviceType(): BreakpointName {
    return useAppSelector((state) => state.renderContext.deviceType);
}
