import { StorageKeys } from '@mediashop/app/constants/storageKeys';
import { useLogoutCustomer } from '@mediashop/app/hooks/api/useLogoutCustomer';
import { useFormik } from 'formik';
import { useState, type CSSProperties } from 'react';
import * as Yup from 'yup';
import { injectComponent } from '@mediashop/app/component-injector';
import { useIntl } from 'react-intl';
import { useLoader } from '@mediashop/app/store/loader';
import { BaseProps, BrxCTAButton } from '@mediashop/app/bloomreach/types';
import BloomreachHtml, { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { PASSWORD_REGEX } from '@mediashop/app/constants/passwordRegex';
import { ButtonStyle } from '@mediashop/base/pattern/atom/Button/types';
import { useQueryParams } from '@mediashop/app/hooks/useQueryParams';
import { PasswordCriteria } from '../PasswordCriteria/PasswordCriteria';
import PasswordInput from '@mediashop/base/pattern/atom/PasswordInput';
import CTAButton from '@mediashop/base/pattern/atom/CTAButton';
import { useChangePassword } from '@mediashop/app/hooks/api/useChangePassword';
import useToast from '@mediashop/app/hooks/useToast';
import { useAppSelector } from '@mediashop/app/store/hooks/hooks';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

const componentName = 'new-passwords';

type PasswordsProps = BaseProps & {
    labelPassword?: string;
    labelPasswordConfirm?: string;
    text?: BrxHtml;
    buttonText: string;
    buttonStyle?: ButtonStyle;
    customButtonStyle?: CSSProperties;
    buttonClassName?: string;
    buttonFullWidth?: boolean;
    changePasswordWithToken: boolean;
    redirectUrl?: string;
    ctaButton?: BrxCTAButton;
};

interface PasswordForm {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const validationShape = {
    currentPassword: Yup.string().notRequired(),
    newPassword: Yup.string()
        .min(8, 'register.passwordMinError')
        .required('register.passwordEmptyError')
        .matches(PASSWORD_REGEX, 'register.passwordNotValidError'),
    confirmPassword: Yup.string()
        .required('login.passwordsNotIdentical')
        .oneOf([Yup.ref('newPassword')], 'login.passwordsNotIdentical'),
};

/**
 * @param labelCurrentPassword
 * @param labelPassword
 * @param labelPasswordConfirm
 * @param text
 * @param buttonText
 * @param buttonStyle
 * @param buttonClassName
 * @param buttonFullWidth
 * @param changePasswordWithToken if this is true, everything regarding "confirmPassword" is disabled
 * @constructor
 */
function NewPasswordInputs({
    labelPassword,
    labelPasswordConfirm,
    text,
    buttonText,
    buttonStyle = 'primary',
    customButtonStyle,
    buttonClassName,
    buttonFullWidth,
    changePasswordWithToken,
    redirectUrl,
    ctaButton,
}: PasswordsProps) {
    const intl = useIntl();
    const { customerClient } = useLoader();
    const navigate = useNavigate();
    const { isSignedIn } = useAppSelector((state) => state.customer);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const onLogoutSuccess = () => {
        toast.success(intl.formatMessage({ id: 'login.passwordChanged' }));
        setTimeout(() => {
            navigate(redirectUrl?.length ? redirectUrl : '/account/login-and-registration');
        }, 100);
    };

    const { mutate: performLogout } = useLogoutCustomer({
        onSuccess: onLogoutSuccess,
    });

    const changePasswordSuccess = () => {
        onLogoutSuccess();
        setIsLoading(false);
    };

    const changePasswordError = () => {
        toast.error(intl.formatMessage({ id: 'customer.wrongPassword' }));
        setIsLoading(false);
    };

    const { mutate: changePasswordMutation } = useChangePassword({
        onError: changePasswordError,
        onSuccess: changePasswordSuccess,
    });

    const { tokenId: token = '' } = useQueryParams();

    const changePassword = (values: PasswordForm) => {
        setIsLoading(true);
        if (changePasswordWithToken) {
            customerClient
                .resetPasswordWithToken(token, values.newPassword)
                .then((result) => {
                    if (result.isSuccess) {
                        if (localStorage.getItem(StorageKeys.CUSTOMER_DATA) || isSignedIn) {
                            performLogout();
                        } else {
                            onLogoutSuccess();
                        }
                    } else {
                        throw new Error('wrong password');
                    }
                })
                .catch(() => {
                    toast.error(intl.formatMessage({ id: 'customer.wrongPassword' }));
                })
                .finally(() => setIsLoading(false));
        } else {
            changePasswordMutation({
                currentPassword: values.currentPassword,
                newPassword: values.newPassword,
            });
        }
    };

    if (!changePasswordWithToken) {
        validationShape.currentPassword = Yup.string().required('register.passwordEmptyError');
    }

    const { errors, handleBlur, handleChange, handleSubmit, touched, values } = useFormik<PasswordForm>({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object().shape(validationShape),
        onSubmit: changePassword,
    });

    const getFormattedErrorMessage = (fieldName: string) =>
        touched[fieldName] && errors[fieldName] ? intl.formatMessage({ id: errors[fieldName] }) : undefined;

    return (
        <form onSubmit={handleSubmit}>
            {!changePasswordWithToken && (
                <PasswordInput
                    className={`${componentName}__password`}
                    value={values.currentPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="currentPassword"
                    name="currentPassword"
                    label={intl.formatMessage({ id: 'personalInfo.currentPassword' })}
                    required
                    error={getFormattedErrorMessage('currentPassword')}
                />
            )}
            <PasswordInput
                className={`${componentName}__password`}
                value={values.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                id="newPassword"
                name="newPassword"
                min="8"
                label={labelPassword ?? intl.formatMessage({ id: 'personalInfo.newPassword' })}
                required
                error={getFormattedErrorMessage('newPassword')}
            />
            <PasswordInput
                className={`${componentName}__password`}
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                id="confirmPassword"
                name="confirmPassword"
                min="8"
                label={labelPasswordConfirm ?? intl.formatMessage({ id: 'personalInfo.newPasswordConfirm' })}
                required
                error={getFormattedErrorMessage('confirmPassword')}
            />
            <BloomreachHtml className={`${componentName}__password-requirements`} html={text} />
            <PasswordCriteria password={values.newPassword} />
            <CTAButton
                style={buttonStyle}
                className={buttonClassName}
                fullWidth={buttonFullWidth}
                type="submit"
                loading={isLoading}
                customStyles={customButtonStyle}
                ctaButton={ctaButton}
            >
                {buttonText}
            </CTAButton>
        </form>
    );
}

export default injectComponent('pattern.molecule.NewPasswordInputs', NewPasswordInputs);
