import { lazy } from 'react';

export type AccountVerificationModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const AccountVerificationModalMolecule = lazy(() => lazyRetry(() => import('./AccountVerificationModal')));

const AccountVerificationModal = (props: AccountVerificationModalProps): JSX.Element => (
    <LazyComponent>
        <AccountVerificationModalMolecule {...props} />
    </LazyComponent>
);

export default AccountVerificationModal;
