import { injectComponent } from '@mediashop/app/component-injector';
import { lazy } from 'react';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { BaseProps } from '@mediashop/app/bloomreach/types';

const HeaderBarLazy = lazy(() => lazyRetry(() => import('./HeaderBar')));

export type HeaderBarProps = BaseProps & {
    headline: string;
    subheadline: string;
    backButton: string;
};

const HeaderBar = (props: HeaderBarProps): JSX.Element => (
    <LazyComponent>
        <HeaderBarLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.HeaderBar', HeaderBar);
