import { useAppSelector } from '../store/hooks/hooks';
import { PageState } from '../store/reducer/page';
import { shallowEqual } from 'react-redux';

/**
 * Hook that returns payment data from store.
 * @returns page
 */
export const usePage = (equalityCheck = false): PageState => {
    return useAppSelector((state) => state.page, {
        equalityFn: equalityCheck ? shallowEqual : undefined,
    });
};
