import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import FooterTextItem from './FooterTextItem';
import { FooterColumnProps } from './types';

type FooterTextProps = BaseProps & {
    backgroundColorHex?: string;
    fontColorHex?: string;
    separatorLineColorHex: string;
    footerColumns: FooterColumnProps[];
};

const componentName = 'footer-text';

function FooterText({ backgroundColorHex, fontColorHex, separatorLineColorHex, footerColumns }: FooterTextProps) {
    return (
        <BackgroundColorWrapper className={`${componentName}__main`} hexBackgroundColor={backgroundColorHex}>
            <ContentWrapper>
                <div style={{ color: fontColorHex ? fontColorHex : undefined }} className={`${componentName}__wrapper`}>
                    {footerColumns.filter(Boolean).map((column) => (
                        <FooterTextItem
                            footerItem={column}
                            separatorLineColorHex={separatorLineColorHex}
                            key={column.title}
                        />
                    ))}
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.organism.FooterText', FooterText);
