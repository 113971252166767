import { useEffect, useState } from 'react';
import { convertSecondsToHours } from '@mediashop/catalog-base/utils/convertSecondsToHours';
import { DealOfTheDayCountdown } from '../../DealOfTheDay/types';

export const useCountdown = (timestamp: number): DealOfTheDayCountdown | undefined => {
    const [countdown, setCountdown] = useState<DealOfTheDayCountdown | undefined>();

    useEffect(() => {
        setCountdown(convertSecondsToHours(timestamp));

        const timer = setInterval(() => {
            setCountdown(convertSecondsToHours(timestamp));
        }, 1000);

        return () => clearInterval(timer);
    }, [setCountdown, timestamp]);

    return countdown;
};
