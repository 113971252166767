import { BaseProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import { useCustomer } from '@mediashop/app/hooks/api/useCustomer';
import classNames from 'classnames';

const componentName = 'account-header';

type AccountHeaderProps = BaseProps;

function AccountHeader({ className }: AccountHeaderProps) {
    const customer = useCustomer();

    return (
        <div className={classNames(componentName, className)}>
            {customer ? (
                <h1 className={`${componentName}__customer-name`}>
                    {customer?.firstName} {customer?.middleName} {customer?.lastName}
                </h1>
            ) : (
                <div className={classNames(`${componentName}__customer-name`, `${componentName}__skeleton`)}></div>
            )}
        </div>
    );
}

export default injectComponent('pattern.molecule.AccountHeader', AccountHeader, 'catalog-base');
