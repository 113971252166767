import { lazy } from 'react';
import { Cart } from '@mediashop/app/api/types/ClientCart';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const SummaryProductsMolecule = lazy(() => lazyRetry(() => import('./Products')));

export type SummaryProductsProps = BaseProps & {
    cart: Cart;
};

const SummaryProducts = (props: SummaryProductsProps): JSX.Element => (
    <LazyComponent>
        <SummaryProductsMolecule {...props} />
    </LazyComponent>
);

export default SummaryProducts;
