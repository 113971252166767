import LazyComponent from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy } from 'react';
const TrackAndTrace = lazy(() => import('@parcellab/js-plugin-utils/v5/react'));

import { injectComponent } from '@mediashop/app/component-injector';
import classNames from 'classnames';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import useIsClientSide from '@mediashop/app/hooks/useIsClientSide';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

/**
 * Render tracking and trace component.
 */
function TrackingAndTrace() {
    const componentName = 'tracking-and-trace';
    const isClientSide = useIsClientSide();

    return (
        <ContentWrapper className={classNames(componentName)}>
            <LazyComponent>{isClientSide ? <TrackAndTrace /> : SKIP_RENDER}</LazyComponent>
        </ContentWrapper>
    );
}

export default injectComponent('pattern.molecule.TrackingAndTrace', TrackingAndTrace, 'catalog-base');
