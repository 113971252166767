import { RefObject } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';

import { BaseProps } from '@mediashop/app/bloomreach/types';
import { AddressForm } from '@mediashop/app/api/types/Address';
import { AddressFieldSet } from '@mediashop/app/api/types/AddressConfiguration';
import { FormikProps } from 'formik';

export type OnAddressChangeHandler = ({ formData, isValid }: { formData: AddressForm; isValid?: boolean }) => void;

export type DynamicAddressFormProps = BaseProps & {
    formRef: RefObject<FormikProps<AddressForm>>;
    billingFields: AddressFieldSet[];
    shippingFields: AddressFieldSet[];
    initialFormData: AddressForm;
    onChange: OnAddressChangeHandler;
};

function DynamicAddressForm() {
    return <div>Form</div>;
}

export default injectComponent('pattern.molecule.DynamicAddressForm', DynamicAddressForm);
