import { DealOfTheDayCountdown } from '@mediashop/catalog-base/pattern/molecule/DealOfTheDay/types';

export const convertSecondsToHours = (timestamp: number): DealOfTheDayCountdown => {
    let second = Math.floor((new Date(timestamp).getTime() - new Date().getTime()) / 1000);
    let minute = Math.floor(second / 60);
    let hour = Math.floor(minute / 60);
    const day = Math.floor(hour / 24);

    hour = hour - day * 24;
    minute = minute - day * 24 * 60 - hour * 60;
    second = second - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60;

    let isEnd = false;

    if (
        new Date(timestamp).getTime() === new Date().getTime() ||
        new Date(timestamp).getTime() < new Date().getTime()
    ) {
        isEnd = true;
    }

    return {
        isEnd,
        days: day,
        hours: hour,
        minutes: minute,
        seconds: second,
    };
};
