import { useMutation } from '@tanstack/react-query';
import { useLoader } from '../../store/loader';
import { useAppDispatch } from '../../store/hooks/hooks';
import { partnerUpdated } from '../../store/reducer/partner';

/**
 * Hook for setting the partner id
 * @param params callbacks
 * @returns useMutation result
 */
export const useSetPartnerId = () => {
    const dispatch = useAppDispatch();
    const { cartClient } = useLoader();
    const setPartnerId = (partner: string) => cartClient.setPartnerId(partner);

    const onSuccess = (partnerId: string) => {
        dispatch(partnerUpdated({ partnerId }));
    };

    return useMutation({ mutationFn: setPartnerId, onSuccess });
};
