import { useMemo, useState } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import SelectButton from '../SelectButton';
import Icon from '../../atom/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import { injectComponent } from '@mediashop/app/component-injector';
import Quantity from '@mediashop/app/domain/Quantity';
import useRoute from '@mediashop/app/hooks/useRoute';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

type Props = BaseProps & {
    onClick: (value: number) => void;
    isLoading: boolean;
    minQuantity?: number;
    maxQuantity?: number;
    quantitySteps?: number;
    textId?: string;
    iconName?: string;
    goToCart?: boolean;
    showSelect?: boolean;
};

/**
 * Render a SelectButton with given quantity-options.
 */
function BuyButton({
    className = 'buy-btn',
    onClick,
    minQuantity = 1,
    maxQuantity = 10,
    quantitySteps = 1,
    isLoading,
    textId = 'product.buyNow',
    iconName = 'ArrowRightLight',
    goToCart = true,
    showSelect,
}: Props) {
    const intl = useIntl();
    const options = useMemo(
        () => Quantity.getSelectOptions(minQuantity, maxQuantity, quantitySteps),
        [minQuantity, maxQuantity, quantitySteps]
    );
    const [value, setValue] = useState(options[0].value);
    const cartRoute = useRoute('Frontend.Master.Cart');
    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (typeof onClick === 'function') {
            onClick(value);
        }

        if (goToCart) {
            navigate(cartRoute);
        }
    };

    return (
        <SelectButton
            className={className}
            onClick={handleButtonClick}
            options={options}
            value={value}
            isLoading={isLoading}
            onChange={(event) => setValue(parseInt(event.target.value, 10))}
            ariaLabel={intl.formatMessage({ id: textId })}
            showSelect={showSelect}
        >
            <FormattedMessage id={textId} />
            {iconName && <Icon name={iconName} className="select-button__icon" />}
        </SelectButton>
    );
}

export default injectComponent('pattern.molecule.BuyButton', BuyButton);
