import { BaseProps, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import { BrXImageVariant } from '../../../types/imageVariants';
import BloomreachImageResponsive from '../../atom/BloomreachImageResponsive';

const componentName = 'image-with-text';

type ImageWithTextProps = BaseProps & {
    text: string;
    image: BrxImageSetImpl;
    imageVariant: BrXImageVariant;
};

const ImageWithText = ({ image, imageVariant: variant, text }: ImageWithTextProps) => (
    <>
        <BloomreachImageResponsive
            className={`${componentName}__icon`}
            alt={text}
            desktopImage={image}
            variant={variant}
        />
        <span>{text}</span>
    </>
);

export default injectComponent('pattern.molecule.ImageWithText', ImageWithText);
