import { FormattedMessage } from 'react-intl';
import { PayPalMessages } from '@paypal/react-paypal-js';
import { useCart } from '@mediashop/app/hooks/useCart';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { usePayPal } from '@mediashop/app/payment/payPal/usePayPal';

const componentName = 'paypal-banner';

type PayPalBannerProps = {
    showBanner?: boolean;
    showText?: boolean;
};

export const PayPalBanner = ({ showBanner = false, showText = true }: PayPalBannerProps): JSX.Element | null => {
    const { cart } = useCart();
    const { route } = useShopContext();
    const billingCountry = cart?.billingAddress?.country;

    const renderBanner = route === '/checkout' ? billingCountry === 'DE' : showBanner;

    const { isResolved } = usePayPal({ isEnabled: renderBanner });

    return isResolved ? (
        <>
            {showText ? <FormattedMessage id="checkout.payment.descriptions.payPalPayLater" /> : SKIP_RENDER}
            <div className={`${componentName}__container`}>
                <PayPalMessages
                    placement="home"
                    style={{
                        layout: 'text',
                        text: { color: 'black', size: 12, align: 'left' },
                        logo: { type: 'primary', position: 'left' },
                    }}
                />
            </div>
        </>
    ) : (
        SKIP_RENDER
    );
};
