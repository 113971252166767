import { lazy, RefObject } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { AddressForm } from '@mediashop/app/api/types/Address';
import { Cart } from '@mediashop/app/api/types/ClientCart';

export type CheckoutAddressFormSubmitHandle = {
    submit: () => void;
};

export type OnePageCheckoutAddressesProps = BaseProps & {
    refId: RefObject<CheckoutAddressFormSubmitHandle>;
    isLoading: boolean;
    cart: Cart;
    selectedBillingCountry: string;
    selectedShippingCountry: string;
    validAddress: boolean;
    onChange?: ({ values, validated }: { values: AddressForm; validated: boolean }) => void;
};
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const OnePageCheckoutAddressesMolecule = lazy(() => lazyRetry(() => import('./Addresses')));

const OnePageCheckoutAddresses = (props: OnePageCheckoutAddressesProps): JSX.Element => (
    <LazyComponent>
        <OnePageCheckoutAddressesMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.OnePageCheckout.Addresses', OnePageCheckoutAddresses);
