import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLoader } from '../../store/loader';
import useShouldCreateOrder from '../order/useShouldCreateOrder';
import NavigationBlocker from '../../components/NavigationBlocker';
import { SKIP_RENDER } from '../../constants/semanticConstants';
import { useBrxPageContext } from '../useBrxPageContext';

/**
 * Create order on checkoutsuccess-page
 */
export default function OrderCreationConnector(): JSX.Element {
    const brxPageContext = useBrxPageContext();
    const { orderLoader } = useLoader();
    const intl = useIntl();
    const leaveMessage = intl.formatMessage({ id: 'checkout.orderNotSavedYet' });
    const [isLoading, setIsLoading] = useState(false);
    const shouldCreateOrder = useShouldCreateOrder();
    const mountedRef = useRef(false);

    useEffect(() => {
        if (!shouldCreateOrder) {
            return;
        }

        if (!mountedRef.current) {
            // Prevent navigation
            setIsLoading(true);
            orderLoader.createOrder().finally(() => setIsLoading(false));
        }

        // eslint-disable-next-line consistent-return
        return () => {
            mountedRef.current = true;
        };
    }, [brxPageContext, orderLoader]);

    // NavigationBlocker will prompt the user to confirm his navigation.
    return <> {isLoading ? <NavigationBlocker when={isLoading} message={leaveMessage} /> : SKIP_RENDER} </>;
}
