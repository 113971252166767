import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { HeadlineProps } from '@mediashop/base/pattern/atom/Headlines';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const OrderDetailMolecule = lazy(() => lazyRetry(() => import('./OrderDetail')));

export type OrderDetailProps = BaseProps & {
    headline: HeadlineProps;
};

const OrderDetail = (props: OrderDetailProps): JSX.Element => (
    <LazyComponent>
        <OrderDetailMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.OrderDetail', OrderDetail);
