import { ShippingKey } from '../api/types/ClientCart';
import { calculateShippingMultiple, calculateShippingSingle } from '../helper/calculateShipping';
import { useCart } from './useCart';

type FreeShippingInfoReturnProps = {
    isFreeShippingAvailable: boolean;
    isShippingPriceZero: boolean;
    isTotalPriceGreaterThanFreeShippingLimit: boolean;
};

/**
 * Hook that returns infos about free shipping.
 * @returns FreeShippingInfoReturnProps
 */
export const useFreeShippingInfo = (hasShippingDiscounts?: boolean): FreeShippingInfoReturnProps => {
    const { cart } = useCart();

    if (!cart?.shippingInfo && (!cart?.shipping || cart?.shipping?.length === 0)) {
        return {
            isFreeShippingAvailable: false,
            isShippingPriceZero: false,
            isTotalPriceGreaterThanFreeShippingLimit: false,
        };
    }

    const { shippingCost, shippingPrice } =
        cart.shippingMode === 'Single'
            ? calculateShippingSingle(cart.shippingInfo, cart.customFields, hasShippingDiscounts)
            : calculateShippingMultiple(cart.shipping);

    const totalLineItemPrice = cart.lineItems.reduce((totalPrice, lineItem) => {
        const lineItemPrice =
            lineItem.discountedPricePerQuantity[0]?.discountedPrice?.value.centAmount ?? lineItem.price.centAmount;
        return totalPrice + lineItemPrice * lineItem.quantity;
    }, 0);

    const currentShippingInfo =
        cart.shippingMode === 'Single'
            ? cart.shippingInfo
            : cart.shipping?.find((shipping) => shipping.shippingKey === ShippingKey.STANDARD)?.shippingInfo;

    const isFreeShippingAvailable = Boolean(currentShippingInfo?.shippingRate.freeAbove);
    const isTotalPriceGreaterThanFreeShippingLimit =
        isFreeShippingAvailable &&
        currentShippingInfo !== undefined &&
        totalLineItemPrice - shippingCost.centAmount >= currentShippingInfo.shippingRate.freeAbove!.centAmount;
    const isShippingPriceZero = shippingPrice.centAmount === 0;

    return {
        isFreeShippingAvailable,
        isShippingPriceZero,
        isTotalPriceGreaterThanFreeShippingLimit,
    };
};
