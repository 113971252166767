import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { BrxLinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import { useProject } from '@mediashop/app/hooks/useProject';
import { removeLocaleFromPathname } from '@mediashop/app/helper/localeHelper';

export type MainNavigationVerticalBrandNavigationItems = Pick<BrxLinkOpenInTab, 'tab'> & {
    link?: string;
    linkText?: string;
    hide: boolean;
    isHeadline: boolean;
    newColumn?: boolean;
    childItems: MainNavigationVerticalBrandNavigationItems[];
};

type Props = {
    extendedNavigation: MainNavigationVerticalBrandNavigationItems[];
    isMobileMenuOpen: boolean;
    toggleMobileMenuOpen: () => void;
};

const MainNavigationVerticalBrandMenu = ({
    extendedNavigation,
    isMobileMenuOpen,
    toggleMobileMenuOpen,
}: Props): JSX.Element => {
    const location = useLocation();
    const deviceType = useDeviceType();
    const { takeLanguageFromUrl } = useProject();

    const isMobileOrTablet = deviceType === 'mobile' || deviceType === 'tablet';

    const [activeMainNavigationItem, setActiveMainNavigationItem] =
        useState<MainNavigationVerticalBrandNavigationItems>();
    const [hoveredMainNavigationItem, setHoveredMainNavigationItem] =
        useState<MainNavigationVerticalBrandNavigationItems>();
    const [childMenuVisible, setChildMenuVisible] = useState(false);

    /**
     * Mark current active route as active
     */
    const determineActiveNavigationItem = () => {
        const cleanedPathname = takeLanguageFromUrl ? removeLocaleFromPathname(location.pathname) : location.pathname;

        extendedNavigation.forEach((navItem) => {
            if (navItem.childItems.length > 0) {
                navItem.childItems.forEach((childItem) => {
                    if (cleanedPathname === childItem.link || cleanedPathname === navItem.link) {
                        setActiveMainNavigationItem(navItem);
                    }
                });
            } else if (cleanedPathname === navItem.link) {
                setActiveMainNavigationItem(navItem);
            }
        });
    };

    /**
     * Main-Navigation hover
     */
    const hoverMainNavigationItem = (navItem: MainNavigationVerticalBrandNavigationItems) => {
        setHoveredMainNavigationItem(navItem);
        setChildMenuVisible(true);
    };

    /**
     * Main-Navigation unhover
     */
    const unhoverMainNavigationItem = () => {
        if (
            hoveredMainNavigationItem?.childItems.length === 0 &&
            location.pathname === hoveredMainNavigationItem.link
        ) {
            return;
        }

        setHoveredMainNavigationItem(undefined);
    };

    /**
     * Child-Navigation hover
     */
    const hoverChildNavigationItem = () => {
        setChildMenuVisible(true);
    };

    /**
     * Child-Navigation unhover
     */
    const unhoverChildNavigationItem = (navItem) => {
        if (hoveredMainNavigationItem?.linkText !== navItem.linkText) {
            setChildMenuVisible(false);
        }
    };

    useEffect(() => {
        setActiveMainNavigationItem(undefined);
        setHoveredMainNavigationItem(undefined);

        determineActiveNavigationItem();
    }, [location.pathname]);

    useEffect(() => {
        if (isMobileOrTablet && childMenuVisible && !isMobileMenuOpen) {
            setChildMenuVisible(false);
        }
    }, [childMenuVisible, isMobileMenuOpen, isMobileOrTablet]);

    return (
        <>
            {/**
             * Desktop Menu
             */}
            {!isMobileOrTablet ? (
                <DesktopMenu
                    extendedNavigation={extendedNavigation}
                    hoveredMainNavigationItem={hoveredMainNavigationItem}
                    activeMainNavigationItem={activeMainNavigationItem}
                    childMenuVisible={childMenuVisible}
                    hoverChildNavigationItem={hoverChildNavigationItem}
                    hoverMainNavigationItem={hoverMainNavigationItem}
                    unhoverMainNavigationItem={unhoverMainNavigationItem}
                    determineActiveNavigationItem={determineActiveNavigationItem}
                    unhoverChildNavigationItem={unhoverChildNavigationItem}
                />
            ) : (
                <>
                    {/**
                     * Mobile Menu
                     */}
                    {isMobileMenuOpen ? (
                        <MobileMenu
                            extendedNavigation={extendedNavigation}
                            activeMainNavigationItem={activeMainNavigationItem}
                            setChildMenuVisible={setChildMenuVisible}
                            toggleMobileMenuOpen={toggleMobileMenuOpen}
                        />
                    ) : (
                        SKIP_RENDER
                    )}
                </>
            )}
        </>
    );
};

export default MainNavigationVerticalBrandMenu;
