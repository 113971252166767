import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import RadioButton from '../RadioButton';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { RadioFieldValue } from '@mediashop/app/api/types/AddressConfiguration';
import { injectComponent } from '@mediashop/app/component-injector';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const componentName = 'radio-button-group';

type RadioButtonGroupProps = BaseProps & {
    id: string;
    name: string;
    value?: string;
    values: RadioFieldValue[];
    error?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    labelId: string;
};

function RadioButtonGroup({
    id,
    className,
    name,
    value,
    values,
    error,
    onChange,
    labelId,
}: RadioButtonGroupProps): JSX.Element {
    return (
        <>
            <div className={classNames(componentName, className)} id={id}>
                <div className={`${componentName}__title`}>
                    <FormattedMessage id={labelId} />
                </div>
                <div className={`${componentName}__radios`}>
                    {/* Inject required and setIsChecked */}
                    {values
                        ? values.map((valueObject) => (
                              <RadioButton
                                  key={valueObject.value}
                                  name={name}
                                  defaultChecked={valueObject.value === value}
                                  value={valueObject.value}
                                  onChange={onChange}
                              >
                                  <FormattedMessage id={valueObject.labelId} />
                              </RadioButton>
                          ))
                        : SKIP_RENDER}
                </div>
            </div>

            {error ? (
                <div className="input-error-message">
                    <FormattedMessage id={error} />
                </div>
            ) : (
                SKIP_RENDER
            )}
        </>
    );
}

export default injectComponent('pattern.atom.RadioButtonGroup', RadioButtonGroup);
