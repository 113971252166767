import { CustomerSignIn } from './ClientCustomer';
import { QueryResponse } from './Query';
import { GatewayCustomer, GatewayCustomerV2 } from './GatewayCustomer';
import { ValueOf } from '../../helper/utility';
import { CustomerOrderOverviewDto } from '../dtos/customer/order/customerOrderOverviewDto';
import { CustomerOrderDto } from '../dtos/customer/order/customerOrderDto';
import { CustomerSubscription } from '../../types/customer/subscription/customerSubscription';

export interface CustomerQueryContextParams {
    locale: string;
    currency: string;
    country: string;
}

export interface CustomerSignInRequest extends CustomerQueryContextParams {
    email: string;
    password: string;
    fullName?: string;
    approveNewStore?: boolean;
}

export type CustomerSignInResponse = CustomerSignIn;

export interface SignUpCustomerRequest extends CustomerQueryContextParams {
    accountVerificationUrlBase: string;
    address: {
        apartment?: string;
        block?: string;
        city: string;
        company?: string;
        country: string;
        county?: string;
        floor?: string;
        isCompany?: boolean;
        phone?: string;
        postalCode: string;
        sector?: string;
        stairway?: string;
        streetName: string;
        streetNumber: string;
        vatId?: string;
    };
    dateOfBirth?: string;
    email: string;
    fullName?: string;
    firstName: string;
    lastName: string;
    linkCartId?: string;
    password: string;
    salutation: string;
}

export interface SignUpCustomerResponse {
    customer: {
        id: string;
        email: string;
        externalId: string;
    };
}

export interface CustomerResponse extends QueryResponse {
    data: {
        customer: GatewayCustomer;
    };
}

export interface CustomerGetOrdersRequest {
    limit: number;
    locale: string;
    offset: number;
}

export interface CustomerGetOrdersResponse {
    orders: Array<CustomerOrderOverviewDto>;
    count: number;
    offset: number;
    total: number;
}

export interface CustomerGetOrderRequest {
    locale: string;
    orderId: string;
}

export type CustomerGetOrderResponse = CustomerOrderDto;

export interface ChangePasswordRequest {
    currentPassword: string;
    newPassword: string;
}

export interface ResetPasswordRequest {
    newPassword: string;
    token: string;
}

export interface ResetPasswordResponse {
    isSuccess: boolean;
}

export interface CustomerIsSubscribedResponse {
    isSubscribed: boolean;
}

export interface GetCatalogResponse extends QueryResponse {
    data: {
        success: boolean;
    };
}

export interface CustomerUpdateResponse extends QueryResponse {
    data: GatewayCustomer;
}

export interface VerifyAccountRequest {
    token: string;
}

export interface VerifyAccountResponse {
    isSuccess: boolean;
}

export interface CreatePasswordResetTokenRequest {
    country: string;
    email: string;
    fullName: string;
    locale: string;
    resetPasswordUrlBase: string;
}

export type CreatePasswordResetTokenResponse = object;

export const NewsletterRegistrationErrorCode = {
    VALIDATION_FAILED: 'Validation failed',
    NEWSLETTER_SUBSCRIPTION_SUCCESSFUL: 'NEWSLETTER_SUBSCRIPTION_SUCCESSFUL',
} as const;

export type NewsletterRegistrationError = ValueOf<typeof NewsletterRegistrationErrorCode>;

export interface SubscribeToNewsletterRequest {
    channel?: string;
    companyTransactions?: string;
    country: string;
    email: string;
    locale: string;
}

export interface SubscribeToNewsletterResponse {
    alreadySubscribed: boolean;
    isSuccess: boolean;
}

export interface UnsubscribeFromNewsletterRequest {
    uid: string;
}

export interface UnsubscribeFromNewsletterResponse {
    isSuccess: boolean;
    wasSubscribed: boolean;
}

export interface CustomerSignOutRequest {
    locale: string;
}

export interface CustomerSignOutResponse {
    isSuccess: boolean;
}

export interface CheckEmailRegisteredRequest {
    email: string;
}

export interface CheckEmailRegisteredResponse {
    isEmailRegistered: boolean;
}

export interface SetEmarsysTimestampRequest {
    uid: string;
    fieldId: number;
}

export interface SetEmarsysTimestampResponse {
    isSuccess: boolean;
}

export type CustomerSignInStateRequest = void;

export interface CustomerSignInStateResponse {
    isSignedIn: boolean;
}

export interface UpdateCustomerRequest {
    customer: {
        address: UpdateCustomerAddress;
        dateOfBirth?: string | null;
        firstName: string;
        lastName: string;
        salutation: string;
    };
}

interface UpdateCustomerAddress {
    apartment?: string;
    block?: string;
    city: string;
    company?: string;
    country: string;
    county?: string;
    floor?: string;
    isCompany?: boolean;
    phone?: string;
    postalCode: string;
    sector?: string;
    stairway?: string;
    streetName: string;
    streetNumber: string;
    vatId?: string;
}

export type UpdateCustomerResponse = GatewayCustomerV2;

export type CustomerGetSubscriptionsRequest = object;

export interface CustomerGetSubscriptionsResponse {
    subscriptions: Array<CustomerSubscription>;
    count: number;
    total: number;
}

export interface CustomerCancelSubscriptionRequest {
    subscriptionId: string;
}

export interface CustomerCancelSubscriptionResponse {
    isSuccess: boolean;
}
