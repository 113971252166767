import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { NetworkChangeHandler } from '../../../../../types';

const PaymentMethodsListLazy = lazy(() => lazyRetry(() => import('./PaymentMethodsList')));

export type PaymentMethodsListProps = BaseProps & {
    addressFormData?: any;
    goToSummary?: () => void;
    goToAddress?: () => void;
    isLoading: boolean;
    onNetworkChange: NetworkChangeHandler;
    validAddress: boolean;
};

const PaymentMethodsList = (props: PaymentMethodsListProps): JSX.Element => (
    <LazyComponent>
        <PaymentMethodsListLazy {...props} />
    </LazyComponent>
);

export default PaymentMethodsList;
