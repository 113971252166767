import { MainNavigationVerticalBrandNavigationItems } from './Menu';

export const getGroupedNavigationItems = (items: MainNavigationVerticalBrandNavigationItems[]) => {
    const grouped: MainNavigationVerticalBrandNavigationItems[][] = [];
    let currentGroup: MainNavigationVerticalBrandNavigationItems[] = [];

    items.forEach((item) => {
        item.childItems.forEach((child, index) => {
            if (child.newColumn || index === 0) {
                if (currentGroup.length > 0) {
                    grouped.push([...currentGroup]);
                    currentGroup = [];
                }
            }

            currentGroup.push(child);
        });

        if (currentGroup.length > 0 && !grouped.includes(currentGroup)) {
            grouped.push([...currentGroup]);
            currentGroup = [];
        }
    });

    return grouped;
};
