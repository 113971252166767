/*
 *   This component is only there for the transition to Flexbox.
 *   When all Samsung components in Bloomreach are changed to Flexbox this component should be removed.
 */

import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxBackground, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const SamsungMolecule = lazy(() => lazyRetry(() => import('./Samsung')));

export type SamsungProps = BaseProps & {
    background: BrxBackground;
    headline: string;
    subheadline: string;
    items: FlexboxItemProps[];
};

type FlexboxItemProps = {
    contentType: string;
    ctaReference: string;
    ctaTarget: string;
    ctaText: string;
    ctareference: string;
    ctatarget: string;
    ctatext: string;
    displayName: string;
    image: BrxImageSetImpl;
    imagelink: BrxImageSetImpl;
    name: string;
    size: string;
};

const Samsung = (props: SamsungProps) => (
    <LazyComponent>
        <SamsungMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.Samsung', Samsung);
