import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';

const componentName = 'loading-indicator';

type LoadingIndicatorProps = BaseProps;

/**
 * Display a spinning loading-indicator
 */
function LoadingIndicator({ className }: LoadingIndicatorProps) {
    return <div data-testid="loading-indicator" className={classNames(componentName, className)} />;
}

export default injectComponent('pattern.atom.LoadingIndicator', LoadingIndicator);
