import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { PriceBoxProps } from '../../../molecule/PriceBox';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Variant } from '@mediashop/app/api/types/ClientProduct';

export type CartProductProps = BaseProps & {
    custom: PriceBoxProps['custom'];
    name: string;
    quantity: number;
    variant: Variant;
    onSelectChange: (value: number) => void;
    onRemoveItem: () => void;
    lineItemPrice: Variant['price'];
};
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CartProductMolecule = lazy(() => lazyRetry(() => import('./CartProduct')));

const CartProduct = (props: CartProductProps): JSX.Element => (
    <LazyComponent>
        <CartProductMolecule {...props} />
    </LazyComponent>
);
export default injectComponent('pattern.organism.CartProduct', CartProduct);
