import { PaymentMethod } from '@mediashop/app/api/types/Payment';
import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const PaymentMethodsItemLazy = lazy(() => lazyRetry(() => import('./PaymentMethodsItem')));

export type PaymentMethodsItemProps = BaseProps & {
    addressFormData?: any;
    isActive: boolean;
    paymentMethod: PaymentMethod;
    onSelect: (value: string) => void;
    goToSummary?: () => void;
    validAddress: boolean;
};

const PaymentMethodsItem = (props: PaymentMethodsItemProps): JSX.Element => (
    <LazyComponent>
        <PaymentMethodsItemLazy {...props} />
    </LazyComponent>
);

export default PaymentMethodsItem;
