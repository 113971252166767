import { FormattedMessage, useIntl } from 'react-intl';
import { Price } from '@mediashop/base/pattern/molecule/Price';
import { Money } from '@mediashop/app/api/types/Money';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { useCart } from '@mediashop/app/hooks/useCart';

type CheaperSetPriceProps = {
    componentName: string;
    cheaperSetPrice: Money;
    variant?: Variant;
};

export default function CheaperSetPrice({
    componentName,
    cheaperSetPrice,
    variant,
}: Readonly<CheaperSetPriceProps>): JSX.Element {
    const intl = useIntl();
    const { cart } = useCart();
    const showCheaperSetPrice = cart?.lineItems.some((lineItem) => lineItem.variant.sku === variant?.sku);
    const quantityAdvantageText = intl.formatMessage({
        id: showCheaperSetPrice ? 'product.quantityAdvantageActive' : 'product.quantityAdvantage',
    });

    const createCheaperSetText = () => {
        if (showCheaperSetPrice) {
            return (
                <span className={`${componentName}__cheaper-set-quantity-advantage-text`}>{quantityAdvantageText}</span>
            );
        }

        return (
            <>
                {quantityAdvantageText !== 'undefined' && (
                    <span className={`${componentName}__cheaper-set-quantity-advantage-text`}>
                        {quantityAdvantageText} &nbsp;
                    </span>
                )}
                <div>
                    <FormattedMessage
                        id={variant?.attributes?.setTypeLclzd ? 'product.setTypeXplusY' : 'product.cheaperSet'}
                    />
                    <span>&nbsp;</span>
                    <span className={`${componentName}__cheaper-set-price price-colored-gratis`}>
                        <Price price={cheaperSetPrice} />
                    </span>
                </div>
            </>
        );
    };

    return (
        <div className={`${componentName}__cheaper-set-box`}>
            <div className={`${componentName}__cheaper-set-triangle`} />
            <div className={`${componentName}__cheaper-set-text`}>{createCheaperSetText()}</div>
        </div>
    );
}
