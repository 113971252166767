import { FormattedMessage } from 'react-intl';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Money } from '@mediashop/app/api/types/Money';
import classNames from 'classnames';
import { getSavingsPercentage } from '../../../utils';
import MoneyFunctions from '@mediashop/app/domain/Money';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { Price } from '@mediashop/base/pattern/molecule/Price';

const componentName = 'original-price';

type OriginalPriceProps = BaseProps & {
    originalPrice?: Money;
    price: Money;
    className?: string;
};

const showStattPrice = (price: Money, originalPrice?: Money) => {
    const hasSavings = getSavingsPercentage(originalPrice?.centAmount ?? 0, price.centAmount) > 1;

    const hasSuggestedRetailPrice = originalPrice?.priceType === 'SUGGESTEDRETAILPRICE';
    const priceIsAllowedToBeStatt = ['DEAL', 'UPSELL'].includes(price.priceType ?? EMPTY_STRING);

    return hasSavings && (priceIsAllowedToBeStatt || !hasSuggestedRetailPrice);
};

/**
 * Format a price with a strike-through.
 * E.g. old prices, discounts, etc.
 */
export default function OriginalPrice({ price, className, originalPrice }: OriginalPriceProps): JSX.Element | null {
    const pricePrefix = showStattPrice(price, originalPrice) ? 'price.statt' : 'price.uvp';

    const originalPriceHigherThanPrice = originalPrice && MoneyFunctions.greaterThan(originalPrice, price);

    if (!originalPriceHigherThanPrice) {
        return SKIP_RENDER;
    }

    return (
        <div className={classNames(componentName, className)}>
            <FormattedMessage
                id={pricePrefix}
                values={{
                    price: <Price price={originalPrice} />,
                }}
            />
        </div>
    );
}
