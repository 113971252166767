import { ReactElement, useState } from 'react';
import TabTitle from './TabTitle';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import classNames from 'classnames';
import { TabProps } from './Tab';

const componentName = 'tabs';

type TabsProps = BaseProps & {
    children: ReactElement<TabProps>[];
    onChange?: (index: number | string) => void;
    initialValue?: number | string;
};

const Tabs = ({ className, children, onChange, initialValue = 0 }: TabsProps): ReactElement => {
    const [selectedTab, setSelectedTab] = useState<string | number>(initialValue);

    return (
        <div className={classNames(componentName, className)}>
            <div className={`${componentName}__list`}>
                {children.map(({ props: { tabRef, title, value } }, index) => (
                    <div className={`${componentName}__tab`} key={value} onClick={() => onChange && onChange(value)}>
                        <TabTitle
                            tabRef={tabRef}
                            title={title}
                            value={value}
                            active={value === selectedTab}
                            setSelectedTab={setSelectedTab}
                            isFirstItem={index === 0}
                            isLastItem={index === children.length - 1}
                        />
                    </div>
                ))}
            </div>
            <div className={`${componentName}__content`}>
                {children.find(({ props: { value } }) => value === selectedTab)}
            </div>
        </div>
    );
};

export default Tabs;
