import { useAppSelector } from '../store/hooks/hooks';
import { CartState } from '../store/reducer/cart';
import { shallowEqual } from 'react-redux';

/**
 * Hook that returns cart data.
 * @returns cart
 */
export const useCart = (equalityCheck = false): CartState => {
    return useAppSelector((state) => state.cart, {
        equalityFn: equalityCheck ? shallowEqual : undefined,
    });
};
