export const noConsentPages = [
    'impressum',
    'datenschutz',
    'mentions-legales',
    'protection-donnees',
    'privacyverklaring',
    'dse',
    'imprint',
    'privacy',
];
