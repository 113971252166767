import * as React from 'react';
import { ButtonProps } from '../Button/types';
import { BrxCTAButton } from '@mediashop/app/bloomreach/types';
import Button from '../Button';

interface CTAButtonProps extends ButtonProps {
    children: React.ReactNode;
    ctaButton?: BrxCTAButton;
}
const CTAButton = ({ ctaButton, children, ...buttonProps }: CTAButtonProps) => {
    return (
        <Button
            {...buttonProps}
            customStyles={{
                ...buttonProps.customStyles,
                color: ctaButton?.fontColorHex ? ctaButton.fontColorHex : undefined,
                background: ctaButton?.backgroundColorHex ? ctaButton.backgroundColorHex : undefined,
            }}
        >
            {children}
        </Button>
    );
};

export default CTAButton;
