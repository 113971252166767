import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { NeededPriceType } from '../../api/types/PriceType';
import { queries, QueryKeys } from '..';
import ProductClient from '../../api/ProductClient';
import { useProductApiClient } from '../../api/hooks/useProductApiClient';
import { useShopContext } from '../../hooks/useShopContext';

type ProductsBySku = QueryKeys['products']['productsBySku'];

const fetchProductsBySku = (productClient: ProductClient) => (ctx: QueryFunctionContext<ProductsBySku['queryKey']>) => {
    const [, , skus, neededPriceType, locale, country, currency] = ctx.queryKey;

    return productClient.getProductsBySku(skus, locale, country, currency, neededPriceType);
};

export const useProductsBySku = (enabled: boolean, skus: string[], neededPriceType?: NeededPriceType) => {
    const apiClient = useProductApiClient();
    const { locale, country, currency } = useShopContext();

    return useQuery({
        ...queries.products.productsBySku(skus, locale, country, currency, neededPriceType),
        queryFn: fetchProductsBySku(apiClient),
        enabled,
    });
};
