import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { FormattedMessage } from 'react-intl';

const componentName = 'progress-bar';

export interface ProgressBarProps extends BaseProps {
    messageId: string;
    items: number;
    total: number;
}

/**
 * Display a ProgressBar for PLP and PDP.
 * Multiple sizes and colors available.
 */
function ProgressBar({ messageId, items = 0, total = 0 }: ProgressBarProps) {
    const progressWidth = (100 / total) * items;

    return (
        <div className={`${componentName}__details-container`}>
            <span className={`${componentName}__articles-container`}>
                <FormattedMessage id={messageId} values={{ items, total }} />
            </span>

            <div className={`${componentName}__progress-bar`}>
                <span className={`${componentName}__progress`} style={{ width: `${progressWidth}%` }} />
            </div>
        </div>
    );
}

export default injectComponent('pattern.atom.ProgressBar', ProgressBar, 'catalog-base');
