import { ThunkMiddleware } from 'redux-thunk';
import { RootState } from '../index';
import { handlePageReceivedAction } from './utils/handlePageReceivedAction';
import analytics from '../../analytics';
import { ProjectStateFinished } from '../reducer/project';
import { pageReceived } from '../reducer/page';
import { checkoutStarted, orderSummaryViewed, paymentsViewed } from '../reducer/checkout';
import { orderCreated } from '../reducer/order';
import { cartEmptyReceived, cartReceived, lineItemAdded } from '../reducer/cart';
import { clientSideTookOver } from '../reducer/renderContext';

type SupportedActionTypes =
    | ReturnType<typeof lineItemAdded>
    | ReturnType<typeof cartEmptyReceived>
    | ReturnType<typeof cartReceived>
    | ReturnType<typeof clientSideTookOver>
    | ReturnType<typeof checkoutStarted>
    | ReturnType<typeof paymentsViewed>
    | ReturnType<typeof orderSummaryViewed>
    | ReturnType<typeof orderCreated>
    | ReturnType<typeof pageReceived>;

export const analyticsMiddleware: ThunkMiddleware<RootState> =
    (storeApi) => (next) => (action: SupportedActionTypes) => {
        if (typeof window !== 'undefined') {
            const { type } = action;
            const state = storeApi.getState();

            switch (type) {
                case clientSideTookOver.type: {
                    analytics.dispatchTestMode(state.project.key!, state.renderContext.environment);
                    break;
                }

                case cartReceived.type: {
                    const { cart } = action.payload;

                    analytics.dispatchCartContentReceived({
                        cart,
                        emarsysAccountCurrency: (state.project as ProjectStateFinished).emarsysAccountCurrency,
                        exchangeRates: state.context.exchangeRates,
                    });
                    break;
                }

                case cartEmptyReceived.type: {
                    analytics.dispatchCartContentReceived({
                        emarsysAccountCurrency: (state.project as ProjectStateFinished).emarsysAccountCurrency,
                        exchangeRates: state.context.exchangeRates,
                    });
                    break;
                }

                case lineItemAdded.type: {
                    const { sku, quantity, facebookTrackingId } = action.payload;

                    analytics.dispatchAddToCart({
                        cart: state.cart,
                        sku,
                        quantity,
                        emarsysAccountCurrency: (state.project as ProjectStateFinished).emarsysAccountCurrency,
                        exchangeRates: state.context.exchangeRates,
                        facebookTrackingId,
                    });
                    break;
                }

                case pageReceived.type: {
                    handlePageReceivedAction(action.payload, state);
                    break;
                }

                case orderCreated.type: {
                    const { order, facebookTrackingId } = action.payload;

                    analytics.dispatchOrderCreated({
                        emarsysAccountCurrency: (state.project as ProjectStateFinished).emarsysAccountCurrency,
                        exchangeRates: state.context.exchangeRates,
                        order,
                        facebookTrackingId,
                    });
                    break;
                }

                case checkoutStarted.type: {
                    analytics.dispatchCheckoutStep({
                        cart: state.cart,
                        pageType: state.page.pageType,
                        step: 2,
                        type: action.payload.checkoutType,
                    });

                    analytics.dispatchCheckoutAccountChanged({
                        step: 2,
                        optionType: 'Account',
                        option: action.payload.activeTab,
                    });
                    break;
                }

                case paymentsViewed.type: {
                    analytics.dispatchCheckoutStep({
                        cart: state.cart,
                        pageType: state.page.pageType,
                        step: 3,
                        type: state.checkout.type!,
                    });
                    break;
                }

                case orderSummaryViewed.type: {
                    analytics.dispatchCheckoutStep({
                        cart: state.cart,
                        pageType: state.page.pageType,
                        step: 4,
                        type: state.checkout.type!,
                    });
                    break;
                }

                default:
                    break;
            }
        }

        return next(action);
    };
