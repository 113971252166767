import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { CheckoutStepDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';
import { CartState } from '../../store/reducer/cart';
import { getProductDataLayerFromCartLineItem } from '../utils/getProductDataLayerFromCartLineItem';

type CheckoutStepData = {
    type: 'MultiPage' | 'OnePage';
    step: 1 | 2 | 3 | 4;
    cart: CartState;
    pageType: string;
};

const createGtmDataLayer = (
    event: TagManagerEvent,
    { step, cart, pageType }: CheckoutStepData
): CheckoutStepDataLayer => {
    const products = cart.cart?.lineItems.map((lineItem) =>
        getProductDataLayerFromCartLineItem(lineItem, lineItem.quantity)
    );

    return {
        dataLayer: {
            pageType,
            event,
            ecommerce: {
                checkout: {
                    actionField: {
                        step,
                    },
                    products,
                },
            },
        },
    };
};

export const dispatchCheckoutStep = (data: CheckoutStepData): void => {
    triggerGtmEvent(createGtmDataLayer(ANALYTICS_EVENT.CHECKOUT_STEP, data));
};
