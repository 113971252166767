import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BackgroundProps, BaseProps, BrxHeadlines, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ProductComponentDetailsMolecule = lazy(() => lazyRetry(() => import('./ProductComponentDetails')));

export type ProductComponentDetailsProps = BaseProps & {
    headlines: BrxHeadlines;
    image: BrxImageWithAltText;
    mobileImage: BrxImageWithAltText;
    background: BackgroundProps;
    descriptionItems: {
        title: string;
        description: string;
        coordinates: {
            coordinates: string;
            coordinatesMobile: string;
            productId: string;
            text: string;
        };
    }[];
};

const ProductComponentDetails = (props: ProductComponentDetailsProps): JSX.Element => (
    <LazyComponent>
        <ProductComponentDetailsMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ProductComponentDetails', ProductComponentDetails);
