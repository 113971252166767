import { Order } from '../types/ClientOrder';
import { OrderDto } from '../dtos/order/OrderDto';
import CartMapper from './CartMapper';
import { CustomerOrderDto } from '../dtos/customer/order/customerOrderDto';
import AddressMapper from './AddressMapper';
import { CustomerOrderOverviewDto } from '../dtos/customer/order/customerOrderOverviewDto';
import { CustomerOrder } from '../../types/customer/order/customerOrder';
import { CustomerOrderOverview } from '../../types/customer/order/customerOrderOverview';

export default class OrderMapper {
    /**
     * Map order from api-gateway to a more consumable order-type.
     * @param order
     */
    static mapOrder(order: OrderDto): Order {
        return {
            ...order,
            cart: CartMapper.mapCart(order.cart),
            orderedCart: CartMapper.mapCart(order.orderedCart),
            orderNumber: order.orderNumber?.toString(),
        };
    }

    static mapCustomerOrder(order: CustomerOrderDto): CustomerOrder {
        return {
            ...order,
            billingAddress: AddressMapper.mapAddressDto(order.billingAddress)!,
            shippingAddress: AddressMapper.mapAddressDto(order.shippingAddress)!,
        };
    }

    static mapCustomerOrderOverview(order: CustomerOrderOverviewDto): CustomerOrderOverview {
        return { ...order };
    }
}
