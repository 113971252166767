export const QueryKeys = {
    FETCH_CART: 'FETCH_CART',
    FETCH_CHEAPER_SET_PRICE: 'FETCH_CHEAPER_SET_PRICE',
    FETCH_CUSTOMER: 'FETCH_CUSTOMER',
    FETCH_CUSTOMER_SIGN_IN_STATE: 'FETCH_CUSTOMER_SIGN_IN_STATE',
    FETCH_ORDER: 'FETCH_ORDER',
    FETCH_ORDERS: 'FETCH_ORDERS',
    FETCH_PRODUCT_REVIEWS: 'FETCH_PRODUCT_REVIEWS',
    FETCH_USER_MANUALS: 'FETCH_USER_MANUALS',
    FETCH_WKO_PRODUCTS: 'FETCH_WKO_PRODUCTS',
    FETCH_IS_CUSTOMER_SUBSCRIBED: 'FETCH_IS_CUSTOMER_SUBSCRIBED',
} as const;
