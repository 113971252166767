export default [
    {
        name: 'Austria',
        dialCode: '+43',
        code: 'AT',
    },
    {
        name: 'Germany',
        dialCode: '+49',
        code: 'DE',
    },
    {
        name: 'Switzerland',
        dialCode: '+41',
        code: 'CH',
    },
    {
        name: 'Liechtenstein',
        dialCode: '+423',
        code: 'LI',
    },
    {
        name: 'Italy',
        dialCode: '+39',
        code: 'IT',
    },
    {
        name: 'Belgium',
        dialCode: '+32',
        code: 'BE',
    },
    {
        name: 'Netherlands',
        dialCode: '+31',
        code: 'NL',
    },
    {
        name: 'Bulgaria',
        dialCode: '+359',
        code: 'BG',
    },
    {
        name: 'Cyprus',
        dialCode: '+357',
        code: 'CY',
    },
    {
        name: 'Czech Republic',
        dialCode: '+420',
        code: 'CZ',
    },
    {
        name: 'Denmark',
        dialCode: '+45',
        code: 'DK',
    },
    {
        name: 'Estonia',
        dialCode: '+372',
        code: 'EE',
    },
    {
        name: 'Spain',
        dialCode: '+34',
        code: 'ES',
    },
    {
        name: 'Finland',
        dialCode: '+358',
        code: 'FI',
    },
    {
        name: 'France',
        dialCode: '+33',
        code: 'FR',
    },
    {
        name: 'United Kingdom',
        dialCode: '+44',
        code: 'GB',
    },
    {
        name: 'Greece',
        dialCode: '+30',
        code: 'GR',
    },
    {
        name: 'Croatia',
        dialCode: '+385',
        code: 'HR',
    },
    {
        name: 'Hungary',
        dialCode: '+36',
        code: 'HU',
    },
    {
        name: 'Ireland',
        dialCode: '+353',
        code: 'IE',
    },
    {
        name: 'Lithuania',
        dialCode: '+370',
        code: 'LT',
    },
    {
        name: 'Luxembourg',
        dialCode: '+352',
        code: 'LU',
    },
    {
        name: 'Latvia',
        dialCode: '+371',
        code: 'LV',
    },
    {
        name: 'Malta',
        dialCode: '+356',
        code: 'MT',
    },
    {
        name: 'Poland',
        dialCode: '+48',
        code: 'PL',
    },
    {
        name: 'Portugal',
        dialCode: '+351',
        code: 'PT',
    },
    {
        name: 'Sweden',
        dialCode: '+46',
        code: 'SE',
    },
    {
        name: 'Slovakia',
        dialCode: '+421',
        code: 'SK',
    },
    {
        name: 'Slovenia',
        dialCode: '+386',
        code: 'SI',
    },
    {
        name: 'Romania',
        dialCode: '+40',
        code: 'RO',
    },
];
