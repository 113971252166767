import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import Select from '../../atom/Select';
import { injectComponent } from '@mediashop/app/component-injector';
import classnames from 'classnames';
import LoadingIndicator from '../../atom/LoadingIndicator';
import { SelectOption } from '@mediashop/app/domain/Quantity';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

type Props = BasePropsWithChildren & {
    onClick: () => void;
    onChange: (event: any) => void;
    options: SelectOption[];
    value: number;
    disabled?: boolean;
    isLoading: boolean;
    ariaLabel: string;
    showSelect?: boolean;
};

/**
 * Render a combination of select-box and button.
 * This currently doesn't use the default button-styling but a separate class.
 */
function SelectButton({
    onClick,
    onChange,
    children,
    options,
    value,
    disabled,
    isLoading,
    className = '',
    ariaLabel,
    showSelect = true,
}: Props) {
    const loadingOrDisabled = isLoading || disabled;
    const isSingleChoiceSelect = options.length === 1 && options[0].value === 1;

    return (
        <div
            className={classnames('select-button', className, {
                'select-button--disabled': disabled,
                'select-button--loading': isLoading,
            })}
        >
            {showSelect && !isSingleChoiceSelect ? (
                <Select
                    className="select-button__select"
                    onChange={onChange}
                    value={value}
                    size="large"
                    disabled={loadingOrDisabled}
                >
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
            ) : (
                SKIP_RENDER
            )}
            <button
                className="select-button__button"
                onClick={onClick}
                disabled={loadingOrDisabled}
                aria-label={ariaLabel}
            >
                {isLoading ? <LoadingIndicator /> : children}
            </button>
        </div>
    );
}

export default injectComponent('pattern.molecule.SelectButton', SelectButton);
