import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy, ReactElement } from 'react';
import { MainNavigationCustomQuickLinksProps } from '../../types';

const BurgerMenuQuickLinksLazy = lazy(() => lazyRetry(() => import('./BurgerMenuQuickLinks')));

export type BurgerMenuQuickLinksProps = BaseProps &
    MainNavigationCustomQuickLinksProps & {
        closeBurgerMenu: () => void;
    };

const BurgerMenuQuickLinks = (props: BurgerMenuQuickLinksProps): ReactElement => (
    <LazyComponent>
        <BurgerMenuQuickLinksLazy {...props} />
    </LazyComponent>
);

export default BurgerMenuQuickLinks;
