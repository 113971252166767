import { useMemo } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import BloomreachHtml, { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import BloomreachLink from '@mediashop/app/components/BloomreachLink';
import Button from '../../atom/Button';
import Headlines from '../../atom/Headlines';
import { BackgroundProps, BaseProps, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import ContentWrapper from '../../atom/ContentWrapper';
import BackgroundColorWrapper from '../../atom/BackgroundColorWrapper';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

type CTAProps = BaseProps & {
    headlines: BrxHeadlines;
    text: BrxHtml;
    ctaTextPrimary: string;
    ctaReferencePrimary: string;
    ctaTextSecondary: string;
    ctaReferenceSecondary: string;
    background: BackgroundProps;
    ctaReferencePrimaryTarget: 'sameTab' | 'newTab';
    ctaReferenceSecondaryTarget: 'sameTab' | 'newTab';
};

/**
 * This component renders a Cta Banner
 */
function CTA({
    headlines,
    text,
    ctaTextPrimary,
    ctaReferencePrimary,
    ctaTextSecondary,
    ctaReferenceSecondary,
    background = 'secondary',
    ctaReferencePrimaryTarget = 'sameTab',
    ctaReferenceSecondaryTarget = 'sameTab',
}: CTAProps) {
    const componentName = 'cta';

    const anchorClickHandler = (reference: string) => {
        if (!reference.startsWith('#')) {
            return undefined;
        }

        return (event: MouseEvent) => {
            event.preventDefault();
            const sanitizedId = reference.replace('#', '');
            document?.getElementById(sanitizedId)?.scrollIntoView();
        };
    };

    const isNewTabPrimary = useMemo(() => ctaReferencePrimaryTarget === 'newTab', [ctaReferencePrimaryTarget]);

    const isNewTabSecondary = useMemo(() => ctaReferenceSecondaryTarget === 'newTab', [ctaReferenceSecondaryTarget]);

    return (
        <BackgroundColorWrapper backgroundColor={background}>
            <ContentWrapper>
                <div className={`${componentName}__container`}>
                    {headlines?.headline ? (
                        <Headlines {...headlines} className={`${componentName}__headlines`} />
                    ) : (
                        SKIP_RENDER
                    )}

                    <div className={`${componentName}__content-container`}>
                        <BloomreachHtml className={`${componentName}__text-container`} html={text} />
                        <div className={`${componentName}__button-primary-container`}>
                            <BloomreachLink
                                href={ctaReferencePrimary}
                                onClick={anchorClickHandler(ctaReferencePrimary)}
                                target={isNewTabPrimary ? '_blank' : undefined}
                                rel="noopener noreferrer"
                            >
                                <Button className={`${componentName}__button-primary`}>{ctaTextPrimary}</Button>
                            </BloomreachLink>
                        </div>
                        {ctaReferenceSecondary || ctaTextSecondary ? (
                            <div className={`${componentName}__button-secondary-container`}>
                                <BloomreachLink
                                    className={`${componentName}__button-secondary`}
                                    href={ctaReferenceSecondary}
                                    onClick={anchorClickHandler(ctaReferenceSecondary)}
                                    target={isNewTabSecondary ? '_blank' : undefined}
                                    rel="noopener noreferrer"
                                >
                                    {ctaTextSecondary}
                                </BloomreachLink>
                            </div>
                        ) : (
                            SKIP_RENDER
                        )}
                    </div>
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.CTA', CTA);
