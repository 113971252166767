import { useMemo } from 'react';
import noop from 'lodash/noop';
import { FormattedMessage, useIntl } from 'react-intl';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import { BaseProps, BrxLink } from '@mediashop/app/bloomreach/types';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import BloomreachLink from '@mediashop/app/components/BloomreachLink';
import { useCustomer } from '@mediashop/app/hooks/api/useCustomer';
import { useLogoutCustomer } from '@mediashop/app/hooks/api/useLogoutCustomer';
import useToast from '@mediashop/app/hooks/useToast';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

const componentName = 'login-dropdown';

type LoginDropdownProps = BaseProps & {
    onLogout?: () => void;
    links: BrxLink[];
};

export function LoginDropdown({ onLogout = noop, links }: LoginDropdownProps): JSX.Element {
    const intl = useIntl();
    const navigate = useNavigate();
    const { formatLinkBase } = useReactRouterLink();
    const toast = useToast();

    const customer = useCustomer();

    const customerFullName = useMemo(
        () => [customer?.firstName, customer?.middleName, customer?.lastName].filter(Boolean).join(' '),
        [customer]
    );

    const onLogoutSuccess = () => {
        toast.success(intl.formatMessage({ id: 'mainNavigation.loggedout' }));

        setTimeout(() => {
            navigate(EMPTY_STRING);
        }, 200);

        onLogout();
    };

    const { mutate: performLogout } = useLogoutCustomer({
        onSuccess: onLogoutSuccess,
    });

    return (
        <div className={`${componentName}`}>
            <p className={`${componentName}__welcome-message`}>
                <FormattedMessage id="mainNavigation.greeting" values={{ name: <span>{customerFullName}</span> }} />
            </p>
            <ul>
                {links?.map((link) => (
                    <li key={link.text}>
                        <BloomreachLink href={formatLinkBase(link.reference)}>{link.text}</BloomreachLink>
                    </li>
                ))}
                <li>
                    <span onClick={() => performLogout()}>
                        <FormattedMessage id="mainNavigation.logout" />
                    </span>
                </li>
            </ul>
        </div>
    );
}
