import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Money } from '@mediashop/app/api/types/Money';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ClubPriceMolecule = lazy(() => lazyRetry(() => import('./ClubPrice')));

export type ClubPriceSizeProps = 'small' | 'medium';

export type ClubPriceAlignmentProps = 'left' | 'right';

export type ClubPriceProps = BaseProps & {
    className?: string;
    price: Money;
    size?: ClubPriceSizeProps;
    align?: ClubPriceAlignmentProps;
    showFrom?: boolean;
    displayModalIndicator?: boolean;
};

const ClubPrice = (props: ClubPriceProps): JSX.Element => (
    <LazyComponent>
        <ClubPriceMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ClubPrice', ClubPrice, 'base');
