import { BaseProps, BrxLink } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useCustomer } from '@mediashop/app/hooks/api/useCustomer';
import { useCart } from '@mediashop/app/hooks/useCart';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';
import { useOutsideClicked } from '@mediashop/app/hooks/useOutsideClicked';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import useRoute from '@mediashop/app/hooks/useRoute';
import Icon from '@mediashop/base/pattern/atom/Icon';
import CountrySwitch from '@mediashop/base/pattern/molecule/CountrySwitch';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { LoginDropdown } from '../LoginDropdown';

const componentName = 'user-menu';

export type UserMenuProps = BaseProps & {
    linksForLoggedInUsers: BrxLink[];
    userIconLinkLoggedIn: BrxLink;
    userIconLinkLoggedOut: BrxLink;
    closeMenu?: () => void;
};

const UserMenu = ({
    className,
    linksForLoggedInUsers,
    userIconLinkLoggedIn,
    userIconLinkLoggedOut,
    closeMenu,
}: UserMenuProps): JSX.Element => {
    const intl = useIntl();
    const customer = useCustomer();
    const { cart } = useCart();
    const { formatLinkBase } = useReactRouterLink();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdownClicked, setIsDropdownClicked] = useState(false);
    const accountRef = useRef<HTMLDivElement>(null);

    const isLoggedInCustomer = Boolean(customer);
    const loginOrAccountOverviewLink = isLoggedInCustomer
        ? formatLinkBase(userIconLinkLoggedIn.reference)
        : formatLinkBase(userIconLinkLoggedOut.reference);

    const cartRoute = useRoute('Frontend.Master.Cart');
    const cartLink = formatLinkBase(cartRoute);

    const resetAccountStates = () => {
        setIsDropdownClicked(false);
        setIsDropdownOpen(false);
    };

    const onLinkClick = (event) => {
        // check if the device is a touch device
        if (matchMedia('(pointer:coarse)').matches && isLoggedInCustomer) {
            event.preventDefault();
            event.stopPropagation();

            if (isDropdownClicked) {
                resetAccountStates();
                navigate(loginOrAccountOverviewLink);
            } else {
                setIsDropdownClicked(true);
            }
        }
    };

    useEffect(() => resetAccountStates(), [pathname]);
    useOutsideClicked(accountRef, () => resetAccountStates());

    return (
        <div className={classNames(componentName, className)}>
            <CountrySwitch showTitle={false} icon="ChevronDown" />

            <div
                ref={accountRef}
                className={`${componentName}__account`}
                onClick={closeMenu && closeMenu}
                onMouseEnter={() => setIsDropdownOpen(true)}
                onTouchStart={() => setIsDropdownOpen(true)}
                onMouseLeave={resetAccountStates}
            >
                <Link
                    to={loginOrAccountOverviewLink}
                    aria-label={intl.formatMessage({ id: 'mainNavigation.accountLinkLabel' })}
                    onClick={onLinkClick}
                >
                    <Icon name={isLoggedInCustomer ? 'AccountFilled' : 'Account'} />
                </Link>
                {isLoggedInCustomer && isDropdownOpen ? <LoginDropdown links={linksForLoggedInUsers} /> : SKIP_RENDER}
            </div>

            <Link className={`${componentName}__cart-link`} to={cartLink} onClick={closeMenu && closeMenu}>
                <Icon name="Cart" />
                <div className={`${componentName}__cart-item-amount`}>{cart?.totalLineItemQuantity ?? 0}</div>
            </Link>
        </div>
    );
};

export default UserMenu;
