import { throttle } from 'throttle-debounce';
import { AppThunk, AppThunkDispatch } from '..';
import { viewportDimensionsChanged } from '../reducer/renderContext';

let resizeEventListener: EventListenerOrEventListenerObject | undefined;

/**
 * Continuously dispatch ViewportDimensionsChangedMessage's on resize
 */
export function viewportDimensionsChangedListener(): AppThunk {
    const dispatchViewportDimensions = (dispatch: AppThunkDispatch) =>
        dispatch(
            viewportDimensionsChanged({
                viewportDimensions: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                    orientation: window.innerHeight > window.innerWidth ? 'portrait' : 'landscape',
                },
            })
        );

    return function (dispatch) {
        dispatchViewportDimensions(dispatch);
        if (resizeEventListener) {
            // Remove previous listener if this is not the first dispatch
            window.removeEventListener('resize', resizeEventListener);
        }

        resizeEventListener = throttle(
            200,
            dispatchViewportDimensions.bind(null, dispatch)
        ) as EventListenerOrEventListenerObject;
        window.addEventListener('resize', resizeEventListener);
    };
}
