import { FormattedMessage } from 'react-intl';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { Price } from '../../molecule/Price';
import { Money } from '@mediashop/app/api/types/Money';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

interface UnitPriceProps {
    variant: Variant;
}

/**
 * Display the unit-price of an article.
 * Rendered as "100€ / 100ml".
 * Unit-Price is calculated from article-price and purchaseContent & baseUnitMultiplier.
 *
 * baseUnitMultiplier: A multiplier for calculating between referenceUnit and referenceUnit (e.g. 0.1 for l to ml)
 * referenceUnit: A string with the name of the referenceUnit (e.g. 100ml)
 *
 * @param variant Product variant
 * @param className
 */
function UnitPrice({ variant }: UnitPriceProps): JSX.Element | null {
    if (!variant.price) {
        return null;
    }

    const { baseUnitMultiplier = 0, purchaseContent = 0, referenceUnit = '' } = variant.attributes;

    const hasUnitPrice = Boolean(baseUnitMultiplier) && Boolean(referenceUnit) && Boolean(purchaseContent);

    const referencePrice: Money = {
        ...variant.price,
        centAmount: (baseUnitMultiplier / purchaseContent) * variant.price.centAmount,
    };

    return hasUnitPrice ? (
        <FormattedMessage
            id="price.reference"
            values={{
                price: <Price price={referencePrice} />,
                baseUnitMultiplier,
                referenceUnit,
            }}
        />
    ) : (
        SKIP_RENDER
    );
}

export default UnitPrice;
