import { useQuery } from '@tanstack/react-query';
import { useLoader } from '../../store/loader';
import { queries } from '..';
import { useShopContext } from '../../hooks/useShopContext';
import { useQueryParams } from '../../hooks/useQueryParams';

const PRODUCT_ID_PARAM = 'product_id';

/**
 * Hook that gets recommended products for the bounce-commerce exit page.
 */
export const useExitRecommendations = () => {
    const { productClient } = useLoader();
    const { country } = useShopContext();
    const { [PRODUCT_ID_PARAM]: sku } = useQueryParams();

    const fetchRecommendedProducts = () => productClient.getRecommendedProducts(sku);

    return useQuery({
        ...queries.products.exitRecommendations(sku, country),
        queryFn: fetchRecommendedProducts,
    });
};
