import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const UserManualsLazy = lazy(() => lazyRetry(() => import('./UserManuals')));

const UserManuals = (): JSX.Element => (
    <LazyComponent>
        <UserManualsLazy />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.Manuals', UserManuals);
