import Theme from '@mediashop/app/Theme';
import VerticalBrandsTheme from '@mediashop/vertical-brands-base';
import './pattern/template/Meta/index.tsx';
import icons from './icons';

const TupperwareNlTheme: Theme = {
    name: 'tupperware-nl',
    extends: VerticalBrandsTheme,
    icons,
};

export default TupperwareNlTheme;
