import {
    BaseProps,
    BrxBackground,
    BrxCTAButton,
    BrxHeadlines,
    BrxImageWithAltText,
} from '@mediashop/app/bloomreach/types';
import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const FlexboxMolecule = lazy(() => lazyRetry(() => import('./Flexbox')));

export type FlexboxProps = BaseProps & {
    background: BrxBackground;
    headline: BrxHeadlines;
    items: FlexboxItemProps[];
};

type FlexboxItemProps = {
    contentType: string;
    ctaButton: BrxCTAButton;
    ctaTarget: 'sameTab' | 'newTab' | null | undefined;
    image: BrxImageWithAltText;
    size: string;
};

const Flexbox = (props: FlexboxProps): JSX.Element => (
    <LazyComponent>
        <FlexboxMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.Flexbox', Flexbox);
