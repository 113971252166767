import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxRelationType, ContentBackgroundProps } from '@mediashop/app/bloomreach/types';
import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { useGetProductBySlug } from '@mediashop/app/hooks/useGetProductBySlug';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import useRedirectToNotFound from '@mediashop/app/hooks/useRedirectToNotFound';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ProductDetailsMolecule = lazy(() => lazyRetry(() => import('./ProductDetails')));

export type ProductDetailsContainerProps = BaseProps & {
    contentBackground: ContentBackgroundProps;
    infotextForPaymentByInstallments?: BrxHtml;
    productIDs: string[];
    productRelationType?: BrxRelationType;
    shippingCostsInfo?: BrxHtml;
    hideAddToCartBar: boolean;
};

function ProductDetailsContainer(props: ProductDetailsContainerProps) {
    const { product, status } = useGetProductBySlug();
    const redirectToNotFoundPage = useRedirectToNotFound();

    const isNotFound = status === 'notfound';

    if (isNotFound) {
        redirectToNotFoundPage();
    }

    return !product?.product || isNotFound ? (
        SKIP_RENDER
    ) : (
        <LazyComponent>
            <ProductDetailsMolecule {...props} product={product} />
        </LazyComponent>
    );
}

export default injectComponent('pattern.molecule.ProductDetails', ProductDetailsContainer);
