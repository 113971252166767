import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EMPTY_STRING } from '../../constants/semanticConstants';

export interface TestData {
    testId: string;
    group: string;
}

const initialState: TestData = { testId: EMPTY_STRING, group: EMPTY_STRING };

const testDataSlice = createSlice({
    name: 'testData',
    initialState,
    reducers: {
        testDataReceived(state, action: PayloadAction<{ testData: TestData }>) {
            const { testData } = action.payload;
            return {
                ...state,
                ...testData,
            };
        },
    },
});

export const { testDataReceived } = testDataSlice.actions;

export default testDataSlice.reducer;
