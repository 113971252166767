import { Product, Variant } from '@mediashop/app/api/types/ClientProduct';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { useEffect } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import RadioButton from '@mediashop/base/pattern/atom/RadioButton';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from '@mediashop/base/pattern/atom/Select';
import { BaseProps } from '@mediashop/app/bloomreach/types';

const componentName = 'product-subscription-options';

type Props = BaseProps & {
    aboProduct: Product;
    activeVariant?: Variant;
    setDelivery: (type: string) => void;
    selectedDelivery: string;
    setDeliveryInterval: (interval: number) => void;
    deliveryInterval: number;
    error: boolean;
};

type IntervalOption = {
    amount: number;
    unit: string;
};

function ProductDetailsContentSubscription({
    aboProduct,
    activeVariant,
    setDelivery,
    selectedDelivery,
    setDeliveryInterval,
    deliveryInterval,
    error,
}: Props): JSX.Element | null {
    const intl = useIntl();

    const isAboSelected = selectedDelivery === 'abo';
    const intervalOptions: IntervalOption[] = aboProduct.variants[0].attributes.subscriptionIntervalls.map((interval) =>
        JSON.parse(interval)
    );
    const recommendedInterval: IntervalOption | undefined = aboProduct.variants[0].attributes
        .subscriptionDefaultIntervall
        ? JSON.parse(aboProduct.variants[0].attributes.subscriptionDefaultIntervall)
        : undefined;

    if (intervalOptions.length === 0) {
        return null;
    }

    useEffect(() => {
        if (intervalOptions.length === 1) {
            setDeliveryInterval(intervalOptions[0].amount);
        }
    }, [intervalOptions]);

    let percentSavings;
    if (activeVariant && aboProduct) {
        percentSavings = Math.round(
            ((activeVariant?.price.centAmount - aboProduct?.variants[0].price.centAmount) /
                aboProduct?.variants[0].price.centAmount) *
                100
        );
    }

    return (
        <div className={componentName}>
            <div className={`${componentName}__radio-group`}>
                <RadioButton
                    name={`${componentName}__radio`}
                    value="once"
                    defaultChecked={selectedDelivery === 'once'}
                    setIsChecked={() => null}
                    onChange={() => setDelivery('once')}
                >
                    <FormattedMessage id="product.subscription.oneTimeDelivery" />
                </RadioButton>
                <RadioButton
                    name={`${componentName}__radio`}
                    value="abo"
                    defaultChecked={selectedDelivery === 'abo'}
                    setIsChecked={() => null}
                    onChange={() => setDelivery('abo')}
                >
                    <FormattedMessage id="product.subscription.abo" />
                    <div className={`${componentName}__abo-info-text`}>
                        <FormattedMessage id="product.subscription.aboInfo" values={{ percent: percentSavings }} />{' '}
                        <Icon name="Info" />
                    </div>
                </RadioButton>
            </div>
            {isAboSelected ? (
                <Select
                    size="large"
                    name="interval-select"
                    className={`${componentName}__select`}
                    value={deliveryInterval}
                    onChange={(event) => setDeliveryInterval(Number(event.target.value))}
                >
                    <option disabled value={0}>
                        {intl.formatMessage({ id: 'product.subscription.emptyOption' })}
                    </option>
                    {intervalOptions.map((option) => (
                        <option key={option.amount} value={option.amount}>
                            {intl.formatMessage(
                                {
                                    id: `product.subscription.intervalUnit.${option.unit}`,
                                },
                                {
                                    amount: option.amount,
                                }
                            )}
                            {option.amount === recommendedInterval?.amount
                                ? intl.formatMessage({ id: 'product.subscription.recommendedInterval' })
                                : SKIP_RENDER}
                        </option>
                    ))}
                </Select>
            ) : (
                SKIP_RENDER
            )}
            {error && isAboSelected ? (
                <span className={`${componentName}__interval-error`}>
                    <Icon className={`${componentName}__attention-icon`} name="Attention" />
                    <FormattedMessage id="product.emptyVariantError" />
                </span>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}

export default injectComponent(
    'pattern.molecule.ProductDetailsContentSubscription',
    ProductDetailsContentSubscription,
    'catalog-base'
);
