/* eslint-disable max-lines-per-function */
import { useAppSelector } from '@mediashop/app/store/hooks/hooks';
import { Children, useEffect, useMemo, useRef, useState } from 'react';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import classNames from 'classnames';
import SwiperCore from 'swiper';
import { Autoplay, Navigation, Thumbs, Pagination } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperRef } from 'swiper/react';
import Icon from '@mediashop/base/pattern/atom/Icon';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { breakpointWidthMap } from '@mediashop/app/config/breakpoints';
import { AutoplayOptions } from 'swiper/types/modules/autoplay';

type Props = BasePropsWithChildren &
    SwiperProps & {
        showArrows?: boolean;
        showPagination?: boolean;
        animatePagination?: boolean;
        useCustomTransitionAnimation?: boolean;
        isPlaying?: boolean;
        disablePagination?: boolean;
    };

const componentName = 'slider';

// eslint-disable-next-line complexity
function Slider({
    className,
    children,
    direction,
    showArrows = true,
    showPagination = true,
    animatePagination = false,
    modules = [],
    useCustomTransitionAnimation = false,
    isPlaying = false,
    disablePagination = false,
    ...swiperProps
}: Props): JSX.Element | null {
    const deviceType = useDeviceType();
    const userAgent = useAppSelector((state) => state.renderContext.userAgent);

    const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
    const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
    const [paginationEl, setPaginationEl] = useState<HTMLElement | null>(null);
    const [isArrowButtonHovered, setArrowButtonHovered] = useState(false);
    const [isSlideshowStopped, setSlideshowStopped] = useState(false);

    const swiperRef = useRef<SwiperRef | null>(null);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const swiperBreakpointOptions = useMemo(() => {
        if (swiperProps.breakpoints && deviceType) {
            return swiperProps.breakpoints[breakpointWidthMap[deviceType]];
        }

        return null;
    }, [deviceType, swiperProps]);

    const navOptions = {
        prevEl,
        nextEl,
        disabledClass: `arrow-button--navigation-disabled`,
    };

    const paginationOptions = {
        el: paginationEl,
        bulletClass: classNames(`${componentName}__pagination-bullet ${className}__pagination-bullet`, {
            [`${componentName}__pagination-disabled`]: disablePagination,
        }),
        bulletActiveClass: classNames(`${className}__pagination-bullet-active`, {
            [`${componentName}__pagination-bullet-active`]: !animatePagination,
            [`${componentName}__pagination-bullet-active-animated`]: animatePagination,
        }),
        clickable: true,
    };

    const startSlideshow = () => {
        const swiperInstance = swiperRef.current?.swiper;
        if (swiperInstance) {
            isSlideshowStopped && swiperInstance.slideNext();
            swiperInstance.autoplay.start();
            setSlideshowStopped(false);
        }
    };

    const stopSlideshow = () => {
        const swiperInstance = swiperRef.current?.swiper;
        if (swiperInstance) {
            swiperInstance.autoplay.stop();
            setSlideshowStopped(true);
        }
    };

    useEffect(() => {
        if (isPlaying) {
            startSlideshow();
        } else {
            stopSlideshow();
        }
    }, [isPlaying]);

    useEffect(() => {
        if (animatePagination) {
            const delay = (swiperProps?.autoplay as AutoplayOptions)?.delay;

            if (wrapperRef.current && delay) {
                wrapperRef.current?.style.setProperty(
                    '--swiper-pagination-bullet-animation-duration',
                    `${delay - 500}ms`
                );
            }
        }
    }, [animatePagination, swiperProps?.autoplay, wrapperRef]);

    if (!Children.count(children)) {
        return SKIP_RENDER;
    }

    return (
        <div
            ref={wrapperRef}
            className={classNames(componentName, `${className}__${componentName}`, {
                [`${componentName}__pagination-bullet-active-animation-paused-on-hover`]:
                    (swiperProps.autoplay as AutoplayOptions)?.pauseOnMouseEnter && !isArrowButtonHovered,
                [`${componentName}__pagination-bullet-active-animation-completed`]: isSlideshowStopped,
            })}
        >
            <div
                className={classNames(`${componentName}__inner-wrapper`, {
                    [`${componentName}__inner-wrapper--with-arrows`]: showArrows,
                })}
            >
                <Swiper
                    onUpdate={(swiper) => {
                        swiper.navigation.update();
                    }}
                    ref={swiperRef}
                    className={classNames(`${className}__swiper`, {
                        [`${componentName}__custom-transition-animation`]: useCustomTransitionAnimation,
                    })}
                    direction={direction ?? 'horizontal'}
                    grabCursor={swiperProps.grabCursor ?? true}
                    modules={[Autoplay, Navigation, Thumbs, Pagination, ...modules]}
                    navigation={navOptions}
                    observer={swiperProps.observer ?? true}
                    pagination={showPagination ? paginationOptions : undefined}
                    userAgent={userAgent}
                    {...swiperProps}
                    {...(swiperBreakpointOptions ?? {})}
                >
                    {children}
                </Swiper>

                {showArrows ? (
                    <div
                        className={`${componentName}__inner-wrapper--arrows-wrapper`}
                        onMouseEnter={() => setArrowButtonHovered(true)}
                        onMouseLeave={() => setArrowButtonHovered(false)}
                    >
                        <div
                            ref={(node) => setPrevEl(node)}
                            className={classNames(
                                'arrow-button',
                                { 'arrow-button--left': direction !== 'vertical' },
                                { 'arrow-button--top': direction === 'vertical' }
                            )}
                        >
                            {direction === 'vertical' ? <Icon name="ChevronUp" /> : <Icon name="ArrowLeft" />}
                        </div>
                        <div
                            ref={(node) => setNextEl(node)}
                            className={classNames(
                                'arrow-button',
                                { 'arrow-button--right': direction !== 'vertical' },
                                { 'arrow-button--bottom': direction === 'vertical' }
                            )}
                        >
                            {direction === 'vertical' ? <Icon name="ChevronDown" /> : <Icon name="ArrowRight" />}
                        </div>
                    </div>
                ) : (
                    SKIP_RENDER
                )}
            </div>

            {showPagination ? (
                <div
                    ref={(node) => setPaginationEl(node)}
                    className={classNames(`${componentName}__pagination`, `${className}__pagination`)}
                />
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}

export type SwiperType = SwiperCore;

export default injectComponent('pattern.molecule.Slider', Slider);
