import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import BloomreachImageResponsive from '../../atom/BloomreachImageResponsive';
import Headlines from '../../atom/Headlines';
import Badge, { BadgeStyleProps } from '../../atom/Badge';
import ContentWrapper from '../../atom/ContentWrapper';
import BackgroundColorWrapper from '../../atom/BackgroundColorWrapper';
import { BackgroundProps, BaseProps, BrxHeadlines, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const componentName = 'asymmetric-image';

type AsymmetricImageProps = BaseProps & {
    headlines: BrxHeadlines;
    desktopImage: BrxImageWithAltText;
    mobileImage: BrxImageWithAltText;
    imageTitle: string;
    imageSubtitle: string;
    titlePosition?: 'top' | 'bottom';
    titleStyle?: BadgeStyleProps;
    background?: BackgroundProps;
};

/**
 * AsymmetricImage Component
 * Provides the HTML for rendering of the AsymmetricImage.
 */
function AsymmetricImage({
    headlines,
    desktopImage,
    mobileImage,
    imageTitle,
    imageSubtitle,
    titlePosition = 'top',
    titleStyle = 'info',
    background = 'primary',
}: AsymmetricImageProps): JSX.Element {
    const deviceType = useDeviceType();
    const [device, setDevice] = useState('desktop');
    const isMobileDevice = device === 'mobile';

    useEffect(() => {
        setDevice(deviceType);
    }, [deviceType]);

    return (
        <BackgroundColorWrapper backgroundColor={background}>
            <ContentWrapper>
                <div
                    className={classNames(
                        componentName,
                        `${componentName}--${background}`,
                        `${componentName}--title-position-${titlePosition}`
                    )}
                >
                    {headlines?.headline ? (
                        <Headlines {...headlines} className={`${componentName}__headlines`} />
                    ) : (
                        SKIP_RENDER
                    )}

                    <div className={`${componentName}__content-container`}>
                        <div className={`${componentName}__image-container`}>
                            <BloomreachImageResponsive
                                alt={
                                    isMobileDevice && mobileImage.altText
                                        ? mobileImage.altText
                                        : desktopImage.altText || EMPTY_STRING
                                }
                                desktopImage={desktopImage.image}
                                mobileImage={mobileImage.image}
                                variant={BrXImageVariant.AsymetricImage}
                            />
                        </div>
                        <div className={`${componentName}__content`}>
                            {imageTitle && (
                                <Badge
                                    text={imageTitle}
                                    size="large"
                                    align="left"
                                    style={titleStyle}
                                    className={`${componentName}__image-title`}
                                />
                            )}
                            {imageSubtitle && (
                                <Badge
                                    text={imageSubtitle}
                                    size="medium"
                                    align="left"
                                    style={titleStyle}
                                    className={`${componentName}__image-subtitle`}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.AsymmetricImage', AsymmetricImage);
