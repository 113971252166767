import { lazy, ReactElement } from 'react';
import { LoginFormProps } from '../types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const LoginFormMolecule = lazy(() => lazyRetry(() => import('./LoginForm')));

const LoginForm = (props: LoginFormProps): ReactElement => (
    <LazyComponent>
        <LoginFormMolecule {...props} />
    </LazyComponent>
);

export default LoginForm;
