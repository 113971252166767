import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Cart } from '@mediashop/app/api/types/ClientCart';
import { Money } from '@mediashop/app/api/types/Money';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const OnePageCheckoutCartMolecule = lazy(() => lazyRetry(() => import('./Cart')));

export type OnePageCheckoutCartProps = BaseProps & {
    cart: Cart;
    productTotal: Money;
};

const OnePageCheckoutCart = (props: OnePageCheckoutCartProps): JSX.Element => (
    <LazyComponent>
        <OnePageCheckoutCartMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.OnePageCheckout.Cart', OnePageCheckoutCart);
