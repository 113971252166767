import * as React from 'react';
import { useIntl } from 'react-intl';
import Select from '../../../atom/Select';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { RadioFieldValue } from '@mediashop/app/api/types/AddressConfiguration';
import classNames from 'classnames';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const componentName = 'dynamic-select';

type DynamicSelectProps = BaseProps & {
    name: string;
    label: string;
    values: RadioFieldValue[];
    placeholderId: string;
    value?: string;
    disabled?: boolean;
    error?: string;
    required?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onBlur?: () => void;
};

function DynamicSelect({
    label,
    name,
    placeholderId,
    value,
    values,
    disabled = false,
    error,
    required,
    onChange,
    onBlur,
}: DynamicSelectProps): JSX.Element {
    const intl = useIntl();

    const placeholder = intl.formatMessage({ id: placeholderId });

    return (
        <>
            <Select
                ariaLabel={label}
                className={classNames(componentName, { [`${componentName}--error`]: Boolean(error) })}
                disabled={disabled}
                error={Boolean(error)}
                size="small"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                required={required}
            >
                <option value="" disabled>
                    {required ? `${placeholder}*` : placeholder}
                </option>
                {values.map((value) => (
                    <option value={value.value} key={value.value.toString()}>
                        {intl.formatMessage({ id: `${value.labelId}` })}
                    </option>
                ))}
            </Select>

            {error ? <div className={`${componentName}__error-message`}>{error}</div> : SKIP_RENDER}
        </>
    );
}

export default DynamicSelect;
