import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy } from 'react';
import { OptionSelectorProps } from './OptionSelector';

const OptionSelectorLazy = lazy(() => lazyRetry(() => import('./OptionSelector')));

const OptionSelector = (props: OptionSelectorProps): JSX.Element => (
    <LazyComponent>
        <OptionSelectorLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.OptionSelector', OptionSelector);
