import classNames from 'classnames';
import { useIntl } from 'react-intl';
import ReactPaginate from 'react-paginate';
import { injectComponent } from '@mediashop/app/component-injector';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { BaseProps } from '@mediashop/app/bloomreach/types';

// The range of pages displayed.
const DISPLAYED_PAGE_RANGE = 3;

// The number of pages to display for margins ("...")
const MARGIN_PAGE_RANGE = 1;

const componentName = 'pagination';

type PaginationProps = BaseProps & {
    pageCount: number;
    selectedPage?: number;
    onPageChange: (page: number) => void;
    hrefBuilder?: (page: number) => void;
};

/**
 * Component that shows a pagination bar that can be used to paginate any kind of data.
 */
function Pagination({
    className,
    pageCount,
    selectedPage = 1,
    onPageChange,
    hrefBuilder,
}: PaginationProps): JSX.Element {
    const { formatMessage } = useIntl();

    const onPageChangeFn = ({ selected: pageIndex }) => {
        onPageChange(pageIndex + 1);
    };

    const ariaLabelBuilder = (pageNumber: number) => formatMessage({ id: 'pagination.ariaPage' }, { pageNumber });

    const selectedPageIndex = selectedPage - 1;

    return (
        <div className={classNames(className, `${componentName}__wrapper`)}>
            <ReactPaginate
                pageCount={pageCount}
                forcePage={selectedPageIndex}
                onPageChange={onPageChangeFn}
                hrefBuilder={hrefBuilder}
                ariaLabelBuilder={ariaLabelBuilder}
                pageRangeDisplayed={DISPLAYED_PAGE_RANGE}
                marginPagesDisplayed={MARGIN_PAGE_RANGE}
                className={componentName}
                pageClassName={`${componentName}__item`}
                pageLinkClassName={`${componentName}__link ${componentName}__page-link`}
                breakClassName={`${componentName}__item`}
                breakLinkClassName={`${componentName}__link ${componentName}__break-link`}
                nextAriaLabel={formatMessage({ id: 'pagination.next' })}
                nextClassName={`${componentName}__next-previous`}
                nextLinkClassName={`${componentName}__link ${componentName}__next`}
                nextLabel={
                    <>
                        <span className={`${componentName}__next-text`}>
                            {formatMessage({ id: 'pagination.next' })}
                        </span>
                        <Icon name="ArrowRight" className={`${componentName}__next-icon`} />
                    </>
                }
                previousAriaLabel={formatMessage({ id: 'pagination.previous' })}
                previousClassName={`${componentName}__next-previous`}
                previousLinkClassName={`${componentName}__link ${componentName}__previous`}
                previousLabel={
                    <>
                        <Icon name="ArrowLeft" className={`${componentName}__previous-icon`} />
                        <span className={`${componentName}__previous-text`}>
                            {formatMessage({ id: 'pagination.previous' })}
                        </span>
                    </>
                }
                activeClassName="active"
                activeLinkClassName={`${componentName}__item-active`}
                selectedPageRel={null}
            />
        </div>
    );
}

export default injectComponent('pattern.molecule.Pagination', Pagination);
