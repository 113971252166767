import { BaseProps } from '@mediashop/app/bloomreach/types';
import { BrXLinkOpenInTabWithColorsAndBorder } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy, ReactElement } from 'react';

const SpecialButtonLazy = lazy(() => lazyRetry(() => import('./SpecialButton')));

export type SpecialButtonProps = BaseProps & BrXLinkOpenInTabWithColorsAndBorder;

const SpecialButton = (props: SpecialButtonProps): ReactElement => (
    <LazyComponent>
        <SpecialButtonLazy {...props} />
    </LazyComponent>
);

export default SpecialButton;
