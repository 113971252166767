import {
    ProductAsset,
    GatewayProductAttributeRaw,
    GatewayProductAttributeType,
    ProductAttributeValue,
    GatewayProductVariant,
    GatewayProductContainer,
} from '../../api/types/GatewayProduct';
import {
    Product,
    ProductWithActiveVariant,
    Variant,
    VariantAsset,
    VariantAttributeValue,
    VariantDimensionValue,
} from '../../api/types/ClientProduct';
import { ContextState } from '../../store/reducer/context';
import camelCase from 'camelcase';
import { GatewayProductDetailedVariant } from '../../api/types/GatewayProductDetailed';
import { DetailedProduct, DetailedVariant } from '../../api/types/ClientProductDetailed';
import { QueryProductsResponse } from '../../api/types/ProductQueries';

type PartialContextState = Pick<ContextState, 'country' | 'currency' | 'locale'>;

/**
 * Recursively resolve localized attribute-values.
 */
export const getLocalizedAttributeValue = (
    value: ProductAttributeValue,
    type: GatewayProductAttributeType,
    context: PartialContextState
): VariantAttributeValue => {
    const typeName = type.name;
    const locale = context.locale;

    // Localized text
    if (typeName === 'ltext') {
        return value[locale];
    }

    // Localized enums
    if (typeName === 'lenum') {
        return {
            key: value.key,
            label: value.label[locale],
        };
    }

    // Recursive resolution for sets (works only on one-dimensional sets currently)
    if (typeName === 'set' && type.elementType && Array.isArray(value)) {
        return value.map((val) => getLocalizedAttributeValue(val, type.elementType!, context));
    }

    return value;
};

/**
 * Map commercetools attributes to ClientVariantAttributeValue
 * The attribute-names are camelcased, the values are localized.
 */
const mapAttributes = (
    data: GatewayProductAttributeRaw[],
    context: PartialContextState
): Record<string, VariantAttributeValue> => {
    const attributeMap = {};
    data?.forEach((attribute) => {
        if (attribute.attributeDefinition === null) {
            attribute.attributeDefinition = { label: '', type: { name: 'text' } };
        }
        attributeMap[camelCase(attribute.name)] = getLocalizedAttributeValue(
            attribute.value,
            attribute.attributeDefinition.type,
            context
        );
    });
    return attributeMap;
};

/**
 * Map commercetools assets to ClientVariantAssets
 */
const mapAssets = (data: ProductAsset[], context: PartialContextState): VariantAsset[] =>
    data.map((asset) => {
        const customFieldsRaw = asset.custom.customFieldsRaw;

        customFieldsRaw.forEach((field) => {
            asset[field.name] = typeof field.value === 'object' ? field.value[context.locale] : field.value;
        });

        return asset;
    });

/**
 * Map commercetools attribute to ClientVariantDimension
 */
const mapAttributeToDimension = (
    attribute: GatewayProductAttributeRaw,
    context: PartialContextState
): VariantDimensionValue => {
    const value = getLocalizedAttributeValue(attribute.value, attribute.attributeDefinition.type, context);
    if (!value.key || !value.label) {
        console.error('ProductMapper: variant_dimensions must only include attributes of type enum or lenum');
    }

    return {
        key: camelCase(value.key),
        name: attribute.name,
        label: attribute.attributeDefinition.label,
        value: value.key,
        valueLabel: value.label,
    };
};

/**
 * Get all valid dimensions of the given variant.
 */
const getVariantDimensions = (
    attributes: GatewayProductAttributeRaw[],
    context: PartialContextState
): VariantDimensionValue[] => {
    const variantDimensions = attributes.find((attribute) => attribute.name === 'variant_dimensions');
    if (!variantDimensions) {
        return [];
    }

    return attributes
        .filter((attribute) => variantDimensions.value.includes(attribute.name))
        .map((attribute) => mapAttributeToDimension(attribute, context));
};

/**
 * Check if a variant is eligible to be a cheaper set
 * @param productId id of the product (to find upsell)
 * @param attributes variant attributes
 * @returns (boolean) indicator that variant is a cheaperSet
 */
const variantMightHaveCheaperSets = (productId?: string, attributes?: Record<string, any>): boolean =>
    attributes?.relatedUpsell?.find(({ id }) => id === productId) !== undefined;

/**
 * Map commercetools variant to ClientVariant.
 */
const mapDetailedVariant = (
    data: GatewayProductDetailedVariant,
    context: PartialContextState,
    productId: string
): DetailedVariant => {
    const attributes = mapAttributes(data.attributesRaw, context);
    const dimensions = getVariantDimensions(data.attributesRaw, context);
    const assets = mapAssets(data.assets, context);
    const productDetails = mapAttributes(data.productDetails, context);
    const mightHaveCheaperSets = variantMightHaveCheaperSets(productId, attributes);

    return {
        id: data.id,
        imageUri: '',
        sku: data.sku,
        key: data.key,
        price: data.price?.value,
        originalPrice: data?.originalPrice?.value,
        assets,
        attributes,
        position: data.position,
        productDetails,
        dimensions,
        country: context.country,
        cheapestCustomerPrice: data.cheapestCustomerPrice?.value,
        cheapestPrice: data.cheapestPrice?.value,
        existsOtherThenCheapestCustomerPrices: data.existsOtherThenCheapestCustomerPrices,
        existsOtherThenCheapestPrices: data.existsOtherThenCheapestPrices,
        customerPrice: data?.customerPrice?.value,
        mightHaveCheaperSets,
    };
};

/**
 * Map commercetools variant to ClientVariant.
 */
const mapVariant = (data: GatewayProductVariant, context: PartialContextState, productId: string): Variant => {
    const attributes = data.attributes ? data.attributes : mapAttributes(data.attributesRaw, context);
    const dimensions = data.attributesRaw ? getVariantDimensions(data.attributesRaw, context) : [];
    const assets = mapAssets(data.assets, context);
    const mightHaveCheaperSets = variantMightHaveCheaperSets(productId, attributes);

    return {
        id: data.id,
        imageUri: '',
        sku: data.sku,
        key: data.key,
        position: data.position,
        price: data.price?.value,
        originalPrice: data?.originalPrice?.value,
        assets,
        attributes,
        dimensions,
        country: context.country,
        locale: context.locale,
        cheapestCustomerPrice: data.cheapestCustomerPrice?.value,
        cheapestPrice: data.cheapestPrice?.value,
        existsOtherThenCheapestPrices: data.existsOtherThenCheapestPrices,
        existsOtherThenCheapestCustomerPrices: data.existsOtherThenCheapestCustomerPrices,
        customerPrice: data?.customerPrice?.value,
        mightHaveCheaperSets,
    };
};

/**
 * Map Commercetools-Product (contained in a ProductEntity from Redux) to ClientProduct.
 * For easier for consumption.
 */
const mapProduct = (data: GatewayProductContainer, context: PartialContextState): Product => {
    const current = data.masterData.current;
    const variants = current.allVariants.map((variant) => mapVariant(variant, context, data.id));

    return {
        id: data.id,
        imageUri: '',
        secondaryImageUri: '',
        key: current.key,
        name: current.name,
        categories: current.categories ?? [],
        slug: current.slug ?? '',
        variantDimensions: [],
        variants,
    };
};

/**
 * Fills the QueryProductsResponse with information on the active variant.
 * @param data QueryProductsResponse
 * @returns QueryProductsResponse with added active variant
 */
const enrichProductResponseWithActiveVariant = (data: QueryProductsResponse): QueryProductsResponse => {
    return Object.entries(data.products).reduce(
        (acc, [id, product]) => {
            const activeVariant = product.variants.find((variant) => variant.sku === id);
            // add active variants to product which have a price set with centAmount > 0
            if (!activeVariant || (activeVariant?.price?.centAmount ?? 0) > 0) {
                acc.products[id] = { ...product, activeVariant };
            }

            return acc;
        },
        { products: {} }
    );
};

/**
 * Return active variant and its index. Falls back to first variant.
 */
const getActiveVariantData = (data: Product, variantId?: number): Variant => {
    let activeVariantIndex = data.variants.findIndex((variant) => variant.id === variantId);
    if (activeVariantIndex === -1) {
        activeVariantIndex = 0;
    }
    return data.variants[activeVariantIndex];
};

/**
 * Map variants of QueryProduct endpoint
 * @param data Variant
 * @param context PartialContextState
 * @returns mapped variant
 */
const mapQueryProductsVariant = (data: Variant, context: PartialContextState): Variant => {
    return {
        ...data,
        country: context.country,
        locale: context.locale,
    };
};

/**
 * Modifies the QueryProductsResponse replacing the variant with the mappedVariant
 * @param data
 * @param context
 * @returns
 */
const enrichQueryProductsVariantWithContext = (
    data: QueryProductsResponse,
    context: PartialContextState
): QueryProductsResponse => {
    const modifiedProducts = Object.entries(data.products).reduce(
        (acc, [productId, productData]) => {
            const modifiedVariants = productData.variants.map((variant) =>
                mapQueryProductsVariant(
                    {
                        ...variant,
                        country: context.country,
                        locale: context.locale,
                    },
                    context
                )
            );

            acc[productId] = {
                ...productData,
                variants: modifiedVariants,
            };

            return acc;
        },
        {} as { [key: string]: ProductWithActiveVariant }
    );

    return {
        products: modifiedProducts,
    };
};

const mapNestJsVariant = (
    variant: DetailedVariant,
    productId: string,
    context: PartialContextState
): DetailedVariant => {
    const mightHaveCheaperSets = variantMightHaveCheaperSets(productId, variant.attributes);
    const assets = mapAssets(variant.assets, context);

    return {
        ...variant,
        assets,
        mightHaveCheaperSets,
    };
};

const mapNestJsProduct = (product: DetailedProduct, context: PartialContextState): DetailedProduct => {
    const variants = product.variants.map((variant) => mapNestJsVariant(variant, product.id, context));

    return {
        ...product,
        totalNumberOfVariants: variants.length,
        variants,
    };
};

export default {
    mapAttributes,
    mapDetailedVariant,
    mapVariant,
    mapProduct,
    mapNestJsProduct,
    mapNestJsVariant,
    getActiveVariantData,
    enrichQueryProductsVariantWithContext,
    enrichProductResponseWithActiveVariant,
};
