import { Category } from '@mediashop/app/api/types/ClientCategory';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import { useAppDispatch } from '@mediashop/app/store/hooks/hooks';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useCategory } from '@mediashop/app/hooks/useCategory';
import DesktopMenuItemCustom from '../DesktopMenuItemCustom';
import { selectedCategoryIdChanged } from '@mediashop/app/store/reducer/category';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

export const componentName = 'desktop-menu-item';

export type DesktopMenuItemProps = BaseProps & {
    menuItem: Category;
};

const DesktopMenuItem = ({ menuItem }: DesktopMenuItemProps): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const { formatLinkBase } = useReactRouterLink();
    const { selectedCategoryId } = useCategory();
    const dispatch = useAppDispatch();

    const navigateToPLP = (event, childItem: Category | undefined = undefined) => {
        event.preventDefault();

        const targetCategoryId = childItem?.id ?? menuItem.id;

        if (selectedCategoryId !== targetCategoryId) {
            dispatch(selectedCategoryIdChanged({ selectedCategoryId: targetCategoryId }));
            navigate(childItem ? `c/${menuItem.slug}/${childItem.slug}` : `c/${menuItem.slug}`);
        }
    };

    return (
        <li className={componentName}>
            <Link
                className={classNames(`${componentName}__menu-item`, {
                    [`${componentName}__menu-item--active`]: location.pathname
                        .split('/')
                        .filter(Boolean)
                        .some((part) => location.pathname.includes('/c/') && part === menuItem.slug),
                })}
                to={formatLinkBase(`c/${menuItem.slug}`)}
                onClick={(event) => navigateToPLP(event)}
            >
                {menuItem.name}
            </Link>
            {menuItem.children.length ? (
                <ul className={`${componentName}__submenu`}>
                    {menuItem.children
                        .sort((prev, curr) => Number(prev.orderHint) - Number(curr.orderHint))
                        .map((childItem) => (
                            <li key={childItem.id} className={`${componentName}__submenu-item`}>
                                <Link
                                    className={classNames(`${componentName}__submenu-item-link`, {
                                        [`${componentName}__submenu-item-link--active`]: location.pathname.includes(
                                            childItem.slug
                                        ),
                                    })}
                                    to={formatLinkBase(`c/${menuItem.slug}/${childItem.slug}`)}
                                    onClick={(event) => navigateToPLP(event, childItem)}
                                >
                                    {childItem.name}
                                </Link>
                            </li>
                        ))}

                    <DesktopMenuItemCustom customItem={menuItem.custom} />
                </ul>
            ) : (
                SKIP_RENDER
            )}
        </li>
    );
};

export default DesktopMenuItem;
