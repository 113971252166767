import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import {
    BackgroundProps,
    BaseProps,
    HeadlineStyleProps,
    HeadlineTextStyleProps,
    HippoIconProps,
} from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ContentListMolecule = lazy(() => lazyRetry(() => import('./ContentList')));

type ContentItem = {
    title: string;
    content: BrxHtml;
    icon: HippoIconProps;
};

type ContentListProps = BaseProps & {
    headline: string;
    subheadline?: string;
    headlineStyle: HeadlineStyleProps;
    textStyle?: HeadlineTextStyleProps;
    isNumericList: boolean;
    background: BackgroundProps;
    contentItems: ContentItem[];
};

const ContentList = (props: ContentListProps): JSX.Element => (
    <LazyComponent>
        <ContentListMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ContentList', ContentList);
