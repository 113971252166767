import MoneyFunctions from '../domain/Money';
import { Money } from '../api/types/Money';
import { CustomLineItem } from '../api/types/ClientCart';
import { VoucherRowDiscountType } from '../api/types/Discount';

function calcCustomLineItemCost(lineItems: CustomLineItem[]) {
    return lineItems.length > 0
        ? lineItems.map((item) => item.money).reduce((total, cost) => MoneyFunctions.add(total, cost))
        : null;
}

export default function calculateSubtotal(productTotal: Money, customLineItems?: CustomLineItem[]): Money {
    if (!customLineItems) {
        return productTotal;
    }

    const customLineItemCost = calcCustomLineItemCost(customLineItems);
    return customLineItemCost ? MoneyFunctions.subtract(productTotal, customLineItemCost) : productTotal;
}

export function calculateSubtotalPlusDiscounts(
    productTotal: Money,
    discounts: VoucherRowDiscountType[],
    customLineItems?: CustomLineItem[]
): Money {
    const subtotal = calculateSubtotal(productTotal, customLineItems);
    const discountSum = discounts.reduce((accumulator, currentValue) => accumulator + currentValue.value.centAmount, 0);

    return {
        ...subtotal,
        centAmount: subtotal.centAmount + discountSum,
    };
}
