export enum CroppedImageVariant {
    Bundle = 'Bundle',
    Cart = 'Cart',
    CategoryImage = 'CategoryImage',
    CheckoutMiniCart = 'CheckoutMiniCart',
    CheckoutSummary = 'CheckoutSummary',
    DealOfTheDay = 'DealOfTheDay',
    HeroProductSliderHeroImage = 'HeroProductSliderHeroImage',
    OrderDetails = 'OrderDetails',
    OrderOverview = 'OrderOverview',
    PDPBigImage = 'PDPBigImage',
    PDPThumbnail = 'PDPThumbnail',
    PLP = 'PLP',
    PreCheckout = 'PreCheckout',
    ProductDefault = 'ProductDefault',
    ProductGrid3 = 'ProductGrid--3',
    ProductGrid4 = 'ProductGrid--4',
    ProductGrid5 = 'ProductGrid--5',
    ProductSlider1 = 'ProductSlider--1',
    ProductSlider2 = 'ProductSlider--2',
    ProductSlider3 = 'ProductSlider--3',
    ProductSlider4 = 'ProductSlider--4',
    ProductSlider5 = 'ProductSlider--5',
    ProductSlider6 = 'ProductSlider--6',
    SearchSuggestion = 'SearchSuggestion',
    SpecialDeal = 'SpecialDeal',
    SubscriptionManagement = 'SubscriptionManagement',
    WkoGiftImage = 'WkoGiftImage',
}

export enum BrXImageVariant {
    AccordionContent = 'AccordionContent',
    AccordionIcon = 'AccordionIcon',
    AmbienteShopping = 'AmbienteShopping',
    AsymetricImage = 'AsymetricImage',
    BackgroundImageWithStyle = 'BackgroundImage',
    BackgroundImageWithText = 'BackgroundImageWithText',
    CartSummary = 'CartSummary',
    CheckoutUsp = 'CheckoutUsp',
    Contact = 'Contact',
    ContactBlock = 'ContactBlock',
    ContentSliderBasicSlide = 'ContentSliderBasicSlide',
    CrossSellingBanner = 'CrossSellingBanner',
    CustomerClub = 'CustomerClub',
    DataCell = 'DataCell',
    DealOfTheDay = 'DealOfTheDay',
    DownloadWithImageItem = 'DownloadWithImageItem',
    FlexBox = 'FlexBox',
    HeroTeaser = 'HeroTeaser',
    ImageBanner = 'ImageBanner',
    InfoBanner = 'InfoBanner',
    LogoSliderBasicSlide = 'LogoSliderBasicSlide',
    MainNavigation = 'MainNavigation',
    Newsletter = 'Newsletter',
    Polaroid = 'Polaroid',
    ProductCard = 'ProductCard',
    ProductStage = 'ProductStage',
    ProductStageLogos = 'ProductStageLogos',
    Samsung = 'Samsung',
    SpecialDeal = 'SpecialDeal',
    SPLPNavigation = 'SPLPNavigation',
    StageSlider = 'StageSlider',
    TeaserGridTile = 'TeaserGridTile',
    TestimonialSlide = 'TestimonialSlide',
    TextWithImage = 'TextWithImage',
    TopHeader = 'TopHeader',
    USPs = 'USPs',
}
