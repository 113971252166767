import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy } from 'react';
import { AboSelectorProps } from './AboSelector';

const AboSelectorLazy = lazy(() => lazyRetry(() => import('./AboSelector')));

const AboSelector = (props: AboSelectorProps): JSX.Element => (
    <LazyComponent>
        <AboSelectorLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.AboSelector', AboSelector);
