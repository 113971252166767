import { useEffect } from 'react';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { BrxRelationType } from '@mediashop/app/bloomreach/types';
import { useEmarsysRecommendations } from './useEmarsysRecommendations';
import { useCommerceToolsProductRelations } from './useCommerceToolsProductRelations';
import { ProductWithRelation } from '../types/productRelations';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';

type HookProps = {
    crosssellAiContainerId?: string;
    relationType?: BrxRelationType;
    useStandardPrice?: boolean;
    variant?: Variant;
    isCartPage?: boolean;
};

type ReturnType = {
    isLoading: boolean;
    relatedProducts: ProductWithRelation[];
};

/**
 * Hook that fetches product relations for a given variant
 * @param object containing the type of the relation and the product variant
 * @returns loading state and related products
 */
export const useProductRelations = ({
    crosssellAiContainerId,
    relationType,
    useStandardPrice,
    variant,
    isCartPage,
}: HookProps): ReturnType => {
    const isCrosssellAi = relationType === 'crosssell_AI';

    const {
        isLoading: isRecommendLoading,
        recommendedProducts,
        triggerRecommend,
    } = useEmarsysRecommendations({
        variant,
        containerId: crosssellAiContainerId ?? EMPTY_STRING,
        isCartPage,
    });

    const {
        isLoading: isRelationLoading,
        relatedProducts,
        triggerRelations,
    } = useCommerceToolsProductRelations({
        relationType,
        useStandardPrice,
        variant,
    });

    useEffect(() => {
        if ((variant || isCartPage) && relationType && relationType !== 'only_productIDs') {
            if (isCrosssellAi) {
                triggerRecommend();
            } else if (variant) {
                triggerRelations();
            }
        }
    }, [relationType, variant, triggerRecommend, triggerRelations, isCrosssellAi, isCartPage]);

    return {
        isLoading: isCrosssellAi ? isRecommendLoading : isRelationLoading,
        relatedProducts: isCrosssellAi ? recommendedProducts : relatedProducts,
    };
};
