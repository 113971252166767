import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

export interface SovendusProps extends BaseProps {
    trafficMediumNumber: string;
    trafficSourceNumber: string;
}

const SovendusMolecule = lazy(() => lazyRetry(() => import('./Sovendus')));

const Sovendus = (props: SovendusProps): JSX.Element => (
    <LazyComponent>
        <SovendusMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.Sovendus', Sovendus);
