import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { BrxRelationType } from '@mediashop/app/bloomreach/types';
import { ProductRelation } from '../types/productRelations';

export const getRelatedProductIds = (
    variant: Variant,
    productRelationType: BrxRelationType | undefined
): ProductRelation[] => {
    const { attributes } = variant;

    const productIDsRel: ProductRelation[] = [];

    // UPSELL
    if (productRelationType === 'upsell') {
        return attributes.relatedUpsell?.map(({ id }) => ({
            id,
            neededPriceType: 'UPSELL',
            relationType: productRelationType,
        }));
    }

    // UPGRADE
    if (productRelationType === 'upgrade') {
        return attributes.relatedUpgrade?.map(({ id }) => ({
            id,
            neededPriceType: 'UPGRADE',
            relationType: productRelationType,
        }));
    }

    // CROSSSELL
    if (productRelationType === 'crosssell') {
        return attributes.relatedCrosssellProduct?.map(({ id }) => ({
            id,
            neededPriceType: 'CROSSSELL',
            relationType: productRelationType,
        }));
    }

    // UPSELL + UPGRADE (special case)
    if (productRelationType === 'upsell_upgrade') {
        attributes.relatedUpsell?.forEach(({ id }) =>
            productIDsRel.push({
                id,
                neededPriceType: 'UPSELL',
                relationType: 'upsell',
            })
        );

        attributes.relatedUpgrade?.forEach(({ id }) =>
            productIDsRel.push({
                id,
                neededPriceType: 'UPGRADE',
                relationType: 'upgrade',
            })
        );
    }

    return productIDsRel;
};
