import { SKIP_RENDER } from '../../constants/semanticConstants';
import { useCustomerSignInState } from '../../hooks/api/useCustomerSignInState';

/**
 * Calls useCustomerSignInState hook on page load.
 */
export default function CustomerSignInStateConnector(): null {
    useCustomerSignInState();

    return SKIP_RENDER;
}
