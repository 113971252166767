import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { HeadlineProps } from '@mediashop/base/pattern/atom/Headlines';

export type PersonalInfoProps = BaseProps & {
    headline: HeadlineProps;
    passwordHeadline: string;
    passwordText: BrxHtml;
    redirectUrl?: string;
};

import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const PersonalInfoMolecule = lazy(() => lazyRetry(() => import('./PersonalInfo')));

const PersonalInfo = (props: PersonalInfoProps): JSX.Element => (
    <LazyComponent>
        <PersonalInfoMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.PersonalInfo', PersonalInfo);
