import { lazy, MutableRefObject } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const YoutubeVideoMolecule = lazy(() => lazyRetry(() => import('./YoutubeVideo')));

export type PlayerProps = {
    getPlayerState: () => number | undefined;
    pauseVideo: () => void;
};

export enum PlayerState {
    ENDED = 0,
    PLAYING = 1,
    PAUSED = 2,
    BUFFERING = 3,
    CUED = 5,
}

export type YoutubeVideoProps = BaseProps & {
    videoUrl: string;
    badge?: string;
    previewImageUrl?: string;
    previewImageAlt?: string;
    videoRef?: MutableRefObject<PlayerProps | undefined>;
};

const YoutubeVideo = (props: YoutubeVideoProps): JSX.Element => (
    <LazyComponent>
        <YoutubeVideoMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.YoutubeVideo', YoutubeVideo);
