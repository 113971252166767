import { Category } from '../api/types/ClientCategory';

/**
 * Finds a category based on category id
 * @param categories
 * @param categoryKey
 */
export default function findCategoryByKey(categories: Category[], categoryKey: string): Category {
    let result;
    let found = false;
    categories.forEach((category) => {
        if (found) {
            return;
        }

        if (category.key === categoryKey) {
            result = category;
            found = true;
            return;
        }

        if (category.children) {
            result = findCategoryByKey(category.children, categoryKey);
        }
    });

    return result;
}
