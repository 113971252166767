import { lazy, RefObject } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { AddressForm, EmailPasswordRegistrationForm } from '@mediashop/app/api/types/Address';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { CheckoutAddressFormSubmitHandle } from '../types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CheckoutRegisterMolecule = lazy(() => lazyRetry(() => import('./CheckoutRegister')));

export type CheckoutRegisterProps = BaseProps & {
    billingFormRef: RefObject<CheckoutAddressFormSubmitHandle>;
    shippingFormRef: RefObject<CheckoutAddressFormSubmitHandle>;
    passwordFormRef: RefObject<CheckoutAddressFormSubmitHandle>;
    selectedBillingCountry: string;
    selectedShippingCountry: string;
    handleNextStepPayment: (onError?: () => void) => void;
    onBillingFormChange?: ({ values, validated }: { values: AddressForm; validated: boolean }) => void;
    onShippingFormChange?: ({ values, validated }: { values: AddressForm; validated: boolean }) => void;
    defaultBillingFormValues?: AddressForm;
    defaultShippingFormValues?: AddressForm;
    handleEmailPasswordFormChange: (data: { values: EmailPasswordRegistrationForm; validated: boolean }) => void;
    isLoading: boolean;
};

const CheckoutRegister = (props: CheckoutRegisterProps): JSX.Element => (
    <LazyComponent>
        <CheckoutRegisterMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.Checkout.CheckoutRegister', CheckoutRegister, 'catalog-base');
