// eslint-disable-next-line no-restricted-imports
import { useNavigate as useReactRouterNavigate, NavigateOptions, To } from 'react-router-dom';
import useReactRouterLink from './useReactRouterLink';
import { removeLocaleFromPathname } from '../helper/localeHelper';

interface NavigateFunctionWithKeepLanguage {
    (delta: number): void;
    (to: To, options?: NavigateOptionsWithKeepLanguage): void;
}

interface NavigateOptionsWithKeepLanguage extends NavigateOptions {
    keepLanguage?: boolean;
}

export const useNavigate = (): NavigateFunctionWithKeepLanguage => {
    const { formatLinkBase } = useReactRouterLink();
    const navigate = useReactRouterNavigate();

    const internalNavigate = (toOrDelta: To | number, options?: NavigateOptionsWithKeepLanguage) => {
        if (typeof toOrDelta === 'number') {
            navigate(toOrDelta);
        } else {
            let navigateTo = toOrDelta;
            if (!options?.keepLanguage) {
                if (typeof toOrDelta === 'string') {
                    const urlWithoutLocale = removeLocaleFromPathname(toOrDelta);
                    navigateTo = formatLinkBase(urlWithoutLocale);
                } else if (toOrDelta.pathname) {
                    const urlWithoutLocale = removeLocaleFromPathname(toOrDelta.pathname);
                    const link = formatLinkBase(urlWithoutLocale);
                    navigateTo = { ...toOrDelta, pathname: link };
                }
            }

            setTimeout(() => {
                navigate(navigateTo, options);
            }, 200);
        }
    };

    return internalNavigate;
};
