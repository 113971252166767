import { BrxLinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import { lazy, ReactElement } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BackgroundProps, BaseProps, BrxImageWithAltText, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ContentSliderMolecule = lazy(() => lazyRetry(() => import('./ContentSlider')));

export type SliderItem = {
    contentType: string;
    displayName: string;
    image: BrxImageWithAltText;
    link: BrxLinkOpenInTab;
    name: string;
    text: Record<string, string>;
};

export type ContentSliderProps = BaseProps & {
    headlines: BrxHeadlines;
    background: BackgroundProps;
    contentBackground: BackgroundProps;
    items: SliderItem[];
    roundedImages: boolean;
    slidesInViewDesktop: string;
    slidesInViewMobile: number;
};

const ContentSlider = (props: ContentSliderProps): ReactElement => (
    <LazyComponent>
        <ContentSliderMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ContentSlider', ContentSlider, 'catalog-base');
