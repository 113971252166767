import { useAppSelector } from '../store/hooks/hooks';
import { NonceState } from '../store/reducer/nonce';

/**
 * Hook that returns nonce redux state.
 * @returns NonceState
 */
export const useNonce = (): NonceState => {
    return useAppSelector((state) => state.nonce);
};
