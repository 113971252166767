import { FormattedMessage } from 'react-intl';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import Icon from '@mediashop/base/pattern/atom/Icon';

export default function RegisterSuggestion({ className }: BaseProps): JSX.Element {
    const componentName = className;
    return (
        <>
            <p className={`${componentName}__register-suggestion`}>
                <FormattedMessage id="orderSuccessPage.accountQuestion" />
            </p>
            <ul className={`${componentName}__register-advantages`}>
                <li className={`${componentName}__registerAdvantage`}>
                    <Icon className={`${componentName}__checkmark`} name="Check" />
                    <FormattedMessage id="orderSuccessPage.accountAdvantageOne" />
                </li>
                <li className={`${componentName}__register-advantage`}>
                    <Icon className={`${componentName}__checkmark`} name="Check" />
                    <FormattedMessage id="orderSuccessPage.accountAdvantageTwo" />
                </li>
                <li className={`${componentName}__register-advantage`}>
                    <Icon className={`${componentName}__checkmark`} name="Check" />
                    <FormattedMessage id="orderSuccessPage.accountAdvantageThree" />
                </li>
            </ul>
        </>
    );
}
