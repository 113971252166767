import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const TestimonialSlideMolecule = lazy(() => lazyRetry(() => import('./TestimonialSlide')));

export type TestimonialSlideProps = BaseProps & {
    name: string;
    position: string;
    starRating: string;
    text: string;
    image: BrxImageWithAltText;
};

const TestimonialSlide = (props: TestimonialSlideProps): JSX.Element => (
    <LazyComponent>
        <TestimonialSlideMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.TestimonialSlider.TestimonialSlide', TestimonialSlide);
