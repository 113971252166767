import { injectComponent } from '@mediashop/app/component-injector';
import BloomreachImageResponsive from '../../../atom/BloomreachImageResponsive';
import ContactBlockItem, { ContactBlockItemProps } from '../ContactBlockItem';
import { BaseProps, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';

const componentName = 'contact-block';

export type ContactBlockProps = BaseProps & {
    contactBlocks: ContactBlockItemProps[];
    icon: BrxImageSetImpl;
    title: string;
};

function ContactBlock({ contactBlocks, icon, title }: ContactBlockProps): JSX.Element {
    return (
        <div className={`${componentName}`}>
            <div className={`${componentName}__icon`}>
                <BloomreachImageResponsive alt={title} desktopImage={icon} variant={BrXImageVariant.ContactBlock} />
            </div>
            <div className={`${componentName}__items`}>
                <div className={`${componentName}__title`}>{title}</div>
                <div className={`${componentName}__contact-infos`}>
                    {contactBlocks.map((contactBlock, key) => (
                        <ContactBlockItem key={key} {...contactBlock} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default injectComponent('pattern.molecule.Contact.ContactBlock', ContactBlock);
