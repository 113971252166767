import { Variant } from '../api/types/ClientProduct';
import { useCheaperSetPrice } from './api/useCheaperSetPrice';
import { useCart } from './useCart';

export const useCheaperSet = (variant?: Variant) => {
    const { cart } = useCart();
    const { cheaperSetPrice, isLoading } = useCheaperSetPrice({ variant });
    const showCheaperSetPrice: boolean = Boolean(
        cheaperSetPrice && cart?.lineItems.some((lineItem) => lineItem.variant.sku === variant?.sku)
    );

    return { showCheaperSetPrice, cheaperSetPrice, isLoading };
};
