import { TrackableButton } from '@mediashop/app/analytics/components/TrackableButton';
import { TrackingKeys } from '@mediashop/app/analytics/constants/trackingKeys';
import { Category } from '@mediashop/app/api/types/ClientCategory';
import { BaseProps, BrxImageSetImpl, BrxLink } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import Icon from '@mediashop/base/pattern/atom/Icon';
import {
    BrxLinkOpenInTab,
    BrxLinkOpenInTabWithColors,
    LinkOpenInTab,
} from '@mediashop/base/pattern/atom/LinkOpenInTab';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { createPortal } from 'react-dom';
import HeaderLogo from '../HeaderLogo';
import UserMenu from '../UserMenu';
import MobileMenuItem from './MobileMenuItem';
import parseHtml from 'html-react-parser';

const componentName = 'mobile-menu';

type MobileMenuProps = BaseProps & {
    closeMenu: () => void;
    extendedItems?: BrxLinkOpenInTab[];
    linksForLoggedInUsers: BrxLink[];
    menuItems: Category[];
    msLogoDesktop: BrxImageSetImpl;
    msLogoMobile: BrxImageSetImpl;
    offCanvasLinksMobile: BrxLinkOpenInTabWithColors[];
    shippingText: string;
    userIconLinkLoggedIn: BrxLink;
    userIconLinkLoggedOut: BrxLink;
};

const MobileMenu = ({
    closeMenu,
    extendedItems,
    linksForLoggedInUsers,
    menuItems,
    msLogoMobile,
    msLogoDesktop,
    offCanvasLinksMobile,
    shippingText,
    userIconLinkLoggedIn,
    userIconLinkLoggedOut,
}: MobileMenuProps): ReactElement =>
    createPortal(
        <BackgroundColorWrapper className={componentName}>
            <ContentWrapper>
                <div className={`${componentName}__header`}>
                    <TrackableButton
                        className={`${componentName}__mobile-button`}
                        trackingKey={TrackingKeys.BURGER_MENU}
                        aria-label="menu-button"
                        onClick={closeMenu}
                    >
                        <Icon className={`${componentName}__mobile-icon`} name="Close" />
                    </TrackableButton>

                    <HeaderLogo msLogoDesktop={msLogoDesktop} msLogoMobile={msLogoMobile} onClick={closeMenu} />

                    {shippingText ? (
                        <div className={`${componentName}__shipping-text`}>{parseHtml(shippingText)}</div>
                    ) : (
                        SKIP_RENDER
                    )}

                    <UserMenu
                        linksForLoggedInUsers={linksForLoggedInUsers}
                        userIconLinkLoggedIn={userIconLinkLoggedIn}
                        userIconLinkLoggedOut={userIconLinkLoggedOut}
                        closeMenu={closeMenu}
                    />
                </div>

                <nav className={`${componentName}__navigation`}>
                    <ul>
                        {menuItems.map((item) => (
                            <MobileMenuItem key={item.id} menuItem={item} closeMenu={closeMenu} />
                        ))}
                        {extendedItems?.map((link) => (
                            <li
                                onClick={closeMenu}
                                key={typeof link.link === 'object' ? link.link.text : link.link}
                                className="mobile-menu-item"
                            >
                                <LinkOpenInTab className={`${componentName}__menu-link`} {...link} />
                            </li>
                        ))}
                        {offCanvasLinksMobile.map((link) => (
                            <li
                                key={typeof link.link === 'object' ? link.link.text : link.link}
                                className={classNames('mobile-menu-item', 'mobile-menu-item__off-canvas')}
                                onClick={closeMenu}
                            >
                                <LinkOpenInTab className={`${componentName}__menu-link`} {...link} />
                            </li>
                        ))}
                    </ul>
                </nav>
            </ContentWrapper>
        </BackgroundColorWrapper>,
        document.getElementById(`${componentName}-portal`)!
    );

export default MobileMenu;
