import Icon from '../../../atom/Icon';
import { BaseProps } from '@mediashop/app/bloomreach/types';

type YoutubeVideoPosterProps = BaseProps & {
    showPreview: boolean;
    previewImageUrl: string;
    previewImageAlt: string;
};

/**
 * Displays a Youtube preview image.
 */
function YoutubeVideoPoster({
    showPreview,
    previewImageUrl,
    previewImageAlt,
}: YoutubeVideoPosterProps): JSX.Element | null {
    const componentName = 'youtube-video-poster';

    const videoImage = {
        backgroundImage: `url('${previewImageUrl}')`,
    };

    if (!showPreview) {
        return null;
    }

    return (
        <div className={componentName} style={videoImage} title={previewImageAlt}>
            <div className={`${componentName}__play-button`}>
                <Icon className={`${componentName}__arrow`} name="Play" />
            </div>
        </div>
    );
}

export default YoutubeVideoPoster;
