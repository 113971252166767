import { lazy, ReactElement } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { MainNavigationCustomNavigationProps } from '../../types';

const BurgerMenuNavigationLazy = lazy(() => lazyRetry(() => import('./BurgerMenuNavigation')));

export type BurgerMenuNavigationProps = BaseProps & {
    closeBurgerMenu: () => void;
    navigation: MainNavigationCustomNavigationProps;
};

const BurgerMenuNavigation = (props: BurgerMenuNavigationProps): ReactElement => (
    <LazyComponent>
        <BurgerMenuNavigationLazy {...props} />
    </LazyComponent>
);

export default BurgerMenuNavigation;
