import { useState } from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import BloomreachImageResponsive from '../../../atom/BloomreachImageResponsive';
import Icon from '../../../atom/Icon';
import BloomreachHtml, { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { BackgroundProps, BaseProps, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';

const componentName = 'accordion-item';

type Props = BaseProps & {
    itemKey: number;
    item: {
        title: string;
        subtitle: string;
        icon: BrxImageSetImpl;
        content: BrxHtml;
    };
    backgroundTitle: BackgroundProps;
    backgroundContent: BackgroundProps;
    separatorColorHex?: string;
    itemHeadlineFontColorHex?: string;
    itemSubheadlineFontColorHex?: string;
    itemHeadlineStyle?: string;
};

function AccordionItem({
    itemKey,
    item,
    backgroundTitle = 'primary',
    backgroundContent = 'primary',
    separatorColorHex,
    itemHeadlineFontColorHex,
    itemSubheadlineFontColorHex,
    itemHeadlineStyle,
}: Props) {
    const [isOpen, setIsOpen] = useState(itemKey === 0);

    return (
        <div
            className={componentName}
            style={{
                borderColor:
                    separatorColorHex && separatorColorHex.length > 0 ? separatorColorHex : 'var(--color-border)',
            }}
        >
            <div
                className={classNames(`${componentName}__header`, `background--${backgroundTitle}`)}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className={`${componentName}__header-text`}>
                    {item.icon && (
                        <div className={`${componentName}__icon-container`}>
                            <Icon name="ListBackground" className={`${componentName}__icon-background`} />
                            <BloomreachImageResponsive
                                className={`${componentName}__icon`}
                                alt={item.title}
                                desktopImage={item.icon}
                                variant={BrXImageVariant.AccordionIcon}
                            />
                        </div>
                    )}
                    {item.title && (
                        <div className={`${componentName}__headline-container`}>
                            <div
                                className={classNames(`${componentName}__title`, `title-${itemHeadlineStyle}`)}
                                style={{
                                    color:
                                        itemHeadlineFontColorHex && itemHeadlineFontColorHex.length > 0
                                            ? itemHeadlineFontColorHex
                                            : 'var(--color-headline)',
                                }}
                            >
                                {item.title}
                            </div>
                            {item.subtitle && (
                                <div
                                    className={classNames(
                                        `${componentName}__subtitle`,
                                        `title-${itemHeadlineStyle}-sub`
                                    )}
                                    style={{
                                        color:
                                            itemSubheadlineFontColorHex && itemSubheadlineFontColorHex.length > 0
                                                ? itemSubheadlineFontColorHex
                                                : 'var(--color-subheadline)',
                                    }}
                                >
                                    {item.subtitle}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className={`${componentName}__arrow`}>
                    {isOpen ? <Icon name="ArrowUp" /> : <Icon name="ArrowDown" />}
                </div>
            </div>
            <div
                className={classNames(
                    `${componentName}__content`,
                    `${componentName}__content--${isOpen ? 'open' : 'closed'}`,
                    item.icon ? `${componentName}__content--has-icon` : '',
                    `background--${backgroundContent}`
                )}
            >
                <BloomreachHtml className={`${componentName}__html`} html={item.content} />
            </div>
        </div>
    );
}

export default injectComponent('pattern.molecule.Accordion.AccordionItem', AccordionItem);
