import Icon from '../../../atom/Icon';
import { BaseProps } from '@mediashop/app/bloomreach/types';

type Props = BaseProps & {
    showErrorIcon: boolean;
};

export default function ErrorIcon({ showErrorIcon = false }: Props): JSX.Element {
    return <Icon name={showErrorIcon ? 'Close' : 'Check'} />;
}
