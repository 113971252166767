import isAbsoluteUrl from 'is-absolute-url';

export default class LinkHelper {
    /**
     * Check if a URL is absolute.
     */
    public static isAbsoluteUrl(url: string): boolean {
        return isAbsoluteUrl(url);
    }

    /**
     * Determine whether the given url leads to a different origin.
     */
    public static isExternalUrl(url: string): boolean {
        if (!this.isAbsoluteUrl(url)) {
            return false;
        }

        const parsed = new URL(url);
        return parsed.origin !== location.origin;
    }

    /**
     * Return only the origin-independent part of a url.
     */
    public static getRelativeUrl(url: string): string {
        const parsed = new URL(url);
        return parsed.pathname + parsed.search + parsed.hash;
    }

    public static isAnchorLink(url: string, pathname: string): boolean {
        const isSamePage = url.includes(pathname);
        return url.includes('#') && isSamePage;
    }
}
