import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Variant } from '@mediashop/app/api/types/ClientProduct';

const VariantSelectorLazy = lazy(() => lazyRetry(() => import('./VariantSelector')));

export type VariantSelectorProps = BaseProps & {
    isError: boolean;
    onVariantChange: (variant: Variant) => void;
    selectedVariant?: Variant;
    totalNumberOfVariants: number;
    variants: Variant[];
    showSubscriptionVariants?: boolean;
};

const VariantSelector = (props: VariantSelectorProps): JSX.Element => (
    <LazyComponent>
        <VariantSelectorLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.ProductStage.VariantSelector', VariantSelector);
