import { createQueryKeys } from '@lukemorales/query-key-factory';

export const customer = createQueryKeys('customer', {
    order: (orderId: string, locale: string) => ({
        queryKey: [orderId, locale],
    }),
    orders: {
        queryKey: null,
    },
    subscriptions: (locale: string) => ({
        queryKey: [locale],
    }),
});
