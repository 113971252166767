import { useEffect, useRef } from 'react';
import { useCategory } from '../../hooks/useCategory';
import { useShopContext } from '../../hooks/useShopContext';
import findCategoryByKey from '../../helper/findCategoryByKey';
import { useChangedLocation } from '../../hooks/useChangedLocation';
import { useAppDispatch } from '../../store/hooks/hooks';
import { determineProductsCategory } from '../product/determineProductsCategory';
import { useCategories } from '../../queries/categories/useCategories';
import { useProject } from '../../hooks/useProject';
import { selectedCategoryIdChanged } from '../../store/reducer/category';

const URL_PARAM_SUBCATEGORY = 'subcategory';

/**
 * Load all missing categories on page load
 */
export default function CategoryDataConnector(): null {
    const dispatch = useAppDispatch();

    const { categoryTreeRootKey } = useProject();

    const { country: userCountry } = useShopContext();
    const { selectedCategoryId } = useCategory();

    /**
     * Keep a reference to the selected category id in order to avoid too many reloads.
     */
    const selectedCategoryIdRef = useRef<string>(selectedCategoryId);

    /**
     * ---
     * Load categories when they are missing.
     * This should only happen when rendering client-side.
     */
    const { data: categories = [] } = useCategories();

    /**
     * ---
     * Update ref when selected category changed.
     */
    useEffect(() => {
        const categoryHasChanged = selectedCategoryId !== selectedCategoryIdRef.current;

        if (categoryHasChanged) {
            selectedCategoryIdRef.current = selectedCategoryId;
        }
    }, [selectedCategoryId]);

    /**
     * ---
     * Reset selected category whenever navigating away from a category page.
     */
    useChangedLocation((location) => {
        const isCategoryPage = location.pathname?.includes('/c/');

        if (!isCategoryPage && selectedCategoryId) {
            dispatch(selectedCategoryIdChanged({ selectedCategoryId: '' }));
            selectedCategoryIdRef.current = '';
        }
    });

    /*
     * ---
     * History POP/PUSH listener
     */
    useChangedLocation(
        (location) => {
            const isCategoryPage = location.pathname?.includes('/c/');
            const categoryTreeRootId = findCategoryByKey(categories, categoryTreeRootKey)?.id;

            const nextCategoryId = determineProductsCategory(
                categoryTreeRootId ?? '',
                categories,
                userCountry,
                location.pathname
            );

            if (
                isCategoryPage &&
                nextCategoryId !== selectedCategoryIdRef.current &&
                nextCategoryId !== categoryTreeRootId
            ) {
                dispatch(selectedCategoryIdChanged({ selectedCategoryId: nextCategoryId }));
            }
        },
        { watchPathname: true, watchQueryParams: [URL_PARAM_SUBCATEGORY] }
    );

    // Return null to please react
    return null;
}
