import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { HeadlineProps } from '@mediashop/base/pattern/atom/Headlines';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const AccountNavigationMolecule = lazy(() => lazyRetry(() => import('./AccountNavigation')));

export type AccountNavigationProps = BaseProps & {
    headline: HeadlineProps;
    links: {
        text: string;
        reference: string;
    }[];
};

const AccountNavigation = (props: AccountNavigationProps) => (
    <LazyComponent>
        <AccountNavigationMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.AccountNavigation', AccountNavigation);
