import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxAnchor, BrxCTAButton, BrxHeadlines, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const AmbienteShoppingMolecule = lazy(() => lazyRetry(() => import('./AmbienteShopping')));

export type AmbienteShoppingProps = BaseProps & {
    anchor?: BrxAnchor;
    availableContentSpace: string;
    backgroundColorHex: string;
    ctabutton: BrxCTAButton;
    ctaBackgroundColorHex: string;
    ctaFontColorHex: string;
    desktopCoordinates: {
        productId: string[];
        coordinates: string;
        text: BrxHtml;
    };
    desktopImage: BrxImageWithAltText;
    dropshadow: boolean;
    headlines: BrxHeadlines;
    mobileCoordinates: {
        productId: string[];
        coordinates: string;
        text: BrxHtml;
    };
    mobileImage: BrxImageWithAltText;
    teaserBackgroundColorHex: string;
    teaserFontColorHex: string;
    titleFontColorHex: string;
    useSquareCorners: boolean;
};

const AmbienteShopping = (props: AmbienteShoppingProps): JSX.Element => (
    <LazyComponent>
        <AmbienteShoppingMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.AmbienteShopping', AmbienteShopping, 'catalog-base');
