import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { BaseProps, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';

export type PolaroidProps = BaseProps & {
    image: BrxImageWithAltText;
    title: string;
    text: BrxHtml;
};
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const PolaroidMolecule = lazy(() => lazyRetry(() => import('./Polaroid')));

const Polaroid = (props: PolaroidProps): JSX.Element => (
    <LazyComponent>
        <PolaroidMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.PolaroidCollection.Polaroid', Polaroid);
