import { TrackableLink } from '@mediashop/app/analytics/components/TrackableLink';
import { TrackingKeys } from '@mediashop/app/analytics/constants/trackingKeys';
import { BaseProps, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import { BreakpointName } from '@mediashop/app/config/breakpoints';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import BloomreachImageResponsive from '@mediashop/base/pattern/atom/BloomreachImageResponsive';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export const componentName = 'header-logo';

export enum LogoEffects {
    None,
    Flip,
    FadeInFadeOut,
}

type HeaderLogoProps = BaseProps & {
    msLogoDesktop: BrxImageSetImpl;
    msLogoMobile: BrxImageSetImpl;
    msLogoEffectDesktop?: BrxImageSetImpl;
    msLogoEffectMobile?: BrxImageSetImpl;
    effect?: LogoEffects;
    minWidthMobileLogo?: string;
    minWidthDesktopLogo?: string;
    onClick?: () => void;
};

const HeaderLogo = ({
    className,
    msLogoDesktop,
    msLogoMobile,
    msLogoEffectDesktop: msLogoEffect,
    msLogoEffectMobile,
    effect = LogoEffects.None,
    minWidthMobileLogo,
    minWidthDesktopLogo,
    onClick,
}: HeaderLogoProps): JSX.Element => {
    const intl = useIntl();
    const { formatLinkBase } = useReactRouterLink();
    const homeLink = formatLinkBase(EMPTY_STRING);

    const deviceType = useDeviceType();
    const [device, setDevice] = useState<BreakpointName>('desktop');
    useEffect(() => setDevice(deviceType), [deviceType]);

    const isMobile = device === 'mobile';

    let style = {};
    if (isMobile && minWidthMobileLogo) {
        style = { minWidth: `${minWidthMobileLogo}px` };
    } else if (!isMobile && minWidthDesktopLogo) {
        style = { minWidth: `${minWidthDesktopLogo}px` };
    }

    return (
        <div className={classNames(componentName, className)}>
            <div className={classNames(`${componentName}__desktop`)}>
                <TrackableLink
                    to={homeLink}
                    aria-label={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                    trackingKey={TrackingKeys.HEADER_LOGO}
                >
                    {effect === LogoEffects.None && (
                        <BloomreachImageResponsive
                            alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                            desktopImage={msLogoDesktop}
                            variant={BrXImageVariant.MainNavigation}
                            style={style}
                        />
                    )}
                    {effect === LogoEffects.Flip && (
                        <div className={classNames(`${componentName}__flip-box`)}>
                            <div className={classNames(`${componentName}__flip-box-inner`)}>
                                <div className={classNames(`${componentName}__flip-box-front`)}>
                                    <BloomreachImageResponsive
                                        alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                                        desktopImage={msLogoDesktop}
                                        variant={BrXImageVariant.MainNavigation}
                                        style={style}
                                    />
                                </div>
                                <div className={classNames(`${componentName}__flip-box-back`)}>
                                    <BloomreachImageResponsive
                                        alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                                        desktopImage={msLogoEffect ?? msLogoDesktop}
                                        variant={BrXImageVariant.MainNavigation}
                                        style={style}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {effect === LogoEffects.FadeInFadeOut && (
                        <div className={classNames(`${componentName}__fade`)}>
                            <div className={classNames(`${componentName}__fade-first`)}>
                                <BloomreachImageResponsive
                                    alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                                    desktopImage={msLogoDesktop}
                                    variant={BrXImageVariant.MainNavigation}
                                    style={style}
                                />
                            </div>
                            <div className={classNames(`${componentName}__fade-second`)}>
                                <BloomreachImageResponsive
                                    alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                                    desktopImage={msLogoEffect ?? msLogoDesktop}
                                    variant={BrXImageVariant.MainNavigation}
                                    style={style}
                                />
                            </div>
                        </div>
                    )}
                </TrackableLink>
            </div>
            <div className={`${componentName}__mobile`}>
                <TrackableLink
                    to={homeLink}
                    onClick={onClick}
                    aria-label={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                    trackingKey={TrackingKeys.HEADER_LOGO}
                >
                    {effect === LogoEffects.None && (
                        <BloomreachImageResponsive
                            alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                            desktopImage={msLogoMobile}
                            variant={BrXImageVariant.MainNavigation}
                            style={style}
                        />
                    )}
                    {effect === LogoEffects.Flip && (
                        <div className={classNames(`${componentName}__flip-box`)}>
                            <div className={classNames(`${componentName}__flip-box-inner`)}>
                                <div className={classNames(`${componentName}__flip-box-front`)}>
                                    <BloomreachImageResponsive
                                        alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                                        desktopImage={msLogoMobile}
                                        variant={BrXImageVariant.MainNavigation}
                                        style={style}
                                    />
                                </div>
                                <div className={classNames(`${componentName}__flip-box-back`)}>
                                    <BloomreachImageResponsive
                                        alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                                        desktopImage={msLogoEffectMobile ?? msLogoMobile}
                                        variant={BrXImageVariant.MainNavigation}
                                        style={style}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {effect === LogoEffects.FadeInFadeOut && (
                        <div className={classNames(`${componentName}__fade`)}>
                            <div className={classNames(`${componentName}__fade-first`)}>
                                <BloomreachImageResponsive
                                    alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                                    desktopImage={msLogoMobile}
                                    variant={BrXImageVariant.MainNavigation}
                                    style={style}
                                />
                            </div>
                            <div className={classNames(`${componentName}__fade-second`)}>
                                <BloomreachImageResponsive
                                    alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                                    desktopImage={msLogoEffectMobile ?? msLogoMobile}
                                    variant={BrXImageVariant.MainNavigation}
                                    style={style}
                                />
                            </div>
                        </div>
                    )}
                </TrackableLink>
            </div>
        </div>
    );
};

export default HeaderLogo;
