import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BackgroundProps, BaseProps, BrxImageWithTextProps, HeadlineStyleProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { PaymentMeta } from '@mediashop/app/api/types/Payment';

const OnePageCheckoutMolecule = lazy(() => lazyRetry(() => import('./OnePageCheckout')));

export type OnePageCheckoutProps = BaseProps & {
    headline: string;
    headlineStyle: HeadlineStyleProps;
    background: BackgroundProps;
    icons: BrxImageWithTextProps[];
    payment?: PaymentMeta;
};

const OnePageCheckout = (props: OnePageCheckoutProps): JSX.Element => (
    <LazyComponent>
        <OnePageCheckoutMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.OnePageCheckout', OnePageCheckout);
