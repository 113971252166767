import { dispatchAddToCart } from './actions/dispatchAddToCart';
import { dispatchCartContentReceived } from './actions/dispatchCartContentReceived';
import { dispatchCheckoutAccountChanged } from './actions/dispatchCheckoutAccountChanged';
import { dispatchCheckoutStep } from './actions/dispatchCheckoutStep';
import { dispatchCreatePayment } from './actions/dispatchCreatePayment';
import { dispatchCustomerLoggedIn } from './actions/dispatchCustomerLoggedIn';
import { dispatchCustomerRegistered } from './actions/dispatchCustomerRegistered';
import { dispatchDiscountApplied } from './actions/dispatchDiscountApplied';
import { dispatchOrderCreated } from './actions/dispatchOrderCreated';
import { dispatchPageViewed } from './actions/dispatchPageViewed';
import { dispatchPaymentChanged } from './actions/dispatchPaymentChanged';
import { dispatchProductDetails } from './actions/dispatchProductDetails';
import { dispatchProductListDisplayed } from './actions/dispatchProductListDisplayed';
import { dispatchProductListItemClicked } from './actions/dispatchProductListItemClicked';
import { dispatchTestMode } from './actions/dispatchTestMode';

export default {
    dispatchAddToCart,
    dispatchCartContentReceived,
    dispatchCheckoutAccountChanged,
    dispatchCheckoutStep,
    dispatchCustomerLoggedIn,
    dispatchCustomerRegistered,
    dispatchCreatePayment,
    dispatchDiscountApplied,
    dispatchOrderCreated,
    dispatchPageViewed,
    dispatchPaymentChanged,
    dispatchProductDetails,
    dispatchProductListDisplayed,
    dispatchProductListItemClicked,
    dispatchTestMode,
};
