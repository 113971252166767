import { NostoSearch } from '@nosto/nosto-react';
import { ReactElement } from 'react';
import { SKIP_RENDER } from '../constants/semanticConstants';
import { useQueryParams } from '../hooks/useQueryParams';

const NostoSearchWrapper = (): ReactElement | null => {
    const { q: query } = useQueryParams();

    if (query) {
        return <NostoSearch query={query} />;
    }

    return SKIP_RENDER;
};

export default NostoSearchWrapper;
