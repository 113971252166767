import { lazy } from 'react';
import { Cart } from '@mediashop/app/api/types/ClientCart';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { PaymentMeta } from '@mediashop/app/api/types/Payment';

const OrderOverviewMolecule = lazy(() => lazyRetry(() => import('./OrderOverview')));

export type OrderOverviewProps = {
    cart: Cart;
    payment?: PaymentMeta;
};
const OrderOverview = (props: OrderOverviewProps): JSX.Element => (
    <LazyComponent>
        <OrderOverviewMolecule {...props} />
    </LazyComponent>
);

export default OrderOverview;
