import { BaseProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { BrXLinkOpenInTabWithColorsAndBorder } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import classNames from 'classnames';
import { useRef } from 'react';
import { MainNavigationCustomNavigationProps, MainNavigationCustomQuickLinksProps } from '../types';
import BurgerMenuCountry from './BurgerMenuCountry/BurgerMenuCountry';
import BurgerMenuNavigation from './BurgerMenuNavigation';
import BurgerMenuQuickLinks from './BurgerMenuQuickLinks';
import SpecialButton from '../SpecialButton';

const componentName = 'burger-menu';

type BurgerMenuProps = BaseProps & {
    closeBurgerMenu: () => void;
    isOpen: boolean;
    specialButton?: BrXLinkOpenInTabWithColorsAndBorder;
    navigation: MainNavigationCustomNavigationProps;
    quickLinks: MainNavigationCustomQuickLinksProps;
};

const BurgerMenu = ({ closeBurgerMenu, isOpen, specialButton, navigation, quickLinks }: BurgerMenuProps) => {
    const ref = useRef<HTMLDivElement | null>(null);

    return (
        <div
            ref={ref}
            className={classNames(componentName, {
                [`${componentName}--open`]: isOpen,
            })}
        >
            <div className={`${componentName}__inner-wrapper`}>
                {specialButton ? <SpecialButton {...specialButton} /> : SKIP_RENDER}

                <BurgerMenuNavigation navigation={navigation} closeBurgerMenu={closeBurgerMenu} />

                <BurgerMenuQuickLinks {...quickLinks} closeBurgerMenu={closeBurgerMenu} />
            </div>
            <BurgerMenuCountry />
        </div>
    );
};

export default BurgerMenu;
