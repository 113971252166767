import { Money } from '@mediashop/app/api/types/Money';

export default function getInstallmentPrice(lineItemPrice: Money, numberOfInstallments?: number | undefined): Money {
    const installments = numberOfInstallments ?? lineItemPrice?.installments;
    let installmentPrice;
    if (lineItemPrice && installments) {
        installmentPrice = {
            ...lineItemPrice,
            centAmount: Math.round(lineItemPrice.centAmount / installments),
            installments,
        };
    }
    return installmentPrice;
}
