import { BrProps } from '@bloomreach/react-sdk';

// All components which do not require a document
const COMPONENTS_WITHOUT_DOCUMENT = [
    'splpcheckoutsuccess',
    'anchor',
    'productdetails',
    'breadcrums',
    'trackingandtrace',
    'noindex',
    'manuals',
    'gdprsettings',
    'seo',
];

/**
 * Determine whether the given component actually needs a document before displaying a warning.
 * Currently this is done by checking the name of the component against a whitelist.
 * A smarter approach would be favourable.
 *
 * Update there would be: brx has a props.component.getType() function defined in the prototype of component, but does
 * not expose this function in the {@see BrProps} components type.
 *
 * Bloomreach adds an id to the componentName so if there are multiple components, the names become
 * component, component1,...
 *
 * @param props
 */
export default function shouldComponentHaveDocument(props: BrProps): boolean {
    const componentName = props.component?.getName().toLowerCase().replace(/\d+/, '');

    return !COMPONENTS_WITHOUT_DOCUMENT.includes(componentName!);
}
