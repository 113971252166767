import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const SelectBoxLazy = lazy(() => lazyRetry(() => import('./SelectBox')));

type SelectBoxOptionsProp = {
    value: string;
    label?: string;
    iconName: string;
};

export type SelectBoxProps = BaseProps & {
    defaultValue: SelectBoxOptionsProp;
    onChange: (newValue: Record<string, any>) => void;
    options: SelectBoxOptionsProp[];
    selectEnabled?: boolean;
};

const SelectBox = (props: SelectBoxProps): JSX.Element => (
    <LazyComponent>
        <SelectBoxLazy {...props} />
    </LazyComponent>
);

export default SelectBox;
