import { ComponentType, useContext } from 'react';
import { ThemeContext } from '@mediashop/app/Theme';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import classNames from 'classnames';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const componentName = `icon`;

type Props = BaseProps & {
    name: string;
    props?: unknown;
};

/**
 * Add Icon component
 */
export default function Icon({ name = 'info', className = '', ...props }: Props): JSX.Element | null {
    const theme = useContext(ThemeContext);
    const IconComponent = theme.icons?.[name] as ComponentType<BaseProps>;

    return IconComponent ? (
        <IconComponent className={classNames(className, componentName, `${componentName}--${name}`)} {...props} />
    ) : (
        SKIP_RENDER
    );
}
