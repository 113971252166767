import { injectComponent } from '@mediashop/app/component-injector';
import classNames from 'classnames';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';

export type BadgeSizeProps = 'small' | 'medium' | 'large';

export type BadgeStyleProps = 'highlight' | 'promotion' | 'info' | 'warning' | 'success' | 'error';

export type BadgeTransformProps = 'none' | 'uppercase';

export type BadgeAlignProps = 'left' | 'right' | 'center';

type Props = BasePropsWithChildren & {
    text: string;
    badgeKey?: string;
    size?: BadgeSizeProps;
    spacing?: BadgeSizeProps;
    align?: BadgeAlignProps;
    style?: BadgeStyleProps;
    transform?: BadgeTransformProps;
};

/**
 * Display a badge.
 * Multiple sizes and colors available.
 */
function Badge({
    text = '',
    badgeKey = '',
    size = 'medium',
    style = 'info',
    align = 'center',
    spacing = 'medium',
    transform = 'none',
    children,
    className,
}: Props) {
    const componentName = 'badge';
    const key = badgeKey ? `${componentName}--type-${badgeKey}` : ``;

    return (
        <div
            className={classNames(
                componentName,
                `${componentName}--${size}`,
                `${componentName}--${style}`,
                `${componentName}--align-${align}`,
                `${componentName}--spacing-${spacing}`,
                `${componentName}--transform-${transform}`,
                key,
                className
            )}
        >
            {text}
            {children}
        </div>
    );
}

export default injectComponent('pattern.atom.Badge', Badge);
