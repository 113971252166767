import { injectComponent } from '@mediashop/app/component-injector';
import Button from '@mediashop/base/pattern/atom/Button';
import Icon from '../Icon';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { SocialShareProps } from '../../../types';
import LinkButton from '../LinkButton';

function SocialShareButton(props: SocialShareProps) {
    if (props.type !== 'link' && props.type !== 'button') {
        throw new Error('Invalid type passed to SocialShare');
    }

    const { platform, messageId, mobileOnly = false, componentName } = props;
    const messageIdProp = messageId ?? platform.toLowerCase();
    const baseClassName = `${componentName}__button`;
    const mobileClassName = `${baseClassName}-mobile`;

    return (
        <>
            {props.type === 'link' && (
                <LinkButton
                    className={classNames(baseClassName, {
                        [mobileClassName]: mobileOnly,
                    })}
                    to={props.url}
                    target="_blank"
                >
                    <Icon className={`${componentName}__button-icon`} name={platform} />
                    <span className={`${componentName}__button-text`}>
                        <FormattedMessage id={`socialMedia.${messageIdProp}`} />
                    </span>
                </LinkButton>
            )}
            {props.type === 'button' && (
                <Button
                    className={classNames(baseClassName, {
                        [mobileClassName]: mobileOnly,
                    })}
                    onClick={props.clickHandler}
                >
                    <Icon className={`${componentName}__button-icon`} name={platform} />
                    <span className={`${componentName}__button-text`}>
                        <FormattedMessage id={`socialMedia.${messageIdProp}`} />
                    </span>
                </Button>
            )}
        </>
    );
}

export default injectComponent('pattern.atom.SocialShareButton', SocialShareButton);
