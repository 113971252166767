import { CategoryCustomFields } from '@mediashop/app/api/types/ClientCategory';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import CroppedImage from '@mediashop/base/pattern/atom/CroppedImage';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';

const componentName = 'desktop-menu-item-custom';

export type DesktopMenuItemCustomProps = BaseProps & {
    customItem?: CategoryCustomFields;
};

const DesktopMenuItemCustom = ({ customItem }: DesktopMenuItemCustomProps): JSX.Element | null => {
    if (!customItem) {
        return SKIP_RENDER;
    }

    return (
        <li className={componentName}>
            {customItem.categoryImageURL ? (
                <CroppedImage
                    className={`${componentName}__image`}
                    src={customItem.categoryImageURL}
                    altText={customItem.categoryImageAltText}
                    variant={CroppedImageVariant.CategoryImage}
                />
            ) : (
                SKIP_RENDER
            )}
            {customItem.categoryBadge ? (
                <div className={`${componentName}__badge`}>{customItem.categoryBadge}</div>
            ) : (
                SKIP_RENDER
            )}
            {customItem.categoryCtaText ? (
                <div className={`${componentName}__link`}>
                    <a className={`${componentName}__link-text`} href={customItem.categoryCtaLink}>
                        {customItem.categoryCtaText}
                    </a>
                </div>
            ) : (
                SKIP_RENDER
            )}
        </li>
    );
};

export default DesktopMenuItemCustom;
