export const ANALYTICS_EVENT = {
    ADD_TO_CART: 'addToCart',
    CHECKOUT_ACCOUNT: 'checkout-option',
    CHECKOUT_STEP: 'checkout-step',
    CREATE_PAYMENT: 'createPayment',
    DISCOUNT_APPLY: 'checkout-coupon-apply',
    LOGIN: 'user-login',
    ORDER_CREATE: 'purchase',
    PAGEVIEW: 'pageview',
    PAYMENT_CHANGE: 'checkout-option',
    PRODUCT_DETAILS: 'product-details',
    PRODUCTLIST_CLICK: 'productList-click',
    PRODUCTLIST_VIEW: 'productList-view',
    REGISTRATION: 'user-registration',
} as const;
