import { useAppSelector } from '../store/hooks/hooks';
import { OrderState } from '../store/reducer/order';
import { shallowEqual } from 'react-redux';

/**
 * Hook that returns order data from store.
 * @returns order
 */
export const useOrder = (equalityCheck = false): OrderState => {
    return useAppSelector((state) => state.order, {
        equalityFn: equalityCheck ? shallowEqual : undefined,
    });
};
