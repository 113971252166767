import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { queries, QueryKeys } from '..';
import { useCartApiClient } from '../../api/hooks/useCartApiClient';
import { useShopContext } from '../../hooks/useShopContext';
import { useCart } from '../../hooks/useCart';
import { useAppSelector } from '../../store/hooks/hooks';

type ExpressCheckoutConfig = QueryKeys['checkout']['expressConfig'];

/**
 * Load express checkout configuration (e.g. PayPal Express)
 */
export const useExpressCheckoutConfig = () => {
    const apiClient = useCartApiClient();

    const { isSignedIn = false } = useAppSelector((state) => state.customer);
    const { cart } = useCart();
    const { country, locale } = useShopContext();

    const queryFn = (ctx: QueryFunctionContext<ExpressCheckoutConfig['queryKey']>) => {
        const [, , locale] = ctx.queryKey;
        return apiClient.getExpressCheckoutConfig(locale);
    };

    const query = useQuery({
        ...queries.checkout.expressConfig(locale, country, isSignedIn),
        queryFn,
        enabled: Boolean(cart),
        staleTime: 1000 * 60 * 15, // 15 minutes = max lifetime of PayPal ID token
    });

    return query;
};
