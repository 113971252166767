import { useMemo } from 'react';
import { ChannelSettings } from './types/channelSettings';
import { useBrxPageContext } from './useBrxPageContext';

export const useChannelSettings = () => {
    const brxContext = useBrxPageContext();

    if (!brxContext) {
        throw new Error('useChannelSettings must be called inside a Bloomreach Page');
    }

    const channelSettings = useMemo<ChannelSettings>(() => {
        const {
            channelPreviewImage,
            channelPreviewImageAltText,
            googleSiteVerification,
            productTileEffect = 'Zoom',
            seoMetaTitle,
            showReviews,
            useInformalGerman = false,
            usercentricsID,
        } = brxContext.getChannelParameters();

        return {
            channelPreviewImage,
            channelPreviewImageAltText,
            googleSiteVerification,
            productTileEffect,
            seoMetaTitle,
            showReviews,
            useInformalGerman,
            usercentricsId: usercentricsID,
        };
    }, [brxContext]);

    return channelSettings;
};
