import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { VoucherRowDiscountType, DiscountCode } from '@mediashop/app/api/types/Discount';
import Button from '@mediashop/base/pattern/atom/Button';
import Modal from '@mediashop/base/pattern/molecule/Modal';
import {
    getDiscountedValuePerCurrency,
    getRelativeDiscountValue,
    isRelativeDiscount,
} from '@mediashop/base/utils/discounts';
import { Price } from '@mediashop/base/pattern/molecule/Price';
import { Money } from '@mediashop/app/api/types/Money';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const componentName = 'voucher-check-modal';

const emptyMoney: Money = {
    centAmount: 0,
    fractionDigits: 2,
    currencyCode: 'EUR',
};

type VoucherCheckModalProps = {
    onModalClose: () => void;
    discount?: VoucherRowDiscountType;
    discountCode: DiscountCode;
};

export function VoucherCheckModal({ discountCode, onModalClose, discount }: VoucherCheckModalProps): JSX.Element {
    const { currency } = useShopContext();

    const [isModalOpen, setModalOpen] = useState(true);

    const isRelativeDiscountCode = isRelativeDiscount(discountCode);

    const minimumOrderValue = discountCode?.minimumOrderValue;

    const legalText = discountCode?.legalText;

    const discountComponent = isRelativeDiscountCode ? (
        <span>{getRelativeDiscountValue(discountCode)}%</span>
    ) : (
        <Price price={getDiscountedValuePerCurrency(discountCode, currency) ?? emptyMoney} />
    );

    const handleModalClose = () => {
        setModalOpen(false);
        onModalClose();
    };

    return (
        <Modal isOpen={isModalOpen} onRequestClose={handleModalClose} overlayClassName={`${componentName}__overlay`}>
            <div className={`${componentName}`}>
                <h4 className={`${componentName}__headline`}>
                    {discount?.targetType === 'GiftLineItem' ? (
                        <FormattedMessage id="cart.voucherModal.headlineGiftLineItem" />
                    ) : (
                        <FormattedMessage id="cart.voucherModal.headline" />
                    )}
                </h4>
                <span className={`${componentName}__voucher-details`}>
                    <p className={`${componentName}__voucher-code`}>{discountCode.code}</p>
                    {discount?.targetType !== 'GiftLineItem' ? (
                        <p className={`${componentName}__voucher-text`}>
                            <FormattedMessage
                                id="voucher.modal.savings"
                                values={{
                                    amount: discountComponent,
                                }}
                            />

                            {minimumOrderValue ? (
                                <FormattedMessage
                                    id="voucher.modal.minimalOrderAmount"
                                    values={{ amount: <Price price={minimumOrderValue} /> }}
                                />
                            ) : (
                                SKIP_RENDER
                            )}
                        </p>
                    ) : (
                        SKIP_RENDER
                    )}
                </span>

                <span className={`${componentName}__legal-text`}>{legalText}</span>
            </div>
            <Button style="primary" size="medium" className={`${componentName}__button`} onClick={handleModalClose}>
                <FormattedMessage id="cart.voucherModal.continue" />
            </Button>
        </Modal>
    );
}
