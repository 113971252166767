import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { TextProps } from '../../atom/Text';

const CheckoutSuccessMolecule = lazy(() => lazyRetry(() => import('./CheckoutSuccess')));

export type CheckoutSuccessProps = BaseProps & {
    text?: TextProps;
};

const CheckoutSuccess = (props: CheckoutSuccessProps): JSX.Element => (
    <LazyComponent>
        <CheckoutSuccessMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.CheckoutSuccess', CheckoutSuccess);
