import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

export interface StructuredDataProps extends BaseProps {
    structuredData: BrxHtml;
}

const StructuredDataLazy = lazy(() => lazyRetry(() => import('./StructuredData')));

const StructuredData = (props: StructuredDataProps): JSX.Element => (
    <LazyComponent>
        <StructuredDataLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.StructuredData', StructuredData);
