import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CardExtensionMolecule = lazy(() => lazyRetry(() => import('./CardExtension')));

export type CardExtensionProps = BaseProps & {
    ctaTextMembership: string;
    className?: string;
    inProgress: boolean;
    addToCart: () => void;
    daysToExpiry?: number;
};

const CardExtension = (props: CardExtensionProps): JSX.Element => (
    <LazyComponent>
        <CardExtensionMolecule {...props} />
    </LazyComponent>
);

export default CardExtension;
