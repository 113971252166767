import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxAnchor } from '@mediashop/app/bloomreach/types';

export type LiveTVProps = BaseProps & {
    anchor?: BrxAnchor;
    videoUrl: {
        reference: string;
        text: string;
    };
    embedKey: string;
};

import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const LiveTVPlayerMolecule = lazy(() => lazyRetry(() => import('./LiveTVPlayer')));

const LiveTV = (props: LiveTVProps): JSX.Element => (
    <LazyComponent>
        <LiveTVPlayerMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.LiveTV', LiveTV);
