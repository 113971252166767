import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Cart, MultipleShippingInfo, ShippingInfo, ShippingMode } from '@mediashop/app/api/types/ClientCart';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ShippingInfoMolecule = lazy(() => lazyRetry(() => import('./ShippingInfo')));

export type ShippingInfoProps = BaseProps & {
    shipping?: Array<MultipleShippingInfo>;
    shippingInfo?: ShippingInfo;
    shippingMode: ShippingMode;
    customFields?: Cart['customFields'];
};

const ShippingInfo = (props: ShippingInfoProps) => (
    <LazyComponent>
        <ShippingInfoMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.ShippingInfo', ShippingInfo, 'catalog-base');
