import useNostoSearchAnalytics from '@mediashop/app/hooks/useNostoSearchAnalytics';
import Button from '@mediashop/base/pattern/atom/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import BuyButton from '../../../BuyButton';
import { getVariant } from '../../helper';
import { ProductWithActiveVariant } from '@mediashop/app/api/types/ClientProduct';
import { BaseProps, BrxRelationType } from '@mediashop/app/bloomreach/types';
import { usePartner } from '@mediashop/app/hooks/usePartner';
import useToast from '@mediashop/app/hooks/useToast';
import { useLoader } from '@mediashop/app/store/loader';
import { useState } from 'react';

const componentName = 'product-button';

type ProductButtonProps = BaseProps & {
    product: ProductWithActiveVariant;
    dealOfTheDay?: boolean;
    disabled?: boolean;
    partnerId?: string;
    productRelationType?: BrxRelationType;
    handleDisableAllButtons?: (disable: boolean) => void;
    goToProduct?: (event: unknown) => void;
};

function ProductButton({
    product,
    dealOfTheDay,
    disabled,
    partnerId: nextPartnerId,
    productRelationType,
    handleDisableAllButtons,
    goToProduct,
}: ProductButtonProps): JSX.Element {
    const currentVariant = getVariant(product);
    const [isInProgress, setIsInProgress] = useState(false);
    const [addToCartErrorMessage, setAddToCartErrorMessage] = useState('');
    const intl = useIntl();
    const toast = useToast();
    const { cartLoader } = useLoader();
    const { partnerId } = usePartner();
    const { trackProductClick } = useNostoSearchAnalytics();

    /**
     * Add article to cart and redirect to cart.
     */
    const handleAddToCartClick = async (quantity: number) => {
        if (!currentVariant) {
            return;
        }

        setIsInProgress(true);
        setAddToCartErrorMessage('');
        if (handleDisableAllButtons) {
            handleDisableAllButtons(true);
        }

        try {
            const { exceededMaxQuantity } = await cartLoader.addLineItem({
                partner: nextPartnerId ?? partnerId,
                quantity,
                variant: currentVariant,
            });

            if (exceededMaxQuantity) {
                const errorMessage = intl.formatMessage(
                    { id: 'product.exceededMaxOrderQuantity' },
                    {
                        // TODO: we should fix the types for variant attributes
                        amount: currentVariant?.attributes?.maxOrderQuantity as number | undefined,
                    }
                );
                toast.error(errorMessage);
                setAddToCartErrorMessage(errorMessage);
            } else {
                trackProductClick('category', product);
            }
        } catch {
            const addToCartErrorMessage = intl.formatMessage({ id: 'product.cantAddToCart' });
            toast.error(addToCartErrorMessage);
            setAddToCartErrorMessage(addToCartErrorMessage);
        } finally {
            setIsInProgress(false);
            if (handleDisableAllButtons) {
                handleDisableAllButtons(false);
            }
        }
    };

    return (
        <div className={`${componentName}__cta-btn`} onClick={(event) => event.stopPropagation()}>
            {product.activeVariant?.price || product.variants.filter((variant) => variant.price).length <= 1 ? (
                <BuyButton
                    className={`${componentName}__cta-btn-cart`}
                    product={product}
                    activeVariant={currentVariant}
                    onClick={handleAddToCartClick}
                    textId="product.addToCart"
                    isLoading={isInProgress}
                    goToCart={false}
                    dealOfTheDay={dealOfTheDay}
                    hidePreCheckoutFlyout={isInProgress}
                    productRelationType={productRelationType}
                    showSelect={false}
                    addToCartErrorMessage={addToCartErrorMessage}
                    disabled={disabled}
                />
            ) : (
                <Button
                    style="tertiary"
                    className={`${componentName}__cta-btn-details`}
                    onClick={goToProduct}
                    fullWidth
                >
                    <FormattedMessage id="product.showDetails" />
                </Button>
            )}
        </div>
    );
}

export default ProductButton;
