import { Product } from '../../api/types/ClientProduct';
import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { ProductListItemClickedDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';
import { getProductCategories, getProductCategory } from '../utils/categoryUtils';
import { Category } from '../../api/types/ClientCategory';

export type DispatchProductListItemClickedData = {
    product: Product;
    listName: string;
    position: number;
    mainCategory?: Category;
    country: string;
};

const createGtmDataLayer = (
    event: TagManagerEvent,
    { country, listName: list, mainCategory, position, product }: DispatchProductListItemClickedData
): ProductListItemClickedDataLayer => ({
    dataLayer: {
        event,
        ecommerce: {
            click: {
                actionField: {
                    list,
                },
                products: [
                    {
                        id: product?.id ?? 'unknown',
                        sku: product?.variants?.length > 1 ? 'unknown' : product.variants[0].sku,
                        groupId: product?.key ?? 'unknown',
                        name: product?.name ?? 'unknown',
                        brand: product ? product.variants[0].attributes.brandName : 'unknown',
                        price: product ? product.variants[0].price?.centAmount / 100 : 'unknown',
                        category: getProductCategory(mainCategory, product, country).productCategory ?? '',
                        categories: getProductCategories(mainCategory, product, country),
                        list: 'Category_Main',
                        position,
                    },
                ],
            },
        },
    },
});

export const dispatchProductListItemClicked = (data: DispatchProductListItemClickedData): void => {
    triggerGtmEvent(createGtmDataLayer(ANALYTICS_EVENT.PRODUCTLIST_CLICK, data));
};
