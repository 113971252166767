import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Money } from '@mediashop/app/api/types/Money';
import { CartLineItem, CustomLineItem, ShippingInfo } from '@mediashop/app/api/types/ClientCart';

export type CartSummaryProps = BaseProps & {
    shippingInfo: ShippingInfo;
    productTotal: Money;
    totalPrice: Money;
    isSticky?: boolean;
    isLoading: boolean;
    customLineItems?: CustomLineItem[];
    lineItems: CartLineItem[];
};
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CartSummaryMolecule = lazy(() => lazyRetry(() => import('./CartSummary')));

const CartSummary = (props: CartSummaryProps): JSX.Element => (
    <LazyComponent>
        <CartSummaryMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.CartSummary', CartSummary);
