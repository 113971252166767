import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ProductSafetyMolecule = lazy(() => lazyRetry(() => import('./ProductSafety')));

type ProductSafetyProps = BaseProps & {
    descriptionChemicalArticle: string;
    hazardWarnings: string[];
    hazardWarningsPictograms: string[];
    signalWord: string;
};

const ProductSafety = (props: ProductSafetyProps): JSX.Element => (
    <LazyComponent>
        <ProductSafetyMolecule {...props} />
    </LazyComponent>
);

export default ProductSafety;
