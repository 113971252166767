import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { Link } from 'react-router-dom';
import { BackgroundProps, BaseProps, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import BloomreachImageResponsive from '../../atom/BloomreachImageResponsive';
import NavigationItem, { NavigationItemProps } from './NavigationItem';
import BackgroundColorWrapper from '../../atom/BackgroundColorWrapper';
import ContentWrapper from '../../atom/ContentWrapper';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';

type NavigationProps = BaseProps & {
    logo: BrxImageWithAltText;
    navigationItems: NavigationItemProps[];
    background: BackgroundProps;
    backgroundBar: BackgroundProps;
};

/**
 * SPLP Navigation Component.
 * Provide the navigation of your SPLP with just a Logo and NavigationItems in a Bar.
 */
function SPLPNavigation({
    logo,
    navigationItems,
    background = 'primary',
    backgroundBar = 'secondary',
}: NavigationProps) {
    const componentName = 'splp-navigation';

    return (
        <>
            {logo.image && (
                <BackgroundColorWrapper backgroundColor={background}>
                    <ContentWrapper>
                        <div className={classNames(componentName, `${componentName}--${background}`)}>
                            {logo && (
                                <Link className={`${componentName}__logo`} to="/">
                                    <BloomreachImageResponsive
                                        className={`${componentName}__logo-image`}
                                        alt={logo.altText ?? EMPTY_STRING}
                                        desktopImage={logo.image}
                                        variant={BrXImageVariant.SPLPNavigation}
                                    />
                                </Link>
                            )}
                        </div>
                    </ContentWrapper>
                </BackgroundColorWrapper>
            )}
            <BackgroundColorWrapper backgroundColor={backgroundBar}>
                <ContentWrapper>
                    {navigationItems && (
                        <div className={`${componentName}__wrapper`}>
                            <ul
                                className={classNames(
                                    `${componentName}__navigation-bar`,
                                    `${componentName}__navigation-bar--${backgroundBar}`,
                                    `${navigationItems.length < 4 ? `${componentName}__navigation-bar--center` : ``}`
                                )}
                            >
                                {navigationItems.map((navigationItem, key) => (
                                    <NavigationItem
                                        key={key}
                                        reference={navigationItem.reference}
                                        text={navigationItem.text}
                                    />
                                ))}
                            </ul>
                        </div>
                    )}
                </ContentWrapper>
            </BackgroundColorWrapper>
        </>
    );
}

export default injectComponent('pattern.organism.SPLPNavigation', SPLPNavigation);
