import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import { useProject } from '../hooks/useProject';
import { useShopContext } from '../hooks/useShopContext';
import { ProjectStateFinished } from '../store/reducer/project';
import { EMPTY_STRING } from '../constants/semanticConstants';
import { useNonce } from '../hooks/useNonce';

const getPayPalClientId = (country: string, config: ProjectStateFinished['payPalClientId'] = []) => {
    const clientIdByCountry = config.find((group) => group.countries?.includes(country));
    return clientIdByCountry?.clientId ?? config.find((group) => !group.countries)?.clientId;
};

export const PaymentProvider = ({ children }: BasePropsWithChildren): JSX.Element => {
    const { country, currency } = useShopContext();
    const { key, payPalClientId: payPalClientIdConfig } = useProject();
    const { nonce } = useNonce();
    const clientId = getPayPalClientId(country, payPalClientIdConfig) ?? EMPTY_STRING;

    // passing the buyerCountry is only allow for the PayPal sandbox environment
    const buyerCountry = key.endsWith('-stage') ? country : undefined;

    return (
        <PayPalScriptProvider
            options={{
                buyerCountry,
                clientId,
                components: ['messages', 'buttons'],
                currency,
                commit: false,
                enableFunding: ['paylater'],
                intent: 'authorize',
                dataCspNonce: nonce,
            }}
            deferLoading
        >
            {children}
        </PayPalScriptProvider>
    );
};
