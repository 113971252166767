import { EmarsysCommand } from './commants';
import { dispatchQueue, EmarsysQueue, setTestMode } from './queue';
import { EmarsysCart, EmarsysQueueEvent } from './types';

const TRIGGER_GO_TIMEOUT = 1000;

/**
 * The goal of the go-timeout is to always wait one second after an event before triggering the go-command.
 * When a new event is being triggered/dispatched in the meantime, then the timeout is being reset again to one second.
 * The should ensure that the go command waits for all other events to be triggered before -> GO is always the last.
 */
let goTimeout;
let lastCartData: EmarsysCart = [];

const addCartEventIfMissing = (lastCartData: EmarsysCart) => {
    if (!EmarsysQueue.find((currEvent: EmarsysQueueEvent) => currEvent.command === EmarsysCommand.CART)) {
        EmarsysQueue.push({ command: EmarsysCommand.CART, data: lastCartData });
    }
};

export const triggerEmarsysEvent = (event: EmarsysQueueEvent): void => {
    if (event.command === EmarsysCommand.TEST_MODE) {
        setTestMode();
        return;
    }

    // Reset timeout
    if (goTimeout) {
        clearTimeout(goTimeout);
    }

    const existingEntryIndex = EmarsysQueue.findIndex((entry) => entry.command === event.command);
    if (existingEntryIndex >= 0) {
        EmarsysQueue[existingEntryIndex].data = event.data;
    } else {
        if (event.command === EmarsysCommand.GO) {
            addCartEventIfMissing(lastCartData);
        }
        EmarsysQueue.push(event);
    }

    if (event.command === EmarsysCommand.CART) {
        lastCartData = event.data;
    }

    if (event.command === EmarsysCommand.GO) {
        dispatchQueue();
    } else {
        goTimeout = setTimeout(() => {
            addCartEventIfMissing(lastCartData);
            EmarsysQueue.push({ command: EmarsysCommand.GO });
            dispatchQueue();
        }, TRIGGER_GO_TIMEOUT);
    }
};
