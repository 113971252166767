import { injectComponent } from '@mediashop/app/component-injector';
import { lazy, ReactElement } from 'react';
import { BasePropsWithChildren, BrxBackgroundImageWithStyle } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const BackgroundImageLazy = lazy(() => lazyRetry(() => import('./BackgroundImage')));

export type BackgroundImageProps = BasePropsWithChildren & {
    backgroundImageWithStyle?: BrxBackgroundImageWithStyle;
};

const BackgroundImage = (props: BackgroundImageProps): ReactElement => (
    <LazyComponent>
        <BackgroundImageLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.BackgroundImage', BackgroundImage);
