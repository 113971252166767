import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { RegisterProps } from '@mediashop/catalog-base/types/register';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const RegisterMolecule = lazy(() => lazyRetry(() => import('./Register')));

const Register = (props: RegisterProps): JSX.Element => (
    <LazyComponent>
        <RegisterMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.Register', Register, 'catalog-base');
