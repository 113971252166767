import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { injectComponent } from '@mediashop/app/component-injector';
import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const VariantOptionLazy = lazy(() => lazyRetry(() => import('./VariantOption')));

export type VariantOptionProps = BaseProps & {
    dimension: string;
    lastSelectedDimension: string;
    onClick: (key: string, value: string, variant?: Variant) => void;
    option: string;
    selectedDimensions: Record<string, string>;
    selectedVariant?: Variant;
    type: 'badge' | 'pill';
    variantDimensions: Array<{ key: string; label: string }>;
    variants: Array<Variant>;
};

const VariantOption = (props: VariantOptionProps): JSX.Element => (
    <LazyComponent>
        <VariantOptionLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.ProductStage.VariantSelector.VariantOption', VariantOption);
