import { injectComponent } from '@mediashop/app/component-injector';
import ContentWrapper from '../../atom/ContentWrapper';
import BackgroundColorWrapper from '../../atom/BackgroundColorWrapper';
import { BackgroundProps, BaseProps, BrxUsp } from '@mediashop/app/bloomreach/types';
import USPsItem from './USPsItem';

const componentName = 'usps';

type UspProps = BaseProps & {
    background?: BackgroundProps;
    usps: BrxUsp[];
    backgroundColorHex?: string;
    fontColorHex?: string;
};

/**
 * This component renders icons and its text in flexbox display.
 */
function USPs({ background = 'primary', usps, backgroundColorHex, fontColorHex }: UspProps): JSX.Element {
    return (
        <BackgroundColorWrapper backgroundColor={background} hexBackgroundColor={backgroundColorHex}>
            <ContentWrapper>
                <div className={componentName}>
                    <div className={`${componentName}__container`}>
                        {usps.map((item, key) => (
                            <USPsItem key={key} usp={item} className={componentName} fontColorHex={fontColorHex} />
                        ))}
                    </div>
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.USPs', USPs);
