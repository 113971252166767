import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import {
    BackgroundProps,
    BaseProps,
    HeadlineStyleProps,
    HeadlineTextStyleProps,
} from '@mediashop/app/bloomreach/types';

export type SocialShareProps = BaseProps & {
    background?: BackgroundProps;
    headline?: string;
    headlineStyle?: HeadlineStyleProps;
    textStyle?: HeadlineTextStyleProps;
    shareHeadline?: string;
    shareText?: string;
    facebook?: boolean;
    twitter?: boolean;
    whatsapp?: boolean;
    nativeShare?: boolean;
    fullWidth?: boolean;
    className?: string;
};
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const SocialShareMolecule = lazy(() => lazyRetry(() => import('./SocialShare')));

const SocialShare = (props: SocialShareProps): JSX.Element => (
    <LazyComponent>
        <SocialShareMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.SocialShare', SocialShare);
