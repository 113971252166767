import { ThunkMiddleware } from 'redux-thunk';
import { SessionStorageKeys } from '../../constants/storageKeys';
import { RootState } from '../index';
import { getPartnerId, partnerInitiated } from '../reducer/partner';

type SupportedActionTypes = ReturnType<typeof partnerInitiated>;

export const partnerMiddleware: ThunkMiddleware<RootState> = () => (next) => (action: SupportedActionTypes) => {
    if (typeof window !== 'undefined') {
        const { type } = action;

        switch (type) {
            case partnerInitiated.type: {
                const partnerId = getPartnerId(action.payload);
                if (partnerId) {
                    sessionStorage.setItem(SessionStorageKeys.PARTNER_ID, partnerId);
                }
                break;
            }
            default:
                break;
        }
    }
    return next(action);
};
