import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxAnchor, BrxBackgroundImageWithStyle, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import { TeaserGridTileType } from './TeaserGridTile';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const TeaserGridMolecule = lazy(() => lazyRetry(() => import('./TeaserGrid')));

export type TeaserGridProps = BaseProps & {
    anchor?: BrxAnchor;
    backgroundImageWithStyle?: BrxBackgroundImageWithStyle;
    headline: BrxHeadlines;
    teaserbackgroundColor: string;
    backgroundColor: string;
    teaserfontColor: string;
    titleFontColor: string;
    teasers: TeaserGridTileType[];
    elementsDesktop: string;
    elementsMobile: string;
    dropshadow: boolean;
    useSquareCorners: boolean;
    availableContentSpace: string;
    noGapBetweenTeasers: boolean;
    noBottomPadding: boolean;
};

const TeaserGrid = (props: TeaserGridProps): JSX.Element => (
    <LazyComponent>
        <TeaserGridMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.TeaserGrid', TeaserGrid, 'catalog-base');
