import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const DataCellMolecule = lazy(() => lazyRetry(() => import('./DataCell')));

export type DataCellProps = BaseProps & {
    type: 'Text' | 'Image';
    text?: string;
    image?: BrxImageSetImpl;
};

const DataCell = (props: DataCellProps): JSX.Element => (
    <LazyComponent>
        <DataCellMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.Table.DataCell', DataCell);
