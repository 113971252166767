import * as React from 'react';
import Select from '../../../atom/Select';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';

interface Props extends BaseProps {
    label: string;
    dimensionKey: string;
    dimensionIndex: number;
    options: Array<{ value: string; valueLabel: string }>;
    onChange: ({
        index,
        name,
        value,
    }: {
        index: number;
        name: string;
        value?: { value: string; valueLabel: string };
    }) => void;
    value: { value: string };
}

/**
 * Display a single variant dimension (dimension-label and select-box)
 * @param label {string}
 * @param dimensionKey {string}
 * @param dimensionIndex {number}
 * @param options {ClientVariantDimension[]}
 * @param onChange {function}
 * @param value {ClientVariantDimension}
 */
function ProductStageVariantDimension({ label, dimensionKey, dimensionIndex, options, onChange, value }: Props) {
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange({
            index: dimensionIndex,
            name: dimensionKey,
            value: options.find((option) => option.value === event.target.value),
        });
    };

    return (
        <div className="product-stage-variant-dimension">
            <div className="product-stage-variant-dimension__label">{label}</div>
            <Select onChange={handleChange} value={value.value} disabled={options.length === 1}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.valueLabel}
                    </option>
                ))}
            </Select>
        </div>
    );
}

export default injectComponent('pattern.organism.ProductStageVariantDimension', ProductStageVariantDimension);
