import { AssetTag } from '@mediashop/app/api/types/ClientProduct';
import { BrxPageModel } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useGetProductBySlug } from '@mediashop/app/hooks/useGetProductBySlug';
import { useResizeObserver } from '@mediashop/app/hooks/useResizeObserver';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { LinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import classnames from 'classnames';
import { UIEvent, useEffect, useRef, useState } from 'react';
import { AccordionPDPProps } from '../AccordionPDP/AccordionPDP';
import { ContentNavigationProps } from './index';
import { useBrxPageContext } from '@mediashop/app/bloomreach/useBrxPageContext';

export const componentName = 'content-navigation';
const CONTENT_NAVIGATION_SCROLL_LENGTH = 200;
export const CONTENT_NAVIGATION_HEIGHT = 42;

// eslint-disable-next-line max-lines-per-function
const ContentNavigation = ({
    backgroundColorHex,
    ctaColorHex,
    fontColorHex,
    lineColorHex,
}: ContentNavigationProps): JSX.Element | null => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const [contentNavigationScrollPosition, setContentNavigationScrollPosition] = useState<number>(0);
    const [maxScrollPosition, setMaxScrollPosition] = useState<number>(0);
    const [position, setPosition] = useState<'relative' | 'fixed'>('relative');

    const { product: foundProduct } = useGetProductBySlug();
    const assets = foundProduct?.product?.variants[0].assets || [];
    const downloadAssets = assets.filter((item) => item.tags.includes(AssetTag.DOWNLOAD));
    const productBySlug = foundProduct?.product;
    const ratingCount = productBySlug?.variants[0]?.attributes?.ratingCountLocalized ?? 0;
    const hasVideos =
        Boolean(foundProduct?.product?.variants[0]?.attributes?.youtubeVideoLink) ||
        Boolean(foundProduct?.product?.variants[0]?.attributes?.userVideoGuide);

    const brxPageContext = useBrxPageContext();
    const anchorComponents = Object.values(brxPageContext.toJSON().page as BrxPageModel)
        .filter(
            (component) =>
                component.data?.anchor ||
                component.data?.contentType === 'mediashop:ContentNavigationItem' ||
                component.data?.contentType === 'mediashop:AccordionPDP'
        )
        .map((anchorItem) => {
            const data: AccordionPDPProps = anchorItem.data;
            if (data.contentType === 'mediashop:AccordionPDP') {
                const {
                    hideDetails,
                    hideAttributes,
                    hideDeliveryContents,
                    hideDownloads,
                    hideRatings,
                    hideVideos,
                    hideFaq,
                    hideSpecialContent,
                    items,
                } = data;
                return items.filter(
                    // eslint-disable-next-line complexity
                    (accordionItem) =>
                        accordionItem.anchor?.text &&
                        ((accordionItem.accordionEntryType === 'attributes' && !hideAttributes) ||
                            (accordionItem.accordionEntryType === 'details' && !hideDetails) ||
                            (accordionItem.accordionEntryType === 'deliveryContent' && !hideDeliveryContents) ||
                            (accordionItem.accordionEntryType === 'downloads' &&
                                !hideDownloads &&
                                downloadAssets.length) ||
                            (accordionItem.accordionEntryType === 'ratings' && !hideRatings && ratingCount) ||
                            (accordionItem.accordionEntryType === 'videos' && !hideVideos && hasVideos) ||
                            (accordionItem.accordionEntryType === 'faq' && !hideFaq) ||
                            (accordionItem.accordionEntryType === 'specialContent' && !hideSpecialContent))
                );
            }

            return anchorItem;
        })
        .flat();

    const [activeSection, setActiveSection] = useState<string>('');

    const [width] = useResizeObserver(divRef);

    const scrollContentNavigation = (scrollTo: number) => {
        setContentNavigationScrollPosition(Math.min(Math.max(scrollTo, 0), maxScrollPosition));
    };

    useEffect(() => {
        const root = document.documentElement;
        backgroundColorHex.length && root.style.setProperty('--content-navigation-bg', backgroundColorHex);
        ctaColorHex.length && root.style.setProperty('--content-navigation-cta', ctaColorHex);
        fontColorHex.length && root.style.setProperty('--content-navigation-font-color', fontColorHex);
        lineColorHex.length && root.style.setProperty('--content-navigation-line', lineColorHex);
    }, []);

    const checkNavigationPosition = () => {
        const currentScrollPositionY = window.scrollY;
        if (divRef.current) {
            const relativeTopPositionContentNavigation = divRef.current.getBoundingClientRect().top;
            if (relativeTopPositionContentNavigation > 0) {
                const firstAnchorComponentId = anchorComponents[0]?.data?.anchor?.id ?? anchorComponents[0]?.anchor?.id;
                const firstAnchorElement = document.getElementById(firstAnchorComponentId);
                const bottomPositionContentNavigation =
                    relativeTopPositionContentNavigation + currentScrollPositionY + divRef.current.clientHeight;
                if (firstAnchorElement && firstAnchorElement.offsetTop - 5 <= bottomPositionContentNavigation) {
                    setActiveSection(firstAnchorComponentId);
                }
            }

            const wrapperElement: HTMLElement | null = divRef.current?.closest('.br-component');
            if (wrapperElement) {
                wrapperElement.style.height = `${CONTENT_NAVIGATION_HEIGHT}px`;
            }
            if (position === 'relative' && divRef.current.getBoundingClientRect().top < -1) {
                setPosition('fixed');
            } else if (position === 'fixed' && wrapperElement && currentScrollPositionY <= wrapperElement?.offsetTop) {
                setPosition('relative');
            }
        }
    };

    const handleScroll = () => {
        const bodyDocumentPosition = window.getComputedStyle(document.body).position;
        if (bodyDocumentPosition === 'fixed') {
            return;
        }
        const currentScrollPositionY = window.scrollY;
        let currentSection = '';
        for (const anchorComponent of anchorComponents) {
            const sectionId = anchorComponent.data?.anchor?.id ?? anchorComponent.anchor?.id;
            const sectionElement = document.getElementById(sectionId);

            if (sectionElement) {
                const sectionTop = sectionElement.offsetTop - CONTENT_NAVIGATION_HEIGHT - 1;
                const sectionBottom = sectionTop + sectionElement.offsetHeight;

                if (currentScrollPositionY >= sectionTop && currentScrollPositionY < sectionBottom) {
                    currentSection = sectionId;
                    break;
                }
            }
        }
        setActiveSection(currentSection.split('_')[0]);
        checkNavigationPosition();
    };

    useEffect(() => {
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    useEffect(() => {
        const sectionLink = document.getElementById(`${activeSection}Link`);
        if (sectionLink && divRef.current) {
            const scrollPaddingLeft = divRef.current?.clientWidth / 2 - sectionLink.clientWidth / 2;
            const scrollTo = sectionLink.offsetLeft - scrollPaddingLeft;
            divRef.current.scrollLeft = scrollTo;
            scrollContentNavigation(scrollTo);
        }
    }, [activeSection]);

    useEffect(() => {
        if (divRef.current && maxScrollPosition !== divRef.current.scrollWidth - divRef.current.clientWidth - 1) {
            setMaxScrollPosition(divRef.current.scrollWidth - divRef.current.clientWidth - 1);
        }
    }, [anchorComponents, width]);

    const scrollNavigationLeft = () => {
        if (divRef.current) {
            divRef.current.scrollLeft = divRef.current.scrollLeft - CONTENT_NAVIGATION_SCROLL_LENGTH;
        }
    };

    const scrollNavigationRight = () => {
        if (divRef.current) {
            divRef.current.scrollLeft = divRef.current.scrollLeft + CONTENT_NAVIGATION_SCROLL_LENGTH;
        }
    };

    const onContentNavigationScroll = (event: UIEvent<HTMLDivElement>) => {
        scrollContentNavigation((event.target as HTMLDivElement).scrollLeft);
    };

    const goToAnchor = (event, id: string) => {
        event.preventDefault();
        const anchor = document.getElementById(id);

        if (anchor && divRef.current) {
            const anchorTopPosition = anchor.offsetTop;
            window.scrollTo({ top: anchorTopPosition - CONTENT_NAVIGATION_HEIGHT, behavior: 'smooth' });

            // Accordion PDP actions
            const clickableAnchor = anchor.getElementsByClassName('accordion-pdp-item__heading');
            const anchorAccordionItem = anchor.getElementsByClassName('accordion-pdp-item__item');
            if (clickableAnchor.length && !anchorAccordionItem.length) {
                (clickableAnchor[0] as HTMLElement).click();
            }
        }
    };

    if (!anchorComponents.length) {
        return SKIP_RENDER;
    }

    const renderContentnavigationItem = (component, index): JSX.Element | null => {
        const item = component.data?.anchor ?? component?.anchor;

        if (!item?.text && !component.data?.link?.text) {
            return SKIP_RENDER;
        }

        if (!item) {
            return (
                <LinkOpenInTab
                    key={`${componentName}-item-${index}`}
                    link={component.data.link.reference}
                    tab={component.data.tab}
                >
                    <div className={`${componentName}__item`}>
                        <div className={`${componentName}__item-link`}>
                            {component.data.link.text} <Icon name="ArrowTopRight" />
                        </div>
                    </div>
                </LinkOpenInTab>
            );
        }

        return (
            <a key={item.id} id={`${item.id}Link`} href={`#${item.id}`} onClick={(event) => goToAnchor(event, item.id)}>
                <div className={`${componentName}__item`}>
                    <div
                        className={classnames(`${componentName}__item-link`, {
                            [`${componentName}__item-link--active`]: item.id === activeSection,
                        })}
                    >
                        {item.text}
                    </div>
                </div>
            </a>
        );
    };

    return (
        <BackgroundColorWrapper
            hexBackgroundColor={backgroundColorHex.length ? backgroundColorHex : '#FFFFFF'}
            position={position}
            className={`${componentName}__background-color-wrapper`}
        >
            <ContentWrapper>
                <div className={componentName}>
                    <div
                        className={classnames(`${componentName}__arrow`, `${componentName}__arrow-left`, {
                            [`${componentName}__arrow--hidden`]: contentNavigationScrollPosition <= 0,
                        })}
                        onClick={scrollNavigationLeft}
                    >
                        <Icon name="ChevronLeft" />
                    </div>
                    <div className={`${componentName}__item-wrap`} ref={divRef} onScroll={onContentNavigationScroll}>
                        {anchorComponents.map((component, index) => renderContentnavigationItem(component, index))}
                    </div>
                    <div
                        className={classnames(`${componentName}__arrow`, `${componentName}__arrow-right`, {
                            [`${componentName}__arrow--hidden`]: contentNavigationScrollPosition >= maxScrollPosition,
                        })}
                        onClick={scrollNavigationRight}
                    >
                        <Icon name="ChevronRight" />
                    </div>
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
};

export default ContentNavigation;
