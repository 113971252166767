import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const HeroTeaserMolecule = lazy(() => lazyRetry(() => import('./HeroTeaser')));

export type HeroTeaserProps = BaseProps & {
    desktopImage: BrxImageWithAltText;
    mobileImage?: BrxImageWithAltText;
    ctaBox: {
        boxAlignment: 'left' | 'right';
        buttonLink: string;
        buttonText: string;
        boxBackgroundColor: string;
        ctaBackgroundColor: string;
        ctaFontColor: string;
        headline: string;
        headlineColor: string;
        subheadlineColor: string;
        text: string;
    };
};

const HeroTeaser = (props: HeroTeaserProps): JSX.Element => (
    <LazyComponent>
        <HeroTeaserMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.HeroTeaser', HeroTeaser);
