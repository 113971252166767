import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { LoginAndRegisterProps } from './types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const LoginAndRegisterMolecule = lazy(() => lazyRetry(() => import('./LoginAndRegister')));

const LoginAndRegister = (props: LoginAndRegisterProps) => (
    <LazyComponent>
        <LoginAndRegisterMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.LoginAndRegister', LoginAndRegister);
