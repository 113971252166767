import { useEffect, useRef } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { FormikErrors } from 'formik';

type DynamicFormikErrorFocusProps = BaseProps & {
    isSubmitting: boolean;
    isValidating: boolean;
    errors: FormikErrors<Record<string, string>>;
};

/**
 * This component focus the first formik field which has errors
 * @param {boolean} isSubmitting
 * @param {boolean} isValidating
 * @param {FormikErrors} errors
 */
function DynamicFormikErrorFocus({ isSubmitting, isValidating, errors }: DynamicFormikErrorFocusProps) {
    const mounted = useRef(false);
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            const keyify = (obj, prefix = '') =>
                Object.keys(obj).reduce((res, el) => {
                    if (Array.isArray(obj[el])) {
                        return res;
                    } else if (typeof obj[el] === 'object' && obj[el] !== null) {
                        return [...res, ...keyify(obj[el], `${prefix + el}.`)];
                    }
                    return [...res, prefix + el];
                }, []);
            const keys = keyify(errors);
            if (keys.length > 0 && isSubmitting && !isValidating) {
                const selector = `[name="${keys[0]}"]`;

                const errorElement = document.querySelector(selector) as HTMLElement;
                if (errorElement) {
                    errorElement.focus();
                }
            }
        }
    });
    return null;
}

export default injectComponent('pattern.molecule.DynamicFormik.DynamicFormikErrorFocus', DynamicFormikErrorFocus);
