import { useProject } from '@mediashop/app/hooks/useProject';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import * as icons from '@mediashop/base/icons';
import CountrySelectContainer from '@mediashop/base/pattern/molecule/CountrySwitch/CountrySelectContainer';
import { getMappedCountry, getMappedLocale } from '@mediashop/base/utils/country-switcher';
import { useState } from 'react';

const componentName = 'burger-menu-country';

const allIcons = icons.default;

const BurgerMenuCountry = () => {
    const { country, locale } = useShopContext();
    const { locales } = useProject();

    const currentCountry = getMappedCountry(country);
    const currentLocale = getMappedLocale(locale, locales);

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`${componentName}__wrapper`} onClick={() => setIsOpen(true)}>
            <div className={componentName}>
                <img
                    alt="Flag"
                    height="18"
                    width="25"
                    src={allIcons[currentCountry.iconName]}
                    className={`${componentName}__flag`}
                />
                <span>{currentCountry.label}</span>
                <span>|</span>
                <span>{currentLocale.label}</span>
            </div>

            <CountrySelectContainer isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
};

export default BurgerMenuCountry;
