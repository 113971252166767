import bloomify from '@mediashop/app/bloomreach/bloomify';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import ProductsPreCheckout from '../pattern/molecule/ProductsPreCheckout';

type ProductIdsObject = {
    [key: string]: string;
};

export type ProductsPreCheckoutProps = BaseProps & {
    productIDs: ProductIdsObject;
};

const ProductsPreCheckoutBloomreach = (props: ProductsPreCheckoutProps) => <ProductsPreCheckout {...props} />;

export default bloomify(ProductsPreCheckoutBloomreach);
