import * as React from 'react';
import classNames from 'classnames';
import Icon from '../../atom/Icon';
import { BaseProps } from '@mediashop/app/bloomreach/types';

const componentName = 'input';

export type InputProps = BaseProps & {
    autoComplete?: string;
    disabled?: boolean;
    error?: string;
    id?: string;
    label?: string;
    name: string;
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    required?: boolean;
    testId?: string;
    type?: React.HTMLInputTypeAttribute;
    value?: string;
    inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
    style?: React.CSSProperties;
    hideCheckIcon?: boolean;
};

export default React.forwardRef(function Input(
    {
        autoComplete,
        className,
        disabled,
        error,
        id,
        inputMode,
        label,
        name,
        onBlur,
        onChange,
        placeholder = label,
        required,
        type,
        testId,
        value,
        style,
        hideCheckIcon,
    }: InputProps,
    ref: React.Ref<HTMLInputElement>
): JSX.Element {
    return (
        <div className={classNames(componentName, className)}>
            <label className={`${componentName}__label`}>
                <input
                    className={classNames(`${componentName}__input`, {
                        [`${componentName}__input--error`]: Boolean(error),
                    })}
                    id={id}
                    inputMode={inputMode}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    required={required}
                    autoComplete={autoComplete}
                    data-testid={testId}
                    disabled={disabled}
                    ref={ref}
                    style={style}
                />

                <span className={`${componentName}__title`}>{required ? `${label}*` : label}</span>

                {error ? (
                    <div className={`${componentName}__error`}>{error}</div>
                ) : (
                    !hideCheckIcon && (
                        <span className={`${componentName}__check`}>
                            <Icon className={`${componentName}__check-icon`} name="Check" />
                        </span>
                    )
                )}
            </label>
        </div>
    );
});
