export interface SelectOption {
    value: number;
    label: string;
}

/**
 * Builds an array with the allowed quantities.
 */
const getQuantities = (min: number, max: number, step = 1, current = 1): number[] => {
    const quantities: number[] = [];

    for (let i = min; i <= max; i += step) {
        quantities.push(i);
    }

    if (current > max) {
        quantities.push(current);
    }

    return quantities;
};

/**
 * Build an array with allowed select-options for a quantity select-box.
 */
const getSelectOptions = (min: number, max: number, step = 1): SelectOption[] => {
    const quantities = getQuantities(min, max, step);
    return quantities.map((quantity) => ({
        label: quantity.toString(),
        value: quantity,
    }));
};

export default {
    getQuantities,
    getSelectOptions,
};
