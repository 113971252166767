import { GatewayCategory } from '../types/GatewayCategory';
import { Category } from '../types/ClientCategory';

export default class CategoryMapper {
    private missingParents = new Array<Category>();

    /**
     * Map category from api-gateway to a more consumable category-type.
     * @param categories
     */
    public mapCategory(categories: GatewayCategory[]) {
        return this.categoryWalker(categories);
    }

    /**
     * Walk
     * @param categories
     */
    private categoryWalker(categories: GatewayCategory[]) {
        let filtered: Category[] = new Array<Category>();

        // Remove unneeded ancestor ID's
        categories.forEach((category: GatewayCategory) => {
            if (category.ancestors) {
                const idsToRemove = new Array<string>();
                category.ancestors.forEach(({ id: ancestorId }) => {
                    const results = categories.filter((cat) => cat.id === ancestorId);
                    if (!results.length) {
                        idsToRemove.push(ancestorId);
                    }
                });

                category.ancestors = category.ancestors.filter(({ id }) => !idsToRemove.includes(id));
            }
        });

        categories.forEach((category: GatewayCategory) => {
            const newCategory: Category = {
                ...category,
                ancestorIds: category.ancestors.map(({ id }) => id),
                children: [],
            };
            if (!category.parentId) {
                filtered.push(newCategory);
            } else {
                filtered = this.walk(filtered, newCategory, [...category.ancestors.map(({ id }) => id)]);
            }
        });

        // check for categories with missing parents and try to add them
        if (this.missingParents.length > 0) {
            this.missingParents.forEach((category: Category) => {
                filtered = this.walk(filtered, category, [...category.ancestorIds]);
            });
        }

        return filtered;
    }

    /**
     * Recursively walk the categories array in order to create the tree
     *
     * @param filtered
     * @param category
     * @param ancestors
     */
    private walk(filtered: Category[], category: Category, ancestors: Array<string>) {
        if (ancestors.length > 0) {
            const firstAncestorId = ancestors.shift();
            const results = filtered.filter((cat) => cat.id === firstAncestorId);
            if (results.length) {
                const index = filtered.indexOf(results[0]);
                if (ancestors.length) {
                    // Go a level deeper
                    filtered[index].children = this.walk(results[0].children ?? [], category, ancestors);
                } else {
                    filtered[index].children
                        ? filtered[index].children.push(category)
                        : (filtered[index].children = [category]);
                }
            } else {
                this.missingParents.push(category);
            }
        }

        return filtered;
    }
}
