import { SearchByAttributeFilter } from '../../api/types/ProductQueries';
import { BrxFilter } from '../../bloomreach/types';

export const useFilterWithPriceRange = (filters: Array<BrxFilter>): Array<SearchByAttributeFilter> => {
    const filtersWithRange: Array<SearchByAttributeFilter> = [];
    let skipNext = false;
    for (let index = 0; index < filters.length; index++) {
        if (skipNext) {
            skipNext = false;
        } else {
            const filter = filters[index];

            if (filter.attribute !== 'minPrice' && filter.attribute !== 'maxPrice') {
                filtersWithRange.push(filter);
            } else {
                const { value: _, ...rangeFilter } = { ...filter, attribute: 'price' } as SearchByAttributeFilter;
                if (filter.attribute === 'minPrice') {
                    // eslint-disable-next-line id-length
                    rangeFilter.range = { gt: filter.value };
                    const nextFilter = filters[index + 1];
                    if (nextFilter?.attribute === 'maxPrice') {
                        // eslint-disable-next-line id-length
                        rangeFilter.range.lt = nextFilter.value;
                        skipNext = true;
                    }
                } else {
                    // eslint-disable-next-line id-length
                    rangeFilter.range = { lt: filter.value };
                    const nextFilter = filters[index + 1];
                    if (nextFilter?.attribute === 'minPrice') {
                        // eslint-disable-next-line id-length
                        rangeFilter.range.gt = nextFilter.value;
                        skipNext = true;
                    }
                }

                filtersWithRange.push(rangeFilter);
            }
        }
    }

    return filtersWithRange;
};
