import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../../atom/Icon';
import Button from '../../../../atom/Button';

type Props = {
    goToAddress?: () => void;
};

const componentName = 'payment-network-unavailable';

function PaymentNetworkUnavailable({ goToAddress }: Props): JSX.Element {
    return (
        <>
            <div className={`${componentName}__container`}>
                <div className={`${componentName}__attention-icon-container`}>
                    <Icon className={`${componentName}__attention-icon`} name="Attention" />
                </div>
                <div className={`${componentName}__text`}>
                    <FormattedMessage id="checkout.payment.unavailableShippingCountryTitle" tagName="strong" />
                    <FormattedMessage id="checkout.payment.unavailableShippingCountry" tagName="div" />
                </div>
            </div>
            {goToAddress ? (
                <Button
                    className={`${componentName}__submit op-checkout-payment__submit`}
                    style="tertiary"
                    fullWidth
                    onClick={goToAddress}
                >
                    <FormattedMessage id="checkout.lastStep" />
                </Button>
            ) : (
                SKIP_RENDER
            )}
        </>
    );
}

export default PaymentNetworkUnavailable;
