import { useEffect } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import noop from 'lodash/noop';

function openConsent() {
    globalThis?.UC_UI?.showSecondLayer();
}

function GDPRSettings() {
    useEffect(() => {
        if (!globalThis.UC_UI?.isInitialized()) {
            window.addEventListener('UC_UI_INITIALIZED', openConsent);
            return () => window.removeEventListener('UC_UI_INITIALIZED', openConsent);
        }

        openConsent();

        return noop;
    }, []);

    return SKIP_RENDER;
}

export default injectComponent('pattern.atom.GDPRSettings', GDPRSettings);
