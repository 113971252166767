import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy, ReactElement } from 'react';
import { MainNavigationCustomNavigationItemProps } from '../../../types';

const BurgerMenuNavigationItemLazy = lazy(() => lazyRetry(() => import('./BurgerMenuNavigationItem')));

export type BurgerMenuNavigationItemProps = BaseProps & {
    closeBurgerMenu: () => void;
    item: MainNavigationCustomNavigationItemProps;
};

const BurgerMenuNavigationItem = (props: BurgerMenuNavigationItemProps): ReactElement => (
    <LazyComponent>
        <BurgerMenuNavigationItemLazy {...props} />
    </LazyComponent>
);

export default BurgerMenuNavigationItem;
