import { useEffect } from 'react';

const checkConsentApproved = (serviceName: string): boolean => {
    if (globalThis.UC_UI && globalThis.UC_UI.isInitialized()) {
        const services = globalThis.UC_UI.getServicesBaseInfo();
        const filteredServices = services.filter((service) => service.name === serviceName);

        return Boolean(filteredServices?.[0]?.consent?.status);
    }

    return false;
};

type Props = {
    serviceName: string;
    callbackFn: () => void;
};

/**
 * Hook that calls a callback function when the cookie consent for the provided service name is accepted.
 * @param serviceName name of the service to check for
 * @param callbackFn function to be called when the consent is ACCEPTED
 *
 * https://docs.usercentrics.com/#/v2-events
 */
export const useCmpServiceConsent = ({ serviceName, callbackFn }: Props): void => {
    const handleUcEvent = () => {
        if (checkConsentApproved(serviceName)) {
            callbackFn();
        }
    };

    useEffect(() => {
        window.addEventListener('UC_UI_INITIALIZED', handleUcEvent);
        window.addEventListener('UC_UI_CMP_EVENT', handleUcEvent);

        return () => {
            window.removeEventListener('UC_UI_INITIALIZED', handleUcEvent);
            window.removeEventListener('UC_UI_CMP_EVENT', handleUcEvent);
        };
    });
};
