import { injectComponent } from '@mediashop/app/component-injector';
import { lazy, ReactElement } from 'react';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const BreadcrumbItemLazy = lazy(() => lazyRetry(() => import('./BreadcrumbItem')));

export type BreadcrumbItemProps = BasePropsWithChildren & {
    showIcon?: boolean;
};

const BreadcrumbItem = (props: BreadcrumbItemProps): ReactElement => (
    <LazyComponent>
        <BreadcrumbItemLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.BreadcrumbItem', BreadcrumbItem, 'catalog-base');
