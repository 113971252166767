import { useState, useEffect, type MutableRefObject } from 'react';

const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
};

export const useElementIsVisible = (componentRef: MutableRefObject<null>): boolean => {
    const [isVisible, setIsVisible] = useState(false);

    const callback: IntersectionObserverCallback = (entries) => {
        const [entry] = entries;

        setIsVisible(entry.isIntersecting);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(callback, options);

        if (componentRef.current) {
            observer.observe(componentRef.current);
        }

        return () => {
            if (componentRef.current) {
                observer.unobserve(componentRef.current);
            }
        };
    }, [componentRef]);

    return isVisible;
};
