import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const InstallmentBirthdateCheckLazy = lazy(() => lazyRetry(() => import('./InstallmentBirthdateCheck')));

export type InstallmentBirthdateCheckProps = BaseProps & {
    defaultBirthdate?: string;
};

const InstallmentBirthdateCheck = (props: InstallmentBirthdateCheckProps): JSX.Element => (
    <LazyComponent>
        <InstallmentBirthdateCheckLazy {...props} />
    </LazyComponent>
);

export default InstallmentBirthdateCheck;
