import { injectComponent } from '@mediashop/app/component-injector';
import { lazy, ReactElement } from 'react';
import { BaseProps, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const BeforeAfterImageLazy = lazy(() => lazyRetry(() => import('./BeforeAfterImage')));

export type BeforeAfterImageProps = BaseProps & {
    backgroundColor?: string;
    beforeImageDesktop: BrxImageWithAltText;
    beforeImageMobile?: BrxImageWithAltText;
    afterImageDesktop: BrxImageWithAltText;
    afterImageMobile?: BrxImageWithAltText;
};

const BeforeAfterImage = (props: BeforeAfterImageProps): ReactElement => (
    <LazyComponent>
        <BeforeAfterImageLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.BeforeAfterImage', BeforeAfterImage);
