import { QueryError } from './types/Query';

export class ApiError<T = string> extends Error {
    public errors: QueryError<T>[];

    constructor(message: string, errors: QueryError<T>[]) {
        super(message);
        this.errors = errors;
    }
}
