import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import LazyComponent, { lazyRetry } from '../../../molecule/LazyComponent';

const Variants = lazy(() => lazyRetry(() => import('./Variants')));

export type ProductStageVariantsProps = BaseProps & {
    isError: boolean;
    label: string;
    variants: Variant[];
    disabled: boolean;
    combination: string;
    options: string[];
    onChange: (value: string, type?: string) => void;
    value: string;
    preSelectedVariant?: Variant;
    showLabel?: boolean;
};

const ProductStageVariants = (props) => (
    <LazyComponent>
        <Variants {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.ProductStageVariants', ProductStageVariants);
