import { AddressDto } from '../dtos/common/addressDto';
import { Address, GatewayAddress, GatewayAddressCustomField } from '../types/Address';

/**
 * Convert array of records to an actual object.
 * @param fields
 */
const convertCustomFieldsArrayToRecord = (fields?: GatewayAddressCustomField[]): Record<string, any> => {
    const record = {};
    if (!fields) {
        return record;
    }

    fields.forEach((field) => {
        record[field.name] = field.value;
    });
    return record;
};

/**
 * Remove all standardFields that are not set (==null) from commercetools address.
 * @param allStandardFields
 */
const removeUnsetFields = (allStandardFields): any => {
    const fieldEntries = Object.entries(allStandardFields).filter((addressTuple) => addressTuple[1] !== null);

    return Object.fromEntries(fieldEntries);
};

/**
 * Map commercetools address to a more convenient format.
 * @param address
 */
const mapAddress = (address: GatewayAddress): Address => {
    if (!address) {
        return address;
    }

    const { custom, ...addressRest } = address;
    const customRecord = convertCustomFieldsArrayToRecord(custom?.customFieldsRaw);

    const standardFields = removeUnsetFields(addressRest);

    return {
        ...standardFields,
        ...customRecord,
    };
};

/**
 * Map commercetools address to a more convenient format.
 * @param address
 */
const mapAddressDto = (address?: AddressDto): Address | undefined => {
    if (!address) {
        return address;
    }

    const standardFields = removeUnsetFields(address);

    return {
        ...standardFields,
    };
};

export default {
    mapAddress,
    mapAddressDto,
};
