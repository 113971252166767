import '../pattern/template/Meta';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import Accordion from './Accordion';
import Anchor from './Anchor';
import AsymmetricImage from './AsymmetricImage';
import BackgroundImageWithText from './BackgroundImageWithText';
import Badge from './Badge';
import CTA from './CTA';
import Cart from './Cart';
import CheckoutSuccess from './CheckoutSuccess';
import Contact from './Contact';
import ContainerInline from '../pattern/template/ContainerInline';
import ContentList from './ContentList';
import ContentSlider from './ContentSlider';
import Countries from './Countries';
import CrossSellingBanner from './CrossSellingBanner';
import DownloadWithImage from './DownloadWithImage';
import Flyout from './Flyout';
import FooterText from './FooterText';
import HeaderBar from './HeaderBar';
import ImageBanner from './ImageBanner';
import InfoBanner from './InfoBanner';
import InfoBannerCustom from './InfoBannerCustom';
import LiveTV from './LiveTV';
import LogoSlider from './LogoSlider';
import NewsletterUnsubscribe from './NewsletterUnsubscribe';
import PolaroidCollection from './PolaroidCollection';
import ProductBox from './ProductBox';
import ProductStage from './ProductStage';
import SEOconfig from './SeoConfig';
import SPLPCart from './SPLPCart';
import SPLPCheckout from './SPLPCheckout';
import SPLPCheckoutSuccess from './SPLPCheckoutSuccess';
import SPLPNavigation from './SPLPNavigation';
import Separator from './Separator';
import SocialShare from './SocialShare';
import Sovendus from './Sovendus';
import StageSlider from './StageSlider';
import StickyButton from './StickyButton';
import StructuredData from './StructuredData';
import Table from './Table';
import TestimonialSlider from './TestimonialSlider';
import Text from './Text';
import TextBox from './TextBox';
import TextWithImage from './TextWithImage';
import USP from './USPs';
import UndefinedContainer from '../pattern/template/UndefinedContainer';
import VideoWithText from './VideoWithText';
import YoutubeVideo from './YoutubeVideo';
import { TYPE_CONTAINER_INLINE, TYPE_CONTAINER_ITEM_UNDEFINED } from '@bloomreach/spa-sdk';

const SkipRenderComponent = (): null => SKIP_RENDER;

export default {
    404: SkipRenderComponent,
    Accordion,
    Anchor,
    AsymmetricImage,
    BackgroundImageWithText,
    Badge,
    CTA,
    Cart,
    CheckoutSuccess,
    Contact,
    ContentList,
    ContentSlider,
    Countries,
    CrossSellingBanner,
    DownloadWithImage,
    Flyout,
    FooterText,
    HeaderBar,
    ImageBanner,
    InfoBanner,
    InfoBannerCustom,
    LiveTV,
    LogoSlider,
    NewsletterUnsubscribe,
    PolaroidCollection,
    ProductBox,
    ProductStage,
    SEOconfig,
    SPLPCart,
    SPLPCheckout,
    SPLPCheckoutSuccess,
    SPLPNavigation,
    Separator,
    SocialShare,
    Sovendus,
    StageSlider,
    StickyButton,
    StructuredData,
    Table,
    TestimonialSlider,
    Text,
    TextBox,
    TextWithImage,
    USP,
    VideoWithText,
    YoutubeVideo,
    [TYPE_CONTAINER_INLINE]: ContainerInline,
    [TYPE_CONTAINER_ITEM_UNDEFINED]: UndefinedContainer,
};
