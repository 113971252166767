import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ExchangeRatesSubset } from '../../types/exchangeRates';

export interface ContextState {
    locale: string;
    isLocaleFromCookie: boolean;
    currency: string;
    country: string;
    route: string | number;
    prevRoute?: string | number;
    geoIPCountryCode: string;
    showSwitchShopModal: boolean;
    isMainMenuOpen: boolean;
    isBrX: boolean;

    location: {
        origin: string;
        href: string;
        hostname: string;
    };

    exchangeRates?: ExchangeRatesSubset;
}

const initialState: ContextState = {
    locale: 'en',
    isLocaleFromCookie: false,
    currency: 'EUR',
    country: 'DE',
    route: '/',
    prevRoute: '/',
    geoIPCountryCode: 'DE',
    isBrX: false,
    showSwitchShopModal: false,
    isMainMenuOpen: false,
    location: {
        href: '',
        origin: '',
        hostname: '',
    },
};

const contextSlice = createSlice({
    name: 'context',
    initialState,
    reducers: {
        contextReceived(state, action: PayloadAction<{ context: ContextState }>) {
            const { context } = action.payload;
            return {
                ...state,
                ...context,
            };
        },
        countryChanged(state, action: PayloadAction<{ country: string; currency: string }>) {
            const { country, currency } = action.payload;
            return {
                ...state,
                country,
                currency,
            };
        },
        localeChanged(state, action: PayloadAction<{ locale: string }>) {
            const { locale } = action.payload;
            return {
                ...state,
                locale,
                isLocaleFromCookie: true,
            };
        },
        mainMenuToggled(state, action: PayloadAction<{ isOpen: boolean }>) {
            const { isOpen: isMainMenuOpen } = action.payload;
            return {
                ...state,
                isMainMenuOpen,
            };
        },
        routeChanged(state, action: PayloadAction<{ previousRoute: string | number; route: string; url: string }>) {
            const { previousRoute, route, url } = action.payload;
            return {
                ...state,
                route,
                prevRoute: previousRoute,
                location: {
                    ...state.location,
                    href: url,
                },
            };
        },
        shopSwitchToggled(state, action: PayloadAction<{ showShopSwitchModal: boolean }>) {
            const { showShopSwitchModal: showSwitchShopModal } = action.payload;
            return {
                ...state,
                showSwitchShopModal,
            };
        },
    },
});

export const { contextReceived, countryChanged, localeChanged, mainMenuToggled, routeChanged, shopSwitchToggled } =
    contextSlice.actions;

export default contextSlice.reducer;
