import { lazy } from 'react';
import { AuthorizationModalProps } from '../types';

import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const AuthorizationModalMolecule = lazy(() => lazyRetry(() => import('./AuthorizationModal')));

const AuthorizationModal = (props: AuthorizationModalProps): JSX.Element => (
    <LazyComponent>
        <AuthorizationModalMolecule {...props} />
    </LazyComponent>
);

export default AuthorizationModal;
