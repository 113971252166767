import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const SeparatorLazy = lazy(() => lazyRetry(() => import('./Separator')));

type SeparatorLineProps = {
    backgroundColor: string;
    height: number;
};

export type SeparatorProps = BaseProps & {
    separatorLine: SeparatorLineProps;
    separatorSpaceAbove: SeparatorLineProps;
    separatorSpaceBelow: SeparatorLineProps;
};

const Separator = (props: SeparatorProps): JSX.Element => (
    <LazyComponent>
        <SeparatorLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.atom.Separator', Separator);
