import { lazy } from 'react';
import { Product } from '@mediashop/app/api/types/ClientProduct';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const PreCheckoutProductDetailsMolecule = lazy(() => lazyRetry(() => import('./PreCheckoutProductDetails')));

export type PreCheckoutProductDetailsProps = BaseProps & {
    product: Product;
    goToProduct: () => void;
    isWkoProduct?: boolean;
};

const PreCheckoutProductDetails = (props: PreCheckoutProductDetailsProps): JSX.Element => (
    <LazyComponent>
        <PreCheckoutProductDetailsMolecule {...props} />
    </LazyComponent>
);

export default PreCheckoutProductDetails;
