import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxImageWithTextProps } from '@mediashop/app/bloomreach/types';
import { Cart } from '@mediashop/app/api/types/ClientCart';
import { Money } from '@mediashop/app/api/types/Money';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { PaymentMethodName } from '@mediashop/app/domain/Payment';

const OnePageCheckoutSummaryMolecule = lazy(() => lazyRetry(() => import('./Summary')));

export interface CheckoutSummaryForm {
    comment?: string;
    subscribeToNewsletter?: boolean;
}

export type CheckoutSummarySubmitHandle = {
    submit: () => void;
};

export type OnePageCheckoutSummaryProps = BaseProps & {
    isLoading: boolean;
    cart: Cart;
    productTotal: Money;
    selectedBillingCountry: string;
    selectedPaymentMethod?: PaymentMethodName;
    onCheckoutSubmit: (formData: CheckoutSummaryForm) => void;
    usps: BrxImageWithTextProps[];
};

const OnePageCheckoutSummary = (props: OnePageCheckoutSummaryProps): JSX.Element => (
    <LazyComponent>
        <OnePageCheckoutSummaryMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.OnePageCheckout.Summary', OnePageCheckoutSummary);
