import { useProduct } from '@mediashop/app/hooks/useProduct';
import { useMemo } from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import {
    BaseProps,
    BrxAnchor,
    BrxBackground,
    BrxSlider,
    ContentBackgroundProps,
} from '@mediashop/app/bloomreach/types';
import Product from '../Product';
import Headlines, { HeadlineProps } from '@mediashop/base/pattern/atom/Headlines';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import HeroTeaser, { HeroTeaserProps } from '../HeroTeaser';
import ProductSlider from '../ProductSlider';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { sanitizeProductIds } from '@mediashop/app/helper/sanitizeProductIds';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

type HeroProductSliderProps = BaseProps & {
    anchor?: BrxAnchor;
    headlines: HeadlineProps;
    heroTeaser: HeroTeaserProps;
    background: BrxBackground;
    contentBackground: ContentBackgroundProps;
    slider: BrxSlider;
    productIDs: string[];
    noBottomPadding: boolean;
};

/**
 * Component renders a HeroTeaser and a ProductSlider
 */
function HeroProductSlider({
    anchor,
    headlines,
    heroTeaser,
    background,
    contentBackground,
    slider,
    productIDs,
    noBottomPadding,
}: HeroProductSliderProps) {
    const componentName = 'product-listing';

    const deviceType = useDeviceType();
    const isDesktopUp = deviceType !== 'mobile' && deviceType !== 'tablet';
    const { products } = useProduct();

    const cleanProductIDs = sanitizeProductIds(productIDs);

    const items = useMemo(
        () =>
            Object.values(products)
                .filter((product) => product.product && cleanProductIDs.includes(product.product?.id))
                .sort(
                    (first, sec) =>
                        cleanProductIDs.indexOf(first.product?.id as string) -
                        cleanProductIDs.indexOf(sec.product?.id as string)
                ),
        [products, cleanProductIDs]
    );

    const showSlider = (items.slice(1).length && isDesktopUp) || (items.length && !isDesktopUp);

    return (
        <BackgroundColorWrapper backgroundColor={background.color} hexBackgroundColor={background.backgroundColorHex}>
            <ContentWrapper id={anchor?.id}>
                <div className={classNames(componentName, `${componentName}--${background.color}`)}>
                    <Headlines {...headlines} className={`${componentName}__headlines`} />
                    {items.length > 0 && (
                        <div
                            className={classNames(`${componentName}__teaser`, {
                                [`${componentName}__teaser--no-slider`]: !showSlider,
                                [`${componentName}__teaser--no-headline`]:
                                    !headlines.headline && !headlines.subheadline,
                            })}
                        >
                            <div
                                className={classNames(
                                    `${componentName}__teaser-wrapper`,
                                    `${componentName}__teaser-wrapper--${deviceType}`,
                                    {
                                        [`${componentName}__teaser-wrapper--no-headline`]:
                                            !headlines.headline && !headlines.subheadline,
                                        [`${componentName}__teaser-wrapper--no-bottom-padding`]: noBottomPadding,
                                    }
                                )}
                            >
                                {items[0].product && isDesktopUp && (
                                    <Product
                                        className={`${componentName}__teaser-product`}
                                        product={items[0].product}
                                        description={items[0].product.variants[0].attributes.claim}
                                        status={items[0].status}
                                        backgroundColor={contentBackground.color}
                                        backgroundColorHex={contentBackground?.backgroundColorHex}
                                        imageVariant={CroppedImageVariant.HeroProductSliderHeroImage}
                                    />
                                )}
                                <HeroTeaser {...heroTeaser} className={`${componentName}__teaser-hero`} />
                            </div>
                            {showSlider ? (
                                <ProductSlider
                                    background={background}
                                    contentBackground={contentBackground}
                                    slider={slider}
                                    productIDs={isDesktopUp ? cleanProductIDs.slice(1) : cleanProductIDs}
                                    spacing={16}
                                />
                            ) : (
                                SKIP_RENDER
                            )}
                        </div>
                    )}
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.HeroProductSlider', HeroProductSlider);
