import { DetailedProduct } from '@mediashop/app/api/types/ClientProductDetailed';
import { Product as ProductSchema, WithContext, BreadcrumbList } from 'schema-dts';
import { hyphenToUnderscore } from '@mediashop/catalog-base/utils/hyphenToUnderscore';
import { AssetTag } from '@mediashop/app/api/types/ClientProduct';

type BreadcrumbsProps = {
    href: string;
    breadcrumbIndex: Record<string, { name: string; parent: string }>;
    product: DetailedProduct;
    shopName: string;
};

/**
 * Generate schema tags according to https://developers.google.com/search/docs/advanced/structured-data/product
 * @param product product
 * @param productLink current product link
 * @returns schema
 */

export const generateProductSchemaForAllVariants = (
    product: DetailedProduct,
    productLink: string,
    includeReviews: boolean
): WithContext<ProductSchema> => {
    const isMultiVariantProduct = product.variants.length > 1;
    const firstVariant = product.variants[0];

    return {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: isMultiVariantProduct ? product.name : firstVariant.attributes.name,
        sku: !isMultiVariantProduct ? firstVariant.sku : undefined,
        description: product.metaDescription,
        brand: {
            '@type': 'Brand',
            name: firstVariant.attributes.brandName ?? 'Mediashop.tv',
        },
        gtin: firstVariant.attributes.ean,
        offers: product.variants.map((variant) => ({
            '@type': 'Offer',
            availability: 'https://schema.org/InStock',
            price: (variant.price?.centAmount ?? 0) / 10 ** (variant.price?.fractionDigits ?? 0),
            priceCurrency: variant.price?.currencyCode,
            sku: variant.sku,
            url: productLink,
            shippingDetails: {
                '@type': 'OfferShippingDetails',
                deliveryTime: {
                    '@type': 'ShippingDeliveryTime',
                    transitTime: {
                        '@type': 'QuantitativeValue',
                        minValue: variant.attributes?.deliveryTimeFrom,
                        maxValue: variant.attributes?.deliveryTimeUntil,
                        unitCode: 'd',
                    },
                },
            },
        })),
        image: firstVariant.assets
            .filter((image) => image.isGlobal && image.tags?.includes(AssetTag.PRODUCT_IMAGE))
            .map((asset) => asset.sources.map((source) => source.uri).flat())
            .flat(),
        aggregateRating:
            includeReviews && firstVariant.attributes?.ratingValueLocalized
                ? {
                      '@type': 'AggregateRating',
                      ratingValue: firstVariant.attributes?.ratingValueLocalized,
                      ratingCount: firstVariant.attributes?.ratingCountLocalized,
                  }
                : undefined,
    };
};

export const generateBreadcrumbsSchema = ({
    href,
    breadcrumbIndex,
    product,
    shopName,
}: BreadcrumbsProps): WithContext<BreadcrumbList> => {
    const shopUrl = href.slice(0, href.indexOf('/p/'));
    const firstProductCategory = product?.categories?.find((cat) => breadcrumbIndex[hyphenToUnderscore(cat.slug)]);
    const isMultiVariantProduct = product.variants.length > 1;
    const firstVariant = product.variants[0];

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                name: shopName,
                item: shopUrl,
            },
            {
                '@type': 'ListItem',
                position: 2,
                name: firstProductCategory?.name,
                item: `${shopUrl}/c/${firstProductCategory?.slug}`,
            },
            {
                '@type': 'ListItem',
                position: 3,
                name: isMultiVariantProduct ? product.name : firstVariant.attributes.name,
                item: href,
            },
        ],
    };
};
