import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import classnames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import BloomreachHtml, { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { BaseProps, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';
import BloomreachImageResponsive from '../../../atom/BloomreachImageResponsive';

export type ContentSliderBasicSlideProps = BaseProps & {
    image: BrxImageWithAltText;
    roundedImages: boolean;
    text: BrxHtml;
};

const componentName = 'content-slider-basic-slide';

function ContentSliderBasicSlide({ image, text, className, roundedImages }: ContentSliderBasicSlideProps): JSX.Element {
    return (
        <div
            className={classnames(componentName, className, {
                [`${componentName}--rounded`]: roundedImages,
            })}
        >
            {image && (
                <div className={`${componentName}__image-container`}>
                    <BloomreachImageResponsive
                        className={`${componentName}__image`}
                        alt={image.altText ?? EMPTY_STRING}
                        desktopImage={image.image}
                        variant={BrXImageVariant.ContentSliderBasicSlide}
                    />
                </div>
            )}

            <BloomreachHtml className={`${componentName}__content`} html={text} />
        </div>
    );
}

export default injectComponent('pattern.molecule.ContentSliderBasicSlide', ContentSliderBasicSlide);
