import { useState } from 'react';
import { useCmpServiceConsent } from './useCmpServiceConsent';

const checkConsentApproved = (serviceName: string): boolean => {
    if (globalThis.UC_UI && globalThis.UC_UI.isInitialized()) {
        const services = globalThis.UC_UI.getServicesBaseInfo();
        const filteredServices = services.filter((service) => service.name === serviceName);

        return Boolean(filteredServices?.[0]?.consent?.status);
    }

    return false;
};

/**
 * Hook that returns whether a CMP service has consent or not
 * @param serviceName name of the service to check for
 */
export const useCmpServiceApproved = (serviceName: string): boolean => {
    const [isApproved, setApproved] = useState(checkConsentApproved(serviceName));

    useCmpServiceConsent({
        serviceName,
        callbackFn: () => setApproved(true),
    });

    return isApproved;
};
