import { Money } from '@mediashop/app/api/types/Money';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { FormattedMessage, useIntl } from 'react-intl';
import UnitPrice from '@mediashop/base/pattern/atom/UnitPrice';
import OriginalPrice from '../../atom/OriginalPrice';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import Badge from '@mediashop/catalog-base/pattern/atom/Badge';
import { BadgeObject } from '@mediashop/catalog-base/types/badges';
import { CartLineItemCustomFields } from '@mediashop/app/api/types/ClientCart';
import { Price } from '@mediashop/base/pattern/molecule/Price';
import { useCheaperSet } from '@mediashop/app/hooks/useCheaperSet';

export interface PriceBoxProps {
    className?: string;
    variant: Variant;
    size?: 'small' | 'medium' | 'large';
    alignment?: 'left' | 'right';
    custom?: CartLineItemCustomFields;
    oldPricePrefix?: string;
    lineItemPrice?: Variant['price'];
    priceClassName?: string;
    installmentPrice?: Money;
    detailBadges?: BadgeObject[];
    hasSaleBadge?: boolean;
    renderCheaperSetPrice?: boolean;
    hideFromText?: boolean;
    showPerDeliveryInfo?: boolean;
}

const getOriginalPrice = (custom: PriceBoxProps['custom'], isCustomerClubPrice: boolean): Money | undefined => {
    if (custom?.originalPrice?.centAmount !== 0 && !isCustomerClubPrice) {
        return custom?.originalPrice ? { ...custom?.originalPrice, priceType: custom?.originalPriceType } : undefined;
    } else if (isCustomerClubPrice && custom?.originalListPrice) {
        return custom?.originalListPrice
            ? { ...custom?.originalListPrice, priceType: custom?.originalListPriceType }
            : undefined;
    }
    return undefined;
};

const getStrikeThroughPrice = ({
    custom,
    variant,
    componentName,
    lineItemPrice,
    isCustomerClubPrice,
}: {
    custom?: PriceBoxProps['custom'];
    variant: PriceBoxProps['variant'];
    lineItemPrice: PriceBoxProps['lineItemPrice'];
    componentName: string;
    isCustomerClubPrice: boolean;
}): JSX.Element | null => {
    if (custom) {
        const originalPrice = getOriginalPrice(custom, isCustomerClubPrice);

        if (originalPrice && lineItemPrice && originalPrice.centAmount > lineItemPrice.centAmount) {
            return (
                <div className={`${componentName}__old-price`}>
                    {Object.keys(originalPrice).length > 0 ? (
                        <OriginalPrice originalPrice={originalPrice} price={lineItemPrice} />
                    ) : (
                        SKIP_RENDER
                    )}
                </div>
            );
        }
        return null;
    }

    if (variant?.originalPrice && lineItemPrice && variant.originalPrice.centAmount > lineItemPrice?.centAmount) {
        return (
            <div className={`${componentName}__old-price`}>
                {Object.keys(variant.originalPrice).length > 0 ? (
                    <OriginalPrice originalPrice={variant.originalPrice} price={variant.price} />
                ) : (
                    SKIP_RENDER
                )}
            </div>
        );
    }
    return null;
};

const componentName = 'price-box';

/**
 * Shows the prices and attributes in a box to the user and contains units
 */
// eslint-disable-next-line complexity
function PriceBox({
    className,
    variant,
    size = 'medium',
    alignment = 'right',
    custom,
    lineItemPrice,
    priceClassName = '',
    installmentPrice,
    detailBadges,
    hasSaleBadge,
    renderCheaperSetPrice,
    hideFromText,
    showPerDeliveryInfo = false,
}: Readonly<PriceBoxProps>) {
    const intl = useIntl();
    const originalPrice = custom?.originalListPrice;
    const isCustomerClubPrice = custom?.priceType === 'CLUBCUSTOMER';

    const clubSavingsPercentage =
        originalPrice && lineItemPrice
            ? ((originalPrice.centAmount - lineItemPrice.centAmount) / originalPrice.centAmount) * 100
            : 0;

    const showFrom = hideFromText ? false : variant?.existsOtherThenCheapestPrices;

    const { showCheaperSetPrice, cheaperSetPrice } = useCheaperSet(variant);

    let price: Money;
    let internalPriceClassName: string;
    if (custom?.priceType !== 'LISTPRICE' && custom?.priceType !== 'DEAL' && showCheaperSetPrice && cheaperSetPrice) {
        price = cheaperSetPrice;
        internalPriceClassName =
            priceClassName.indexOf('price-colored-savings') > 0
                ? priceClassName
                : `${priceClassName} price-colored-savings`;
    } else {
        price =
            lineItemPrice ??
            (showFrom && variant?.existsOtherThenCheapestPrices && variant.cheapestPrice
                ? variant.cheapestPrice
                : variant.price);
        internalPriceClassName = priceClassName;
    }

    return (
        <div
            className={classNames(
                className,
                componentName,
                `${componentName}--${size}`,
                `${componentName}--align-${alignment}`
            )}
        >
            {getStrikeThroughPrice({ custom, variant, componentName, lineItemPrice, isCustomerClubPrice })}
            {detailBadges?.map((badge) =>
                badge.type === 'XPLUSYGRATIS' ? (
                    <Badge {...badge.badgeProps} key={badge.badgeProps.text} />
                ) : (
                    SKIP_RENDER
                )
            )}
            <div
                className={classNames(
                    `${componentName}__price`,
                    { [`${componentName}__price--sale`]: hasSaleBadge },
                    { 'price-box__price--rate': Boolean(installmentPrice) },
                    { 'price-box__price--club-price': isCustomerClubPrice }
                )}
            >
                {isCustomerClubPrice && clubSavingsPercentage > 0 ? (
                    <Badge
                        text={intl.formatMessage(
                            { id: 'product.badges.savings' },
                            { count: Math.ceil(clubSavingsPercentage) }
                        )}
                        style="savings"
                        size="small"
                    />
                ) : (
                    SKIP_RENDER
                )}

                {renderCheaperSetPrice && variant.mightHaveCheaperSets ? (
                    <div className={`${componentName}__cheaper-set-box`}>
                        <span className={`${componentName}__cheaper-set-quantity-advantage-text`}>
                            <FormattedMessage id="product.quantityAdvantage" />
                        </span>
                        <span className={`${componentName}__amount-text`}>
                            <FormattedMessage
                                id={variant?.attributes?.setTypeLclzd ? 'product.setTypeXplusY' : 'product.cheaperSet'}
                            />
                        </span>
                    </div>
                ) : (
                    SKIP_RENDER
                )}

                {installmentPrice && installmentPrice.centAmount > 0 ? (
                    <span
                        className={classNames(
                            `${componentName}__total-installment-price`,
                            `${className}__total-installments-price`,
                            priceClassName
                        )}
                    >
                        <Badge
                            textElement={
                                <FormattedMessage
                                    id="product.badges.installmentsInCart"
                                    values={{
                                        count: installmentPrice.installments,
                                        price: <Price price={installmentPrice} />,
                                    }}
                                />
                            }
                            style="raten"
                            cornerPosition="bottom-left"
                        />
                    </span>
                ) : (
                    SKIP_RENDER
                )}

                <span className={internalPriceClassName}>
                    <Price price={price} showFrom={showFrom} />
                </span>
                {showPerDeliveryInfo ? (
                    <span className={`${componentName}__per-deliver-info`}>
                        <FormattedMessage id="product.attributes.perDelivery" />
                    </span>
                ) : (
                    SKIP_RENDER
                )}
            </div>

            {variant.price ? (
                <div className={`${componentName}__unit-price`}>
                    <UnitPrice variant={variant} />
                </div>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}

export default injectComponent('pattern.molecule.PriceBox', PriceBox, 'catalog-base');
