import { BrxRelationType } from '@mediashop/app/bloomreach/types';
import { CartProductRelationType } from '../types/CartQueries';

export const mapBrxRelationTypeToGatewayRelationType = (
    relationType: BrxRelationType | undefined
): CartProductRelationType => {
    switch (relationType) {
        case 'crosssell':
            return 'TYPE_CROSSSELL';
        case 'upsell':
            return 'TYPE_UPSELL';
        case 'crosssell_AI':
            return 'TYPE_CROSSSELL_AI';
        case 'upgrade':
            return 'TYPE_UPGRADE';
        default:
            return 'TYPE_HAUPTPRODUKT';
    }
};
