import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import {
    BackgroundProps,
    BaseProps,
    BrxAnchor,
    BrxAsset,
    BrxHeadlines,
    HeadlineStyleProps,
    HeadlineTextStyleProps,
} from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const VideoWithTextMolecule = lazy(() => lazyRetry(() => import('./VideoWithText')));

export type VideoWithTextProps = BaseProps & {
    anchor?: BrxAnchor;
    availableContentSpace: string;
    headlines: BrxHeadlines;
    videoUrl: string;
    video?: BrxAsset;
    videoHeadline: string;
    videoSubheadline?: string;
    videoHeadlineStyle: HeadlineStyleProps;
    videoTextStyle: HeadlineTextStyleProps;
    text?: BrxHtml;
    background: BackgroundProps;
};

const VideoWithText = (props: VideoWithTextProps): JSX.Element => (
    <LazyComponent>
        <VideoWithTextMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.VideoWithText', VideoWithText);
