import { BreakpointName } from '@mediashop/app/config/breakpoints';

export const calculateDealPosition = (
    device: BreakpointName,
    initialPosition: number,
    productCount: number,
    productsPerRow: number
): number => {
    let dealPosition: number = initialPosition;

    switch (device) {
        case 'wide':
        case 'desktop':
            if (initialPosition % productsPerRow === 0 || productCount % productsPerRow === productsPerRow - 1) {
                dealPosition = initialPosition - 1;
            }
            break;
        case 'tablet':
            if (initialPosition % 3 === 0 || productCount % 3 === 2) {
                dealPosition = initialPosition - 1;
            }
            break;
        case 'mobile':
            if (initialPosition % 2 === 0 || productCount % 2 === 1) {
                dealPosition = initialPosition - 1;
            }
            break;
        default:
            dealPosition = initialPosition;
    }

    return dealPosition;
};
