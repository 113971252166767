import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const UserManualsTableLazy = lazy(() => lazyRetry(() => import('./UserManualsTable')));

const UserManualsTable = (): JSX.Element => (
    <LazyComponent>
        <UserManualsTableLazy />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.UserManualsTable', UserManualsTable);
