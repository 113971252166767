import { BaseProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import classNames from 'classnames';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MainNavigationCustomNavigationItemProps } from '../types';
import DesktopNavigationItem from './DesktopNavigationItem';

const componentName = 'desktop-navigation';

type DesktopNavigationProps = BaseProps & {
    navigationItems: Array<MainNavigationCustomNavigationItemProps>;
};

const DesktopNavigation = ({ navigationItems }: DesktopNavigationProps): ReactElement | null => {
    const { pathname } = useLocation();
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        setIsHovered(false);
    }, [pathname]);

    if (navigationItems.length === 0) {
        return SKIP_RENDER;
    }

    return (
        <nav
            onMouseEnter={() => setIsHovered(true)}
            onTouchStart={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classNames(componentName, {
                [`${componentName}--hover`]: isHovered,
            })}
        >
            <ul className={`${componentName}__list`}>
                {navigationItems.map((item) => (
                    <DesktopNavigationItem key={item.link.text} navItem={item} />
                ))}
            </ul>
        </nav>
    );
};

export default DesktopNavigation;
