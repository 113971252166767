export type BreakpointName = 'mobile' | 'tablet' | 'desktop' | 'wide';

export interface Breakpoint {
    name: BreakpointName;
    minWidth: number;
    userAgentRegex?: RegExp;
    isDesktopBreakpoint?: boolean;
}

export type BreakpointCollection = Breakpoint[];

/**
 * Sort given breakpoints from smallest to biggest.
 * @param breakpoints
 */
const sortBreakpoints = (breakpoints: BreakpointCollection): BreakpointCollection =>
    breakpoints.sort((itemA, itemB) => itemA.minWidth - itemB.minWidth);

/**
 * Configure breakpoints here!
 * Alternatively, you have to dispatch them via redux.
 */
const breakpoints: BreakpointCollection = [
    {
        name: 'mobile',
        minWidth: 0,
        userAgentRegex:
            /(Android.*Mobile|webOS|iPhone|iPod|BlackBerry|Windows Phone|Smartphone|Google-Mobile|Mobile.*Googlebot)/i,
    },
    {
        name: 'tablet',
        minWidth: 768,
        userAgentRegex: /(Tablet|Playbook|Silk|iPad|Kindle|Android)/i,
    },
    {
        name: 'desktop',
        minWidth: 1200,
        userAgentRegex: /(Googlebot|BingPreview)/i,
        isDesktopBreakpoint: true,
    },
    {
        name: 'wide',
        minWidth: 1300,
        userAgentRegex: /(Googlebot|BingPreview)/i,
        isDesktopBreakpoint: true,
    },
];

export const breakpointWidthMap = breakpoints.reduce<Record<BreakpointName, number>>(
    (breakpointMap, breakpoint) => ({
        ...breakpointMap,
        [breakpoint.name]: breakpoint.minWidth,
    }),
    {} as Record<BreakpointName, number>
);

export default sortBreakpoints(breakpoints);
