import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { DataCellProps } from './DataCell';
import { BackgroundProps, BaseProps, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import LazyComponent from '@mediashop/base/pattern/molecule/LazyComponent';

const TableMolecule = lazy(() => import('./Table'));

type Cell = {
    cells: DataCellProps[];
};

export type TableProps = BaseProps & {
    head: Cell;
    rows: Cell[];
    headlines: BrxHeadlines;
    background: BackgroundProps;
};

const Table = (props: TableProps): JSX.Element => (
    <LazyComponent>
        <TableMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.Table', Table);
