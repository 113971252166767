import { lazy } from 'react';
import { BaseProps, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { BrXImageVariant } from '../../../types/imageVariants';

const BloomreachAssetsLazy = lazy(() => lazyRetry(() => import('./BloomreachAssets')));

export type BloomreachAssetsProps = BaseProps & {
    altText: string;
    mobileImage?: BrxImageWithAltText;
    tabletImage?: BrxImageWithAltText;
    desktopImage: BrxImageWithAltText;
    variant: BrXImageVariant;
};

const BloomreachAssets = (props: BloomreachAssetsProps): JSX.Element => (
    <LazyComponent>
        <BloomreachAssetsLazy {...props} />
    </LazyComponent>
);

export default BloomreachAssets;
