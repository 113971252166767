import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useShopContext } from '../../hooks/useShopContext';
import { useLoader } from '../../store/loader';
import { queries } from '..';

type Props = {
    searchText: string;
    limit?: number;
    enabled?: boolean;
};

/**
 * Fetch product and keyword suggestions for search
 */
export const useAutoSuggest = ({ searchText, limit = 5, enabled = true }: Props) => {
    const { productClient } = useLoader();
    const { country, currency, locale } = useShopContext();
    const getAutoSuggest = () => productClient.getAutoCompleteSuggestions(searchText, country, currency, locale, limit);

    return useQuery({
        ...queries.search.autoSuggest(searchText, country, currency, locale, limit),
        queryFn: () => getAutoSuggest(),
        enabled,
        placeholderData: keepPreviousData,
    });
};
