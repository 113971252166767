import { removeLocaleFromPathname } from '@mediashop/app/helper/localeHelper';
import { Location } from 'react-router-dom';
import { MainNavigationCustomNavigationChildItemProps } from './types';

export const findParentWithClass = (element: HTMLElement | null, className: string): HTMLElement | null => {
    while (element && !element.classList.contains(className)) {
        element = element.parentElement;
    }
    return element;
};

export const splitArrayByNewColumn = (
    subNavigation: Array<MainNavigationCustomNavigationChildItemProps>
): Array<Array<MainNavigationCustomNavigationChildItemProps>> => {
    const splitSubNavigation = subNavigation.reduce(
        (acc: Array<Array<MainNavigationCustomNavigationChildItemProps>>, item) => {
            if (item.newColumn) {
                acc.push([]);
            }
            acc[acc.length - 1].push(item);
            return acc;
        },
        [[]]
    );

    return splitSubNavigation.filter((array) => array.length);
};

export const getIsNavItemActive = (url: string, currentSlug: string): boolean => {
    const urlParts = url.split('/').filter(Boolean);
    const cleanSlug = removeLocaleFromPathname(currentSlug);

    return cleanSlug.includes(urlParts[urlParts.length - 1]);
};

export const getIsChildNavItemActive = (url: string, { pathname, hash }: Location<any>): boolean => {
    const cleanPathname = removeLocaleFromPathname(`${pathname}${hash}`);

    return url === cleanPathname;
};
