import { Address } from './Address';
import { Variant } from './ClientProduct';
import { Money, NewMoney, TaxedPrice } from './Money';
import { DiscountCodeItem, TotalPriceDiscount } from './Discount';
import { PriceFinderPriceType } from './PriceType';
import { CartProductRelationType } from './CartQueries';
import { BrxHeadlines } from '../../bloomreach/types';

export const ShippingKey = {
    STANDARD: 'standard',
    BULKY: 'bulky',
    DROP: 'shipping_',
};

export type ShippingMode = 'Single' | 'Multiple';

export interface DiscountedPricePerQuantity {
    discountedPrice: {
        value: NewMoney;
        includedDiscounts: Array<{
            discount: {
                description: string;
                id: string;
                name: string;
            };
            discountedAmount: NewMoney;
        }>;
    };
    quantity: number;
}

export interface CartLineItemCustomFields {
    aboPrice?: Money;
    customerPrice?: Money;
    isCheaperSet?: boolean;
    numberOfInstallments: number;
    originalListPrice?: Money;
    originalListPriceType?: PriceFinderPriceType;
    originalPrice?: Money;
    originalPriceType?: PriceFinderPriceType;
    priceType?: PriceFinderPriceType;
    productRelationLineItemKey?: string;
    productRelationType: CartProductRelationType;
    subscriptionSelection?: string;
}

export interface CartLineItem {
    custom: CartLineItemCustomFields;
    discountedPricePerQuantity: DiscountedPricePerQuantity[];
    lineItemMode: string;
    id: string;
    key: string;
    name: string;
    price: Money;
    quantity: number;
    variant: Variant;
    productId: string;
    productKey: string;
    productSlug: string;
}

export interface CustomLineItem {
    id: string;
    key: string;
    money: Money;
    name: string;
    quantity: number;
    taxedPrice: TaxedPrice;
}

export interface PercentageDiscountValue {
    code: string;
    description: string;
    name: string;
    value: Money;
}

export interface CartAddress {
    apartment: string;
    city: string;
    company: string;
    country: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    postalCode: string;
    salutation: string;
    streetName: string;
    streetNumber: string;
}

export interface MultipleShippingInfo {
    shippingKey: string;
    shippingInfo: ShippingInfo;
}

export interface ShippingInfo {
    shippingRate: {
        freeAbove?: Money;
        price: Money;
    };
    taxRate?: {
        amount: number;
    };
    taxedPrice: {
        totalGross: Money;
        totalNet: Money;
    };
}

export interface Cart {
    customFields?: {
        bulkyTotalCost: string;
        orderId?: string;
        partnerId: string;
    };
    id: string;
    totalPrice: Money;
    taxedPrice: {
        totalNet: Money;
        totalGross: Money;
        totalTax: Money;
    };
    productTotal: Money;
    shippingMode: ShippingMode;
    shippingInfo?: ShippingInfo;
    shipping: Array<MultipleShippingInfo>;
    billingAddress?: Address;
    shippingAddress: Address;
    state: 'Active' | 'Ordered' | 'Frozen' | 'Merged';
    requiredFields?: unknown;
    lineItems: Array<CartLineItem>;
    customLineItems: Array<CustomLineItem>;
    discountCodes: Array<DiscountCodeItem>;
    discountOnTotalPrice?: TotalPriceDiscount;
    totalLineItemQuantity: number;
    version: number;
    headlines?: BrxHeadlines;
}

export type InstallmentPlanProps = {
    id: string;
    creditInformationUrl: string;
    numberOfInstallments: number;
};
