import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants/queryKeys';
import { useAppDispatch } from '../../store/hooks/hooks';
import { useLoader } from '../../store/loader';
import { setCustomerSignInState } from '../../store/reducer/customer';
import { useEffect } from 'react';

/**
 * Fetch customer sign in state.
 * @returns signInState
 */
export const useCustomerSignInState = () => {
    const { customerClient } = useLoader();
    const dispatch = useAppDispatch();

    const fetchCustomerSignInState = () => customerClient.getCustomerSignInState();

    const query = useQuery({
        queryKey: [QueryKeys.FETCH_CUSTOMER_SIGN_IN_STATE],
        queryFn: fetchCustomerSignInState,
    });

    const { isSuccess, isError, isPending, data } = query;
    useEffect(() => {
        if (isSuccess && !isPending) {
            dispatch(setCustomerSignInState(data.isSignedIn));
        } else if (isError) {
            dispatch(setCustomerSignInState(false));
        }
    }, [isError, isSuccess, isPending, data, dispatch]);

    return query;
};
