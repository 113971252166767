import { Category } from '@mediashop/app/api/types/ClientCategory';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import BaseUrlHelper from '@mediashop/app/helper/BaseUrlHelper';
import { PRODUCT_GRID_PAGE_LIMIT } from '@mediashop/app/constants/api';
import { QueryClient } from '@tanstack/react-query';
import { findQueryDataByKey } from '@mediashop/app/helper/queryHelpers';
import findCategoryByKey from './findCategoryByKey';
import { determineProductsCategory } from '../bloomreach/product/determineProductsCategory';
import { queries } from '../queries';

const categoryUrlMatcher = /\/c\/(?<categorySlug>(\w|-)+)(\/)*(?<subcategorySlug>(\w|-)*)/;

type CategorySlugs = {
    categorySlug: string;
    subcategorySlug?: string;
};

/**
 * Get category-slug and subcategory-slug from url by performing a regex match.
 *
 * e.g. /c/cat-slug returns `{ categorySlug: "cat-slug", subCategorySlug: undefined }`
 *      /c/cat-slug/sub-cat-slug returns `{ categorySlug: "cat-slug", subCategorySlug: "sub-cat-slug" }`
 * @param url raw url (pathname)
 * @returns category-slug and subcategory-slug (if found), otherwise undefined
 */
export const getCategorySlugsFromUrl = (url: URL): CategorySlugs | undefined => {
    const match = categoryUrlMatcher.exec(url.pathname);

    const params = new URLSearchParams(url.search);
    const subcategoryFromQueryParam = params.get('subcategory');

    if (match?.groups) {
        const {
            groups: { categorySlug, subcategorySlug },
        } = match;

        let newSubcategorySlug = subcategorySlug;

        if (!subcategorySlug && subcategoryFromQueryParam) {
            newSubcategorySlug = subcategoryFromQueryParam;
        }

        return { categorySlug, subcategorySlug: newSubcategorySlug };
    }

    return undefined;
};

type CategoryIndex = Record<string, { level: number; parent: string }>;

const buildCategorySlugIndex = (parent: Category, children: Category[], level = 0): CategoryIndex => {
    let index: CategoryIndex = {};

    for (const child of children) {
        index[child.slug] = { parent: parent.slug, level };
        const subIndex = buildCategorySlugIndex(child, child?.children ?? [], level + 1);

        index = { ...index, ...subIndex };
    }
    return index;
};

/**
 * Check if a category slug is known
 * @param url request url
 * @param categories commercetools categories for request
 * @returns indicator if category is known
 */
export const isKnownCategorySlug = (url: URL, categories: Category[]): boolean => {
    const { categorySlug, subcategorySlug } = getCategorySlugsFromUrl(url) ?? {};

    if (categorySlug && subcategorySlug) {
        const categoryIndex = buildCategorySlugIndex(categories[0], categories);
        return Boolean(categoryIndex[categorySlug] && categoryIndex[subcategorySlug]);
    }

    if (categorySlug) {
        const categoryIndex = buildCategorySlugIndex(categories[0], categories);
        return Boolean(categoryIndex[categorySlug]);
    }

    return false;
};

/**
 * Get redirection url for URLs that only contain the subcategory to the url with category + subcategory.
 *
 * @param url request url
 * @param categories commercetools categories for request
 * @param basePath base path
 * @returns redirection url / target
 */
export const getSubCategoryRedirectionTarget = (
    url: URL,
    categories: Category[],
    basePath: string
): string | undefined => {
    const { categorySlug } = getCategorySlugsFromUrl(url) ?? {};

    if (categorySlug) {
        const mainCategory = categories[0];
        const categoryIndex = buildCategorySlugIndex(mainCategory, categories);
        const maxLevel = Math.max(...Object.values(categoryIndex).map((cat) => cat.level));

        // If the slug is at the deepest level, it means that it can only be a subcategory
        if (categoryIndex[categorySlug].level === maxLevel && maxLevel > 2) {
            const parentCategorySlug = categoryIndex[categorySlug].parent;
            return BaseUrlHelper.addBasePathToPath(
                `/c/${parentCategorySlug}/${categorySlug}${url.search}${url.hash}`,
                basePath
            );
        }
    }

    return undefined;
};

/**
 * Check if a category page is known
 * @param url request url
 * @param categories commercetools categories for request
 * @param requestedPage requested page number
 * @returns indicator if category page is known
 */
export const isKnownCategoryPage = (queryClient: QueryClient, requestedPage = 1): boolean => {
    const categoryProducts = findQueryDataByKey<{ total: number; limit: number }>(
        queryClient,
        queries.categories.products._def
    );
    const totalCategoryPages = Math.ceil(
        (categoryProducts?.total ?? 0) / (categoryProducts?.limit ?? PRODUCT_GRID_PAGE_LIMIT)
    );
    return totalCategoryPages >= requestedPage;
};

/**
 * Find currently selected category id by given categories, country and URL
 * @param params input data
 * @returns selected category id
 */
export const findSelectedCategoryId = ({
    categories,
    categoryTreeRootKey,
    country,
    url,
}: {
    categories: Category[];
    categoryTreeRootKey: string;
    country: string;
    url: string;
}): string => {
    if (categories.length) {
        const category = findCategoryByKey(categories, categoryTreeRootKey);

        if (category) {
            return determineProductsCategory(category.id, categories, country, url);
        }
    }

    return EMPTY_STRING;
};
