import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { ProductBundle } from '@mediashop/app/bloomreach/product/types/ProductBundle';
import { BadgeStyleProps } from '../../atom/Badge';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ProductCardMolecule = lazy(() => lazyRetry(() => import('./ProductCard')));

export type ProductCardProps = BaseProps & {
    size: 'large' | 'small';
    product?: ProductBundle;
    badgeColor: BadgeStyleProps;
    productBadgeColor?: BadgeStyleProps;
    orientation?: 'landscape' | 'portrait';
};

const ProductCard = (props: ProductCardProps): JSX.Element => (
    <LazyComponent>
        <ProductCardMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ProductCard', ProductCard);
