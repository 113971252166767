import * as React from 'react';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Page } from '@bloomreach/spa-sdk';
import classnames from 'classnames';
import getComponentDisplayName from '../helper/getComponentDisplayName';
import ProductBundlePropEnhancer from './product/ProductBundlePropEnhancer';
import shouldComponentHaveDocument from './shouldComponentHaveDocument';
import dereferenceData from './dereferenceData';
import ErrorBoundary from '../ErrorBoundary';

interface BloomifyConfig {
    className?: string;
}

export interface BloomreachContextValues {
    props: BrProps;
    page: Page;
}

/**
 * Context, containing the page
 */
export const BloomreachContext = React.createContext<BloomreachContextValues>({} as any);

/**
 * Boilerplate for your Bloomreach-Component.
 * Provides document-data as props to the wrapped component.
 * Adds edit-button.
 * Provides product-data.
 */
export default function bloomify<P extends Record<string, unknown>>(
    Component: React.ComponentType<P>,
    config: BloomifyConfig = {}
): React.FunctionComponent<BrProps> {
    const displayName = getComponentDisplayName(Component);

    function BloomifiedComponent(props: Readonly<BrProps>) {
        const documentRef = props.component?.getModels()?.document;
        const documentContent = props.page?.getContent(documentRef);
        const missingDocument = !documentContent && shouldComponentHaveDocument(props);

        // Display warning if no document has been selected.
        if ((props.page?.isPreview() || process.env.NODE_ENV === 'development') && missingDocument) {
            return <div className="alert alert--warning">Please choose a document for &quot;{displayName}&quot;.</div>;
        }

        if (missingDocument) {
            console.warn(`bloomify: No document selected for "${displayName}"`);
            return null;
        }

        let data: P = {} as P;
        if (documentContent) {
            data = dereferenceData(documentContent.getData(), props.page?.getContent.bind(props.page));
            ProductBundlePropEnhancer.enhance(data!);
        }

        return (
            <div
                className={classnames(
                    config.className,
                    'br-component',
                    { 'br-component--is-preview': props.page?.isPreview() },
                    'container-wrapper'
                )}
            >
                {documentContent ? <BrManageContentButton content={documentContent} /> : null}

                <ErrorBoundary>
                    <BloomreachContext.Provider value={{ props, page: props.page! }}>
                        <Component brData={props} {...data} />
                    </BloomreachContext.Provider>
                </ErrorBoundary>
            </div>
        );
    }

    BloomifiedComponent.displayName = `BloomifiedComponent(${displayName})`;

    return BloomifiedComponent;
}
