import { EMPTY_STRING } from '../constants/semanticConstants';
import { useProject } from './useProject';
import { useShopContext } from './useShopContext';

/**
 * @deprecated Please use `useReactRouterLink` (Attention: it doesn´t include the basePath).
 *
 * A hook that returns a base-link, based on the language from the url (if takeLanguageFromUrl is set).
 * @param suppressBase Don`t add the basePath to the url (e.g. for usage with <Link> from react-router-dom)
 */
export default function useLinkBase(suppressBase = false): string {
    const { takeLanguageFromUrl, basePath: stateBasePath } = useProject();
    const basePath = stateBasePath.replace(/\/$/, '');

    const { country: userCountry, locale: userLocale } = useShopContext();

    const localeUrlPath = takeLanguageFromUrl
        ? `${userCountry.toLowerCase()}/${userLocale.split('-')[0].toLowerCase()}`
        : EMPTY_STRING;

    if (suppressBase) {
        return localeUrlPath;
    }
    return takeLanguageFromUrl ? `${basePath}/${localeUrlPath}` : `${basePath}`;
}
