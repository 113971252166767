import { useAppSelector } from '../store/hooks/hooks';
import { CategoryState } from '../store/reducer/category';
import { shallowEqual } from 'react-redux';

/**
 * Hook that returns category redux state.
 * @returns CategoryState
 */
export const useCategory = (equalityCheck = false): CategoryState => {
    return useAppSelector((state) => state.category, {
        equalityFn: equalityCheck ? shallowEqual : undefined,
    });
};
