import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy } from 'react';

const GoToCartModalMolecule = lazy(() => lazyRetry(() => import('./Modal')));

export type GoToCartModalProps = {
    dismissModal: () => void;
    goToCartAction: () => void;
    showModal: boolean;
};

const GoToCartModal = (props: GoToCartModalProps): JSX.Element => (
    <LazyComponent>
        <GoToCartModalMolecule {...props} />
    </LazyComponent>
);

export default GoToCartModal;
