import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { BaseProps, BrxHeadlines } from '@mediashop/app/bloomreach/types';

const SubscriptionManagementMolecule = lazy(() => lazyRetry(() => import('./SubscriptionManagement')));

export type SubscriptionManagementProps = BaseProps & {
    headline: BrxHeadlines;
};

const SubscriptionManagement = (props: SubscriptionManagementProps) => (
    <LazyComponent>
        <SubscriptionManagementMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.SubscriptionManagement ', SubscriptionManagement);
