import { BrXImageVariant } from '../../../types/imageVariants';

const DEVICE_TYPE_MOBILE = 'mobile';
const DEVICE_TYPE_TABLET = 'tablet';
const DEVICE_TYPE_DESKTOP = 'desktop';
const DEVICE_TYPE_WIDE = 'wide';

/**
 * Generates the size map for an image fetched from Bloomreach.
 * The URL needs width_height, but considers the longer side for the optimization.
 * It doesn't matter if we send a square dimension, the image will return in the correct aspect ratio.
 * - tablet, desktop and wide types are handled the same way, just mobile is different
 * @param desktop width and height of images on tablet, desktop and wide devices
 * @param mobile width and height on mobile devices
 * @returns map of device-type and size
 */
const generateDefaultDeviceTypeSizeMap = (desktop: number, mobile: number) => ({
    [DEVICE_TYPE_MOBILE]: {
        width: mobile,
        height: mobile,
    },
    [DEVICE_TYPE_TABLET]: {
        width: desktop,
        height: desktop,
    },
    [DEVICE_TYPE_DESKTOP]: {
        width: desktop,
        height: desktop,
    },
    [DEVICE_TYPE_WIDE]: {
        width: desktop,
        height: desktop,
    },
});

export const BRX_IMAGE_SIZE_MAP = {
    [BrXImageVariant.AccordionContent]: {
        ...generateDefaultDeviceTypeSizeMap(250, 250),
    },
    [BrXImageVariant.AccordionIcon]: {
        ...generateDefaultDeviceTypeSizeMap(50, 50),
    },
    [BrXImageVariant.AmbienteShopping]: {
        ...generateDefaultDeviceTypeSizeMap(900, 450),
    },
    [BrXImageVariant.AsymetricImage]: {
        ...generateDefaultDeviceTypeSizeMap(1500, 800),
    },
    [BrXImageVariant.BackgroundImageWithStyle]: {
        ...generateDefaultDeviceTypeSizeMap(1920, 800),
    },
    [BrXImageVariant.BackgroundImageWithText]: {
        ...generateDefaultDeviceTypeSizeMap(1920, 800),
    },
    [BrXImageVariant.CartSummary]: {
        ...generateDefaultDeviceTypeSizeMap(150, 150),
    },
    [BrXImageVariant.CheckoutUsp]: {
        ...generateDefaultDeviceTypeSizeMap(20, 20),
    },
    [BrXImageVariant.Contact]: {
        ...generateDefaultDeviceTypeSizeMap(400, 200),
    },
    [BrXImageVariant.ContactBlock]: {
        ...generateDefaultDeviceTypeSizeMap(250, 250),
    },
    [BrXImageVariant.ContentSliderBasicSlide]: {
        ...generateDefaultDeviceTypeSizeMap(300, 300),
    },
    [BrXImageVariant.CrossSellingBanner]: {
        ...generateDefaultDeviceTypeSizeMap(600, 300),
    },
    [BrXImageVariant.CustomerClub]: {
        ...generateDefaultDeviceTypeSizeMap(300, 300),
    },
    [BrXImageVariant.DataCell]: {
        ...generateDefaultDeviceTypeSizeMap(400, 250),
    },
    [BrXImageVariant.DealOfTheDay]: {
        ...generateDefaultDeviceTypeSizeMap(900, 450),
    },
    [BrXImageVariant.DownloadWithImageItem]: {
        ...generateDefaultDeviceTypeSizeMap(400, 400),
    },
    [BrXImageVariant.FlexBox]: {
        ...generateDefaultDeviceTypeSizeMap(600, 300),
    },
    [BrXImageVariant.HeroTeaser]: {
        ...generateDefaultDeviceTypeSizeMap(1000, 500),
    },
    [BrXImageVariant.ImageBanner]: {
        ...generateDefaultDeviceTypeSizeMap(1920, 800),
    },
    [BrXImageVariant.InfoBanner]: {
        ...generateDefaultDeviceTypeSizeMap(50, 50),
    },
    [BrXImageVariant.LogoSliderBasicSlide]: {
        ...generateDefaultDeviceTypeSizeMap(250, 250),
    },
    [BrXImageVariant.MainNavigation]: {
        ...generateDefaultDeviceTypeSizeMap(100, 100),
    },
    [BrXImageVariant.Newsletter]: {
        ...generateDefaultDeviceTypeSizeMap(300, 200),
    },
    [BrXImageVariant.Polaroid]: {
        ...generateDefaultDeviceTypeSizeMap(500, 500),
    },
    [BrXImageVariant.ProductCard]: {
        ...generateDefaultDeviceTypeSizeMap(100, 100),
    },
    [BrXImageVariant.ProductStage]: {
        ...generateDefaultDeviceTypeSizeMap(500, 300),
    },
    [BrXImageVariant.ProductStageLogos]: {
        ...generateDefaultDeviceTypeSizeMap(250, 250),
    },
    [BrXImageVariant.Samsung]: {
        ...generateDefaultDeviceTypeSizeMap(500, 300),
    },
    [BrXImageVariant.SpecialDeal]: {
        ...generateDefaultDeviceTypeSizeMap(700, 500),
    },
    [BrXImageVariant.SPLPNavigation]: {
        ...generateDefaultDeviceTypeSizeMap(150, 100),
    },
    [BrXImageVariant.StageSlider]: {
        ...generateDefaultDeviceTypeSizeMap(1000, 500),
    },
    [BrXImageVariant.TestimonialSlide]: {
        ...generateDefaultDeviceTypeSizeMap(500, 250),
    },
    [BrXImageVariant.TextWithImage]: {
        ...generateDefaultDeviceTypeSizeMap(700, 400),
    },
    [BrXImageVariant.TeaserGridTile]: {
        ...generateDefaultDeviceTypeSizeMap(700, 700),
    },
    [BrXImageVariant.TopHeader]: {
        ...generateDefaultDeviceTypeSizeMap(18, 18),
    },
    [BrXImageVariant.USPs]: {
        ...generateDefaultDeviceTypeSizeMap(30, 48),
    },
};
