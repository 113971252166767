import { injectComponent } from '@mediashop/app/component-injector';
import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { lazy } from 'react';
import { BaseProps, BrxBackground, BrxCTAButton } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { HeadlineProps } from '../../atom/Headlines';

const NewsletterUnsubscribeLazy = lazy(() => lazyRetry(() => import('./NewsletterUnsubscribe')));

export type NewsletterUnsubscribeProps = BaseProps & {
    background: BrxBackground;
    ctaButton: BrxCTAButton;
    description: BrxHtml;
    headline: HeadlineProps;
    legalText: BrxHtml;
    seoHeadline: string;
};

const NewsletterUnsubscribe = (props: NewsletterUnsubscribeProps): JSX.Element => (
    <LazyComponent>
        <NewsletterUnsubscribeLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.NewsletterUnsubscribe', NewsletterUnsubscribe);
