import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { queries } from '..';
import { useLoader } from '../../store/loader';
import { useShopContext } from '../../hooks/useShopContext';

type UseSearchProductsProps = {
    searchText: string;
    nextPageIndex: number;
    limit?: number;
    enabled?: boolean;
};

/**
 * Hook that calls product search endpoint.
 */
export const useSearchProducts = ({
    searchText,
    nextPageIndex,
    limit = 30,
    enabled = true,
}: UseSearchProductsProps) => {
    const { productClient } = useLoader();
    const { country, currency, locale } = useShopContext();

    const offset = nextPageIndex * limit;

    const searchProductsFct = () => productClient.searchProducts(searchText, country, currency, locale, limit, offset);

    const { isFetching, data } = useQuery({
        ...queries.search.searchProducts(searchText, nextPageIndex, country, limit),
        queryFn: () => searchProductsFct(),
        enabled,
        placeholderData: keepPreviousData,
    });

    const { landingPage, products = [], total = 0 } = data ?? {};

    return {
        landingPage,
        isFetching,
        products,
        totalProductCount: total,
    };
};
