import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ClubModalMolecule = lazy(() => lazyRetry(() => import('./ClubModal')));

export type ClubModalProps = BaseProps & {
    showModal?: boolean;
    setShowModal: (boolean) => void;
};

const ClubModal = (props: ClubModalProps): JSX.Element => (
    <LazyComponent>
        <ClubModalMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ClubModal', ClubModal);
