import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { TestimonialSlideProps } from './TestimonialSlide';
import { BackgroundProps, BaseProps, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const TestimonialSliderMolecule = lazy(() => lazyRetry(() => import('./TestimonialSlider')));

export type TestimonialSliderProps = BaseProps & {
    headlines: BrxHeadlines;
    items: TestimonialSlideProps[];
    slidesInViewDesktop?: string;
    background?: BackgroundProps;
    contentBackground?: BackgroundProps;
};

const TestimonialSlider = (props: TestimonialSliderProps): JSX.Element => (
    <LazyComponent>
        <TestimonialSliderMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.TestimonialSlider', TestimonialSlider);
