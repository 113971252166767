import { RefObject } from 'react';
import { useIntl } from 'react-intl';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import classNames from 'classnames';
import DateOfBirthInput from '../../../atom/DateOfBirthInput';
import { FormSubmitHandle } from '../../DynamicFormik';

type InputName = {
    day: string;
    month: string;
    year: string;
};
type DynamicDynamicDateOfBirthProps = BaseProps & {
    id?: string;
    refId?: RefObject<FormSubmitHandle>;
    name: string;
    inputName?: InputName;
    required?: boolean;
    defaultValue?: string;
    error?: string;
    labelId?: string;
    onChange: ({ date, isValid, name }: { date: string; isValid: boolean; name: string }) => void;
};

function DynamicDateOfBirth({ id, className, labelId, error, ...props }: DynamicDynamicDateOfBirthProps): JSX.Element {
    const intl = useIntl();

    return (
        <div id={id} className={classNames('input', className, { 'input-error': error })}>
            {labelId && (
                <div className={classNames('form__title', { 'form__title--empty': labelId === 'empty' })}>
                    {labelId !== 'empty' && intl.formatMessage({ id: labelId })}
                </div>
            )}
            <label className="input__label">
                <DateOfBirthInput {...props} />
            </label>
        </div>
    );
}

export default DynamicDateOfBirth;
