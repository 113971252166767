import { CartLineItem } from '../../api/types/ClientCart';
import { CartProductDataLayer } from '../gtm/types';

export const getProductDataLayerFromGatewayCartLineItem = function (
    lineItem: CartLineItem,
    quantity: number
): CartProductDataLayer {
    return {
        name: lineItem?.name,
        id: lineItem?.id,
        sku: lineItem?.variant.sku,
        groupId: lineItem?.productKey,
        price: lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
        variant: lineItem?.variant.sku,
        quantity,
    };
};
