import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { CartLineItem } from '@mediashop/app/api/types/ClientCart';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const OnePageCheckoutCartLineItemMolecule = lazy(() => lazyRetry(() => import('./CartLineItem')));

export type OnePageCheckoutCartLineItemProps = BaseProps & {
    lineItem: CartLineItem;
};

const OnePageCheckoutCartLineItem = (props: OnePageCheckoutCartLineItemProps): JSX.Element => (
    <LazyComponent>
        <OnePageCheckoutCartLineItemMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.OnePageCheckout.CartLineItem', OnePageCheckoutCartLineItem);
