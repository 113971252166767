import { Ref, forwardRef, useState, type ChangeEvent } from 'react';
import Icon from '../Icon';
import classNames from 'classnames';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const componentName = 'password-input';

export type InputProps = BaseProps & {
    autoComplete?: string;
    error?: string;
    id?: string;
    label: string;
    max?: string | number;
    min?: string | number;
    name: string;
    onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    required?: boolean;
    value?: string;
};

export default forwardRef(function PasswordInput(
    {
        autoComplete,
        className,
        error,
        id,
        label,
        max,
        min,
        name,
        onBlur,
        onChange,
        placeholder = label,
        required,
        value,
    }: InputProps,
    ref: Ref<HTMLInputElement>
): JSX.Element {
    const [type, setType] = useState<'password' | 'text'>('password');
    const [icon, setIcon] = useState<'EyeHide' | 'EyeShow'>('EyeShow');

    const switchInputType = () => {
        setType((typeState) => (typeState === 'password' ? 'text' : 'password'));
        setIcon((iconState) => (iconState === 'EyeShow' ? 'EyeHide' : 'EyeShow'));
    };

    return (
        <div className={classNames(componentName, className)}>
            <label className={`${componentName}__label`}>
                <input
                    className={classNames(`${componentName}__input`, {
                        [`${componentName}__input--error`]: Boolean(error),
                    })}
                    id={id}
                    name={name}
                    type={type}
                    min={min}
                    max={max}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    required={required}
                    autoComplete={autoComplete}
                    ref={ref}
                />

                <span className={`${componentName}__title`}>{required ? `${label}*` : label}</span>

                <span onClick={switchInputType} className={`${componentName}__show-hide`}>
                    <Icon className={`${componentName}__show-hide-icon`} name={icon} />
                </span>

                {error ? <div className={`${componentName}__error`}>{error}</div> : SKIP_RENDER}
            </label>
        </div>
    );
});
