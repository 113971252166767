import { NostoProduct } from '@nosto/nosto-react';
import { useEffect, useState } from 'react';
import { SKIP_RENDER } from '../constants/semanticConstants';
import Variants from '../domain/Variants';
import { useGetProductBySlug } from '../hooks/useGetProductBySlug';

const NostoProductWrapper = () => {
    const { product, status } = useGetProductBySlug();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (!product || status !== 'succeeded' || !mounted) {
        return SKIP_RENDER;
    }

    const { product: foundProduct, selectedVariant, isSelectedVariantSelectedByUser } = product;

    if (
        !foundProduct ||
        !selectedVariant ||
        status !== 'succeeded' ||
        (Variants.getApplicableVariants(foundProduct.variants).length > 1 && !isSelectedVariantSelectedByUser)
    ) {
        return SKIP_RENDER;
    }

    return (
        <NostoProduct
            product={foundProduct.key}
            tagging={{ product_id: foundProduct.key, selected_sku_id: selectedVariant.sku }}
        />
    );
};

export default NostoProductWrapper;
