import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import AccountHeader from '../../molecule/AccountHeader';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';

const componentName = 'container-account';

export function ContainerAccount({ children }: BasePropsWithChildren) {
    return (
        <ContentWrapper className={`${componentName}__content-wrapper`}>
            <AccountHeader className={`${componentName}__account-header`} />

            {children}
        </ContentWrapper>
    );
}
