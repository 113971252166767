import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { CartLineItem } from '@mediashop/app/api/types/ClientCart';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { GetWkoProductsResponse } from '@mediashop/app/api/types/ProductQueries';

const WKOModalMolecule = lazy(() => lazyRetry(() => import('./WKOModal')));

export type WKOModalProps = BaseProps & {
    showModal?: boolean;
    setShowModal: (value: boolean) => void;
    wkoProducts?: GetWkoProductsResponse;
    mainProduct?: CartLineItem;
};

const WKOModal = (props: WKOModalProps): JSX.Element => (
    <LazyComponent>
        <WKOModalMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.WKOModal', WKOModal, 'catalog-base');
