import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { LocationType } from '../routing/types';

type DefaultConfigType = {
    watchPathname: boolean;
    watchQueryParams: string[];
};

const DEFAULT_CONFIG: DefaultConfigType = {
    watchPathname: true,
    watchQueryParams: [],
};

/**
 * Filter URLSearchParams by watched parameters
 * @param search search/query string
 * @param watchedParameters parameters that should be watched
 * @returns filtered object of parameters
 */
const getFilteredURLSearchParams = (search: string | undefined, watchedParameters: string[]) => {
    const searchParams = new URLSearchParams(search);
    const searchParamObj = Object.fromEntries(searchParams);

    return Object.entries(searchParamObj).reduce((acc, [key, value]) => {
        if (watchedParameters.includes(key)) {
            return { ...acc, [key]: value };
        }
        return acc;
    }, {});
};

/**
 * Checks if two `location` have changed by comparing pathnames and
 * query parameters (optional).
 */
export const isRouteChange = (
    location: LocationType,
    prevLocation: LocationType,
    { watchPathname, watchQueryParams }: DefaultConfigType
): boolean => {
    const prevQueryParams = getFilteredURLSearchParams(prevLocation.search, watchQueryParams);
    const queryParams = getFilteredURLSearchParams(location.search, watchQueryParams);

    const hasPathnameChanged = watchPathname ? !isEqual(prevLocation.pathname, location.pathname) : false;
    const hasQueryChanged = !isEqual(prevQueryParams, queryParams);

    return hasPathnameChanged || hasQueryChanged;
};

type CallbackFnType = (location: LocationType) => void;

/**
 * Hook that calls a provided function when the location pathname or query changes.
 * @param callbackFn Function to be called when location changes.
 */
export const useChangedLocation = (callbackFn: CallbackFnType, config = DEFAULT_CONFIG): void => {
    const location = useLocation();
    const locationPrevRef = useRef<LocationType | null>(null);
    const isChange = isRouteChange(location || {}, locationPrevRef.current || {}, config);

    const effectFn = () => {
        /*
         * Return if is a non-relevant change,
         * or update the ref otherwise.
         */
        if (!isChange) {
            return;
        }
        locationPrevRef.current = location;

        callbackFn(location);
    };

    useEffect(effectFn, [location, callbackFn, isChange]);
};
