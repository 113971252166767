import getRenderedComponent, { BrxHtml } from '@mediashop/app/components/BloomreachHtml';

type StandardDeliveryModalContentProps = {
    htmlContent?: BrxHtml;
};

export default function StandardDeliveryModalContent({
    htmlContent,
}: StandardDeliveryModalContentProps): JSX.Element | null {
    return getRenderedComponent({ html: htmlContent });
}
