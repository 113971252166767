import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ContentNavigationLazy = lazy(() => lazyRetry(() => import('./ContentNavigation')));

export type ContentNavigationProps = BaseProps & {
    backgroundColorHex: string;
    ctaColorHex: string;
    fontColorHex: string;
    lineColorHex: string;
};

const ContentNavigation = (props: ContentNavigationProps): JSX.Element => (
    <LazyComponent>
        <ContentNavigationLazy {...props} />
    </LazyComponent>
);

export default ContentNavigation;
