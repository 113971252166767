import { useProduct } from '@mediashop/app/hooks/useProduct';
import { useMemo, useState, useRef } from 'react';
import { useProductListAnalytics } from '@mediashop/app/analytics/hooks/useProductListAnalytics';
import { Product, ProductWithActiveVariant } from '@mediashop/app/api/types/ClientProduct';
import {
    BaseProps,
    BrxFilter,
    BrxHeadlines,
    BrxRelationType,
    ContentBackgroundProps,
} from '@mediashop/app/bloomreach/types';
import Grid from '../Grid';
import { useProductClickedAnalytics } from '@mediashop/app/analytics/hooks/useProductClickedAnalytics';
import { SpecialDealProps } from '..';
import { sanitizeProductIds } from '@mediashop/app/helper/sanitizeProductIds';
import Headlines from '@mediashop/base/pattern/atom/Headlines';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useSearchByAttribute } from '@mediashop/app/queries/search/useSearchByAttribute';
import Pagination from '../../Pagination';
import {
    calculatePagination,
    filterDoubleSpecialDealProducts,
    getSpecialDealProduct,
    paginateArray,
} from '../functions';
import { PRODUCT_GRID_PAGE_LIMIT } from '@mediashop/app/constants/api';

const componentName = 'product-grid-standalone';

const GTM_LIST_NAME = 'Category_Standalone';

type ProductGridStandaloneProps = BaseProps & {
    headlineFontColor?: string;
    headlines?: BrxHeadlines;
    productIDs: string[];
    productFilters?: BrxFilter[];
    productRelationType: BrxRelationType;
    productsPerRowDesktop?: string;
    specialDealData?: SpecialDealProps;
    limit?: number;
    contentBackground?: ContentBackgroundProps;
    maxProductCardCountDesktop?: number;
    maxProductCardCountMobile?: number;
    isMobileDevice: boolean;
};

export function ProductGridStandalone({
    headlineFontColor,
    headlines,
    productIDs,
    productRelationType,
    productsPerRowDesktop,
    specialDealData,
    productFilters,
    limit = PRODUCT_GRID_PAGE_LIMIT,
    contentBackground,
    maxProductCardCountDesktop,
    maxProductCardCountMobile,
    isMobileDevice,
}: ProductGridStandaloneProps): JSX.Element {
    const [pageIndex, setPageIndex] = useState(0);
    const gridRef = useRef<HTMLDivElement | null>(null);

    const { products: storeProducts } = useProduct();

    const cleanProductIDs = sanitizeProductIds(productIDs);

    const specialDealProduct = getSpecialDealProduct(storeProducts, specialDealData);

    const page = useMemo(() => pageIndex + 1, [pageIndex]);

    const products: ProductWithActiveVariant[] = useMemo(() => {
        let maxCount: number | undefined;
        if (!isMobileDevice && maxProductCardCountDesktop) {
            if (specialDealProduct) {
                maxCount = maxProductCardCountDesktop - 1;
            } else {
                maxCount = maxProductCardCountDesktop;
            }
        } else if (isMobileDevice && maxProductCardCountMobile) {
            if (specialDealProduct) {
                maxCount = maxProductCardCountMobile - 1;
            } else {
                maxCount = maxProductCardCountMobile;
            }
        }

        // Products are in the same order as cleanProductIDs array
        return cleanProductIDs
            .map((id) => storeProducts[id]?.product)
            .filter((product): product is ProductWithActiveVariant => Boolean(product))
            .slice(0, maxCount);
    }, [
        cleanProductIDs,
        storeProducts,
        maxProductCardCountDesktop,
        maxProductCardCountMobile,
        isMobileDevice,
        specialDealProduct,
    ]);

    const specialDealFilteredProducts = useMemo(
        () => filterDoubleSpecialDealProducts(products, specialDealProduct),
        [products, specialDealProduct]
    );

    const scrollToGridRef = () => {
        if (gridRef.current) {
            gridRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const paginatedProducts = useMemo(() => {
        const [calcOffset, calcLimit] = calculatePagination(page, limit, Boolean(specialDealProduct));
        return paginateArray<ProductWithActiveVariant>(specialDealFilteredProducts, calcOffset, calcLimit);
    }, [page, limit, specialDealProduct, specialDealFilteredProducts]);

    const transitionToPage = (pageNumber: number) => {
        setPageIndex(pageNumber - 1);
        scrollToGridRef();
    };

    const { data: { products: filteredProducts = [], total: totalProductCount = 0 } = {} } = useSearchByAttribute({
        filters: productFilters ?? [],
        offset: pageIndex * limit,
        limit,
    });

    const totalNumberPagesFilter = Math.ceil(totalProductCount / limit);
    const totalNumberPagesStandaloneGrid = Math.ceil(
        (specialDealFilteredProducts.length + (specialDealProduct ? 2 : 0)) / limit
    );

    /*
     * Analytics (GTM)
     */
    useProductListAnalytics(products, GTM_LIST_NAME);
    const dispatchProductListItemClicked = useProductClickedAnalytics();

    const handleProductClick = (product: Product, position: number) => {
        dispatchProductListItemClicked({
            listName: GTM_LIST_NAME,
            position,
            product,
        });
    };

    const paginationPageCount =
        totalProductCount > 0 && totalNumberPagesFilter > 1 ? totalNumberPagesFilter : totalNumberPagesStandaloneGrid;

    return (
        <div ref={gridRef}>
            {/** Headlines */}
            {headlines ? (
                <Headlines className={`${componentName}__headlines`} textColor={headlineFontColor} {...headlines} />
            ) : (
                SKIP_RENDER
            )}

            {/** Grid */}
            <Grid
                products={
                    filteredProducts && filteredProducts.length > 0
                        ? filterDoubleSpecialDealProducts(filteredProducts, specialDealProduct)
                        : paginatedProducts
                }
                specialDealProduct={page === 1 ? specialDealProduct : undefined}
                specialDealData={page === 1 ? specialDealData : undefined}
                productRelationType={productRelationType}
                productsPerRowDesktop={productsPerRowDesktop}
                onProductClick={handleProductClick}
                contentBackground={contentBackground}
            />

            {/** Pagination Filter */}
            {paginationPageCount > 1 ? (
                <div className={`${componentName}__pagination-container`}>
                    <Pagination
                        className={`${componentName}__pagination`}
                        pageCount={paginationPageCount}
                        selectedPage={pageIndex + 1}
                        onPageChange={transitionToPage}
                    />
                </div>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}
