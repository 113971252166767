import { useMemo } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { AddressConfiguration } from '../../api/types/AddressConfiguration';
import { queries } from '..';
import { addressConfigurationLoader } from '../../api/loaders/addressConfigurationLoader';
import { useProject } from '../../hooks/useProject';
import { addShippingCountriesToAdresses } from './addShippingCountriesToAddresses';

/**
 * Hooks that returns address-form configuration for the given country.
 * @param country country to get address-form configuration for.
 * @returns address configuration
 */
export function useAddressConfig(country: string): AddressConfiguration | undefined {
    const { shippingCountries } = useProject();

    const { data } = useQuery({
        ...queries.addresses.country(country),
        queryFn: addressConfigurationLoader,

        // Address configuration cannot change at runtime so we can keep it for the lifetime of the application
        gcTime: Infinity,
        staleTime: Infinity,

        // keep previous data to avoid flashing of forms
        placeholderData: keepPreviousData,
    });

    /**
     * Extend country dropdowns with ALL shipping countries from the current project
     */
    const addressConfigWithShippingCountries = useMemo(
        () => (data ? addShippingCountriesToAdresses(data, shippingCountries) : data),
        [data, shippingCountries]
    );

    return addressConfigWithShippingCountries;
}
