import { useCallback } from 'react';
import analytics from '@mediashop/app/analytics';
import { Product } from '../../api/types/ClientProduct';
import { useShopContext } from '../../hooks/useShopContext';
import { useCategories } from '../../queries/categories/useCategories';

type HookReturnTypeParams = {
    listName: string;
    position: number;
    product: Product;
};

type HookReturnType = (params: HookReturnTypeParams) => void;

/**
 * Hook that handles analytics event when a product (from a list) has been clicked
 * @returns A function that can be used to trigger the analytics event
 */
export const useProductClickedAnalytics = (): HookReturnType => {
    const { country } = useShopContext();
    const { data: categories = [] } = useCategories();
    const mainCategory = categories[0];

    const dispatchProductDetails = useCallback(
        ({ listName, position, product }) =>
            analytics.dispatchProductListItemClicked({
                country,
                listName,
                mainCategory,
                position,
                product,
            }),
        [country, mainCategory]
    );

    return dispatchProductDetails;
};
