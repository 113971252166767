import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { PolaroidProps } from './Polaroid';
import { BackgroundProps, BaseProps, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const PolaroidCollectionMolecule = lazy(() => lazyRetry(() => import('./PolaroidCollection')));

export type PolaroidCollectionProps = BaseProps & {
    headlines: BrxHeadlines;
    background?: BackgroundProps;
    polaroids: PolaroidProps[];
};

const PolaroidCollection = (props: PolaroidCollectionProps): JSX.Element => (
    <LazyComponent>
        <PolaroidCollectionMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.PolaroidCollection', PolaroidCollection);
