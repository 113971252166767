import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import useIsClientSide from '../hooks/useIsClientSide';

/**
 * Add CSSVariables to React Helmet
 */
export default function CSSVariables(): JSX.Element {
    const isClientSide = useIsClientSide();
    const [maxWidth, setMaxWidth] = useState(isClientSide ? `${document.body.clientWidth}px` : '100vw');

    const customCSSVariables = () => `
        :root {
            --scrollbar-width: ${isClientSide ? `${window.innerWidth - document.body.clientWidth}px` : '100vw'};
            --max-width: ${maxWidth};
        }
    `;

    // On Render
    useEffect(() => {
        function handleResize() {
            setMaxWidth(`${document.body.clientWidth}px`);
        }

        window.addEventListener('resize', handleResize);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', handleResize);
        };
    });

    // Initial
    useEffect(() => {
        setMaxWidth(`${document.body.clientWidth}px`);
    }, []);

    return (
        <Helmet>
            <style>{customCSSVariables()}</style>
        </Helmet>
    );
}
