import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CustomerState {
    isSignedIn?: boolean;
}

const initialState: CustomerState = {
    isSignedIn: undefined,
};

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        customerLoggedIn(state) {
            return {
                ...state,
                isSignedIn: true,
            };
        },
        customerLoggedOut(state) {
            return {
                ...state,
                isSignedIn: false,
            };
        },
        setCustomerSignInState(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isSignedIn: action.payload,
            };
        },
    },
});

export const { customerLoggedIn, customerLoggedOut, setCustomerSignInState } = customerSlice.actions;

export default customerSlice.reducer;
