import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RoutesState {
    'Frontend.Master.Cart': string;
    'Frontend.Master.Checkout': string;
    'Frontend.Master.CheckoutSuccess': string;
    'Frontend.Master.OrderCreate': string;
    'Frontend.Master.OrderPaymentAbort': string;
    'Frontend.Master.ErrorPayment': string;
    [key: string]: string;
}

const initialState: RoutesState = {
    'Frontend.Master.Cart': '/cart',
    'Frontend.Master.Checkout': '/checkout',
    'Frontend.Master.CheckoutSuccess': '/checkoutsuccess',
    'Frontend.Master.OrderCreate': '/checkoutsuccess',
    'Frontend.Master.OrderPaymentAbort': '/checkout?step=1&interaction=USER_CANCELLATION',
    'Frontend.Master.ErrorPayment': '/checkout?step=2&error=PAYMENT_FAILURE',
};

/**
 * Reducer containing all known routes.
 * @param state
 * @param action
 */
const routesSlice = createSlice({
    name: 'routes',
    initialState,
    reducers: {
        routesReceived(state, action: PayloadAction<{ routes: Record<string, string> }>) {
            return {
                ...state,
                ...action.payload.routes,
            };
        },
    },
});

export default routesSlice.reducer;
