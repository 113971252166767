import { Helmet } from 'react-helmet-async';
import { useStore } from 'react-redux';
import { useProject } from '../hooks/useProject';
import { useShopContext } from '../hooks/useShopContext';
import Usercentrics from './Usercentrics';
import TagManager from './TagManager';
import ComponentInjector from '../component-injector';
import NewRelic from './NewRelic';
import CSSVariables from './CSSVariables';
import { useChangedLocation } from '../hooks/useChangedLocation';
import { EMPTY_STRING, SKIP_RENDER } from '../constants/semanticConstants';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import GoogleSiteVerification from './GoogleSiteVerification';
import { EmarsysWebExtend } from './Emarsys';
import { routeChanged } from '../store/reducer/context';
import { useChannelSettings } from '../bloomreach/useChannelSettings';
import { useBrxPageContext } from '../bloomreach/useBrxPageContext';
import EventTracking from './EventTracking';

export default function PageMetadata(): JSX.Element {
    const { formatLinkBase } = useReactRouterLink();
    const { locale, route } = useShopContext(true);
    const originalUrl = useShopContext()?.location?.href;
    const project = useProject();
    const store = useStore();
    const homeLink = formatLinkBase(EMPTY_STRING);
    const baseUrl = originalUrl.split(homeLink);
    const startPageUrl = baseUrl && baseUrl.length > 0 ? baseUrl[0].concat(homeLink) : undefined;
    const originalUrlWithoutQueryParams = originalUrl?.split('?')[0];

    const { googleSiteVerification, usercentricsId, channelPreviewImage, channelPreviewImageAltText } =
        useChannelSettings();

    const { bloomreachImageEndpoint: bloomreachImageUrl } = useProject();

    const brPageContext = useBrxPageContext();
    const title = brPageContext.getTitle();

    // Try to import the Meta-Component of the theme
    const ThemeMeta = ComponentInjector.tryGet('pattern.template.Meta');

    /**
     * Update the context-route only when the location changes.
     * For initial firing, we also check if the route already provided by SSR
     * is different to the location, and only update if when they are different.
     */
    useChangedLocation((location) => {
        const isSameRoute = location.pathname === route;

        if (location.pathname && !isSameRoute) {
            store.dispatch(
                routeChanged({
                    previousRoute: route,
                    route: location.pathname,
                    url: window.location.href,
                })
            );
        }
    });

    return (
        <>
            <Helmet>
                <html lang={locale} translate="no" />
                <title>{title}</title>
                <link rel="canonical" href={originalUrlWithoutQueryParams} />
                {startPageUrl && startPageUrl === originalUrl ? (
                    <link rel="alternate" hrefLang="x-default" href={startPageUrl} />
                ) : (
                    SKIP_RENDER
                )}
                {project?.bloomreachImageEndpoint ? (
                    <link rel="preconnect" href={new URL(project.bloomreachImageEndpoint).origin} />
                ) : (
                    SKIP_RENDER
                )}

                {/** Open Graph tags */}
                {channelPreviewImage && (
                    <meta property="og:image" content={`${bloomreachImageUrl}/site/binaries${channelPreviewImage}`} />
                )}
                {channelPreviewImageAltText && <meta property="og:image:alt" content={channelPreviewImageAltText} />}
            </Helmet>

            <CSSVariables />

            <GoogleSiteVerification content={googleSiteVerification} />

            <NewRelic />

            <EventTracking />

            {project.emarsysMerchantId && <EmarsysWebExtend merchantId={project.emarsysMerchantId} />}
            {usercentricsId && <Usercentrics usercentricsSettingsID={usercentricsId} />}
            {project.gtmContainer && <TagManager gtmContainer={project.gtmContainer} />}
            {/**
             * Helmet allows its children to only be native html-elements.
             * So the ThemeMeta-Component has to contain its own Helmet-Component.
             */}
            {ThemeMeta ? <ThemeMeta /> : null}
        </>
    );
}
