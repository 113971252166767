import { useCallback, type ChangeEvent, type ReactNode } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import classNames from 'classnames';
import { BaseProps } from '@mediashop/app/bloomreach/types';

const componentName = 'radio-button';

type RadioButtonProps = BaseProps & {
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    defaultChecked: boolean;
    name: string;
    children: ReactNode;
    value: string;
    required?: boolean;
    setIsChecked?: (isChecked: boolean) => void;
    error?: boolean;
};

function RadioButton({
    onChange,
    defaultChecked,
    value,
    name,
    className,
    children,
    required,
    setIsChecked,
    error = false,
    ...props
}: RadioButtonProps) {
    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked && setIsChecked) {
                setIsChecked(true);
            }

            if (onChange) {
                onChange(event);
            }
        },
        [onChange]
    );

    return (
        <label className={classNames(componentName, className)}>
            <div className={classNames(`${componentName}__label`, { [`${componentName}--error`]: error })}>
                <input
                    type="radio"
                    onChange={handleChange}
                    className={`${componentName}__input`}
                    name={name}
                    value={value}
                    checked={defaultChecked}
                    required={required}
                    {...props}
                />
                <span className={`${componentName}__button`} data-testid={`radio-${name}-${value}`} />
            </div>

            <span
                className={classNames(`${componentName}__text`, {
                    [`${className}-button-text`]: className,
                    [`${componentName}__error-text`]: error,
                })}
            >
                {children}
            </span>
        </label>
    );
}

export default injectComponent('pattern.atom.RadioButton', RadioButton);
