import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BackgroundProps, BaseProps, BrxHeadlines, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const LogoSliderMolecule = lazy(() => lazyRetry(() => import('./LogoSlider')));

export type LogoSliderProps = BaseProps & {
    background: BackgroundProps;
    sliderStyle: 'continuous' | 'paginated';
    headlines: BrxHeadlines;
    logos: BrxImageWithAltText[];
};

const LogoSlider = (props: LogoSliderProps): JSX.Element => (
    <LazyComponent>
        <LogoSliderMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.LogoSlider', LogoSlider);
