import { useAppSelector } from '../store/hooks/hooks';
import { ProjectStateFinished } from '../store/reducer/project';
import { shallowEqual } from 'react-redux';

/**
 * Hook that returns project data from store.
 * @returns project
 */
export const useProject = (equalityCheck = false): ProjectStateFinished => {
    return useAppSelector((state) => state.project as ProjectStateFinished, {
        equalityFn: equalityCheck ? shallowEqual : undefined,
    });
};
