import { ComponentType } from 'react';
import getComponentDisplayName from '../helper/getComponentDisplayName';
import ComponentInjector from './';
import { BaseProps } from '../bloomreach/types';

export default function injectComponent<P extends BaseProps>(
    name: string,
    Component: ComponentType<P>,
    themeName = 'base'
): ComponentType<P> {
    ComponentInjector.set(name, Component, themeName);

    const Injector = function Injector(props) {
        const RealComponent = ComponentInjector.get(name);
        return <RealComponent {...props} />;
    };

    Object.defineProperties(Injector, {
        displayName: {
            get() {
                const componentTheme = ComponentInjector.getThemeNameForComponent(name);
                const componentName = getComponentDisplayName(Component, name);
                return `theme:${componentTheme}(${componentName})`;
            },
        },
        propTypes: {
            get() {
                // Find first propTypes in the inheritance-path
                const componentThemes = ComponentInjector.getThemeNamesForComponent(name);
                return componentThemes
                    .map((theme) => ComponentInjector.registry[theme][name])
                    .find((component) => Boolean(component.propTypes))?.propTypes;
            },
        },
        defaultProps: {
            get() {
                return ComponentInjector.get(name).defaultProps;
            },
        },
        inner: {
            get() {
                return Component;
            },
        },
    });

    return Injector;
}
