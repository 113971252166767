import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CheckoutSummaryPrivacyMolecule = lazy(() => lazyRetry(() => import('./SummaryPrivacy')));

const CheckoutSummaryPrivacy = (props: BaseProps): JSX.Element => (
    <LazyComponent>
        <CheckoutSummaryPrivacyMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.Checkout.Summary.Privacy', CheckoutSummaryPrivacy);
