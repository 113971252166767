import { useAppSelector } from '../store/hooks/hooks';
import { ContextState } from '../store/reducer/context';
import { shallowEqual } from 'react-redux';

/**
 * Hook that returns context data from store.
 * @returns context
 */
export const useShopContext = (equalityCheck = false): ContextState => {
    return useAppSelector((state) => state.context, {
        equalityFn: equalityCheck ? shallowEqual : undefined,
    });
};
