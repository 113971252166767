import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';

const AccordionPDPMolecule = lazy(() => lazyRetry(() => import('./AccordionPDP')));

const AccordionPDP = (props): JSX.Element => (
    <LazyComponent>
        <AccordionPDPMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.AccordionPDP', AccordionPDP, 'catalog-base');
