import { matchesSkuRegex } from './skuRegexMatch';

const uuidRegex = /\b[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\b/;

export const notCommented = (id: string) => !id.includes('#');

export const isUUIDOrSKUSequence = (input: string): boolean => {
    const parts = input.split('_');
    return parts.every((part) => uuidRegex.test(part) || matchesSkuRegex(part));
};

export const sanitizeId = (id: string): string[] => {
    const cleanedId = id.slice(id.indexOf('|') + 1).replaceAll(/\s/g, '');

    if (isUUIDOrSKUSequence(cleanedId)) {
        return cleanedId.split('_');
    }

    return [cleanedId];
};

export const cleanArray = (productIDs: string[]) =>
    productIDs.filter(notCommented).reduce((allIds: string[], id) => [...allIds, ...sanitizeId(id)], []);

/**
 * Function to remove the comment, separated by | from the ID-string.
 * @param productIDs accepts string and string[], returns input type.
 */
export const sanitizeProductIds = <T extends string | Array<string> | undefined>(productIDs: T): Array<string> => {
    if (typeof productIDs === 'string') {
        return sanitizeId(productIDs);
    }

    return cleanArray(productIDs ?? []);
};
