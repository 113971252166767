import { useQuery } from '@tanstack/react-query';
import { CustomerV2 } from '../../api/types/ClientCustomer';
import { RETRY_ONCE } from '../../constants/queryConstants';
import { QueryKeys } from '../../constants/queryKeys';
import { StorageKeys } from '../../constants/storageKeys';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { useLoader } from '../../store/loader';
import { customerLoggedOut } from '../../store/reducer/customer';
import { useEffect } from 'react';

/**
 * Hook that returns data of logged in customer.
 * @returns customer
 */
export const useCustomer = (): CustomerV2 | undefined => {
    const { customerClient } = useLoader();
    const dispatch = useAppDispatch();
    const { isSignedIn } = useAppSelector((state) => state.customer);

    const getCustomer = () => customerClient.getCustomer();

    const { data: customer, isError } = useQuery({
        queryKey: [QueryKeys.FETCH_CUSTOMER],
        queryFn: getCustomer,
        enabled: isSignedIn === true,
        retry: RETRY_ONCE,
    });

    useEffect(() => {
        if (isError) {
            // Remove customer data on error (probably expired session)
            localStorage.removeItem(StorageKeys.CUSTOMER_DATA);
            dispatch(customerLoggedOut());
        }
    }, [dispatch, isError]);

    return customer;
};
