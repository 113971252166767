import { Money } from '@mediashop/app/api/types/Money';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { FormattedMessage } from 'react-intl';
import UnitPrice from '../../atom/UnitPrice';
import StrikeThroughPrice from '../../atom/StrikeThroughPrice';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { CartLineItemCustomFields } from '@mediashop/app/api/types/ClientCart';
import { Price } from '../Price';

export interface PriceBoxProps {
    className?: string;
    variant: Variant;
    size?: 'small' | 'medium' | 'large';
    alignment?: 'left' | 'right';
    custom?: CartLineItemCustomFields;
    oldPricePrefix?: string;
    lineItemPrice?: Variant['price'];
    priceClassName?: string;
    installmentPrice?: Money;
}

const getStrikeThroughPrice = ({
    custom,
    variant,
    componentName,
}: {
    custom?: PriceBoxProps['custom'];
    variant: PriceBoxProps['variant'];
    componentName: string;
}): JSX.Element | null => {
    if (custom) {
        const goodPrice = custom.originalPrice?.centAmount !== 0 ? custom.originalPriceType : undefined;
        if (!goodPrice) {
            return null;
        }
        return (
            <div className={`${componentName}__old-price`}>
                {Object.keys(goodPrice).length > 0 ? <StrikeThroughPrice price={custom.originalPrice!} /> : null}
            </div>
        );
    } else if (variant && variant.originalPrice) {
        return (
            <div className={`${componentName}__old-price`}>
                {Object.keys(variant.originalPrice).length > 0 ? (
                    <StrikeThroughPrice price={variant.originalPrice} />
                ) : null}
            </div>
        );
    }
    return null;
};

/**
 * Shows the prices and attributes in a box to the user and contains units
 */
function PriceBox({
    className = '',
    variant,
    size = 'medium',
    alignment = 'right',
    custom,
    lineItemPrice,
    priceClassName = '',
    installmentPrice,
}: PriceBoxProps) {
    const componentName = 'price-box';

    return (
        <div
            className={classNames(
                className,
                componentName,
                `${componentName}--${size}`,
                `${componentName}--align-${alignment}`
            )}
        >
            {getStrikeThroughPrice({ custom, variant, componentName })}
            <div
                className={classNames(`${componentName}__price`, {
                    'price-box__price--rate': Boolean(installmentPrice),
                })}
            >
                <span className={priceClassName}>
                    <Price price={lineItemPrice ?? variant.price} />
                </span>

                {installmentPrice && installmentPrice.centAmount > 0 ? (
                    <span className={classNames(`${componentName}__total-installment-price`, priceClassName)}>
                        <FormattedMessage
                            id="product.badges.installments"
                            values={{
                                count: installmentPrice.installments,
                            }}
                        />{' '}
                        <Price price={installmentPrice} />
                    </span>
                ) : (
                    SKIP_RENDER
                )}
            </div>
            {variant.attributes.referenceUnit && (
                <div className={`${componentName}__unit-price`}>
                    <UnitPrice variant={variant} />
                </div>
            )}
        </div>
    );
}

export default injectComponent('pattern.molecule.PriceBox', PriceBox);
