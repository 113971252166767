import { useRestrictedAccess } from '@mediashop/app/hooks/useRestrictedAccess';
import { useSubscriptions } from '@mediashop/app/queries/customer/useSubscriptions';
import { useProductsBySku } from '@mediashop/app/queries/products/useProductsBySku';
import Headlines from '@mediashop/base/pattern/atom/Headlines';
import LoadingIndicator from '@mediashop/base/pattern/atom/LoadingIndicator';
import { SubscriptionManagementProps } from '.';
import { SubscriptionManagementProduct } from './SubscriptionProduct/SubscriptionProduct';

const componentName = 'subscription-management';

const SubscriptionManagement = ({ headline }: SubscriptionManagementProps) => {
    const isAuthenticated = useRestrictedAccess();

    const { data: subscriptionResponse, isPending: isLoading } = useSubscriptions({
        enabled: isAuthenticated,
    });

    const subscriptionSkus = subscriptionResponse?.subscriptions.map((subscription) => subscription.sku) || [];
    const { data: productsBySku } = useProductsBySku(subscriptionSkus.length > 0, subscriptionSkus);

    const findProduct = (sku: string) =>
        productsBySku?.find((product) => product.variants.find((variantProduct) => variantProduct.sku === sku));

    return (
        <>
            <Headlines {...headline} className={`${componentName}__headlines`} />

            {isLoading ? (
                <LoadingIndicator className={`${componentName}__loading-indicator`} />
            ) : (
                <>
                    {subscriptionResponse?.subscriptions.map((subscription) => (
                        <SubscriptionManagementProduct
                            key={subscription.subscriptionId}
                            subscription={subscription}
                            product={findProduct(subscription.sku)}
                        />
                    ))}
                </>
            )}
        </>
    );
};

export default SubscriptionManagement;
