import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxCTAButton, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CrossSellingBannerMolecule = lazy(() => lazyRetry(() => import('./CrossSellingBanner')));

export type CrossSellingBannerProps = BaseProps & {
    callToActionButton: BrxCTAButton;
    image: BrxImageWithAltText;
    headline: string;
};

const CrossSellingBanner = (props: CrossSellingBannerProps): JSX.Element => (
    <LazyComponent>
        <CrossSellingBannerMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.CrossSellingBanner', CrossSellingBanner);
