import { Helmet } from 'react-helmet-async';
import { EmarsysCommand } from '../../analytics/emarsys/commants';
import { triggerEmarsysEvent } from '../../analytics/emarsys/triggerEmarsysEvent';
import { useCmpServiceConsent } from '../../hooks/cmp/useCmpServiceConsent';
import { scriptContent } from './script';
import { useNonce } from '../../hooks/useNonce';

const EMARSYS_CMP_SERVICE_NAME = 'Emarsys';

type EmarsysWebExtendProps = {
    merchantId: string;
};

export function EmarsysWebExtend({ merchantId }: EmarsysWebExtendProps): JSX.Element | null {
    const onEmarsysConsent = () => {
        triggerEmarsysEvent({ command: EmarsysCommand.GO });
    };

    useCmpServiceConsent({
        serviceName: EMARSYS_CMP_SERVICE_NAME,
        callbackFn: onEmarsysConsent,
    });

    const { nonce } = useNonce();

    return (
        <Helmet>
            <script nonce={nonce}>EMARSYS_GA_DISABLED=true</script>
            <script nonce={nonce} type="text/plain" data-usercentrics={EMARSYS_CMP_SERVICE_NAME}>
                {scriptContent(merchantId)}
            </script>
        </Helmet>
    );
}
