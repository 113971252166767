import { lazy } from 'react';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const MultiPageCheckoutStepsMolecule = lazy(() => lazyRetry(() => import('./Steps')));

export interface MultiPageCheckoutStepsProps {
    activeStep: number;
    changeStep: (step: number) => void;
    goToPaymentStep: () => void;
}

const MultiPageCheckoutSteps = (props: MultiPageCheckoutStepsProps): JSX.Element => (
    <LazyComponent>
        <MultiPageCheckoutStepsMolecule {...props} />
    </LazyComponent>
);

export default MultiPageCheckoutSteps;
