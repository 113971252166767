import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCategorySlugsFromUrl } from '../helper/categoryHelpers';

const useGetCategoryPath = () => {
    const { pathname } = useLocation();
    const [categoryPath, setCategoryPath] = useState<string | null>(null);

    useEffect(() => {
        const slug = getCategorySlugsFromUrl(new URL(window.location.href));
        let catPath = `${slug?.categorySlug}`;

        if (slug?.subcategorySlug) {
            catPath = `${catPath}/${slug.subcategorySlug}`;
        }

        setCategoryPath(catPath);
    }, [pathname]);

    return categoryPath;
};

export default useGetCategoryPath;
