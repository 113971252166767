import { ForwardedRef, ReactElement } from 'react';

const componentName = 'tab';

export type TabProps = {
    title: string;
    children: ReactElement;
    value: string | number;
    tabRef?: ForwardedRef<HTMLDivElement>;
};

const Tab = ({ children }: TabProps): ReactElement => <div className={componentName}>{children}</div>;

export default Tab;
