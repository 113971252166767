import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CheckoutSummaryLegalMolecule = lazy(() => lazyRetry(() => import('./SummaryLegal')));

const CheckoutSummaryLegal = (props: BaseProps): JSX.Element => (
    <LazyComponent>
        <CheckoutSummaryLegalMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.Checkout.Summary.Legal', CheckoutSummaryLegal);
