import { injectComponent } from '@mediashop/app/component-injector';
import { lazy } from 'react';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { BaseProps, BrxAnchor, BrxImageWithAltText, BrxTeaserBoxCompound } from '@mediashop/app/bloomreach/types';

const ImageBannerLazy = lazy(() => lazyRetry(() => import('./ImageBanner')));

export type ImageBannerBorder = Partial<{
    color: string;
    width: number;
}>;

export type ImageBannerShadow = {
    blur: number;
    color: string;
    offset: number;
};

export type ImageBannerProps = BaseProps & {
    anchor?: BrxAnchor;
    backgroundColor: string;
    borderBottom: ImageBannerBorder;
    borderLeft: ImageBannerBorder;
    borderRight: ImageBannerBorder;
    borderTop: ImageBannerBorder;
    desktopImage: BrxImageWithAltText;
    mobileImage: BrxImageWithAltText;
    style: 'banner' | 'contentBanner';
    link: string;
    shadowBottom: ImageBannerShadow;
    shadowTop: ImageBannerShadow;
    tab: 'sameTab' | 'newTab';
    id: string;
    teaserBox?: BrxTeaserBoxCompound;
};

const ImageBanner = (props: ImageBannerProps): JSX.Element => (
    <LazyComponent>
        <ImageBannerLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ImageBanner', ImageBanner);
