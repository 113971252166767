import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { Cart } from '@mediashop/app/api/types/ClientCart';
import { BaseProps, BrxImageWithTextProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { PaymentMeta } from '@mediashop/app/api/types/Payment';

const SummaryMolecule = lazy(() => lazyRetry(() => import('./Summary')));

export interface CheckoutSummaryForm {
    comment?: string;
    subscribeToNewsletter?: boolean;
}

export type CheckoutSummarySubmitHandle = {
    submit: () => void;
};

export type MultiPageCheckoutSummaryProps = BaseProps & {
    cart: Cart;
    goToAddress: () => void;
    goToPayment: () => void;
    isLoading: boolean;
    onCheckoutSubmit: (summaryFormValues: CheckoutSummaryForm) => void;
    payment?: PaymentMeta;
    selectedBillingCountry: string;
    usps: BrxImageWithTextProps[];
};

const MPCheckoutSummary = (props: MultiPageCheckoutSummaryProps): JSX.Element => (
    <LazyComponent>
        <SummaryMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.MPCheckout.MPCheckoutSummary', MPCheckoutSummary);
