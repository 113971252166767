import { useCallback, useState } from 'react';
import groupBy from 'lodash/groupBy';
import { BrxRelationType } from '@mediashop/app/bloomreach/types';
import { Product, Variant } from '@mediashop/app/api/types/ClientProduct';
import { ProductRelation, ProductWithRelation } from '../types/productRelations';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import { NeededPriceType } from '@mediashop/app/api/types/PriceType';
import { getRelatedProductIds } from '../utils/getRelatedProductIds';
import { useLoader } from '@mediashop/app/store/loader';

const removeDuplicates = (product: Product, index: number, self: Product[]) =>
    index === self.findIndex((prod) => prod.id === product.id);

type HookProps = {
    relationType?: BrxRelationType;
    useStandardPrice?: boolean;
    variant?: Variant;
};

type ReturnType = {
    isLoading: boolean;
    relatedProducts: ProductWithRelation[];
    triggerRelations: () => void;
};

export const useCommerceToolsProductRelations = ({
    relationType,
    useStandardPrice,
    variant,
}: HookProps): ReturnType => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [relatedProducts, setRelatedProducts] = useState<ProductWithRelation[]>([]);
    const { productClient } = useLoader();
    const getProductRelations = useCallback(
        async (productRelations: ProductRelation[], selectedVariant: Variant) => {
            try {
                const relatedSku = selectedVariant?.sku ?? EMPTY_STRING;

                const productsPerRequestedPriceType: Partial<Record<NeededPriceType, ProductRelation[]>> = groupBy(
                    productRelations,
                    (product) => product.neededPriceType
                );

                const productsPerRequest = await Promise.all(
                    Object.entries(productsPerRequestedPriceType).map(async ([priceType, products]) => {
                        const productIds = products.map((product) => product.id);
                        const neededPriceType = useStandardPrice ? 'STANDARD' : (priceType as NeededPriceType);

                        const productsResponse = await productClient.getProductsById(
                            productIds,
                            neededPriceType,
                            relatedSku
                        );

                        // Sort products as the query does not return the items in the passed order
                        return productsResponse.sort(
                            (prod1, prod2) => productIds.indexOf(prod1.id) - productIds.indexOf(prod2.id)
                        );
                    })
                );

                // Flat map and remove duplicate products
                const products = productsPerRequest.flat().filter(removeDuplicates);

                const processedProducts: ProductWithRelation[] = products.map((product) => ({
                    ...product,
                    relationType: productRelations.find((rel) => rel.id === product.id)?.relationType,
                }));

                setRelatedProducts(processedProducts);
            } finally {
                setIsLoading(false);
            }
        },
        [productClient, useStandardPrice]
    );

    const triggerRelations = useCallback(() => {
        if (variant) {
            setIsLoading(true);

            const relatedProductIDs = getRelatedProductIds(variant, relationType);

            if (relatedProductIDs?.length) {
                getProductRelations(relatedProductIDs, variant);
            } else {
                setRelatedProducts([]);
                setIsLoading(false);
            }
        }
    }, [getProductRelations, variant, relationType]);

    return {
        isLoading,
        relatedProducts,
        triggerRelations,
    };
};
