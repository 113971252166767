import { useMemo, type ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Category } from '@mediashop/app/api/types/ClientCategory';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import Select from '@mediashop/base/pattern/atom/Select';
import { BaseProps } from '@mediashop/app/bloomreach/types';

const componentName = 'subcategory-filter';

type Props = BaseProps & {
    parentCategory?: Category;
    selectedSubcategoryId: string;
    onChange: (subcategory: { id: string; slug: string }) => void;
    onReset: () => void;
};

export function SubcategoryFilter({
    parentCategory,
    selectedSubcategoryId,
    onChange,
    onReset,
}: Props): JSX.Element | null {
    const { formatMessage } = useIntl();

    const subcategoryOptions = useMemo(
        () =>
            parentCategory?.children?.map((subcategory) => ({
                text: subcategory.name,
                value: subcategory.slug,
                id: subcategory.id,
            })) ?? [],
        [parentCategory]
    );

    const onSubcategoryChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value: slug } = event.target;

        const subcategory = subcategoryOptions.filter((cat) => cat.value === slug)[0];

        onChange({ id: subcategory?.id ?? '', slug: subcategory?.value ?? '' });
    };

    return subcategoryOptions.length > 0 ? (
        <div className={`${componentName}__container`}>
            <Select
                value={selectedSubcategoryId}
                onChange={onSubcategoryChange}
                className={`${componentName}__dropdown`}
            >
                {/** Default option "all" */}
                <option value="">{formatMessage({ id: 'subcategoryFilter.all' })}</option>

                {/** Subcategories */}
                {subcategoryOptions.map(({ text, value }) => (
                    <option key={value} value={value}>
                        {text}
                    </option>
                ))}
            </Select>

            {/** Reset button */}
            {selectedSubcategoryId ? (
                <span role="button" className={`${componentName}__reset`} onClick={onReset}>
                    <FormattedMessage id="subcategoryFilter.reset" />
                </span>
            ) : (
                SKIP_RENDER
            )}
        </div>
    ) : (
        SKIP_RENDER
    );
}
