import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { Cart } from '@mediashop/app/api/types/ClientCart';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const SPLPCartMolecule = lazy(() => lazyRetry(() => import('./SPLPCart')));

export type SPLPCartProps = BaseProps & {
    cart: Cart;
    isLoading: boolean;
    stickySummary: boolean;
};

const SPLPCart = (props: SPLPCartProps): JSX.Element => (
    <LazyComponent>
        <SPLPCartMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.SPLPCart', SPLPCart);
