import { Page } from '@bloomreach/spa-sdk';
import { extractProductReferences } from '../product/extractProductReferences';

export const BrxUtils = {
    /**
     * Extract all product references from the current page
     * @param page BrX page
     * @param pathname location path name
     * @returns list of product references
     */
    extractProductReferences(page: Page, pathname: string) {
        const json = page.toJSON();
        return extractProductReferences(json.page, { pathname });
    },
    /**
     * Find a component by it's class in the page model
     * @param page BrX page
     * @param componentClass component class
     * @returns component or undefined
     */
    getComponentByClass(page: Page, componentClass: string) {
        const json = page.toJSON();
        const component: any = Object.values(json.page).find(
            (component: any) => component.componentClass === componentClass
        );

        return page.getComponent().getComponentById(component?.id);
    },
    /**
     * Find document by content type
     * @param page BrX page
     * @param contentType document content type
     * @returns first found document or undefined
     */
    getDocumentByContentType(page: Page, contentType: string) {
        const json: { page: Record<string, any> } = page.toJSON();

        const entryWithContentType = Object.entries(json.page).find(
            ([, value]) => value.data?.contentType === contentType
        );

        if (entryWithContentType) {
            const ref = entryWithContentType[0];
            return page.getContent(ref);
        }

        return undefined;
    },
};
