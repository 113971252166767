import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import Modal from '../Modal';
import ModalDiscount from '../../atom/ModalDiscount';
import { VoucherRowDiscountType } from '@mediashop/app/api/types/Discount';

const componentName = 'discount-information-modal';

type DiscountInformationModalProps = BaseProps & {
    discount: VoucherRowDiscountType;
    showModal: boolean;
    setShowModal: (show: boolean) => void;
};

/**
 * Displays a modal dialog that shows information about a discount
 * @returns Modal dialog component
 */
function DiscountInformationModal({ discount, showModal, setShowModal }: DiscountInformationModalProps) {
    return (
        <Modal
            className={componentName}
            isOpen={showModal}
            onRequestClose={() => setShowModal(!showModal)}
            overlayClassName="modal__overlay-bg"
        >
            <ModalDiscount discount={discount} showValidity={discount.state !== 'MatchesCart'} alwaysShowLegalText />
        </Modal>
    );
}

export default injectComponent('pattern.molecule.DiscountInformationModal', DiscountInformationModal);
