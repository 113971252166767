import { QueryErrorV2 } from './types/Query';

export class ApiErrorV2<T = string | QueryErrorV2> extends Error {
    public error: QueryErrorV2<T>;

    constructor(message: string, error: QueryErrorV2<T>) {
        super(message);
        this.error = error;
    }
}
