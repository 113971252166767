import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { ProductBundle } from '@mediashop/app/bloomreach/product/types/ProductBundle';
import { BackgroundColor, BaseProps, HeadlineTextStyleProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const StickyButtonMolecule = lazy(() => lazyRetry(() => import('./StickyButton')));

export type StickyButtonProps = BaseProps & {
    background: BackgroundColor;
    callToActionText: string;
    product: ProductBundle;
    position: 'top' | 'bottom';
    textStyle: HeadlineTextStyleProps;
    anchorId: string;
};

const StickyButton = (props: StickyButtonProps): JSX.Element => (
    <LazyComponent>
        <StickyButtonMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.StickyButton', StickyButton);
