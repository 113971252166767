import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Cart, InstallmentPlanProps } from '../../api/types/ClientCart';
import { orderCreated } from './order';

export interface CartState {
    status: 'unloaded' | 'loading' | 'succeeded' | 'error';
    cart?: Cart;
    installmentPlan?: InstallmentPlanProps;
}

const initialState: CartState = {
    status: 'unloaded',
    cart: undefined,
    installmentPlan: undefined,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        cartReceived(state, action: PayloadAction<{ cart: Cart }>) {
            const { cart } = action.payload;
            return {
                ...state,
                status: 'succeeded',
                cart,
            };
        },
        cartEmptyReceived(state) {
            return {
                ...state,
                status: 'succeeded',
            };
        },
        loadingCartStarted(state) {
            return {
                ...state,
                status: 'loading',
            };
        },
        loadingCartFailed(state) {
            return {
                ...state,
                status: 'error',
                cart: state.cart,
            };
        },
        cartReset() {
            return {
                status: 'succeeded',
            };
        },
        installmentPlanReceived(state, action: PayloadAction<{ installmentPlan: InstallmentPlanProps }>) {
            const { installmentPlan } = action.payload;
            return {
                ...state,
                installmentPlan,
            };
        },
        installmentPlanReset(state) {
            return {
                ...state,
                installmentPlan: undefined,
            };
        },
        lineItemAdded(
            state,
            _action: PayloadAction<{
                sku: string;
                quantity: number;
                partner: string | undefined;
                facebookTrackingId: string;
            }>
        ) {
            return state;
        },
    },
    extraReducers: (builder) => {
        /** Reset cart on orderCreated event */
        builder.addCase(orderCreated, (state) => {
            return {
                ...state,
                cart: undefined,
            };
        });
    },
});

export const {
    cartReceived,
    cartEmptyReceived,
    cartReset,
    installmentPlanReceived,
    installmentPlanReset,
    lineItemAdded,
    loadingCartFailed,
    loadingCartStarted,
} = cartSlice.actions;

export default cartSlice.reducer;
