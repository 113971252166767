import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxCTAButton, BrxHeadlines, BrxLink } from '@mediashop/app/bloomreach/types';
import { TextCompoundProps } from '@mediashop/base/pattern/atom/TextCompound';

export type PasswordForgottenProps = BaseProps & {
    headline: BrxHeadlines;
    link: BrxLink;
    passwordResetButton: BrxCTAButton;
    textCompound: TextCompoundProps;
};

import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const PasswordForgottenMolecule = lazy(() => lazyRetry(() => import('./PasswordForgotten')));

const PasswordForgotten = (props: PasswordForgottenProps): JSX.Element => (
    <LazyComponent>
        <PasswordForgottenMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.PasswordForgotten', PasswordForgotten);
