import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy, ReactElement } from 'react';
import { MainNavigationCustomNavigationChildItemProps } from '../../../types';

const BurgerMenuSubNavigationLazy = lazy(() => lazyRetry(() => import('./BurgerMenuSubNavigation')));

export type BurgerMenuSubNavigationProps = BaseProps & {
    closeBurgerMenu: () => void;
    isOpen: boolean;
    subNavigation: Array<MainNavigationCustomNavigationChildItemProps>;
};

const BurgerMenuSubNavigation = (props: BurgerMenuSubNavigationProps): ReactElement => (
    <LazyComponent>
        <BurgerMenuSubNavigationLazy {...props} />
    </LazyComponent>
);

export default BurgerMenuSubNavigation;
