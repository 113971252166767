import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { Cart } from '../../api/types/ClientCart';
import { QueryKeys } from '../../constants/queryKeys';
import { EMPTY_STRING } from '../../constants/semanticConstants';
import { useAppDispatch } from '../../store/hooks/hooks';
import { useLoader } from '../../store/loader';
import { cartEmptyReceived, cartReceived } from '../../store/reducer/cart';
import { useShopContext } from '../useShopContext';
import { useEffect } from 'react';

const buildQueryKey = (country: string, pathName: string) => [QueryKeys.FETCH_CART, country, pathName].filter(Boolean);

interface UseCartProps {
    doRefetchOnPageChange?: boolean;
}

/**
 * Fetch cart.
 * @returns cart
 */
export const useCart = ({ doRefetchOnPageChange = false }: UseCartProps = {}) => {
    const { cartClient } = useLoader();
    const { pathname } = useLocation();

    const dispatch = useAppDispatch();
    const { country } = useShopContext();

    const fetchCart = () => cartClient.getCart();
    const onSuccess = (cart: Cart | null) => {
        if (!cart) {
            dispatch(cartEmptyReceived());
        } else {
            dispatch(cartReceived({ cart }));
        }
    };

    const query = useQuery({
        queryKey: buildQueryKey(country, doRefetchOnPageChange ? pathname : EMPTY_STRING),
        queryFn: fetchCart,
        refetchOnWindowFocus: 'always',
        staleTime: 0,
        gcTime: 0,
    });

    const { isError, isSuccess, isPending, data, error } = query;
    useEffect(() => {
        if (isSuccess && !isPending) {
            onSuccess(data);
        } else if (isError) {
            console.error('CartLoader', error);
        }
    }, [isError, isSuccess, isPending, data, error]);

    return query;
};
