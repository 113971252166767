import { useProduct } from '@mediashop/app/hooks/useProduct';
import BloomreachImageResponsive from '@mediashop/base/pattern/atom/BloomreachImageResponsive';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import { injectComponent } from '@mediashop/app/component-injector';
import { SwiperSlide } from 'swiper/react';
import Product from '../Product';
import useIsClientSide from '@mediashop/app/hooks/useIsClientSide';
import Headlines, { HeadlineProps } from '@mediashop/base/pattern/atom/Headlines';
import { BaseProps, BrxImageSetImpl, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import Slider from '@mediashop/base/pattern/molecule/Slider';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { Countdown } from './Countdown';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';
import { sanitizeProductIds } from '@mediashop/app/helper/sanitizeProductIds';

/**
 * Get image depending on device type, fallback to main image
 * @param productDeal product detail element from BrX
 * @param isMobileDevice identifier for mobile device type
 * @returns BrX image object
 */
const getImage = (productDeal: ProductDeal, isMobileDevice: boolean): { image: BrxImageSetImpl; altText?: string } => {
    if (isMobileDevice) {
        return productDeal.mobileImage?.image ? productDeal.mobileImage : { image: productDeal.imagelink };
    }
    return productDeal.desktopImage?.image ? productDeal.desktopImage : { image: productDeal.imagelink };
};

const componentName = 'deal-of-the-day';

interface ProductDeal {
    productId: string;
    date: number;
    imagelink: BrxImageSetImpl;
    mobileImage: BrxImageWithAltText;
    desktopImage: BrxImageWithAltText;
    backgroundColor?: string;
    displayTime: string;
    fontcolor?: string;
    backgroundcolor?: string;
}

type Props = BaseProps & {
    dealOfTheDayList: ProductDeal[];
    headline: HeadlineProps;
};

function DealOfTheDay({ headline, dealOfTheDayList }: Props) {
    /**
     * Only render the countdown on client-side to avoid hydration errors.
     */
    const renderCountdown = useIsClientSide();

    const customFontColor = dealOfTheDayList[0].fontcolor;
    const customBackgroundColor = dealOfTheDayList[0].backgroundcolor;

    const { products } = useProduct();

    const deviceType = useDeviceType();
    const isMobileDevice = deviceType === 'mobile';

    dealOfTheDayList.forEach((deal) => {
        deal.productId = sanitizeProductIds(deal.productId)[0];
    });

    const mappedItems = useMemo(
        () =>
            dealOfTheDayList.map((item) => ({
                displayTimeInSeconds: parseInt(item.displayTime || '3', 10),
                product: products[item.productId]?.product,
                image: getImage(item, isMobileDevice),
                date: item.date,
                backgroundColor: item.backgroundColor,
            })),
        [dealOfTheDayList, products, isMobileDevice]
    );

    const productCount = mappedItems.filter((item) => item.product).length;

    const [currentItem] = useState(mappedItems[0]);

    const getSlideWrapperStyle = (backgroundColor?: string) => {
        const baseStyles = { borderRadius: '20px', padding: '20px' };
        const extra = backgroundColor ? { backgroundColor: `var(--theme-${backgroundColor})` } : {};
        return { ...baseStyles, ...extra };
    };

    return (
        <BackgroundColorWrapper>
            <ContentWrapper>
                <div className={classNames(componentName, { [`${componentName}__has-products`]: productCount > 0 })}>
                    {headline && (
                        <div className={`${componentName}__header`}>
                            <Headlines className={`${componentName}__header-title`} {...headline} />
                        </div>
                    )}
                    <Slider
                        className={componentName}
                        autoplay={{
                            delay: (currentItem?.displayTimeInSeconds ?? 3) * 1000,
                        }}
                        slidesPerView={parseInt('1', 10) || 4}
                        loop
                    >
                        {mappedItems.map(({ product, image, date, backgroundColor }, key) => (
                            <SwiperSlide className={`${componentName}__wrapper`} key={key}>
                                <div style={getSlideWrapperStyle(backgroundColor)}>
                                    {date && renderCountdown ? (
                                        <Countdown
                                            date={date}
                                            customBackgroundColor={customBackgroundColor}
                                            customFontColor={customFontColor}
                                        />
                                    ) : (
                                        SKIP_RENDER
                                    )}

                                    {product ? (
                                        <div className={`${componentName}__product-container`}>
                                            <div className={`${componentName}__product-container-left`}>
                                                <BloomreachImageResponsive
                                                    className={`${componentName}__main-image`}
                                                    alt={image.altText ?? EMPTY_STRING}
                                                    desktopImage={image.image}
                                                    variant={BrXImageVariant.DealOfTheDay}
                                                />
                                            </div>

                                            <Product
                                                key={key}
                                                roundedImages
                                                product={product}
                                                description={product.variants[0].attributes.claim}
                                                backgroundColor="default"
                                                status="succeeded"
                                                dealOfTheDay
                                            />
                                        </div>
                                    ) : (
                                        SKIP_RENDER
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Slider>
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.DealOfTheDay', DealOfTheDay);
