import { useEffect } from 'react';

/**
 * Ignore Newrelic errors globally.
 */
export default function Newrelic() {
    useEffect(() => {
        if (globalThis.newrelic) {
            globalThis.newrelic.setErrorHandler(
                (error) => error.message.includes('Script error.') || error.message.includes('Minified React error')
            );
        }
    });

    return null;
}
