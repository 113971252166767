import { lazy } from 'react';
import { BaseProps, BrxImageWithTextProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CheckoutSummaryUspMolecule = lazy(() => lazyRetry(() => import('./SummaryUsp')));

export interface CheckoutSummaryUspProp extends BaseProps {
    usps: Array<BrxImageWithTextProps>;
}

const CheckoutSummaryUsp = (props: CheckoutSummaryUspProp): JSX.Element => (
    <LazyComponent>
        <CheckoutSummaryUspMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.Checkout.Summary.USP', CheckoutSummaryUsp);
