import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { CreatePaymentDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';

type CreatePaymentData = {
    facebookTrackingId: string;
};

const createGtmDataLayer = (
    event: TagManagerEvent,
    { facebookTrackingId }: CreatePaymentData
): CreatePaymentDataLayer => ({
    dataLayer: {
        event,
        facebookTrackingId,
    },
});

export const dispatchCreatePayment = (data: CreatePaymentData): void => {
    triggerGtmEvent(createGtmDataLayer(ANALYTICS_EVENT.CREATE_PAYMENT, data));
};
