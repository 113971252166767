import { Category } from '@mediashop/app/api/types/ClientCategory';
import { hyphenToUnderscore } from '@mediashop/catalog-base/utils/hyphenToUnderscore';

export type NestedBreadcrumbIndex = Record<string, { name: string; children: NestedBreadcrumbIndex }>;
export type FlatBreadcrumbIndex = Record<string, { name: string; parent: string }>;

export function buildNestedBreadcrumbsIndex(children: Category[] = []): NestedBreadcrumbIndex {
    const index: NestedBreadcrumbIndex = {};

    for (const child of children) {
        if (child.slug) {
            index[hyphenToUnderscore(child.slug)] = {
                name: child.name,
                children: buildNestedBreadcrumbsIndex(child.children),
            };
        }
    }

    return index;
}

export function buildFlatBreadcrumbsIndex(parent: Category, children: Category[]): FlatBreadcrumbIndex {
    let index: FlatBreadcrumbIndex = {};

    for (const child of children) {
        if (child.slug) {
            index[hyphenToUnderscore(child.slug)] = { name: child.name, parent: parent.key };
            const subIndex = buildFlatBreadcrumbsIndex(child, child?.children ?? []);

            index = { ...index, ...subIndex };
        }
    }
    return index;
}
