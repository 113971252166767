import Theme, { MergedTheme } from '../Theme';

export default class ThemeMerger {
    public static visitAncestors(theme: Theme | undefined, callback: (theme: Theme) => void): void {
        if (!theme) {
            return;
        }
        callback(theme);
        this.visitAncestors(theme.extends, callback);
    }

    public static mergeWithAncestors(theme: Theme): MergedTheme {
        const mergedTheme: MergedTheme = {
            name: theme.name,
            path: [theme.name],
            brComponents: theme.brComponents ?? {},
            icons: theme.icons ?? {},
        };

        this.visitAncestors(theme.extends, (ancestor) => {
            mergedTheme.path = [...mergedTheme.path, ancestor.name];
            mergedTheme.brComponents = {
                ...ancestor.brComponents,
                ...mergedTheme.brComponents,
            };
            mergedTheme.icons = {
                ...ancestor.icons,
                ...mergedTheme.icons,
            };
        });
        return mergedTheme;
    }
}
