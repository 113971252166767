import { useRef, useEffect } from 'react';
import noop from 'lodash/noop';

/**
 *
 * With the help of https://usehooks.com/useEventListener/
 * a hook that allows to add events on html elements. It defaults to the window object to attach event listener
 */
export default function useEventListener<T extends Event>(
    eventName: string,
    handler: (e: T) => void,
    element: null | Window | HTMLElement = window
): void {
    const savedHandler = useRef<(e: T) => void>(noop);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const eventListener = (event) => savedHandler.current(event);
        if (element) {
            element.addEventListener(eventName, eventListener);
        }

        return () => {
            if (element) {
                element.removeEventListener(eventName, eventListener);
            }
        };
    }, [eventName, element]);
}
