import { Variant, VariantDimensionValue } from '../api/types/ClientProduct';

/**
 * A single dimension of a product-variant.
 * E.g. size and its options.
 */
interface VariantDimension {
    key: string;
    label: string;
    value: VariantDimensionValue;
    options: VariantDimensionValue[];
}

/**
 * Provided an array of dimensions, returns all variants with matching dimensions.
 */
const getVariantsWithMatchingDimensions = (variants: Variant[], dimensions: VariantDimensionValue[]): Variant[] =>
    variants.filter((variant) =>
        dimensions.every((dimension) =>
            Boolean(
                variant.dimensions.find(
                    (variantDimension) =>
                        variantDimension.name === dimension.name && variantDimension.value === dimension.value
                )
            )
        )
    );

/**
 * Deduplicate an array of dimensions by value.
 */
const getUniqueDimensions = (dimensions: VariantDimensionValue[]): VariantDimensionValue[] => {
    const addedValues: string[] = [];
    return dimensions.filter((dimension) => {
        if (!addedValues.includes(dimension.value)) {
            addedValues.push(dimension.value);
            return true;
        }
        return false;
    });
};

/**
 * Returns all the dimensions of the active variant.
 * Other variants of the same product may have other dimensions.
 */
const getActiveVariantDimensions = (variants: Variant[], activeVariant: Variant): VariantDimension[] =>
    activeVariant.dimensions.map((_, index) => {
        const variantDimensions = getVariantsWithMatchingDimensions(
            variants,
            activeVariant.dimensions.slice(0, index)
        ).map((variant) => variant.dimensions[index]);

        return {
            key: variantDimensions[0].name,
            label: variantDimensions[0].label,
            value: activeVariant.dimensions[index],
            options: getUniqueDimensions(variantDimensions),
        };
    });

/**
 * Fetches all the ariants that have a price defined
 *
 * @param variants
 */
const getApplicableVariants = (variants: Variant[]): Variant[] => variants.filter((variant) => variant.price);

export default {
    getVariantsWithMatchingDimensions,
    getUniqueDimensions,
    getActiveVariantDimensions,
    getApplicableVariants,
};
