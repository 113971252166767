import { toast, ToastOptions } from 'react-hot-toast';

export default function useToast() {
    const toastId = 'fixedToastId';
    const success = (message: string, options?: ToastOptions) => {
        toast.success(message, { ...options, id: toastId });
    };

    const error = (message: string, options?: ToastOptions) => {
        toast.error(message, { ...options, id: toastId });
    };

    return {
        success,
        error,
    };
}
