import { lazy } from 'react';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { BaseProps, BrxUsp } from '@mediashop/app/bloomreach/types';

const USPsItemLazy = lazy(() => lazyRetry(() => import('./USPsItem')));

export type USPsItemProps = BaseProps & {
    usp: BrxUsp;
    fontColorHex?: string;
};

const USPsItem = (props: USPsItemProps): JSX.Element => (
    <LazyComponent>
        <USPsItemLazy {...props} />
    </LazyComponent>
);

export default USPsItem;
