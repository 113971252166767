import { useEffect, useState } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import Modal from '../Modal';
import Button from '@mediashop/base/pattern/atom/Button';
import { useLoader } from '@mediashop/app/store/loader';
import ModalDiscount from '../../atom/ModalDiscount';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { VoucherRowDiscountType } from '@mediashop/app/api/types/Discount';

const componentName = 'voucher-modal';

type NonStackableDiscountModalProps = BaseProps & {
    isOpen: boolean;
    nonStackableDiscounts: VoucherRowDiscountType[];
    onCloseModal?: () => void;
};

/**
 * Displays a modal dialog that allows the user to choose between non-stackable discounts
 * @returns Modal dialog component
 */
function NonStackableDiscountsModal({
    isOpen,
    nonStackableDiscounts,
    onCloseModal,
}: NonStackableDiscountModalProps): JSX.Element | null {
    const { cartLoader } = useLoader();

    const defaultNonStackableDiscount = nonStackableDiscounts?.find(
        (discount) => discount.stackingMode === 'StopAfterThisDiscount'
    );

    const [activeValue, setActiveValue] = useState(defaultNonStackableDiscount?.code);
    const [isLoading, setIsLoading] = useState(false);

    const deleteDiscountCode = async (code?: string) => {
        setIsLoading(true);
        await cartLoader.removeDiscountCode(code ?? 'DC_DELETED');
        setIsLoading(false);
    };

    const filterNonStackableDiscounts = (discountCodeToKeep = defaultNonStackableDiscount?.code) => {
        setActiveValue(discountCodeToKeep);
        if (onCloseModal) {
            onCloseModal();
        }
        nonStackableDiscounts?.forEach((discount) => {
            if (discount.code !== discountCodeToKeep) {
                deleteDiscountCode(discount.code).catch();
            }
        });
    };

    useEffect(() => {
        if (!activeValue) {
            setActiveValue(defaultNonStackableDiscount?.code);
        }
    }, [activeValue, defaultNonStackableDiscount]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => filterNonStackableDiscounts(defaultNonStackableDiscount?.code)}
            overlayClassName="modal__overlay-bg"
            modalBodyClassName={`${componentName}`}
        >
            <div className={classNames(`${componentName}__headline`, 'h2')}>
                <FormattedMessage id="voucher.modal.headline" />
            </div>
            {nonStackableDiscounts?.map((discount) => (
                <ModalDiscount
                    key={discount.code}
                    isActive={discount.code === activeValue}
                    discount={discount}
                    onClick={() => setActiveValue(discount.code)}
                />
            ))}
            <div className={`${componentName}__button-container`}>
                <Button onClick={() => filterNonStackableDiscounts(activeValue)} loading={isLoading}>
                    <FormattedMessage id="countrySwitcher.modal.submitText" />
                </Button>
            </div>
        </Modal>
    );
}

export default injectComponent('pattern.molecule.NonStackableDiscountsModal', NonStackableDiscountsModal);
