import { RefObject, useCallback, useEffect, useState } from 'react';
import noop from 'lodash/noop';

/**
 * Uses the resize observer (polyfill) to check for size changes of a certain reference
 * you can use the returned values height and width to react to a change or
 * you can add a callback which is fired on changes
 */
export const useResizeObserver = (
    ref: RefObject<HTMLElement>,
    callback?: (entry: DOMRectReadOnly) => void
): [number?, number?] => {
    const [width, setWidth] = useState<number>();
    const [height, setHeight] = useState<number>();

    const handleResize = useCallback(
        (entries: ResizeObserverEntry[]) => {
            if (!Array.isArray(entries)) {
                return;
            }

            const entry = entries[0];
            setWidth(entry.contentRect.width);
            setHeight(entry.contentRect.height);

            if (callback) {
                callback(entry.contentRect);
            }
        },
        [callback]
    );

    useEffect(() => {
        if (!ref.current) {
            return noop;
        }

        const resizeObserver = new ResizeObserver((entries) => handleResize(entries));
        resizeObserver.observe(ref.current);

        return () => resizeObserver.disconnect();
    }, [ref]);

    return [width, height];
};
