import { VariantAsset } from '@mediashop/app/api/types/ClientProduct';
import { useDownloadAssetPreview } from '@mediashop/app/hooks/api/useDownloadAssetPreview';

const componentName = 'download-asset';

type AssetProps = {
    asset: VariantAsset;
    productName?: string;
};

/**
 * Display a preview for download assets.
 * @param props Asset
 * @returns Download asset preview.
 */
function DownloadAsset({ asset, productName }: AssetProps): JSX.Element {
    const assetUrl = asset.sources[0].uri;

    const { isLoading, inlineImage: imageSource } = useDownloadAssetPreview({ assetUrl });

    return (
        <div className={componentName}>
            <a className={`${componentName}__link`} href={assetUrl} target="_blank" rel="noopener noreferrer">
                {isLoading ? (
                    <div className={`${componentName}__image ${componentName}__image-loading`}></div>
                ) : (
                    <img src={imageSource} className={`${componentName}__image`} />
                )}
                <span className={`${componentName}__name`}>{asset.name || productName}</span>
            </a>
        </div>
    );
}

export default DownloadAsset;
