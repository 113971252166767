import { ForwardedRef, ReactElement, useCallback } from 'react';
import classNames from 'classnames';
import parseHtml from 'html-react-parser';
import { useIntl } from 'react-intl';

const componentName = 'tab-title';

type TabTitleProps = {
    title: string;
    value: number | string;
    active: boolean;
    setSelectedTab: (index: number | string) => void;
    tabRef?: ForwardedRef<HTMLDivElement>;
    isFirstItem: boolean;
    isLastItem: boolean;
};

const TabTitle = ({
    title,
    value,
    active,
    setSelectedTab,
    tabRef,
    isFirstItem,
    isLastItem,
}: TabTitleProps): ReactElement => {
    const { formatMessage } = useIntl();

    const onClick = useCallback(() => {
        setSelectedTab(value);
    }, [setSelectedTab, value]);

    return (
        <div
            ref={tabRef ?? null}
            className={classNames(
                componentName,
                { [`${componentName}--active`]: active },
                { [`${componentName}__border-left`]: isFirstItem },
                { [`${componentName}__border-right`]: isLastItem }
            )}
            onClick={onClick}
        >
            {parseHtml(formatMessage({ id: title }))}
        </div>
    );
};

export default TabTitle;
