import { injectComponent } from '@mediashop/app/component-injector';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import PriceBox from '@mediashop/catalog-base/pattern/molecule/PriceBox';
import CroppedImage from '@mediashop/base/pattern/atom/CroppedImage';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { CartLineItem } from '@mediashop/app/api/types/ClientCart';
import DeliveryInfo from '@mediashop/base/pattern/molecule/DeliveryInfo';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import getInstallmentPrice from '@mediashop/catalog-base/utils/getInstallmentPrice';
import { getVariantCustom } from '@mediashop/catalog-base/utils/getVariantCustom';
import { useBadges } from '../../../../hooks/useBadges';

const componentName = 'op-checkout-cart-line-item';

type OnePageCheckoutCartLineItemProps = BaseProps & {
    lineItem: CartLineItem;
};

/*
 *  OnePageCheckoutCartLineItem handles as single line item in the inline cart.
 *
 */
function OnePageCheckoutCartLineItem({ lineItem, className }: OnePageCheckoutCartLineItemProps) {
    const intl = useIntl();

    const variantCustom = getVariantCustom(lineItem.custom, lineItem.price);
    const numberOfInstallments = lineItem.custom?.numberOfInstallments ?? 0;

    const badges = useBadges({
        variant: lineItem.variant,
        variantCustom,
    });

    const { detailBadges } = badges ?? { detailBadges: [] };
    const priceClass = detailBadges.length ? `price-colored-${detailBadges[0]?.badgeProps?.style}` : ``;
    const installmentPrice = getInstallmentPrice(lineItem.price, numberOfInstallments) ?? undefined;

    return (
        <div className={classNames(componentName, className)}>
            <div className={`${componentName}__image-container`}>
                {lineItem.variant ? (
                    <CroppedImage
                        altText={lineItem.name}
                        className={`${componentName}__image`}
                        src={lineItem.variant.imageUri}
                        variant={CroppedImageVariant.Cart}
                    />
                ) : (
                    SKIP_RENDER
                )}
            </div>
            <div className={`${componentName}__content`}>
                <div className={`${componentName}__name`}>{lineItem.variant.attributes.name}</div>
                <div className={`${componentName}__quantity`}>
                    <FormattedMessage id="checkout.lineItem.quantity" />: {lineItem.quantity}
                </div>

                <DeliveryInfo shippingTime={lineItem.variant.attributes.shippingTime} />
                {lineItem.lineItemMode === 'GiftLineItem' ? (
                    <div className={`${componentName}__price-free`}>{intl.formatMessage({ id: 'cart.free' })}</div>
                ) : (
                    <PriceBox
                        variant={lineItem.variant}
                        className={`${componentName}__price`}
                        alignment="right"
                        custom={lineItem.custom}
                        size="small"
                        lineItemPrice={lineItem.price}
                        priceClassName={priceClass}
                        installmentPrice={installmentPrice}
                        detailBadges={detailBadges}
                    />
                )}
            </div>
        </div>
    );
}

export default injectComponent(
    'pattern.organism.OnePageCheckout.CartLineItem',
    OnePageCheckoutCartLineItem,
    'catalog-base'
);
