import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { checkPasswordValidity } from './passwordRules';

const componentName = 'password-criteria';

const FormattedCriteria = ({ text, isValid }: { text: string; isValid: boolean }): JSX.Element => (
    <span
        className={classNames(`${componentName}__criteria`, {
            [`${componentName}__fulfills-criteria`]: isValid,
        })}
    >
        <FormattedMessage id={text} />
    </span>
);

type PasswordCriteriaProps = {
    password: string;
};

export function PasswordCriteria({ password }: PasswordCriteriaProps): JSX.Element {
    const [, { hasLowercaseLetter, hasNumber, hasUppercaseLetter, hasValidLength }] = checkPasswordValidity(password);

    return (
        <>
            <FormattedMessage id="register.passwordCriteriaHeader" tagName="strong" />
            <p className={`${componentName}__criteria-wrapper`}>
                {/** Number of characters */}
                <FormattedCriteria text="register.passwordCriteria.numberOfCharacters" isValid={hasValidLength} />

                {/** Contains number */}
                <FormattedCriteria text="register.passwordCriteria.hasNumber" isValid={hasNumber} />

                {/** Contains uppercase letter */}
                <FormattedCriteria text="register.passwordCriteria.hasUppercaseLetter" isValid={hasUppercaseLetter} />

                {/** Contains lowercase letter */}
                <FormattedCriteria text="register.passwordCriteria.hasLowercaseLetter" isValid={hasLowercaseLetter} />
            </p>
        </>
    );
}
