import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxCTAButton, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';

export type PasswordSetNewProps = BaseProps & {
    headline: BrxHeadlines;
    labelPassword: string;
    labelPasswordConfirm: string;
    text: BrxHtml;
    ctaChangePassword: BrxCTAButton;
    textCompound: TextCompoundProps;
};

import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { TextCompoundProps } from '@mediashop/base/pattern/atom/TextCompound';

const PasswordSetNewMolecule = lazy(() => lazyRetry(() => import('./PasswordSetNew')));

const PasswordSetNew = (props: PasswordSetNewProps): JSX.Element => (
    <LazyComponent>
        <PasswordSetNewMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.PasswordSetNew', PasswordSetNew);
