import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { LinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import { MainNavigationVerticalBrandNavigationItems } from '.';
import { BaseProps } from '@mediashop/app/bloomreach/types';

type Props = BaseProps & {
    childItem: MainNavigationVerticalBrandNavigationItems;
};

const DesktopMenuChildItemLinkText = ({ childItem, className }: Props) => {
    const renderChildItemLinkText = () => {
        if (childItem.link && childItem.linkText) {
            return (
                <LinkOpenInTab className={className} tab={childItem.tab} link={childItem.link}>
                    {childItem.linkText}
                </LinkOpenInTab>
            );
        }

        if ((childItem.link && !childItem.linkText) || (!childItem.link && !childItem.linkText)) {
            return <div style={{ height: '18px' }}></div>;
        }

        if (!childItem.link && childItem.linkText) {
            return <span>{childItem.linkText}</span>;
        }

        return SKIP_RENDER;
    };

    return <div>{renderChildItemLinkText()}</div>;
};

export default DesktopMenuChildItemLinkText;
