import { ComponentType, Suspense } from 'react';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyRetry = function (componentImport: () => Promise<any>): Promise<{ default: ComponentType<any> }> {
    // In case of SSR resolve directly
    if (typeof window === 'undefined') {
        return componentImport();
    }

    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');

        // try to import the component
        componentImport()
            .then((component) => {
                window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
                resolve(component);
            })
            .catch((error) => {
                if (!hasRefreshed) {
                    // not been refreshed yet
                    window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
                    return window.location.reload();
                }
                return reject(error);
            });
    });
};

type Props = BasePropsWithChildren & {
    fallback?: JSX.Element;
};

const LazyComponent = ({ children, fallback }: Props): JSX.Element => (
    <Suspense fallback={fallback ? fallback : undefined}>{children}</Suspense>
);

export default LazyComponent;
