import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import BloomreachImageResponsive from '../../atom/BloomreachImageResponsive';
import Headlines from '../../atom/Headlines';
import ContactBlock, { ContactBlockProps } from './ContactBlock';
import ContentWrapper from '../../atom/ContentWrapper';
import BackgroundColorWrapper from '../../atom/BackgroundColorWrapper';
import { BackgroundProps, BaseProps, BrxHeadlines, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const componentName = 'contact';

type ContactProps = BaseProps & {
    headlines: BrxHeadlines;
    contactItem: ContactBlockProps[];
    quoteItem: {
        avatar: BrxImageWithAltText;
        name: string;
        quote: string;
        title: string;
    };
    subheadlineQuote: string;
    background?: BackgroundProps;
};

function Contact({
    headlines,
    contactItem,
    quoteItem,
    subheadlineQuote,
    background = 'primary',
}: ContactProps): JSX.Element {
    const evenIndicesItems = contactItem.filter((item, index) => index % 2 === 0);
    const oddIndicesItems = contactItem.filter((item, index) => index % 2 === 1);

    return (
        <BackgroundColorWrapper backgroundColor={background}>
            <ContentWrapper>
                <div className={classNames(componentName, `${componentName}--${background}`)}>
                    {headlines?.headline ? <Headlines {...headlines} /> : SKIP_RENDER}

                    {quoteItem.avatar && quoteItem.quote && quoteItem.name && (
                        <div className={`${componentName}__quote-container`}>
                            <div className={`${componentName}__image-container`}>
                                <BloomreachImageResponsive
                                    alt={quoteItem.avatar.altText ?? EMPTY_STRING}
                                    desktopImage={quoteItem.avatar.image}
                                    variant={BrXImageVariant.Contact}
                                />
                            </div>
                            <div className={`${componentName}__quote-content`}>
                                <p className={`${componentName}__quote`}>&quot;{quoteItem.quote}&quot;</p>
                                <div className={`${componentName}__quote-person`}>
                                    <span className={`${componentName}__quote-name`}>{quoteItem.name}, </span>
                                    <span className={`${componentName}__quote-position`}>{quoteItem.title}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    {subheadlineQuote && <h2 className={`${componentName}__contact-headline`}>{subheadlineQuote}</h2>}
                    <div className={`${componentName}__contact-container`}>
                        <div className={`${componentName}__contact-wrapper`}>
                            <div className={`${componentName}__even-grid`}>
                                {evenIndicesItems.map((singleContactItem, key) => (
                                    <ContactBlock key={key} {...singleContactItem} />
                                ))}
                            </div>
                            <div className={`${componentName}__odd-grid`}>
                                {oddIndicesItems.map((singleContactItem, key) => (
                                    <ContactBlock key={key} {...singleContactItem} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.Contact', Contact);
