import { SKIP_RENDER } from '../../constants/semanticConstants';
import { useCart } from '../../hooks/api/useCart';

/**
 * Load cart data on page load
 */
export default function CartDataConnector(): null {
    useCart({ doRefetchOnPageChange: true });

    return SKIP_RENDER;
}
