import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ShopSwitchModalMolecule = lazy(() => lazyRetry(() => import('./ShopSwitchModal')));

const ShopSwitchModal = (): JSX.Element => (
    <LazyComponent>
        <ShopSwitchModalMolecule />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ShopSwitchModal', ShopSwitchModal);
