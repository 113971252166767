import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import breakpoints, { BreakpointName } from '../../config/breakpoints';

export interface RenderContextViewportDimensions {
    width: number;
    height: number;
    orientation: 'portrait' | 'landscape';
}

export interface RenderContextState {
    /**
     * Avoid using this, in order to profit from dead-code-elimination
     */
    environment: string;
    isServerSideRendering: boolean;
    deviceType: BreakpointName;
    viewportDimensions?: RenderContextViewportDimensions;
    userAgent?: string;
    hasUADesktopHeader?: boolean;
}

const initialState: RenderContextState = {
    environment: process.env.NODE_ENV!,
    isServerSideRendering: true,
    deviceType: 'mobile',
    viewportDimensions: undefined,
    userAgent: undefined,
    hasUADesktopHeader: undefined,
};

/**
 * Try to determine which device-type the user is using, based on viewportDimensions & userAgent
 * @param state
 */
function detectDeviceTypeByRenderContext(state: RenderContextState): BreakpointName {
    // Always prefer actual viewport-dimensions
    if (state.viewportDimensions) {
        const matchingBreakpoints = breakpoints.filter(
            (breakpoint) => (state?.viewportDimensions?.width ?? 0) >= breakpoint.minWidth
        );

        // Return largest matching viewport
        if (matchingBreakpoints.length > 0) {
            return matchingBreakpoints[matchingBreakpoints.length - 1].name;
        }
    }

    // Try user-agent matching by regex
    if (state.userAgent) {
        // Return smallest matching viewport
        const matchingBreakpoint = breakpoints.find((breakpoint) =>
            breakpoint.userAgentRegex ? breakpoint.userAgentRegex.test(state.userAgent ?? '') : false
        );
        if (matchingBreakpoint) {
            return matchingBreakpoint.name;
        }
    }

    /*
     * Try matching by http-header 'sec-ch-ua-mobile'
     * user-agent matching still has preference, as we can more accurately determine
     * whether the client is a mobile or tablet user.
     */
    if (state.hasUADesktopHeader) {
        const matchingBreakpoint = breakpoints.find((breakpoint) => breakpoint.isDesktopBreakpoint);
        if (matchingBreakpoint) {
            return matchingBreakpoint.name;
        }
    }

    // Return smallest name if we couldn't determine the breakpoint.
    return breakpoints[0].name;
}

/**
 * Enhance state with deviceType
 * @param state
 */
function renderContextWithDeviceType(state: RenderContextState): RenderContextState {
    return {
        ...state,
        deviceType: detectDeviceTypeByRenderContext(state),
    };
}

const renderContextSlice = createSlice({
    name: 'renderContext',
    initialState,
    reducers: {
        clientHintHeadersReceived(state, action: PayloadAction<{ hasUADesktopHeader: boolean }>) {
            const { hasUADesktopHeader } = action.payload;
            return renderContextWithDeviceType({
                ...state,
                hasUADesktopHeader,
            });
        },
        clientSideTookOver(state) {
            return {
                ...state,
                isServerSideRendering: false,
            };
        },
        userAgentDetected(state, action: PayloadAction<{ userAgent: string }>) {
            const { userAgent } = action.payload;
            return renderContextWithDeviceType({
                ...state,
                userAgent,
            });
        },
        viewportDimensionsChanged(
            state,
            action: PayloadAction<{ viewportDimensions: RenderContextViewportDimensions }>
        ) {
            const { viewportDimensions } = action.payload;
            return renderContextWithDeviceType({
                ...state,
                viewportDimensions,
            });
        },
    },
});

export const { clientHintHeadersReceived, clientSideTookOver, userAgentDetected, viewportDimensionsChanged } =
    renderContextSlice.actions;

export default renderContextSlice.reducer;
