import { InstallmentPlanProps } from '@mediashop/app/api/types/ClientCart';
import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const InstallmentAgreementFormLazy = lazy(() => lazyRetry(() => import('./InstallmentAgreementForm')));

export type InstallmentAgreementFormProps = BaseProps & {
    goToSummary?: () => void;
    installmentPlan: InstallmentPlanProps;
};

const InstallmentAgreementForm = (props: InstallmentAgreementFormProps): JSX.Element => (
    <LazyComponent>
        <InstallmentAgreementFormLazy {...props} />
    </LazyComponent>
);

export default InstallmentAgreementForm;
