import { AppThunk, AppThunkDispatch } from '../index';
import { AppPropsForSSR, CommercetoolsData } from '../../App';
import { EMPTY_STRING } from '../../constants/semanticConstants';
import { configurationReceived } from '../reducer/project';
import { selectedCategoryIdChanged } from '../reducer/category';
import { pageReceived } from '../reducer/page';
import { contextReceived } from '../reducer/context';
import { productVariantSelected, productsReceived, slugProductReceived } from '../reducer/product';
import { clientHintHeadersReceived, userAgentDetected } from '../reducer/renderContext';
import { BrxPageModel } from '../../bloomreach/types';
import { nonceChanged } from '../reducer/nonce';
import { TestData, testDataReceived } from '../reducer/testData';

/**
 * Initialize a store from the given app-props.
 * @param props
 */
export const initStoreFromAppProps =
    (
        props: AppPropsForSSR,
        commercetoolsData?: CommercetoolsData,
        selectedCategoryId?: string,
        pageData?: BrxPageModel,
        nonce?: string,
        testData?: TestData
    ): AppThunk =>
    (dispatch: AppThunkDispatch) => {
        if (props.userAgent) {
            dispatch(userAgentDetected({ userAgent: props.userAgent }));
        }

        if (commercetoolsData) {
            dispatch(productsReceived({ products: commercetoolsData.products }));

            if (commercetoolsData.slugProduct) {
                dispatch(slugProductReceived({ product: commercetoolsData.slugProduct }));

                if (props.selectedProductVariantSku) {
                    dispatch(productVariantSelected({ sku: props.selectedProductVariantSku }));
                }
            }
        }

        if (selectedCategoryId) {
            dispatch(selectedCategoryIdChanged({ selectedCategoryId }));
        }

        if (nonce) {
            dispatch(nonceChanged({ nonce }));
        }

        if (testData) {
            dispatch(testDataReceived({ testData }));
        }

        dispatch(clientHintHeadersReceived({ hasUADesktopHeader: props.hasUADesktopHeader }));

        dispatch(configurationReceived({ config: props.config }));

        dispatch(
            contextReceived({
                context: {
                    locale: props.locale,
                    isLocaleFromCookie: props.isLocaleFromCookie,
                    currency: props.currency,
                    country: props.country,
                    route: props.route,
                    geoIPCountryCode: props.geoIPCountryCode ?? 'DE',
                    showSwitchShopModal: false,
                    isMainMenuOpen: false,
                    isBrX: props.isBrX,
                    location: props.location,
                    exchangeRates: props.exchangeRates,
                },
            })
        );

        dispatch(
            pageReceived({
                page: {
                    title: EMPTY_STRING,
                    pageType: pageData?.rootPage?.name?.toString() ?? EMPTY_STRING,
                    pathName: EMPTY_STRING,
                },
            })
        );
    };
