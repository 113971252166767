import { useMemo, useContext } from 'react';
import { BloomreachContext } from '../bloomreach/bloomify';
import { useProject } from './useProject';

/**
 * Rewrite every href-attribute pointing to this project to point to the
 * correct base-path.
 *
 * In order to not ship a html-parser, this code uses regex and assumes that
 * every rewritable html-attribute is surrounded by double-quotes and starts with /.
 * For further questions regarding regex and html, visit the linked page.
 *
 * @see https://stackoverflow.com/a/1732454/2031632
 */
const rewriteLinksWithBaseUrl = (text: string, baseUrl: string) =>
    text.replace(/href="(\/.*?)"/gi, `href="${baseUrl}$1"`);

/**
 * Uses the rewriteLinks-method of the bloomreach-sdk to rewrite links in a text.
 *
 * @see https://documentation.bloomreach.com/14/library/concepts/page-model-api/page-model-api-v1.0.html
 */
export default function useRewriteLinks(text?: string): string | null {
    const brContext = useContext(BloomreachContext);
    const { basePath } = useProject();

    return useMemo(() => (text ? rewriteLinksWithBaseUrl(brContext.page.rewriteLinks(text), basePath) : null), [text]);
}
