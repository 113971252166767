import { useRef, useState } from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { FormattedMessage } from 'react-intl';
import BloomreachHtml from '@mediashop/app/components/BloomreachHtml';
import useIsomorphicLayoutEffect from '@mediashop/app/hooks/useIsomorphicLayoutEffect';
import ShowMoreButton from '../../../molecule/ShowMoreButton';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Variant } from '@mediashop/app/api/types/ClientProduct';

const componentName = 'product-stage-description';
const maxHeight = 80;

interface ProductStageDescriptionProps extends BaseProps {
    activeVariant?: Variant;
}

/**
 * Display detailed product description.
 * @param activeVariant {Variant|undefined}
 * @param product {Product|undefined}
 */
function ProductStageDescription({ activeVariant }: ProductStageDescriptionProps) {
    const description = activeVariant?.attributes?.claim;
    const [expanded, setExpanded] = useState(false);
    const [isOversize, setIsOversize] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    useIsomorphicLayoutEffect(() => {
        if (ref.current) {
            setIsOversize(ref.current.clientHeight > maxHeight);
        }
    });

    return (
        <div className={componentName} ref={ref}>
            <BloomreachHtml
                html={description}
                className={classNames(`${componentName}__preview`, {
                    [`${componentName}__preview--oversized`]: isOversize && !expanded,
                })}
                style={isOversize && !expanded ? { maxHeight } : {}}
            />

            {isOversize && (
                <ShowMoreButton onClick={() => setExpanded(!expanded)}>
                    <FormattedMessage id={expanded ? 'product.descriptionShowLess' : 'product.descriptionShowMore'} />
                </ShowMoreButton>
            )}
        </div>
    );
}

export default injectComponent('pattern.organism.ProductStageDescription', ProductStageDescription);
