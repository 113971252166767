import { BaseProps } from '@mediashop/app/bloomreach/types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const componentName = 'personal-info-email';

interface PersonalInfoEmailProps extends BaseProps {
    email: string;
}

export function PersonalInfoEmail({ className, email }: PersonalInfoEmailProps): JSX.Element {
    return (
        <div className={classNames(className, componentName)}>
            <h3 className={`${componentName}__title`}>
                <FormattedMessage id="customer.email" />
            </h3>
            <span>{email}</span>
        </div>
    );
}
