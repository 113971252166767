import { injectComponent } from '@mediashop/app/component-injector';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import Icon from '../../Icon';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const getErrorMessageIDFromCode = (discountErrorCode: string) => {
    switch (discountErrorCode) {
        case 'NotActive':
            return 'voucherCodes.notActive';
        case 'NotValid':
            return 'voucherCodes.notValid';
        case 'DoesNotMatchCart':
            return 'voucherCodes.doesNotMatchCart';
        case 'MaxApplicationReached':
            return 'voucherCodes.maxApplicationReached';
        case 'ApplicationStoppedByPreviousDiscount':
            return 'voucherCodes.applicationStoppedByPreviousDiscount';
        default:
            return 'voucherCodes.notActive';
    }
};

type Props = BasePropsWithChildren & {
    errorCode: string;
    className?: string;
};

/**
 * Display a tooltip for Vouchers
 */
function VoucherToolTip({ errorCode = '', className }: Props) {
    const componentName = 'voucher-tool-tip';

    return (
        <span className={classNames(`${componentName}__voucher-overlay`, className)}>
            <div className={`${componentName}__content-container`}>
                <Icon className={`${componentName}__icon`} name="XCircle" />
                <span className={`${componentName}__error-message`}>
                    <FormattedMessage id={getErrorMessageIDFromCode(errorCode)} />
                </span>
            </div>
        </span>
    );
}

export default injectComponent('pattern.atom.VoucherToolTip', VoucherToolTip);
