import { injectComponent } from '@mediashop/app/component-injector';
import { BackgroundProps, BaseProps, BrxLink, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import { BrxLinkOpenInTab, BrxLinkOpenInTabWithColors } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import MainNavigation from '../MainNavigation';

type Props = BaseProps & {
    mainNavigation: {
        background: BackgroundProps;
        msLogoMobile: BrxImageSetImpl;
        msLogoDesktop: BrxImageSetImpl;
        offCanvasLinksWithColorsMobile: BrxLinkOpenInTabWithColors[];
        quickLinkWithColorsDesktop: BrxLinkOpenInTabWithColors;
        quickLinksWithColorsMobile: BrxLinkOpenInTabWithColors[];
        shippingText: string;
        country: string[];
        userIconLinkLoggedIn: BrxLink;
        userIconLinkLoggedOut: BrxLink;
        linksForLoggedInUsers: BrxLink[];
        minWidthMobileLogo?: string;
        minWidthDesktopLogo?: string;
    };
    extendedNavigation: BrxLinkOpenInTab[];
    displayName: string;
};

function MainNavigationExtended({ mainNavigation, extendedNavigation, displayName }: Props) {
    const props = {
        ...mainNavigation,
        extendedNavigation,
        displayName,
    };

    return <MainNavigation {...props} />;
}

export default injectComponent('pattern.organism.MainNavigationExtended', MainNavigationExtended, 'catalog-base');
