import { useEffect, useState } from 'react';

import { injectComponent } from '@mediashop/app/component-injector';
import classNames from 'classnames';
import LoadingIndicator from '../LoadingIndicator';
import Icon from '../Icon';
import { ButtonProps } from './types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const componentName = 'button';

/**
 * Render a button component.
 *
 * If provided with an href, it will render a <a>.
 * Provided a to-prop, it will render a <Link>.
 * Provided with neither of these, it will render a <button>.
 */
function Button({
    style = 'primary',
    size = 'medium',
    fullWidth,
    iconLeft,
    iconRight,
    iconSize = 'medium',
    disabled,
    loading,
    onClick,
    children,
    className = '',
    customStyles,
    animation = false,
    type,
    form,
}: ButtonProps): JSX.Element {
    const disabledOrLoading = disabled || loading;
    const [isButtonAnimated, setIsButtonAnimated] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsButtonAnimated(false);
        }, 7000);
    }, []);

    const targetClassName = classNames(
        className,
        componentName,
        `${componentName}--${style}`,
        `${componentName}--${size}`,
        {
            [`${componentName}--full-width`]: fullWidth,
            [`${componentName}--disabled`]: disabled,
            [`${componentName}--loading`]: loading,
            [`${componentName}--with-icon-left`]: iconLeft && !loading,
            [`${componentName}--with-icon-right`]: iconRight && !loading,
            [`${componentName}--animate`]: animation && isButtonAnimated,
        }
    );

    return (
        <button
            className={targetClassName}
            onClick={onClick}
            style={customStyles}
            disabled={disabledOrLoading}
            type={type}
            form={form}
        >
            {loading ? (
                <LoadingIndicator />
            ) : (
                <>
                    {children}

                    {iconLeft ? (
                        <div
                            className={classNames(
                                `${componentName}__icon-left`,
                                `${componentName}__icon`,
                                `${componentName}__icon--${iconSize}`
                            )}
                        >
                            <Icon name={iconLeft} />
                        </div>
                    ) : (
                        SKIP_RENDER
                    )}

                    {iconRight ? (
                        <div
                            className={classNames(
                                `${componentName}__icon-right`,
                                `${componentName}__icon`,
                                `${componentName}__icon--${iconSize}`
                            )}
                        >
                            <Icon name={iconRight} />
                        </div>
                    ) : (
                        SKIP_RENDER
                    )}
                </>
            )}
        </button>
    );
}

export default injectComponent('pattern.atom.Button', Button);
