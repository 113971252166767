import { Money } from '../api/types/Money';

type ModifyMultipleCallback = (centAmountA: number, centAmountB: number) => number;

/**
 * Check compatibility between multiple money-objects.
 * Returns undefined on success, throws on failure.
 * @param monA
 * @param monB
 */
const checkCompatibility = (monA: Money, monB: Money): undefined => {
    if (monA.fractionDigits !== monB.fractionDigits || monA.currencyCode !== monB.currencyCode) {
        throw new Error('Money: Objects not compatible');
    }
    return undefined;
};

/**
 * Run a callback to calculate a new money-object based on multiple others.
 * Immutable. Does not modify either money-object.
 */
const modifyMultiple = (moneyA: Money, moneyB: Money, modifier: ModifyMultipleCallback): Money =>
    checkCompatibility(moneyA, moneyB) ?? {
        ...moneyA,
        centAmount: modifier(moneyA.centAmount, moneyB.centAmount),
    };

/**
 * Less than
 */
const lessThan = (moneyA: Money, moneyB: Money): boolean =>
    checkCompatibility(moneyA, moneyB) ?? moneyA.centAmount < moneyB.centAmount;

/**
 * Less than or equal
 */
const lessThanOrEqual = (moneyA: Money, moneyB: Money): boolean =>
    checkCompatibility(moneyA, moneyB) ?? moneyA.centAmount <= moneyB.centAmount;

/**
 * Greater Than
 */
const greaterThan = (moneyA: Money, moneyB: Money): boolean =>
    checkCompatibility(moneyA, moneyB) ?? moneyA.centAmount > moneyB.centAmount;

/**
 * Greater than or equal
 */
const greaterThanOrEqual = (moneyA: Money, moneyB: Money): boolean =>
    checkCompatibility(moneyA, moneyB) ?? moneyA.centAmount >= moneyB.centAmount;

/**
 * Get a function which acts upon two money-objects with the given modifier.
 * Immutable. Does not modify either money-object.
 */
const composeModifier =
    (modifier: ModifyMultipleCallback) =>
    (moneyA: Money, moneyB: Money): Money =>
        modifyMultiple(moneyA, moneyB, modifier);

/**
 * Add two money objects.
 */
const add = composeModifier((centA, centB) => centA + centB);

/**
 * Subtract a money-object from another
 */
const subtract = composeModifier((centA, centB) => centA - centB);

/**
 * Multiply two money-objects.
 */
const multiply = composeModifier((centA, centB) => centA * centB);

/**
 * Divide a money-object by another.
 */
const divide = composeModifier((centA, centB) => centA / centB);

/**
 * Run a callback to calculate a new money-object, based on a previous object.
 * Immutable. Does not modify the original object.
 */
const modify = (money: Money, modifier: (cent: number) => number): Money => ({
    ...money,
    centAmount: modifier(money.centAmount),
});

/**
 * Multiply by scalar
 * returns a new money object
 */
const multiplyByScalar = (money: Money, scalar: number): Money => modify(money, (cent) => cent * scalar);

export default {
    modifyMultiple,
    modify,
    add,
    subtract,
    multiply,
    divide,
    lessThan,
    lessThanOrEqual,
    greaterThan,
    greaterThanOrEqual,
    multiplyByScalar,
};
