import { injectComponent } from '@mediashop/app/component-injector';
import Badge from '@mediashop/base/pattern/atom/Badge';
import StrikeThroughPrice from '../../../atom/StrikeThroughPrice';
import UnitPrice from '../../../atom/UnitPrice';
import { FormattedMessage } from 'react-intl';
import Money from '@mediashop/app/domain/Money';
import LoadingIndicator from '../../../atom/LoadingIndicator';
import showOriginalPrice from '@mediashop/app/helper/render/showOriginalPrice';
import { Price } from '@mediashop/base/pattern/molecule/Price';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

interface ProductStageInfosProps extends BaseProps {
    activeVariant: Variant;
    detailBadges?: any;
    hasVariants?: boolean;
    isLoading: boolean;
    showFrom?: boolean;
    showSavings?: boolean;
}

/**
 * Display detailed product information.
 */
// eslint-disable-next-line complexity
function ProductStageInfos({
    className,
    detailBadges,
    activeVariant,
    isLoading,
    showSavings = true,
    showFrom = false,
    hasVariants = false,
}: ProductStageInfosProps) {
    const hasPrice = Boolean(activeVariant?.price);
    const hasUnitPrice = activeVariant?.attributes.referenceUnit && hasPrice;
    const hasOriginalPrice = activeVariant?.originalPrice && hasPrice;
    const priceClass = activeVariant?.attributes.badges ? `price-colored-${activeVariant?.attributes.badges.key}` : ``;

    let savings = { centAmount: 0, fractionDigits: 2 };
    if (activeVariant?.originalPrice && activeVariant?.price) {
        savings = Money.subtract(activeVariant?.originalPrice, activeVariant?.price);
    }

    if (isLoading) {
        return (
            <div className={`${className}__infos`}>
                <LoadingIndicator className="product-stage__loading-indicator" />
            </div>
        );
    }

    return (
        <div className={`${className}__infos`}>
            <div className={`${className}__marketing`}>
                {detailBadges?.length
                    ? detailBadges.map((badge) => <Badge key={badge.badgeProps.text} {...badge.badgeProps} />)
                    : null}
                {hasOriginalPrice && savings && savings?.centAmount > 0 && showSavings ? (
                    <div className={`${className}__saved-money`}>
                        <Price price={savings} showFrom={hasVariants} />
                        <span>&nbsp;</span>
                        <FormattedMessage id="product.savedMoneySuffix" />
                    </div>
                ) : null}
            </div>
            <div className={`${className}__price-container`}>
                {showOriginalPrice(activeVariant) && (
                    <StrikeThroughPrice price={activeVariant.originalPrice!} className={`${className}__old-price`} />
                )}
                <div className={`${className}__price`}>
                    {showFrom && activeVariant?.existsOtherThenCheapestPrices && activeVariant.cheapestPrice ? (
                        <div>
                            <span className={priceClass}>
                                <Price price={activeVariant.cheapestPrice} showFrom />
                            </span>
                        </div>
                    ) : (
                        <span className={priceClass}>
                            <Price price={activeVariant?.price} />
                        </span>
                    )}
                </div>

                {hasUnitPrice ? <UnitPrice variant={activeVariant} /> : SKIP_RENDER}
            </div>
        </div>
    );
}

export default injectComponent('pattern.organism.ProductStageInfos', ProductStageInfos);
