import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { analyticsMiddleware } from './middleware/analytics';
import { partnerMiddleware } from './middleware/partner';
import cartReducer from './reducer/cart';
import checkoutReducer from './reducer/checkout';
import contextReducer from './reducer/context';
import customerReducer from './reducer/customer';
import devToolsReducer from './reducer/devTools';
import orderReducer from './reducer/order';
import partnerReducer from './reducer/partner';
import productReducer from './reducer/product';
import projectReducer from './reducer/project';
import renderContextReducer from './reducer/renderContext';
import routesReducer from './reducer/routes';
import categoryReducer from './reducer/category';
import pageReducer from './reducer/page';
import nonceReducer from './reducer/nonce';
import testDataReducer from './reducer/testData';
import { configureStore, StateFromReducersMapObject } from '@reduxjs/toolkit';

const reducer = {
    testData: testDataReducer,
    cart: cartReducer,
    category: categoryReducer,
    checkout: checkoutReducer,
    context: contextReducer,
    customer: customerReducer,
    devTools: devToolsReducer,
    order: orderReducer,
    partner: partnerReducer,
    product: productReducer,
    project: projectReducer,
    renderContext: renderContextReducer,
    routes: routesReducer,
    page: pageReducer,
    nonce: nonceReducer,
};

export type RootState = StateFromReducersMapObject<typeof reducer>;

export function initStore(preloadedState?: Partial<RootState>) {
    return configureStore({
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware().concat(analyticsMiddleware, partnerMiddleware);
        },
        preloadedState,
        reducer,
    });
}

export type RootStore = ReturnType<typeof initStore>;
export type AppDispatch = RootStore['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, any>;
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, any>;
