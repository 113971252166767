import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';
import BloomreachImageResponsive from '../../../atom/BloomreachImageResponsive';

const componentName = 'product-stage-logos';

type ProductStageLogosProps = BaseProps & {
    logos: BrxImageWithAltText[];
};

/**
 * Render a list of logos provided by bloomreach.
 */
function ProductStageLogos({ logos, className = '' }: ProductStageLogosProps): JSX.Element {
    return (
        <div className={classNames(componentName, className)}>
            {logos.map((logo, index) => (
                <BloomreachImageResponsive
                    key={index}
                    className={`${componentName}__logo`}
                    desktopImage={logo.image}
                    alt={logo.altText ?? EMPTY_STRING}
                    variant={BrXImageVariant.ProductStageLogos}
                />
            ))}
        </div>
    );
}

export default injectComponent('pattern.organism.ProductStageLogos', ProductStageLogos);
