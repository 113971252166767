export const locales = [
    {
        value: 'fr-FR',
        country: 'Français',
        iconShortCode: 'fr',
    },
    {
        value: 'de-DE',
        country: 'Deutsch',
        iconShortCode: 'de',
    },
    {
        value: 'hu-HU',
        country: 'Magyar',
        iconShortCode: 'hu',
    },
    {
        value: 'sk-SK',
        country: 'Slovensky',
        iconShortCode: 'sk',
    },
    {
        value: 'cs-CZ',
        country: 'Čeština',
        iconShortCode: 'cz',
    },
    {
        value: 'ro-RO',
        country: 'Română',
        iconShortCode: 'ro',
    },
    {
        value: 'nl-NL',
        country: 'Nederlands',
        iconShortCode: 'nl',
    },
];

export const countries = [
    {
        code: 'AT',
        name: 'Österreich',
    },
    {
        code: 'DE',
        name: 'Deutschland',
    },
    {
        code: 'CH',
        name: 'Schweiz',
    },
    {
        code: 'LI',
        name: 'Liechtenstein',
    },
    {
        code: 'IT',
        name: 'Italia',
    },
    {
        code: 'BE',
        name: 'Belgique',
    },
    {
        code: 'NL',
        name: 'Nederland',
    },
    {
        code: 'BG',
        name: 'Bulgaria',
    },
    {
        code: 'CY',
        name: 'Cyprus',
    },
    {
        code: 'CZ',
        name: 'Česká republika',
    },
    {
        code: 'DK',
        name: 'Danmark',
    },
    {
        code: 'EE',
        name: 'Eesti',
    },
    {
        code: 'ES',
        name: 'España',
    },
    {
        code: 'FI',
        name: 'Finland',
    },
    {
        code: 'FR',
        name: 'France',
    },
    {
        code: 'GB',
        name: 'United Kingdom',
    },
    {
        code: 'GR',
        name: 'Greece',
    },
    {
        code: 'HR',
        name: 'Hrvatska',
    },
    {
        code: 'HU',
        name: 'Magyarország',
    },
    {
        code: 'IE',
        name: 'Ireland',
    },
    {
        code: 'LT',
        name: 'Lietuva',
    },
    {
        code: 'LU',
        name: 'Luxembourg',
    },
    {
        code: 'LV',
        name: 'Latvija',
    },
    {
        code: 'MK',
        name: 'Северна Македонија',
    },
    {
        code: 'MT',
        name: 'Malta',
    },
    {
        code: 'PL',
        name: 'Polska',
    },
    {
        code: 'PT',
        name: 'Portugal',
    },
    {
        code: 'RO',
        name: 'România',
    },
    {
        code: 'SE',
        name: 'Sverige',
    },
    {
        code: 'SI',
        name: 'Slovenija',
    },
    {
        code: 'SK',
        name: 'Slovensko',
    },
    {
        code: 'SM',
        name: 'San Marino',
    },
    {
        code: 'US',
        name: 'USA',
    },
];
