import classNames from 'classnames';
import { Price } from '../../molecule/Price';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Money } from '@mediashop/app/api/types/Money';

const componentName = 'strike-through-price';

interface StrikeThroughPriceProps extends BaseProps {
    price: Money;
}

/**
 * Format a price with a strike-through.
 * E.g. old prices, discounts, etc.
 */
function StrikeThroughPrice({ className, price }: StrikeThroughPriceProps) {
    return (
        <div className={classNames(componentName, className)}>
            <Price price={price} />
        </div>
    );
}

export default injectComponent('pattern.atom.StrikeThroughPrice', StrikeThroughPrice);
