import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Money } from '@mediashop/app/api/types/Money';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { CartLineItemCustomFields } from '@mediashop/app/api/types/ClientCart';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CartProductMolecule = lazy(() => lazyRetry(() => import('./CartProduct')));

export type CartProductProps = BaseProps & {
    id: string;
    name: string;
    variant: Variant;
    quantity: number;
    lineItemMode: string;
    price: Money;
    discountedPricePerQuantity: {
        discountedPrice: {
            value: {
                centAmount: number;
            };
        };
    }[];
    onSelectChange: (quantity: number) => void;
    onRemoveItem: () => void;
    custom: CartLineItemCustomFields;
    lineItemPrice: Money;
    isLoading: boolean;
    slug: string;
    showClaim?: boolean;
};

/**
 * CartProduct displays a single line-item in the cart
 */
const CartProduct = (props: CartProductProps) => (
    <LazyComponent>
        <CartProductMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.CartProduct', CartProduct, 'catalog-base');
