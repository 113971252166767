import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';

export type CountrySwitchModalProps = BaseProps & {
    isOpen: boolean;
    onSubmit: () => void;
    onClose: () => void;
    newCountry: string;
};

import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CountrySwitchModalMolecule = lazy(() => lazyRetry(() => import('./CountrySwitchModal')));

const CountrySwitchModal = (props: CountrySwitchModalProps): JSX.Element => (
    <LazyComponent>
        <CountrySwitchModalMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.CountrySwitchModal', CountrySwitchModal);
