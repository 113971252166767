import bloomify from '@mediashop/app/bloomreach/bloomify';
import MainNavigation from '../pattern/organism/MainNavigation';

const MainNavigationBloomreach = (props) => {
    // Remove this again when https://mediashop-group.atlassian.net/browse/MS-2486 (Bloomreach) is deployed to production
    // eslint-disable-next-line react/prop-types
    const backwardCompatibleProps = props.element ? { ...props, ...props.element } : props;
    return <MainNavigation {...backwardCompatibleProps} />;
};

export default bloomify(MainNavigationBloomreach);
