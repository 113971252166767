import { FormattedMessage } from 'react-intl';
import { injectComponent } from '@mediashop/app/component-injector';
import PriceBox from '@mediashop/base/pattern/molecule/PriceBox';
import Button from '@mediashop/base/pattern/atom/Button';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import parseHtml from 'html-react-parser';

interface CartAddonProductProps extends BaseProps {
    name: string;
    description: string;
    variants: Variant[];
    onAddToCart: () => void;
}

/*
 * CartAddonProduct displays a single AddonProduct with image, name, description.
 */
function CartAddonProduct({ name, description, variants, onAddToCart }: CartAddonProductProps) {
    const componentName = 'cart-addon-product';

    if (!variants) {
        return SKIP_RENDER;
    }

    return (
        <div className={componentName}>
            <div className={`${componentName}__image-container`}>
                <img src={variants[0]?.imageUri} className={`${componentName}__image`} alt={name} />
            </div>
            <div className={`${componentName}__detail`}>
                <h3 className={`${componentName}__title`}>{name}</h3>
                <div className={`${componentName}__description`}> {parseHtml(description)} </div>
            </div>
            <div className={`${componentName}__order-info`}>
                <PriceBox className={`${componentName}__price`} variant={variants[0]} />

                <Button style="tertiary" className={`${componentName}__add-button`} onClick={onAddToCart}>
                    <FormattedMessage id="cart.addons.add" />
                </Button>
            </div>
        </div>
    );
}

export default injectComponent('pattern.organism.CartAddonProduct', CartAddonProduct, 'catalog-base');
