import * as React from 'react';
import { BaseProps, BrxAnchor, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const InfoBannerCommonMolecule = React.lazy(() => lazyRetry(() => import('./InfoBannerCommon')));

export type InfoBannerCommonProps = BaseProps & {
    anchor?: BrxAnchor;
    backgroundColor?: string;
    textColor?: string;
    dismissalKey: string;
    icon?: BrxImageSetImpl;
    showCloseTrigger: boolean;
    style: 'primary' | 'secondary' | 'tertiary' | 'default' | 'info' | 'warning' | 'error' | 'success';
    text: React.ReactElement;
};

const InfoBannerCommon = (props: InfoBannerCommonProps): JSX.Element => (
    <LazyComponent>
        <InfoBannerCommonMolecule {...props} />
    </LazyComponent>
);

export default InfoBannerCommon;
