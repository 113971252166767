import classnames from 'classnames';
import Headlines from '../../atom/Headlines';
import ContentSliderBasicSlide, { ContentSliderBasicSlideProps } from './BasicSlide';
import Slider from '../Slider';
import { injectComponent } from '@mediashop/app/component-injector';
import ContentWrapper from '../../atom/ContentWrapper';
import BackgroundColorWrapper from '../../atom/BackgroundColorWrapper';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { BackgroundProps, BaseProps, BrxHeadlines } from '@mediashop/app/bloomreach/types';

interface ContentSliderProps extends BaseProps {
    headlines: BrxHeadlines;
    background: BackgroundProps;
    contentBackground: string;
    items: ContentSliderBasicSlideProps[];
    roundedImages: boolean;
    slidesInViewDesktop: string;
}

function ContentSlider({
    headlines,
    background,
    contentBackground,
    items,
    roundedImages,
    slidesInViewDesktop,
}: ContentSliderProps) {
    return (
        <BackgroundColorWrapper backgroundColor={background}>
            <ContentWrapper>
                <div className={classnames('content-slider', `background--${background}`)}>
                    {headlines?.headline ? <Headlines {...headlines} /> : SKIP_RENDER}
                    <Slider slidesPerView={Number.parseFloat(slidesInViewDesktop)}>
                        {items.map((item, key) => (
                            <ContentSliderBasicSlide
                                key={key}
                                {...item}
                                roundedImages={roundedImages}
                                className={`content-slider__slide background--${contentBackground}`}
                            />
                        ))}
                    </Slider>
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.ContentSlider', ContentSlider);
