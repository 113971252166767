import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';

export type ProductComponentDetailsDescriptionProps = BaseProps & {
    index: number;
    title: string;
    description: string;
    isOneSelected: boolean;
    isHighlighted?: boolean;
    id: string;
    onClick: () => void;
};
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ProductComponentDetailsDescriptionMolecule = lazy(() => lazyRetry(() => import('./Description')));

const ProductComponentDetailsDescription = (props: ProductComponentDetailsDescriptionProps): JSX.Element => (
    <LazyComponent>
        <ProductComponentDetailsDescriptionMolecule {...props} />
    </LazyComponent>
);

export default injectComponent(
    'pattern.molecule.ProductComponentDetails.ProductComponentDetailsDescription',
    ProductComponentDetailsDescription
);
