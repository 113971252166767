import useRoute from '../../hooks/useRoute';
import BaseUrlHelper from '../../helper/BaseUrlHelper';
import { useProject } from '../../hooks/useProject';

export const usePaymentCallbackUrls = () => {
    const { basePath } = useProject();
    const routeOrderCreate = useRoute('Frontend.Master.OrderCreate');
    const routeErrorPayment = useRoute('Frontend.Master.ErrorPayment');
    const routeOrderPaymentAbort = useRoute('Frontend.Master.OrderPaymentAbort');

    const origin = typeof window === 'undefined' ? '' : window.location.origin;

    const returnUrl = BaseUrlHelper.addBasePathToPath(routeOrderCreate, origin + basePath);
    const errorUrl = BaseUrlHelper.addBasePathToPath(routeErrorPayment, origin + basePath);
    const cancelUrl = BaseUrlHelper.addBasePathToPath(routeOrderPaymentAbort, origin + basePath);

    return {
        errorUrl,
        returnUrl,
        cancelUrl,
    };
};
