import { DISPATCH_ACTION, SCRIPT_LOADING_STATE, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useEffect } from 'react';
import { useCmpServiceApproved } from '@mediashop/app/hooks/cmp/useCmpServiceApproved';

interface UsePayPalProps {
    isEnabled?: boolean;
}

export const usePayPal = ({ isEnabled = true }: UsePayPalProps = {}) => {
    const [{ isInitial, isPending, isResolved, options }, dispatch] = usePayPalScriptReducer();

    const isConsentApproved = useCmpServiceApproved('PayPal');

    useEffect(() => {
        if (isInitial && isEnabled && isConsentApproved) {
            dispatch({
                type: DISPATCH_ACTION.LOADING_STATUS,
                value: SCRIPT_LOADING_STATE.PENDING,
            });
        }
    }, [dispatch, isConsentApproved, isEnabled, isInitial]);

    const setIdToken = (idToken: string) => {
        if (options.dataUserIdToken !== idToken) {
            dispatch({ type: DISPATCH_ACTION.RESET_OPTIONS, value: { ...options, dataUserIdToken: idToken } });
        }
    };

    return {
        isLoading: isPending && isConsentApproved,
        isResolved: isEnabled && isResolved,
        setIdToken,
    };
};
