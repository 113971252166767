import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxCTAButton, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const AccountSettingsMolecule = lazy(() => lazyRetry(() => import('./AccountSettings')));

export type AccountSettingsProps = BaseProps & {
    headline: BrxHeadlines;
    headlineDeleteAccount: BrxHeadlines;
    headlineNewsletter: BrxHeadlines;
    ctaNewsletter: BrxCTAButton;
    textNewsletter: BrxHtml;
    textDeleteAccount: BrxHtml;
};

/**
 * Component for the account navigation
 * @constructor
 */
const AccountSettings = (props: AccountSettingsProps) => (
    <LazyComponent>
        <AccountSettingsMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.AccountSettings', AccountSettings);
