import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BadgeStyleProps } from '../../atom/Badge';
import { BaseProps, BrxBackground, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import { ProductBundle } from '@mediashop/app/bloomreach/product/types/ProductBundle';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ProductStageMolecule = lazy(() => lazyRetry(() => import('./ProductStage')));

export type ProductStageProps = BaseProps & {
    background: BrxBackground;
    badgeColor: BadgeStyleProps;
    deliveryModalContent: any;
    logos: BrxImageWithAltText[];
    product: ProductBundle;
    productLogo: BrxImageWithAltText;
};

const ProductStage = (props: ProductStageProps): JSX.Element => (
    <LazyComponent>
        <ProductStageMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.ProductStage', ProductStage);
