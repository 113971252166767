import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import FullScreenLoadingIndicator from '@mediashop/base/pattern/atom/FullScreenLoadingIndicator';
import Grid from '../Grid';
import { useExitRecommendations } from '@mediashop/app/queries/products/useExitRecommendations';

const componentName = 'product-grid-exit-page';

export function ProductGridExitPage(): JSX.Element {
    const { isLoading, data: products = [] } = useExitRecommendations();

    return (
        <div className={componentName}>
            {/** Loading indicator */}
            {isLoading ? <FullScreenLoadingIndicator /> : SKIP_RENDER}

            {/** Grid */}
            <Grid products={products} />
        </div>
    );
}
