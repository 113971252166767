import ApiClient, { ApiClientConfig } from '../../api/ApiClient';
import ProductClient from '../../api/ProductClient';
import ProductLoader from './ProductLoader';
import CartClient from '../../api/CartClient';
import { RootStore } from '../index';
import { createContext, useContext, type Context } from 'react';
import CartLoader from './CartLoader';
import OrderClient from '../../api/OrderClient';
import OrderLoader from './OrderLoader';
import CategoryApiClient from '../../api/CategoryApiClient';
import CustomerClient from '../../api/CustomerClient';
import ApiClientV2 from '../../api/ApiClientV2';
import EventTrackingClient from '../../api/EventTrackingClient';

export default class Loader {
    apiClient: ApiClient;
    apiClientV2: ApiClientV2;

    productClient: ProductClient;
    productLoader: ProductLoader;

    cartClient: CartClient;
    cartLoader: CartLoader;

    orderClient: OrderClient;
    orderLoader: OrderLoader;

    categoryApiClient: CategoryApiClient;

    customerClient: CustomerClient;

    eventTrackingClient: EventTrackingClient;

    constructor(
        store: RootStore,
        projectKey: string,
        endpointV1?: string,
        endpointV2?: string,
        clientConfig?: ApiClientConfig
    ) {
        this.apiClient = new ApiClient(projectKey, endpointV1, clientConfig);
        this.apiClientV2 = new ApiClientV2(projectKey, endpointV2, clientConfig);
        this.productClient = new ProductClient(this.apiClient, this.apiClientV2, store);
        this.productLoader = new ProductLoader(this.productClient, store);
        this.cartClient = new CartClient(this.apiClientV2, store);
        this.cartLoader = new CartLoader(this.cartClient, this.productClient, store);
        this.orderClient = new OrderClient(this.apiClientV2, store);
        this.orderLoader = new OrderLoader(this.orderClient, store);
        this.categoryApiClient = new CategoryApiClient(this.apiClientV2);
        this.customerClient = new CustomerClient(this.apiClient, this.apiClientV2, store);
        this.eventTrackingClient = new EventTrackingClient(this.apiClientV2, store);
    }
}

export const LoaderContext: Context<Loader> = createContext(null as any);

export const useLoader = (): Loader => useContext(LoaderContext);
