export const StorageKeys = {
    CUSTOMER_DATA: 'customer_data',
    OLD_CART_ID: 'old_cart_id',
    PAYMENT_SERVICE: 'payment_service',
} as const;

export const SessionStorageKeys = {
    PARTNER_ID: 'partnerId',
    SCROLL_RESTORATION: 'scroll_restoration_',
    SHOP_MODAL_DISMISSED: 'shopModalDismissed',
    CUSTOMER_CHECKOUT_DATA: 'cCheckoutData',
    GUEST_EMAIL: 'guestEmail',
} as const;
