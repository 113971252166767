import { BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import { useMemo } from 'react';
import { BRX_IMAGE_SIZE_MAP } from '@mediashop/base/pattern/atom/BloomreachImageResponsive/brxImageSizeMap';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';
import { useProject } from '@mediashop/app/hooks/useProject';
import { BreakpointName } from '@mediashop/app/config/breakpoints';

const rewriteImageUrl = (image: BrxImageSetImpl['original'], bloomreachImageUrl: string, imageDimensions: string) => {
    const imageUrl = image?.model?.links?.site?.href;

    if (imageUrl) {
        const originalImageUrl = new URL(imageUrl);

        if (imageDimensions) {
            return imageUrl.replace(originalImageUrl.origin, `${bloomreachImageUrl}/${imageDimensions}`);
        }

        return imageUrl.replace(originalImageUrl.origin, `${bloomreachImageUrl}`);
    }
    return imageUrl;
};

/**
 * Rewrite bloomreach image to use ScaleCommerce image proxy
 * @param image bloomreach image model
 * @param variant image variant
 * @param deviceType
 * @returns rewritten image url
 */
export const useBloomreachImage = (
    image: BrxImageSetImpl | undefined,
    variant: BrXImageVariant,
    deviceType: BreakpointName = 'desktop'
): string | undefined => {
    const { bloomreachImageEndpoint: bloomreachImageUrl } = useProject();

    const { width: targetWidth, height: targetHeight } = useMemo(
        () => BRX_IMAGE_SIZE_MAP[variant][deviceType],
        [variant, deviceType]
    );

    const imageUrl = useMemo(() => {
        const originalImage = image?.original;

        const imageDimensions = `${targetWidth}_${targetHeight}`;

        if (!originalImage) {
            console.warn('BloomreachImage: No image received from bloomreach.');
            return '';
        }

        // Transform image url
        if (bloomreachImageUrl) {
            return rewriteImageUrl(originalImage, bloomreachImageUrl, imageDimensions);
        }

        return originalImage?.model?.links?.site?.href;
    }, [bloomreachImageUrl, image?.original, targetHeight, targetWidth]);

    return imageUrl;
};
