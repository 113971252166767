import { useMutation, useQueryClient } from '@tanstack/react-query';
import noop from 'lodash/noop';
import { useLoader } from '../../store/loader';
import { InitiateExpressCheckoutResponse } from '../../api/types/CartQueries';
import { usePaymentCallbackUrls } from '../../payment/hooks/usePaymentCallbackUrls';
import { v4 as uuidv4 } from 'uuid';
import { useShopContext } from '../../hooks/useShopContext';
import { queries } from '..';
import analytics from '../../analytics';

type UseInitiateExpressCheckoutProps = {
    onSuccess?: (data: InitiateExpressCheckoutResponse) => void;
};

/**
 * Hook that initiates an express checkout (e.g. PayPal Express)
 * @returns useMutation result
 */
export const useInitiateExpressCheckout = ({ onSuccess = noop }: UseInitiateExpressCheckoutProps = {}) => {
    const facebookTrackingId = uuidv4();

    const { locale } = useShopContext();
    const { cartClient } = useLoader();
    const paymentCallbackUrls = usePaymentCallbackUrls();
    const queryClient = useQueryClient();

    const initiateCheckout = () =>
        cartClient.initiateExpressCheckout('PAYPAL_EXPRESS', paymentCallbackUrls, locale, facebookTrackingId);

    const onSuccessInternal = (paymentMeta: InitiateExpressCheckoutResponse) => {
        queryClient.setQueryData(queries.payment.get.queryKey, paymentMeta);

        // trigger analytics
        analytics.dispatchCreatePayment({ facebookTrackingId });

        onSuccess(paymentMeta);
    };

    return useMutation({
        mutationFn: initiateCheckout,
        onSuccess: onSuccessInternal,
    });
};
