/**
 * Map the currently selected shipping country to a known category country
 * @param shippingCountry current shipping country
 * @returns target country
 *
 * @deprecated use mapShippingCountryToKnownCategoryCountry instead
 */
export const mapShippingCountryToKnowCategoryCountries = (shippingCountry: string): string => {
    let catCountry: string;
    switch (shippingCountry) {
        case 'AT':
            catCountry = 'AT';
            break;
        case 'CH':
        case 'LI':
            catCountry = 'CH';
            break;
        case 'NL':
            catCountry = 'NL';
            break;
        case 'BE':
            catCountry = 'BE';
            break;
        default:
            catCountry = 'DE';
    }

    return catCountry;
};

export const mapShippingCountryToKnownCategoryCountry = (
    shippingCountry: string,
    knownCategoryCountries?: Record<string, string>
): string => {
    if (!knownCategoryCountries) {
        return shippingCountry;
    }

    return (
        knownCategoryCountries[shippingCountry] ??
        knownCategoryCountries['*'] ??
        Object.values(knownCategoryCountries)[0]
    );
};
