import { Category } from '@mediashop/app/api/types/ClientCategory';
import { ProjectStateFinished } from '@mediashop/app/store/reducer/project';

export const getCategoryItems = (
    categories: Category[],
    userCountry: string,
    shippingCountries: ProjectStateFinished['shippingCountries']
) => {
    if (categories.length) {
        const countryRootCategories = categories[0].children;

        let itemChildren = countryRootCategories?.find((country) => country.name === userCountry)?.children;
        if (!itemChildren && userCountry === 'LI') {
            itemChildren = countryRootCategories?.find((country) => country.name === 'CH')?.children;
        }
        if (!itemChildren) {
            itemChildren = countryRootCategories?.find((country) => country.name === shippingCountries[0])?.children;
        }

        return itemChildren ?? new Array<Category>();
    }
    return new Array<Category>();
};

export const filterEmptyCategories = (categories?: Category[]) => {
    if (!categories?.length) {
        return categories;
    }
    return categories
        .map((category) => {
            if (category.stagedProductCount > 0) {
                const children = filterEmptyCategories(category.children);
                return { ...category, children };
            }
            return undefined;
        })
        .filter(Boolean);
};
