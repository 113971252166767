import { lazy, RefObject } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { AddressForm } from '@mediashop/app/api/types/Address';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { CheckoutAddressFormSubmitHandle } from '../types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CheckoutGuestMolecule = lazy(() => lazyRetry(() => import('./CheckoutGuest')));

export type CheckoutGuestProps = BaseProps & {
    billingFormRef: RefObject<CheckoutAddressFormSubmitHandle>;
    shippingFormRef: RefObject<CheckoutAddressFormSubmitHandle>;
    selectedBillingCountry: string;
    selectedShippingCountry: string;
    handleNextStepPayment: () => void;
    onBillingFormChange?: ({ values, validated }: { values: AddressForm; validated: boolean }) => void;
    onShippingFormChange?: ({ values, validated }: { values: AddressForm; validated: boolean }) => void;
    defaultBillingFormValues?: AddressForm;
    defaultShippingFormValues?: AddressForm;
    isLoading?: boolean;
};

const CheckoutGuest = (props: CheckoutGuestProps): JSX.Element => (
    <LazyComponent>
        <CheckoutGuestMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.Checkout.CheckoutGuest', CheckoutGuest, 'catalog-base');
