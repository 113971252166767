const isValidLength = (password: string) => password.length >= 8;
const containsUppercaseLetter = (password: string) => /\p{Lu}/gu.test(password);
const containsLowercaseLetter = (password: string) => /\p{Ll}/gu.test(password);
const containsNumber = (password: string) => /[0-9]/.test(password);

type IsValidPasswordType = [
    isValid: boolean,
    validationChecks: {
        hasValidLength: boolean;
        hasUppercaseLetter: boolean;
        hasLowercaseLetter: boolean;
        hasNumber: boolean;
    },
];

export const checkPasswordValidity = (password: string): IsValidPasswordType => {
    const hasValidLength = isValidLength(password);
    const hasUppercaseLetter = containsUppercaseLetter(password);
    const hasLowercaseLetter = containsLowercaseLetter(password);
    const hasNumber = containsNumber(password);

    return [
        hasValidLength && hasUppercaseLetter && hasLowercaseLetter && hasNumber,
        {
            hasValidLength,
            hasUppercaseLetter,
            hasLowercaseLetter,
            hasNumber,
        },
    ];
};
