import BloomreachLink from '@mediashop/app/components/BloomreachLink';

import useScrollIntoViewCallback from '@mediashop/app/hooks/useScrollIntoViewCallback';

export type NavigationItemProps = {
    reference: string;
    text: string;
};

function NavigationItem(navigationItem: NavigationItemProps): JSX.Element {
    const componentName = 'splp-navigation-item';
    const handleAnchorClick = useScrollIntoViewCallback(navigationItem.reference, { behavior: 'auto' });

    if (navigationItem.reference.startsWith('#')) {
        return (
            <li className={componentName}>
                <div className={`${componentName}__link ${componentName}__anchor-link`} onClick={handleAnchorClick}>
                    {navigationItem.text}
                </div>
            </li>
        );
    }

    return (
        <li className={componentName}>
            <BloomreachLink href={navigationItem.reference} className={`${componentName}__link`}>
                {navigationItem.text}
            </BloomreachLink>
        </li>
    );
}

export default NavigationItem;
