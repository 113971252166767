import { QueryRequest, QueryRequestResponseMap, QueryResponse } from './types/Query';
import ensureArray from '../helper/ensureArray';
import prettifyErrorMessages from './prettifyErrorMessages';
import BaseUrlHelper from '../helper/BaseUrlHelper';
import { X_PROJECT_HEADER, CONTENT_TYPE_HEADER, X_FB_EVENT_ID } from '../constants/api';
import { ApiError } from './ApiError';
import { ENDPOINT_PATH } from './constants';
import { v4 as uuidv4 } from 'uuid';

const isLocalhost = process.env.NODE_ENV === 'development';

export type ApiClientConfig = {
    logToConsole?: boolean;
};

const defaultConfig: ApiClientConfig = {
    logToConsole: false,
};

/**
 * Return the path to the api with a given prefix.
 * Useful for projects living under a base-path.
 */
export function getEndpointWithBasePath(basePath: string): string {
    return BaseUrlHelper.addBasePathToPath(ENDPOINT_PATH, basePath);
}

/**
 * Provides access to the api-endpoint of the php-application.
 */
export default class ApiClient {
    projectKey: string;
    endpoint: string;
    config: ApiClientConfig;

    constructor(projectKey: string, endpoint = ENDPOINT_PATH, config: ApiClientConfig = defaultConfig) {
        this.projectKey = projectKey;
        this.endpoint = endpoint;
        this.config = config;
    }

    async query<T extends QueryRequest, ErrorType = string>(request: T): Promise<QueryRequestResponseMap[T['query']]> {
        const endpoint = isLocalhost ? `${this.endpoint}?${request.query}` : this.endpoint;

        const facebookTrackingId = request.facebookTrackingId ?? uuidv4();

        const req = await fetch(endpoint, {
            method: 'POST',
            headers: {
                [CONTENT_TYPE_HEADER]: 'application/json',
                [X_PROJECT_HEADER]: this.projectKey,
                [X_FB_EVENT_ID]: facebookTrackingId,
            },
            body: JSON.stringify(request),
        });

        const response = await req.text();
        let data: QueryResponse<ErrorType>;

        try {
            data = JSON.parse(response);
        } catch (error) {
            if (this.config.logToConsole) {
                console.error(`Request:\n${JSON.stringify(request)}\nResponse:\n${response}`);
            }
            throw new ApiError(`ApiClient: Server Error(s):\n${prettifyErrorMessages<ErrorType>(error)}`, error);
        }

        const errors = ensureArray(data.errors);

        if (errors.length > 0) {
            if (this.config.logToConsole) {
                console.error(`Request:\n${JSON.stringify(request)}\nResponse:\n${response}`);
            }
            throw new ApiError(`ApiClient: Server Error(s):\n${prettifyErrorMessages<ErrorType>(errors)}`, errors);
        }

        return data as any;
    }
}
