import { Product } from '@mediashop/app/api/types/ClientProduct';
import { injectComponent } from '@mediashop/app/component-injector';
import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const BundleLazy = lazy(() => lazyRetry(() => import('./Bundle')));

export type BundleProps = BaseProps & {
    sets: Product[];
};

const Bundle = (props: BundleProps): JSX.Element => (
    <LazyComponent>
        <BundleLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.ProductStage.Bundle', Bundle);
