import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxTextCompound } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { BrxLinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';

const MarketingSliderMolecule = lazy(() => lazyRetry(() => import('./MarketingSlider')));

export type MarketingSliderProps = BaseProps & {
    backgroundColorHex?: string;
    fontColorHex?: string;
    displayTime: number;
    texts: Array<{
        desktopText: { value: string };
        mobileText: { value: string };
        link: BrxLinkOpenInTab;
        hideText: boolean;
        textCompound: BrxTextCompound;
    }>;
};

const MarketingSlider = (props: MarketingSliderProps): JSX.Element => (
    <LazyComponent>
        <MarketingSliderMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.MarketingSlider', MarketingSlider, 'catalog-base');
