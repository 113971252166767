import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';

const DEVICE_TYPE_MOBILE = 'mobile';
const DEVICE_TYPE_TABLET = 'tablet';
const DEVICE_TYPE_DESKTOP = 'desktop';
const DEVICE_TYPE_WIDE = 'wide';

/**
 * Generates the size map for a single cropped image variant.
 * Currently the following assumptions / requirements are being considered:
 * - width and height are the same
 * - tablet, desktop and wide types are handeled the same way, just mobile is different
 * @param desktop widht and height of images on tablet, desktop and wide devices
 * @param mobile width and height on mobile devices
 * @returns map of device-type and size
 */
const generateDefaultDeviceTypeSizeMap = (desktop: number, tablet: number, mobile: number) => ({
    [DEVICE_TYPE_MOBILE]: {
        width: mobile,
        height: mobile,
    },
    [DEVICE_TYPE_TABLET]: {
        width: desktop,
        height: desktop,
    },
    [DEVICE_TYPE_DESKTOP]: {
        width: desktop,
        height: desktop,
    },
    [DEVICE_TYPE_WIDE]: {
        width: desktop,
        height: desktop,
    },
});

export const IMAGE_SIZE_MAP = {
    [CroppedImageVariant.Bundle]: {
        ...generateDefaultDeviceTypeSizeMap(85, 85, 85),
    },
    [CroppedImageVariant.Cart]: {
        ...generateDefaultDeviceTypeSizeMap(250, 250, 120),
    },
    [CroppedImageVariant.CategoryImage]: {
        ...generateDefaultDeviceTypeSizeMap(250, 250, 120),
    },
    [CroppedImageVariant.CheckoutMiniCart]: {
        ...generateDefaultDeviceTypeSizeMap(250, 250, 200),
    },
    [CroppedImageVariant.CheckoutSummary]: {
        ...generateDefaultDeviceTypeSizeMap(100, 100, 100),
    },
    [CroppedImageVariant.DealOfTheDay]: {
        ...generateDefaultDeviceTypeSizeMap(400, 400, 250),
    },
    [CroppedImageVariant.HeroProductSliderHeroImage]: {
        ...generateDefaultDeviceTypeSizeMap(350, 350, 200),
    },
    [CroppedImageVariant.OrderDetails]: {
        ...generateDefaultDeviceTypeSizeMap(250, 250, 150),
    },
    [CroppedImageVariant.OrderOverview]: {
        ...generateDefaultDeviceTypeSizeMap(150, 150, 150),
    },
    [CroppedImageVariant.PDPBigImage]: {
        ...generateDefaultDeviceTypeSizeMap(600, 600, 740),
    },
    [CroppedImageVariant.PDPThumbnail]: {
        ...generateDefaultDeviceTypeSizeMap(100, 100, 0),
    },
    [CroppedImageVariant.PLP]: {
        ...generateDefaultDeviceTypeSizeMap(400, 400, 250),
    },
    [CroppedImageVariant.PreCheckout]: {
        ...generateDefaultDeviceTypeSizeMap(100, 100, 120),
    },
    [CroppedImageVariant.ProductDefault]: {
        ...generateDefaultDeviceTypeSizeMap(250, 250, 250),
    },
    [CroppedImageVariant.ProductGrid3]: {
        ...generateDefaultDeviceTypeSizeMap(450, 250, 250),
    },
    [CroppedImageVariant.ProductGrid4]: {
        ...generateDefaultDeviceTypeSizeMap(350, 250, 250),
    },
    [CroppedImageVariant.ProductGrid5]: {
        ...generateDefaultDeviceTypeSizeMap(250, 250, 250),
    },
    [CroppedImageVariant.ProductSlider1]: {
        ...generateDefaultDeviceTypeSizeMap(700, 250, 250),
    },
    [CroppedImageVariant.ProductSlider2]: {
        ...generateDefaultDeviceTypeSizeMap(700, 250, 250),
    },
    [CroppedImageVariant.ProductSlider3]: {
        ...generateDefaultDeviceTypeSizeMap(450, 250, 250),
    },
    [CroppedImageVariant.ProductSlider4]: {
        ...generateDefaultDeviceTypeSizeMap(350, 250, 250),
    },
    [CroppedImageVariant.ProductSlider5]: {
        ...generateDefaultDeviceTypeSizeMap(400, 250, 250),
    },
    [CroppedImageVariant.ProductSlider6]: {
        ...generateDefaultDeviceTypeSizeMap(400, 250, 250),
    },
    [CroppedImageVariant.SearchSuggestion]: {
        ...generateDefaultDeviceTypeSizeMap(100, 100, 100),
    },
    [CroppedImageVariant.SpecialDeal]: {
        ...generateDefaultDeviceTypeSizeMap(400, 400, 300),
    },
    [CroppedImageVariant.SubscriptionManagement]: {
        ...generateDefaultDeviceTypeSizeMap(150, 150, 150),
    },
    [CroppedImageVariant.WkoGiftImage]: {
        ...generateDefaultDeviceTypeSizeMap(100, 100, 100),
    },
};
