import { Cart as NostoCart, Customer as NostoCustomer, NostoSession } from '@nosto/nosto-react';
import { ReactElement } from 'react';
import { Cart } from '../api/types/ClientCart';
import { SKIP_RENDER } from '../constants/semanticConstants';
import { useCustomer } from '../hooks/api/useCustomer';
import { useCart } from '../hooks/useCart';

const mapCartToNostoCart = (cart: Cart): NostoCart => ({
    items: cart.lineItems.map((lineItem) => ({
        name: lineItem.name,
        price_currency_code: lineItem.price.currencyCode!,
        product_id: lineItem.productKey,
        quantity: lineItem.quantity,
        sku_id: lineItem.variant.sku,
        unit_price: lineItem.price.centAmount / Math.pow(10, lineItem.price.fractionDigits),
    })),
});

const NostoSessionConnector = (): ReactElement | null => {
    const { cart } = useCart(true);
    const customer = useCustomer();

    if (cart) {
        const nostoCart = mapCartToNostoCart(cart);
        let nostoCustomer: NostoCustomer | undefined;

        if (customer) {
            nostoCustomer = {
                customer_reference: customer.id,
                email: customer.email,
                first_name: customer.firstName,
                last_name: customer.lastName,
            };
        }

        return <NostoSession cart={nostoCart} customer={nostoCustomer} />;
    }

    return SKIP_RENDER;
};

export default NostoSessionConnector;
