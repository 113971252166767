import BloomreachHtml, { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { FormattedMessage, useIntl } from 'react-intl';
import NewPasswordInputs from '../../NewPasswordInputs';
import classNames from 'classnames';
import { BaseProps } from '@mediashop/app/bloomreach/types';

const componentName = 'personal-info-password';

interface PersonalInfoPasswordProps extends BaseProps {
    headline: string;
    passwordText: BrxHtml;
    redirectUrl?: string;
}

export function PersonalInfoPassword({
    className,
    headline,
    passwordText,
    redirectUrl,
}: PersonalInfoPasswordProps): JSX.Element {
    const { formatMessage } = useIntl();

    return (
        <div className={classNames(className, componentName)}>
            {headline && (
                <h3 className={`${componentName}__title`}>
                    <FormattedMessage id="customer.password" />
                </h3>
            )}
            {passwordText ? <BloomreachHtml className={`${componentName}__text`} html={passwordText} /> : SKIP_RENDER}

            <NewPasswordInputs
                buttonText={formatMessage({ id: 'register.change' })}
                buttonStyle="tertiary"
                buttonFullWidth
                changePasswordWithToken={false}
                redirectUrl={redirectUrl}
            />
        </div>
    );
}
