import ApiClient from './ApiClient';
import { RootStore } from '../store';
import {
    CheckEmailRegisteredRequest,
    CheckEmailRegisteredResponse,
    CreatePasswordResetTokenRequest,
    CreatePasswordResetTokenResponse,
    CustomerGetOrderRequest,
    CustomerGetOrderResponse,
    CustomerGetOrdersRequest,
    CustomerGetOrdersResponse,
    CustomerIsSubscribedResponse,
    CustomerQueryContextParams,
    CustomerSignInRequest,
    CustomerSignInResponse,
    CustomerSignOutRequest,
    CustomerSignOutResponse,
    SubscribeToNewsletterRequest,
    SubscribeToNewsletterResponse,
    ResetPasswordRequest,
    ResetPasswordResponse,
    CustomerSignInStateResponse,
    CustomerSignInStateRequest,
    ChangePasswordRequest,
    UpdateCustomerResponse,
    UpdateCustomerRequest,
    UnsubscribeFromNewsletterRequest,
    UnsubscribeFromNewsletterResponse,
    VerifyAccountRequest,
    VerifyAccountResponse,
    CustomerGetSubscriptionsRequest,
    CustomerGetSubscriptionsResponse,
    CustomerCancelSubscriptionRequest,
    CustomerCancelSubscriptionResponse,
    SignUpCustomerRequest,
    SignUpCustomerResponse,
    SetEmarsysTimestampRequest,
    SetEmarsysTimestampResponse,
} from './types/CustomerQueries';
import { SignUpCustomer, CustomerSignIn, CustomerV2 } from './types/ClientCustomer';
import { AddressForm, RegistrationForm } from './types/Address';
import { PaginationOptions } from './types/Common';
import ApiClientV2 from './ApiClientV2';
import { GatewayCustomerV2 } from './types/GatewayCustomer';
import { ApiErrorV2 } from './ApiErrorV2';
import { EMPTY_STRING } from '../constants/semanticConstants';

type CustomerSignInProps = {
    email: string;
    password: string;
    fullName?: string;
    // we may never send 'false' to the backend for approveNewStore as otherwise the approval will be revoked
    approveNewStore?: true;
};

export const constructUrl = (...args: string[]) => {
    return args
        .map((arg) => {
            if (arg.startsWith('http')) {
                return arg.replace(/\/+$/, '');
            }

            return arg.replace(/^\/+|\/+$/g, '');
        })
        .join('/')
        .replace(/([^:]\/)\/+/g, '$1');
};

export default class CustomerClient {
    private apiClient: ApiClient;
    private apiClientV2: ApiClientV2;
    private store: RootStore;

    constructor(apiClient: ApiClient, apiClientV2: ApiClientV2, store: RootStore) {
        this.apiClient = apiClient;
        this.apiClientV2 = apiClientV2;
        this.store = store;
    }

    private getContextParams(): CustomerQueryContextParams {
        const { context } = this.store.getState();
        return {
            locale: context.locale,
            country: context.country,
            currency: context.currency,
        };
    }

    private getLocale() {
        const { context } = this.store.getState();
        return {
            locale: context.locale,
        };
    }

    async performSignUp(data: RegistrationForm, fullName: string, cartId?: string): Promise<SignUpCustomer> {
        const { context, project } = this.store.getState();
        const url = new URL(window.location.href);
        const userCountry = context.country;
        const userLocale = context.locale;
        const country = userCountry.toLowerCase();
        const locale = userLocale.split('-')[0].toLowerCase();
        const takeLanguageFromURL = project.takeLanguageFromUrl;
        let mainUrl: string;
        if (takeLanguageFromURL) {
            mainUrl = constructUrl(url.origin, project.basePath, country, locale, 'account/login');
        } else {
            mainUrl = constructUrl(url.origin, project.basePath, 'account/login');
        }

        return await this.apiClientV2.query<SignUpCustomerRequest, SignUpCustomerResponse>('SignUpCustomer', {
            ...this.getContextParams(),
            accountVerificationUrlBase: mainUrl,
            address: {
                apartment: data.billing_apartment,
                block: data.billing_block,
                city: data.billing_city!,
                company: data.billing_company !== EMPTY_STRING ? data.billing_company : undefined,
                country: data.billing_country!,
                county: data.billing_county,
                floor: data.billing_floor,
                isCompany: data.billing_isCompany?.toLowerCase() === 'true',
                phone: data.billing_phone,
                postalCode: data.billing_postalCode!,
                sector: data.billing_sector,
                stairway: data.billing_stairway,
                streetName: data.billing_streetName!,
                streetNumber: data.billing_streetNumber!,
                vatId: data.billing_vatId !== EMPTY_STRING ? data.billing_vatId : undefined,
            },
            email: data.billing_email!,
            firstName: data.billing_firstName!,
            lastName: data.billing_lastName!,
            salutation: data.billing_salutation!,
            dateOfBirth: data.billing_birthdate !== EMPTY_STRING ? data.billing_birthdate : undefined,
            password: data.password!,
            fullName,
            linkCartId: cartId,
        });
    }

    performSignIn({ email, password, fullName, approveNewStore }: CustomerSignInProps): Promise<CustomerSignIn> {
        return this.apiClientV2.query<CustomerSignInRequest, CustomerSignInResponse>('SignInCustomer', {
            ...this.getContextParams(),
            email,
            password,
            fullName,
            approveNewStore,
        });
    }

    performSignOut() {
        return this.apiClientV2.query<CustomerSignOutRequest, CustomerSignOutResponse>('SignOutCustomer', {
            ...this.getLocale(),
        });
    }

    async getCustomer(): Promise<CustomerV2> {
        const res = await this.apiClientV2.query<Record<string, never>, GatewayCustomerV2>('GetCustomer', {});
        return res.customer;
    }

    /**
     * Get a customers orders (overview / list)
     * @param locale current shop locale
     * @param pagination pagination params
     * @returns
     */
    getOrders(locale: string, { limit = 10, offset = 0 }: PaginationOptions) {
        return this.apiClientV2.query<CustomerGetOrdersRequest, CustomerGetOrdersResponse>('CustomerGetOrders', {
            limit,
            locale,
            offset,
        });
    }

    /**
     * Fetches customer order by ID
     * @param orderId ID of the customers order to be fetched
     * @param locale current shop locale
     */
    getOrder(orderId: string, locale: string) {
        return this.apiClientV2.query<CustomerGetOrderRequest, CustomerGetOrderResponse>('CustomerGetOrder', {
            locale,
            orderId,
        });
    }

    async createPasswordResetToken(email: string, fullName: string): Promise<boolean> {
        const { context, project } = this.store.getState();
        // This cannot be called from SSR so window is defined every time
        const url = new URL(window.location.href);
        const userCountry = context.country;
        const userLocale = context.locale;
        const country = userCountry.toLowerCase();
        const locale = userLocale.split('-')[0].toLowerCase();
        const takeLanguageFromURL = project.takeLanguageFromUrl;
        let mainUrl;
        if (takeLanguageFromURL) {
            mainUrl = constructUrl(url.origin, project.basePath, country, locale, 'account/password-reset');
        } else {
            mainUrl = constructUrl(url.origin, project.basePath, 'account/password-reset');
        }
        const res = await this.apiClientV2.query<CreatePasswordResetTokenRequest, CreatePasswordResetTokenResponse>(
            'CreatePasswordResetToken',
            {
                country: userCountry,
                email,
                fullName,
                locale: context.locale,
                resetPasswordUrlBase: mainUrl,
            }
        );

        if (Object.keys(res).length !== 0) {
            throw new Error((res as ApiErrorV2).message);
        }

        return true;
    }

    async resetPasswordWithToken(token: string, newPassword: string): Promise<ResetPasswordResponse> {
        return await this.apiClientV2.query<ResetPasswordRequest, ResetPasswordResponse>('ResetPassword', {
            newPassword,
            token,
        });
    }

    async changePassword(currentPassword: string, newPassword: string): Promise<void> {
        await this.apiClientV2.query<ChangePasswordRequest, void>('ChangePassword', {
            currentPassword,
            newPassword,
        });
    }

    async updateCustomer(customer: UpdateCustomerRequest): Promise<CustomerV2> {
        const res = await this.apiClientV2.query<UpdateCustomerRequest, UpdateCustomerResponse>(
            'UpdateCustomer',
            customer
        );

        return res.customer;
    }

    async checkEmailRegistered(email: string): Promise<CheckEmailRegisteredResponse> {
        return await this.apiClientV2.query<CheckEmailRegisteredRequest, CheckEmailRegisteredResponse>(
            'CheckEmailRegistered',
            { email }
        );
    }

    async verifyAccount(token: string): Promise<VerifyAccountResponse> {
        return await this.apiClientV2.query<VerifyAccountRequest, VerifyAccountResponse>('VerifyAccount', {
            token,
        });
    }

    async subscribeToNewsletter(
        email: string,
        companyTransactions?: string,
        channel?: string
    ): Promise<SubscribeToNewsletterResponse> {
        const { context } = this.store.getState();

        return await this.apiClientV2.query<SubscribeToNewsletterRequest, SubscribeToNewsletterResponse>(
            'SubscribeToNewsletter',
            {
                channel,
                companyTransactions,
                country: context.country,
                email,
                locale: context.locale,
            }
        );
    }

    async unsubscribeCustomerFromNewsletter(): Promise<UnsubscribeFromNewsletterResponse> {
        return await this.apiClientV2.query<void, UnsubscribeFromNewsletterResponse>(
            'UnsubscribeCustomerFromNewsletter'
        );
    }

    async isCustomerSubscribed(): Promise<boolean> {
        const res = await this.apiClientV2.query<void, CustomerIsSubscribedResponse>(
            'GetCustomerNewsletterSubscriptionState'
        );
        return res.isSubscribed;
    }

    unsubscribeNewsletter(uid: string) {
        return this.apiClientV2.query<UnsubscribeFromNewsletterRequest, UnsubscribeFromNewsletterResponse>(
            'UnsubscribeFromNewsletter',
            {
                uid,
            }
        );
    }

    async getCatalog(address: AddressForm): Promise<void> {
        const contextParams = this.getContextParams();
        const params = {
            ...contextParams,
            ...address,
        };

        await this.apiClient.query({
            query: 'CatalogRequest',
            parameters: params,
        });
    }

    async connectCustomerCard(cardNumber: string, clubEmail: string): Promise<void> {
        await this.apiClient.query({
            query: 'AssignClubCard',
            parameters: {
                cardNumber,
                clubEmail,
            },
        });
    }

    async setEmarsysField(uid: string, fieldId: number): Promise<void> {
        await this.apiClientV2.query<SetEmarsysTimestampRequest, SetEmarsysTimestampResponse>('SetEmarsysTimestamp', {
            fieldId,
            uid,
        });
    }

    async getCustomerSignInState(): Promise<CustomerSignInStateResponse> {
        return await this.apiClientV2.query<CustomerSignInStateRequest, CustomerSignInStateResponse>(
            'CheckCustomerSignInState'
        );
    }

    getSubscriptions() {
        return this.apiClientV2.query<CustomerGetSubscriptionsRequest, CustomerGetSubscriptionsResponse>(
            'GetSubscriptions'
        );
    }

    async cancelSubscription(subscriptionId: string): Promise<void> {
        await this.apiClientV2.query<CustomerCancelSubscriptionRequest, CustomerCancelSubscriptionResponse>(
            'CancelSubscription',
            {
                subscriptionId,
            }
        );
    }
}
