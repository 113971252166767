import { useEffect, useRef, useState } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import useEventListener from '@mediashop/app/hooks/useEventListener';
import { DateObject } from '@mediashop/app/helper/DateStringHelper';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import Input from '../Input';
import classNames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';

dayjs.extend(isLeapYear);
dayjs.extend(customParseFormat);

type DateOfBirthPros = BaseProps & {
    isRequired: boolean;
    dateOfBirth?: DateObject;
    defaultValue?: {
        day: string;
        month: string;
        year: string;
    };
    inputName?: {
        day: string;
        month: string;
        year: string;
    };
};

/**
 * Encapsulates date of birth input fields. All separate input fields are tested in conjunction for validity using the
 * html5 form validation api. This is done by observing the entered values on all input fields and adjust the input
 * validation pattern(s) accordingly.
 *
 * For dates in the future the setCustomValidity function to trigger invalid state on day, month, year input fields.
 * To suppress the input error bubbles the 'invalid' event prevented by event handlers.
 *
 * Allowed date formats:
 * 01.1.1900, 29.2.2004, 1.1.1989
 * valid dates: between 1.1.1900 and now. no need to check for legal age as by specification.
 */

function DateOfBirth({ isRequired = false, dateOfBirth, defaultValue, inputName }: DateOfBirthPros) {
    const { formatMessage } = useIntl();

    const dayRef = useRef<HTMLInputElement>(null);
    const monthRef = useRef<HTMLInputElement>(null);
    const yearRef = useRef<HTMLInputElement>(null);

    const [isTouched, setIsTouched] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [day, setDay] = useState(dateOfBirth?.day ?? 1);
    const [month, setMonth] = useState(dateOfBirth?.month ?? 1);
    const [year, setYear] = useState(dateOfBirth?.year ?? 1900);

    /**
     * Prevent error bubbles to display
     */
    useEventListener(
        'invalid',
        (event) => {
            event.preventDefault();
        },
        dayRef.current
    );

    useEventListener(
        'invalid',
        (event) => {
            event.preventDefault();
        },
        monthRef.current
    );

    useEventListener(
        'invalid',
        (event) => {
            event.preventDefault();
        },
        yearRef.current
    );

    /**
     * Sets the pattern for the inputs
     * OR sets custom validation on all input fields - above event listeners are needed for not displaying error bubbles
     * Validate date for:
     * - correct day values per month (including leap year)
     * - if date is in the future
     */
    useEffect(() => {
        const dobFormats = ['YYYY-MM-DD', 'YYYY-MM-D', 'YYYY-M-DD', 'YYYY-M-D'];

        const parsedDateOfBirth = dayjs(`${year}-${month}-${day}`, dobFormats);

        if (!dayRef.current || !monthRef.current || !yearRef.current) {
            return;
        }

        // AFTER today
        if (isTouched) {
            const today = dayjs();
            [dayRef, monthRef, yearRef].forEach((ref) => {
                const isMoreThan100Years = today.diff(parsedDateOfBirth, 'years') >= 100;
                const isDobValid = !(parsedDateOfBirth.isAfter(today) || isMoreThan100Years);
                setIsValid(isDobValid);

                ref.current?.setCustomValidity(isDobValid ? '' : '--');
                ref.current?.reportValidity();
            });
        }
    }, [day, month, year]);

    const componentName = 'date-of-birth';

    return (
        <div className={`${componentName}__container`}>
            <div className={`${componentName}__input-wrapper`}>
                {/* Day: depends on month */}
                <Input
                    type="tel"
                    className={`form-row__child--1 ${componentName}__day`}
                    name={inputName?.day ?? 'billing.birthday.day'}
                    placeholder={formatMessage({ id: 'address.dayPlaceholder' })}
                    value={defaultValue?.day}
                    required={isRequired}
                    onChange={(event) => {
                        setDay(event.target.value);
                        setIsTouched(true);
                    }}
                />
                {/* Month */}
                <Input
                    type="tel"
                    className={`form-row__child--1 ${componentName}__month`}
                    name={inputName?.month ?? 'billing.birthday.month'}
                    placeholder={formatMessage({ id: 'address.monthPlaceholder' })}
                    value={defaultValue?.month}
                    required={isRequired}
                    onChange={(event) => {
                        setMonth(event.target.value);
                        setIsTouched(true);
                    }}
                />
                {/* Year */}
                <Input
                    type="tel"
                    className={`form-row__child--2 ${componentName}__year`}
                    name={inputName?.year ?? 'billing.birthday.year'}
                    placeholder={formatMessage({ id: 'address.yearPlaceholder' })}
                    value={defaultValue?.year}
                    required={isRequired}
                    onChange={(event) => {
                        setYear(event.target.value);
                        setIsTouched(true);
                    }}
                />
            </div>
            {!isValid && (
                <div className={`${componentName}__error-wrapper`}>
                    <span className={classNames('input__error')}>
                        <FormattedMessage id="address.birthDayError" />
                    </span>
                </div>
            )}
        </div>
    );
}

export default injectComponent('pattern.atom.DateOfBirth', DateOfBirth);
