import { ValueOf } from '../../helper/utility';

export const PageTypes = {
    ACCOUNT: 'Account',
    CATEGORY: 'Category',
    CHECKOUT: 'Checkout',
    CONTENT: 'Content',
    ERROR: 'Error',
    HOME: 'Home',
    OTHER: 'Other',
    PRODUCT: 'Product',
    SEARCH: 'Search',
} as const;

const fallbackPageTypeSearchByLocation = (pathName: string): ValueOf<typeof PageTypes> => {
    const pathNameSplit = pathName.split('/');
    const conditions = {
        [PageTypes.PRODUCT]: pathNameSplit.includes('p'),
        [PageTypes.CATEGORY]: pathNameSplit.includes('c'),
        [PageTypes.SEARCH]: pathNameSplit.includes('search-results'),
        [PageTypes.CHECKOUT]:
            pathNameSplit.includes('checkout') ||
            pathNameSplit.includes('checkoutsuccess') ||
            pathNameSplit.includes('cart'),
        [PageTypes.ACCOUNT]:
            pathNameSplit.includes('order-overview') ||
            pathNameSplit.includes('settings') ||
            pathNameSplit.includes('personal-information'),
    };

    const matchingPageTypes = Object.keys(conditions).filter((key) => conditions[key]);
    const firstMatchingPageType = matchingPageTypes[0] as ValueOf<typeof PageTypes>;

    return firstMatchingPageType ?? PageTypes.OTHER;
};

export const pageTypeMapper = (pageType: string, pathName: string): ValueOf<typeof PageTypes> => {
    switch (pageType) {
        case 'homepage':
            return PageTypes.HOME;
        case 'products-default':
            return PageTypes.PRODUCT;
        case 'category-default':
            return PageTypes.CATEGORY;
        case 'search-results-contentpage':
            return PageTypes.SEARCH;
        case 'cart':
        case 'checkout':
        case 'checkoutsuccess':
            return PageTypes.CHECKOUT;
        case 'pagenotfound':
            return PageTypes.ERROR;
        case 'order-overview-AccountManagementNavigation':
        case 'personal-information-AccountManagementNavigation':
        case 'settings-AccountManagementNavigation':
            return PageTypes.ACCOUNT;
        default:
            return fallbackPageTypeSearchByLocation(pathName);
    }
};
