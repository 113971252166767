import Icon from '@mediashop/base/pattern//atom/Icon';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import classNames from 'classnames';

const componentName = 'chevron-button';

interface ArrowProps extends BaseProps {
    direction: 'up' | 'down';
    extraClassName?: string;
    onClick: () => void;
}

function Arrow({ direction, className, extraClassName, onClick }: ArrowProps) {
    const arrowIconName = direction === 'up' ? 'ChevronUp' : 'ChevronDown';

    return (
        <button
            type="button"
            onClick={onClick}
            className={classNames(componentName, `${componentName}--${direction}`, className, extraClassName)}
        >
            <Icon name={arrowIconName} />
        </button>
    );
}

export default injectComponent('pattern.molecule.Arrow', Arrow);
