import { useCallback, useEffect } from 'react';
import useIsClientSide from '../hooks/useIsClientSide';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';
import { SKIP_RENDER } from '../constants/semanticConstants';

interface Props {
    message: string;
    when: boolean;
}

/**
 * If when is set, this component will prevent navigation & reloading of the current browser-tab.
 * Instead the user will be asked to confirm his choice.
 */
export default function NavigationBlocker({ message, when }: Props): null {
    const isClientSide = useIsClientSide();

    const unloadHandler = useCallback((event) => {
        event.preventDefault();
        event.returnValue = message;
    }, []);

    useEffect(() => {
        if (isClientSide && when) {
            window.addEventListener('beforeunload', unloadHandler, { capture: true });
        } else {
            window.removeEventListener('beforeunload', unloadHandler, { capture: true });
        }

        return () => {
            window.removeEventListener('beforeunload', unloadHandler, { capture: true });
        };
    }, [when, isClientSide, unloadHandler]);

    usePrompt({
        message,
        when,
    });

    return SKIP_RENDER;
}
