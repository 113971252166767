import { FormattedMessage } from 'react-intl';
import StarRating from '../../atom/StarRating';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';

interface StarRatingBarProps extends BaseProps {
    rating: number;
    ratingCount: number;
}

/**
 * Render stars and an acompanying message.
 */
function StarRatingBar({ rating, ratingCount }: StarRatingBarProps) {
    return (
        <div className="star-rating-bar">
            <StarRating className="star-rating-bar__star-rating" rating={rating} />
            <FormattedMessage id="product.ratingCount" values={{ count: ratingCount }} />
        </div>
    );
}

export default injectComponent('pattern.molecule.StarRatingBar', StarRatingBar);
