import * as React from 'react';
import { Product, ProductWithActiveVariant } from '@mediashop/app/api/types/ClientProduct';
import CroppedImage from '@mediashop/base/pattern/atom/CroppedImage';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';
import { Link } from 'react-router-dom';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import { getSearchParam } from '../../../Product/helper';

const componentName = 'product-suggestions';

type Props = {
    productSuggestions: Array<ProductWithActiveVariant>;
    handleProductClick: (event: React.MouseEvent<HTMLAnchorElement>, product: Product) => void;
};

export function ProductSuggestions({ productSuggestions, handleProductClick }: Readonly<Props>): JSX.Element {
    const { formatLinkBase } = useReactRouterLink();

    const getProductUrlFormatted = (product: Product) => {
        const base = formatLinkBase(`p/${product?.slug}`);
        const search = getSearchParam(product);
        return search ? `${base}?${search}` : base;
    };

    return (
        <>
            {productSuggestions.map((product) => (
                <Link
                    key={product.id}
                    to={getProductUrlFormatted(product)}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleProductClick(event, product)}
                    className={`${componentName}__autosuggest-result`}
                >
                    <CroppedImage
                        altText={product.activeVariant?.attributes.name ?? product?.name}
                        src={product.activeVariant?.imageUri ?? product.imageUri}
                        variant={CroppedImageVariant.SearchSuggestion}
                    />
                    <span className={`${componentName}__search-result-text`}>{product.name}</span>
                </Link>
            ))}
        </>
    );
}
