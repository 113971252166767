import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import { useProject } from './useProject';
import { useShopContext } from './useShopContext';

const formatLinkBase = ({
    takeLanguageFromUrl,
    basePath,
    country,
    locale,
    path,
}: {
    takeLanguageFromUrl: boolean;
    basePath: string;
    country: string;
    locale: string;
    path: string;
}): string => {
    const localeUrlPath = takeLanguageFromUrl ? `${country}/${locale}` : EMPTY_STRING;
    const concatenatedPath = [basePath, localeUrlPath, path].filter(Boolean).join('/');
    const intermediatePath = `/${concatenatedPath}`;

    return intermediatePath.replace(/\/\//, '/');
};

type useReactRouterLinkReturnType = {
    formatLinkBase: (path: string) => string;
};

/**
 * A hook that returns a format function to generate a correct link for usage with react-router-dom (<Link />).
 */
export default function useReactRouterLink(): useReactRouterLinkReturnType {
    const { takeLanguageFromUrl } = useProject();
    const { country: userCountry, locale: userLocale } = useShopContext();

    const country = takeLanguageFromUrl ? userCountry.toLowerCase() : EMPTY_STRING;
    const locale = takeLanguageFromUrl ? userLocale.split('-')[0].toLowerCase() : EMPTY_STRING;

    /*
     * React-router already knows the basePath so we set it to empty.
     * However, it´s left here to be explicit about this.
     */
    const basePath = EMPTY_STRING;

    return {
        formatLinkBase: (path) =>
            formatLinkBase({
                takeLanguageFromUrl,
                basePath,
                country,
                locale,
                path,
            }),
    };
}
