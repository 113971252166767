import * as React from 'react';

type TrackableButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    trackingKey: string;
};

export const TrackableButton = React.forwardRef<HTMLButtonElement, TrackableButtonProps>(
    ({ trackingKey, ...props }, ref): JSX.Element => {
        return <button ref={ref} data-gtm-element={trackingKey} {...props} />;
    }
);

TrackableButton.displayName = 'TrackableButton';
