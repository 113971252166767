import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, HippoIconProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const FlyoutMolecule = lazy(() => lazyRetry(() => import('./Flyout')));

export type FlyoutProps = BaseProps & {
    anchorId: string;
    style: 'primary' | 'secondary' | 'tertiary' | 'default' | 'info' | 'warning' | 'error' | 'success';
    text?: string;
    icon?: HippoIconProps;
};

const Flyout = (props: FlyoutProps): JSX.Element => (
    <LazyComponent>
        <FlyoutMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.Flyout', Flyout);
