import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface PageState {
    title: string;
    pageType: string;
    pathName: string;
}

const initialState: PageState = {
    title: '',
    pageType: '',
    pathName: '',
};

const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        pageReceived(state, action: PayloadAction<{ page: PageState }>) {
            const { page } = action.payload;
            return {
                ...state,
                ...page,
            };
        },
        pageTitleChanged(state, action: PayloadAction<{ title: string }>) {
            const { title } = action.payload;
            return {
                ...state,
                title,
            };
        },
        pageTypeChanged(state, action: PayloadAction<{ pageType: string }>) {
            const { pageType } = action.payload;
            return {
                ...state,
                pageType,
            };
        },
    },
});

export const { pageReceived, pageTitleChanged, pageTypeChanged } = pageSlice.actions;

export default pageSlice.reducer;
