import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { PaymentMethodName } from '@mediashop/app/domain/Payment';

const SummaryPaymentMolecule = lazy(() => lazyRetry(() => import('./Payment')));

export type SummaryPaymentProps = BaseProps & {
    onChangeClick?: () => void;
    paymentMethod: PaymentMethodName;
};

const SummaryPayment = (props: SummaryPaymentProps): JSX.Element => (
    <LazyComponent>
        <SummaryPaymentMolecule {...props} />
    </LazyComponent>
);

export default SummaryPayment;
