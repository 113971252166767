import { BaseProps } from '@mediashop/app/bloomreach/types';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import Button from '@mediashop/base/pattern/atom/Button';
import Icon from '@mediashop/base/pattern/atom/Icon';
import PhoneNumberInput from '@mediashop/base/pattern/atom/PhoneNumberInput';
import classNames from 'classnames';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { isValidPhoneNumber } from 'libphonenumber-js';

const componentName = 'personal-info-phone-number';

type PhoneNumberFormValues = {
    phoneNumber: string;
};

interface PersonalInfoPhoneNumberProps extends BaseProps {
    isLoading: boolean;
    onSubmit: (phoneNumber: string) => void;
    phoneNumber?: string;
    country?: string;
}

export function PersonalInfoPhoneNumber({
    className,
    isLoading,
    onSubmit,
    phoneNumber = EMPTY_STRING,
    country = EMPTY_STRING,
}: PersonalInfoPhoneNumberProps): JSX.Element {
    const { formatMessage } = useIntl();

    const [isEditActive, setEditActive] = useState(false);

    const handleSubmit = (values: PhoneNumberFormValues) => {
        if (isValidPhoneNumber(values.phoneNumber)) {
            setEditActive(false);

            onSubmit(values.phoneNumber);
        }
    };

    const formik = useFormik<PhoneNumberFormValues>({
        enableReinitialize: true,
        validateOnMount: false,
        validateOnChange: true,
        initialValues: { phoneNumber },
        onSubmit: handleSubmit,
    });

    return (
        <div className={classNames(className, componentName)}>
            {/** Header */}
            <div className={`${componentName}__header`}>
                <h3 className={`${componentName}__title`}>
                    <FormattedMessage id="customer.phone" />
                </h3>
                <span role="button" onClick={() => setEditActive((editActive) => !editActive)}>
                    <Icon name="Pencil" className={`${componentName}__edit-icon`} />
                </span>
            </div>

            {/** Readonly display */}
            {!isEditActive ? <span>{phoneNumber}</span> : SKIP_RENDER}

            {/** Form */}
            {isEditActive ? (
                <form onSubmit={formik.handleSubmit}>
                    {/** Phone number input */}
                    <PhoneNumberInput
                        className={`${componentName}__input`}
                        id="phoneNumber"
                        label={formatMessage({ id: 'address.phonePlaceholder' })}
                        name="phoneNumber"
                        onChange={formik.handleChange}
                        required
                        value={formik.values.phoneNumber}
                        country={country}
                    />

                    {/** Submit button */}
                    <Button style="tertiary" fullWidth loading={isLoading} type="submit">
                        <FormattedMessage id="register.change" />
                    </Button>
                </form>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}
