import { Cart, CartLineItem } from '../../../api/types/ClientCart';
import { ExchangeRatesSubset } from '../../../types/exchangeRates';
import { EmarsysCart } from '../types';
import { getExchangeRate } from './getExchangeRate';

const getLineItemPrice = (lineItem: CartLineItem, exchangeRate: number) => {
    if (lineItem.lineItemMode === 'GiftLineItem') {
        return 0;
    }

    const centAmount = Math.round(lineItem.price.centAmount / exchangeRate);

    return centAmount / 10 ** lineItem?.price.fractionDigits;
};

export const getEmarsysCart = (
    cart?: Cart,
    emarsysAccountCurrency?: string,
    exchangeRates?: ExchangeRatesSubset
): EmarsysCart => {
    if (cart?.lineItems) {
        const exchangeRate = getExchangeRate(
            cart.lineItems[0]?.price?.currencyCode,
            exchangeRates,
            emarsysAccountCurrency
        );

        return cart?.lineItems?.map((lineItem) => ({
            item: lineItem.productKey,
            quantity: lineItem.quantity,
            price: getLineItemPrice(lineItem, exchangeRate) * lineItem.quantity,
        }));
    }

    return [];
};

const getLineItemPriceByOrder = (lineItem: CartLineItem, exchangeRate: number) => {
    if (lineItem.lineItemMode === 'GiftLineItem') {
        return 0;
    }

    const centAmount = Math.round(lineItem.price.centAmount / exchangeRate);
    return centAmount / 10 ** lineItem.price.fractionDigits;
};

export const getEmarsysCartByOrder = (
    cart?: Cart,
    emarsysAccountCurrency?: string,
    exchangeRates?: ExchangeRatesSubset
): EmarsysCart => {
    if (cart?.lineItems) {
        const price = cart.lineItems[0].price;

        const exchangeRate = getExchangeRate(price.currencyCode, exchangeRates, emarsysAccountCurrency);

        return cart?.lineItems?.map((lineItem) => ({
            item: lineItem.productKey,
            quantity: lineItem.quantity,
            price: getLineItemPriceByOrder(lineItem, exchangeRate) * lineItem.quantity,
        }));
    }

    return [];
};
