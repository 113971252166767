import { QueryFunctionContext } from '@tanstack/react-query';
import { AddressConfiguration } from '../types/AddressConfiguration';
import { QueryKeys } from '../../queries';

type AddressConfigCountry = QueryKeys['addresses']['country'];

export const addressConfigurationLoader = async (
    ctx: QueryFunctionContext<AddressConfigCountry['queryKey']>
): Promise<AddressConfiguration> => {
    const [, , country] = ctx.queryKey;

    const data = await import(`../../../../config/addresses/${country.toUpperCase()}.yml`);
    return data.default[country] as AddressConfiguration;
};
