import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import Badge from '@mediashop/catalog-base/pattern/atom/Badge';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { Money } from '@mediashop/app/api/types/Money';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { BadgeObject } from '../../../../types/badges';
import OriginalPrice from '../../../atom/OriginalPrice';
import MoneyFunc from '@mediashop/app/domain/Money';
import LoadingIndicator from '@mediashop/base/pattern/atom/LoadingIndicator';
import ClubPrice from '../../../../../base/pattern/molecule/ClubPrice';
import { Price } from '@mediashop/base/pattern/molecule/Price';
import { useCheaperSet } from '@mediashop/app/hooks/useCheaperSet';

type ProductStageInfosProps = BaseProps & {
    activeVariant?: Variant;
    isLoading?: boolean;
    showCheapestPrice?: boolean;
    showFrom: boolean;
    detailBadges: BadgeObject[];
    hasVariants: boolean;
    hasSaleBadge?: boolean;
};

/**
 * Display detailed product information.
 */
// eslint-disable-next-line complexity
function ProductStageInfos({
    className,
    detailBadges = [],
    activeVariant,
    isLoading = false,
    showCheapestPrice = false,
    showFrom = false,
    hasVariants = false,
    hasSaleBadge,
}: ProductStageInfosProps) {
    const priceClass = detailBadges.length ? `price-colored-${detailBadges[0]?.badgeProps?.style}` : ``;
    const hasSavings = detailBadges.filter((badge) => badge.type === 'SAVINGS').length > 0;

    let savings = {} as Money;
    if (activeVariant?.originalPrice && activeVariant?.price) {
        savings = MoneyFunc.subtract(activeVariant.originalPrice, activeVariant.price);
    }

    const price =
        showCheapestPrice && activeVariant?.cheapestPrice
            ? (activeVariant?.cheapestPrice ?? activeVariant?.price)
            : activeVariant?.price;

    const { showCheaperSetPrice, cheaperSetPrice } = useCheaperSet(activeVariant);

    if (isLoading) {
        return (
            <div className={`${className}__infos`}>
                <LoadingIndicator className="product-stage__loading-indicator" />
            </div>
        );
    }

    return (
        <div className={`${className}__infos`}>
            <div className={`${className}__price-container`}>
                {activeVariant ? (
                    <OriginalPrice
                        originalPrice={showCheaperSetPrice ? activeVariant.price : activeVariant.originalPrice}
                        price={showCheaperSetPrice ? cheaperSetPrice! : activeVariant.price}
                        className={`${className}__old-price`}
                    />
                ) : (
                    SKIP_RENDER
                )}

                {detailBadges?.length ? (
                    <div
                        className={classNames(`${className}__marketing`, {
                            [`${className}__marketing--variants`]: hasVariants,
                        })}
                    >
                        {detailBadges.map((badge) => (
                            <Badge key={badge.badgeProps.text} {...badge.badgeProps} />
                        ))}
                    </div>
                ) : (
                    SKIP_RENDER
                )}

                {price ? (
                    <div
                        className={classNames(
                            `${className}__price`,
                            priceClass,
                            { 'price-colored-savings': hasSavings && savings?.centAmount > 0 },
                            { [`${className}__price--sale`]: hasSaleBadge || showCheaperSetPrice }
                        )}
                    >
                        {showCheaperSetPrice ? (
                            <Price price={cheaperSetPrice!} />
                        ) : (
                            <Price price={price} showFrom={showFrom && activeVariant?.existsOtherThenCheapestPrices} />
                        )}
                    </div>
                ) : (
                    SKIP_RENDER
                )}

                {activeVariant?.customerPrice ? (
                    <ClubPrice
                        price={activeVariant?.cheapestCustomerPrice ?? activeVariant?.customerPrice}
                        showFrom={activeVariant?.existsOtherThenCheapestCustomerPrices}
                    />
                ) : (
                    SKIP_RENDER
                )}
            </div>
        </div>
    );
}

export default injectComponent('pattern.organism.ProductStageInfos', ProductStageInfos, 'catalog-base');
