import { injectComponent } from '@mediashop/app/component-injector';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';

/**
 * Email Confirmation component
 */
function EmailConfirmation() {
    const componentName = 'email-confirmation';
    return (
        <ContentWrapper className={`${componentName}__content-wrapper`}>
            <div>EMail Confirmation Component</div>
        </ContentWrapper>
    );
}

export default injectComponent('pattern.molecule.EmailConfirmation', EmailConfirmation, 'catalog-base');
