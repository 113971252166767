import { Product } from '../api/types/ClientProduct';
import BaseUrlHelper from '../helper/BaseUrlHelper';
import { useProject } from './useProject';
import useReactRouterLink from './useReactRouterLink';

type TrackSearchProps = {
    categoryPath?: string;
    isKeyword: boolean;
    limit: number;
    offset: number;
    products: Product[];
    query?: string;
    totalProductCount: number;
    type: 'serp' | 'autocomplete' | 'category';
};

const useNostoSearchAnalytics = () => {
    const { basePath } = useProject();
    const { formatLinkBase } = useReactRouterLink();

    const trackSearch = ({
        categoryPath,
        isKeyword,
        limit,
        offset,
        products,
        query,
        totalProductCount,
        type,
    }: TrackSearchProps) => {
        if (typeof window.nostojs === 'function') {
            window.nostojs(function (api) {
                api.recordSearch(
                    type,
                    {
                        query,
                    },
                    {
                        products: {
                            categoryPath,
                            hits: products.map((product) => ({ productId: product.key })),
                            total: totalProductCount,
                            size: limit,
                            from: offset,
                        },
                    },
                    {
                        isKeyword,
                    }
                );
            });
        }
    };

    const trackSearchOnSubmit = (query: string) => {
        if (typeof window.nostojs === 'function') {
            window.nostojs(function (api) {
                api.recordSearchSubmit(query);
            });
        }
    };

    const trackProductClick = (type: string, product: Product) => {
        if (typeof window.nostojs === 'function') {
            const productUrl = BaseUrlHelper.addBasePathToPath(
                formatLinkBase(`p/${product.slug}`),
                window.location.origin + basePath
            );

            window.nostojs(function (api) {
                api.recordSearchClick(type, { productId: product.key, productUrl });
            });
        }
    };

    return {
        trackSearch,
        trackSearchOnSubmit,
        trackProductClick,
    };
};

export default useNostoSearchAnalytics;
