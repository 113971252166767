import { BaseProps } from '@mediashop/app/bloomreach/types';
import BloomreachHtml, { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { useEffect, useState, type CSSProperties } from 'react';

export type TextCompoundProps = {
    desktop: BrxHtml;
    mobile: BrxHtml;
    style?: CSSProperties;
} & BaseProps;

const TextCompound = ({ desktop, mobile, className, style }: TextCompoundProps): JSX.Element => {
    const [device, setDevice] = useState('desktop');
    const deviceType = useDeviceType();

    useEffect(() => {
        setDevice(deviceType);
    }, [deviceType]);

    const isMobileDevice = device === 'mobile';

    const mobileText: BrxHtml | undefined = typeof mobile === 'string' || !mobile?.value ? desktop : mobile;

    return <BloomreachHtml html={isMobileDevice ? mobileText : desktop} className={className} style={style} />;
};

export default TextCompound;
