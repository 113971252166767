import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const EmarsysFieldWriterLazy = lazy(() => lazyRetry(() => import('./EmarsysFieldWriter')));

export type EmarsysFieldWriterProps = BaseProps & {
    ctaText: string;
    fieldId: string;
};

const EmarsysFieldWriter = (props: EmarsysFieldWriterProps): JSX.Element => (
    <LazyComponent>
        <EmarsysFieldWriterLazy {...props} />
    </LazyComponent>
);

export default EmarsysFieldWriter;
