import { lazy } from 'react';
import { Product } from '@mediashop/app/api/types/ClientProduct';
import { CartLineItem } from '@mediashop/app/api/types/ClientCart';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const WKOUpsellProductMolecule = lazy(() => lazyRetry(() => import('./WKOUpsellProduct')));

export type WKOUpsellProductProps = {
    wkoUpsellProduct?: Product;
    onClose: () => void;
    closeWkoModal?: (value: boolean) => void;
    wkoGiftProduct?: Product;
    deviceType?: string;
    mainProduct?: CartLineItem;
};

const WKOUpsellProduct = (props: WKOUpsellProductProps): JSX.Element => (
    <LazyComponent>
        <WKOUpsellProductMolecule {...props} />
    </LazyComponent>
);

export default WKOUpsellProduct;
