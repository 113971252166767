import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BackgroundProps, BaseProps, BrxCTAButton, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const DownloadWithImageItemMolecule = lazy(() => lazyRetry(() => import('./DownloadWithImageItem')));

export type DownloadWithImageItemProps = BaseProps & {
    imageWithAltText: BrxImageWithAltText;
    roundedImages: boolean;
    ctabutton: BrxCTAButton;
    parentBackground: BackgroundProps;
    text: string;
};

const DownloadWithImageItem = (props: DownloadWithImageItemProps): JSX.Element => (
    <LazyComponent>
        <DownloadWithImageItemMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.DownloadWithImageItem', DownloadWithImageItem);
