import BaseUrlHelper from '@mediashop/app/helper/BaseUrlHelper';
import { BrxPageModel } from '../types';
import ProductVariantIdParser from './ProductVariantIdParser';
import { sanitizeProductIds } from '@mediashop/app/helper/sanitizeProductIds';
import { getProductSlug } from '../../helper/getProductSlug';

type ExtractedProductReferences = {
    ids: string[];
    slug?: string;
};

/**
 * Extract all productIds from given bloomreach pageData.
 */
export const extractProductReferences = (
    pageData: BrxPageModel,
    currentLocation: { pathname: string }
): ExtractedProductReferences => {
    const data: ExtractedProductReferences = { ids: [], slug: undefined };

    // eslint-disable-next-line complexity
    Object.values(pageData).forEach((element) => {
        if (element.data?.productId) {
            data.ids.push(element.data?.productId);
        }
        if (element.data?.productVariantId) {
            const productId = ProductVariantIdParser.getProductId(element?.data?.productVariantId);
            if (productId) {
                data.ids.push(productId);
            }
        }

        if (element.data?.productIDs) {
            Array.prototype.push.apply(data.ids, element.data?.productIDs);
        }

        if (element.data?.productIds) {
            Array.prototype.push.apply(data.ids, element.data?.productIds);
        }

        if (element.data?.specialDealProductId) {
            data.ids.push(element.data?.specialDealProductId);
        }

        if (element.data?.dealOfTheDayList) {
            data.ids.push(...(element.data?.dealOfTheDayList?.map((item) => item.productId) ?? []));
        }

        /**
         * Get the slug for the product if we have product details component
         */
        if (element.name === 'productdetails' && currentLocation.pathname?.includes('/p/')) {
            const urlObject = BaseUrlHelper.getUrlObject(currentLocation.pathname);
            data.slug = getProductSlug(urlObject.pathname);
        }
    });

    // remove Bloomreach-Comments from IDs
    data.ids = sanitizeProductIds(data.ids);

    return data;
};
