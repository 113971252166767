import { Product } from '@mediashop/app/api/types/ClientProduct';
import { injectComponent } from '@mediashop/app/component-injector';
import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const BundleItemLazy = lazy(() => lazyRetry(() => import('./BundleItem')));

export type BundleItemProps = BaseProps & {
    product: Product;
};

const BundleItem = (props: BundleItemProps): JSX.Element => (
    <LazyComponent>
        <BundleItemLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.ProductStage.BundleItem', BundleItem);
