import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { QueryKeys } from '../../constants/queryKeys';
import { GatewayProductReview } from '../../api/types/GatewayReview';
import { REVIEWS_PAGE_LIMIT } from '../../constants/api';
import { useLoader } from '../../store/loader';

type Props = {
    enabled?: boolean;
    page: number;
    sku: string;
};

export type UseProductReviewsReturnProps = {
    isFetching: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    reviews: GatewayProductReview[];
    topReview?: GatewayProductReview;
    totalReviewCount: number;
};

/**
 * Hook that fetches product reviews
 */
export const useProductReviews = ({ enabled = true, page, sku }: Props): UseProductReviewsReturnProps => {
    const { productClient } = useLoader();
    const [topReview, setTopReview] = useState<GatewayProductReview | undefined>(undefined);

    const getReviewsBySkuAndPage = async (sku: string, page: number) =>
        await productClient.getReviews(sku, REVIEWS_PAGE_LIMIT, page);

    const {
        data: { results: reviews = [], total: totalReviewCount = 0 } = {},
        isFetching,
        isLoading,
        isSuccess,
    } = useQuery({
        queryKey: [QueryKeys.FETCH_PRODUCT_REVIEWS, sku, page],
        queryFn: () => getReviewsBySkuAndPage(sku, page),
        select: (data) => {
            const retVal = { ...data };
            if (data?.results?.length && page === 0) {
                const [top, ...otherReviews] = data.results;
                if (topReview === undefined) {
                    setTopReview(top);
                }
                retVal.results = otherReviews;
            }

            return retVal;
        },
        enabled,
        placeholderData: keepPreviousData,
        refetchOnMount: 'always',
    });

    return {
        isFetching,
        isLoading,
        isSuccess,
        reviews,
        topReview,
        totalReviewCount,
    };
};
