import { lazy } from 'react';
import { Address } from '@mediashop/app/api/types/Address';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';

const AddressMolecule = lazy(() => lazyRetry(() => import('./Address')));

export interface AddressProps extends BaseProps {
    address: Address;
    hideCompanyInformation?: boolean;
    hideEmail?: boolean;
    hidePhone?: boolean;
}

const AddressComponent = (props: AddressProps): JSX.Element => (
    <LazyComponent>
        <AddressMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.Address', AddressComponent, 'catalog-base');
