import { Category } from '@mediashop/app/api/types/ClientCategory';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import { useAppDispatch } from '@mediashop/app/store/hooks/hooks';
import classNames from 'classnames';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCategory } from '@mediashop/app/hooks/useCategory';
import { selectedCategoryIdChanged } from '@mediashop/app/store/reducer/category';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

const componentName = 'mobile-menu-item';

const getSubMenuSlugFromUrl = (path: string): string => {
    const pathWithoutTrailingSlash = path.endsWith('/') ? path.slice(0, -1) : path;
    return pathWithoutTrailingSlash.substring(pathWithoutTrailingSlash.lastIndexOf('/') + 1);
};

type MobileMenuItemProps = BaseProps & {
    menuItem: Category;
    closeMenu: () => void;
};

const MobileMenuItem = ({ menuItem, closeMenu }: MobileMenuItemProps): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const { formatLinkBase } = useReactRouterLink();
    const { selectedCategoryId } = useCategory();
    const dispatch = useAppDispatch();

    const [isSubmenuOpen, setIsSubmenuOpen] = useState<boolean>(location.pathname.includes(menuItem.slug));

    const navigateToPLP = (event, childItem: Category | undefined = undefined) => {
        event.preventDefault();

        const targetCategoryId = childItem?.id ?? menuItem.id;

        if (selectedCategoryId !== targetCategoryId) {
            dispatch(selectedCategoryIdChanged({ selectedCategoryId: targetCategoryId }));
            navigate(childItem ? `c/${menuItem.slug}/${childItem.slug}` : `c/${menuItem.slug}`);
        }
        closeMenu();
    };

    const subMenuUrlSlug = getSubMenuSlugFromUrl(location.pathname);

    return (
        <li
            className={classNames(componentName, {
                [`${componentName}__arrow-up`]: isSubmenuOpen,
                [`${componentName}__arrow-down`]: !isSubmenuOpen,
                [`${componentName}__has-children`]: menuItem.children.length,
            })}
            onClick={menuItem.children ? () => setIsSubmenuOpen(!isSubmenuOpen) : undefined}
        >
            <Link
                className={classNames(`${componentName}__menu-item`, {
                    [`${componentName}--active`]: location.pathname.includes(menuItem.slug),
                })}
                to={formatLinkBase(`c/${menuItem.slug}`)}
                onClick={(event) => navigateToPLP(event)}
            >
                {menuItem.name}
            </Link>

            {menuItem.children.length && isSubmenuOpen ? (
                <ul className={`${componentName}__submenu`} onClick={(event) => event.stopPropagation()}>
                    {menuItem.children
                        .sort((prev, curr) => Number(prev.orderHint) - Number(curr.orderHint))
                        .map((childItem) => (
                            <li key={childItem.id} className={`${componentName}__submenu-item`}>
                                <Link
                                    className={classNames({
                                        [`${componentName}--active`]: subMenuUrlSlug === childItem.slug,
                                    })}
                                    to={formatLinkBase(`c/${menuItem.slug}/${childItem.slug}`)}
                                    onClick={(event) => navigateToPLP(event, childItem)}
                                >
                                    {childItem.name}
                                </Link>
                            </li>
                        ))}
                </ul>
            ) : (
                SKIP_RENDER
            )}
        </li>
    );
};

export default MobileMenuItem;
