import { useState } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import classNames from 'classnames';
import VoucherValidityIndicator from '@mediashop/base/pattern/atom/VoucherValidityIndicator';
import { FormattedMessage } from 'react-intl';
import { Price } from '../Price';
import Icon from '../../atom/Icon';
import { useLoader } from '@mediashop/app/store/loader';
import { VoucherRowDiscountType } from '@mediashop/app/api/types/Discount';
import DiscountInformationModal from '../DiscountInformationModal';

const componentName = 'voucher-row';

type VoucherRowProps = BaseProps & {
    discount: VoucherRowDiscountType;
    isDiscountRemovable?: boolean;
};

function VoucherRow({
    className,
    discount,
    isDiscountRemovable = typeof discount.type !== 'undefined',
}: VoucherRowProps) {
    const { cartLoader } = useLoader();

    const isVoucherValid = discount.state === 'MatchesCart';

    const [showModal, setShowModal] = useState(false);

    const deleteDiscountCode = async (code?: string) => {
        await cartLoader.removeDiscountCode(code ?? 'DC_DELETED');
    };

    return (
        <div className={`${componentName}`}>
            {isDiscountRemovable && (
                <span className={`${componentName}__remove-button`} onClick={() => deleteDiscountCode(discount.code)}>
                    <Icon
                        name="Trash"
                        className={`${componentName}__remove-icon`}
                        data-testid={`deleteVoucher-${discount.code}`}
                    />
                </span>
            )}
            <div
                className={classNames(
                    className,
                    isVoucherValid ? `${componentName}__voucher-valid` : `${componentName}__voucher-invalid`
                )}
            >
                <VoucherValidityIndicator
                    discountState={discount.state}
                    discountCode={discount.code}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
                <div className={`${componentName}__value`}>
                    {discount.targetType === 'GiftLineItem' && isVoucherValid ? (
                        <FormattedMessage
                            id="voucher.priceGiftLineItem"
                            values={{ price: <Price price={discount.value} /> }}
                        />
                    ) : (
                        <span>
                            - <Price price={discount.value} />
                        </span>
                    )}
                </div>
            </div>
            <DiscountInformationModal discount={discount} showModal={showModal} setShowModal={setShowModal} />
        </div>
    );
}

export default injectComponent('pattern.organism.VoucherRow', VoucherRow);
