import * as React from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';

const componentName = 'select';

type SelectProps = BaseProps & {
    ariaLabel?: string;
    children: React.ReactNode;
    dataType?: string;
    disabled?: boolean;
    error?: boolean;
    icon?: string;
    name?: string;
    onBlur?: () => void;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onFocus?: () => void;
    required?: boolean;
    size?: 'medium' | 'small' | 'large';
    value?: string | number;
};

/**
 * A styled select-box. Wrapper around native select-element.
 */
function Select({
    ariaLabel,
    children,
    className,
    dataType,
    disabled = false,
    error = false,
    icon = 'ArrowDown',
    name,
    onBlur,
    onChange,
    onFocus,
    required = false,
    size = 'medium',
    value,
}: SelectProps) {
    return (
        <div
            className={classNames(componentName, className, `${componentName}--${size}`, {
                [`${componentName}--disabled`]: disabled,
            })}
        >
            <select
                className={classNames(`${componentName}__select`, { [`${componentName}__select--error`]: error })}
                data-type={dataType}
                disabled={disabled}
                name={name}
                onChange={onChange}
                required={required}
                value={value}
                onFocus={onFocus}
                onBlur={onBlur}
                aria-label={ariaLabel}
            >
                {children}
            </select>

            <Icon
                name={icon}
                className={classNames(`${componentName}__arrow`, { [`${componentName}__arrow--error`]: error })}
            />
        </div>
    );
}

export default injectComponent('pattern.atom.Select', Select);
