import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { ProductBundle } from '@mediashop/app/bloomreach/product/types/ProductBundle';
import {
    BaseProps,
    BackgroundProps,
    HeadlineStyleProps,
    HeadlineTextStyleProps,
} from '@mediashop/app/bloomreach/types';
import { BadgeStyleProps } from '../../atom/Badge';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ProductBoxMolecule = lazy(() => lazyRetry(() => import('./ProductBox')));

export type ProductBoxProps = BaseProps & {
    boxSize: 'large' | 'small';
    product?: ProductBundle;
    headline: string;
    subheadline?: string;
    headlineStyle?: HeadlineStyleProps;
    textStyle?: HeadlineTextStyleProps;
    background: BackgroundProps;
    badgeColor: BadgeStyleProps;
    productBadgeColor?: BadgeStyleProps;
};

const ProductBox = (props: ProductBoxProps): JSX.Element => (
    <LazyComponent>
        <ProductBoxMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ProductBox', ProductBox);
