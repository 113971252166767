import { lazy } from 'react';
import { Address } from '@mediashop/app/api/types/Address';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const SummaryAddressMolecule = lazy(() => lazyRetry(() => import('./Address')));

export type OrderAddressProps = {
    address: Address;
    hideCompanyInformation?: boolean;
    hideEmail?: boolean;
    onChangeClick?: () => void;
    title: string;
};

const SummaryAddress = (props: OrderAddressProps): JSX.Element => (
    <LazyComponent>
        <SummaryAddressMolecule {...props} />
    </LazyComponent>
);

export default SummaryAddress;
