import { ReactComponent as ArrowLeft } from './assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from './assets/icons/arrow-right.svg';
import { ReactComponent as ArrowUp } from './assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from './assets/icons/arrow-down.svg';
import { ReactComponent as ArrowTopRight } from './assets/icons/arrow-top-right.svg';
import { ReactComponent as BurgerMenuClose } from './assets/icons/burgermenu-close.svg';
import { ReactComponent as BurgerMenuOpen } from './assets/icons/burgermenu-open.svg';
import { ReactComponent as Trash } from './assets/icons/trash.svg';
import { ReactComponent as Pencil } from './assets/icons/pencil.svg';
import { ReactComponent as Share } from './assets/icons/share.svg';
import { ReactComponent as AddToCart } from './assets/icons/add-to-cart.svg';
import { ReactComponent as MagnifyingGlass } from './assets/icons/magnifying-glass.svg';
import { ReactComponent as FilesEmptyState } from '@mediashop/base/assets/icons/empty-state-no-files.svg';

/* Payment Methods */
import { ReactComponent as afterpay } from './assets/icons/paymentMethods/afterpay-riverty.svg';
import { ReactComponent as bancontact } from './assets/icons/paymentMethods/bancontact.svg';
import { ReactComponent as ideal } from './assets/icons/paymentMethods/ideal.svg';
import { ReactComponent as invoiceDe } from './assets/icons/paymentMethods/invoice-de.svg';
import { ReactComponent as invoiceFr } from './assets/icons/paymentMethods/invoice-fr.svg';
import { ReactComponent as mastercard } from './assets/icons/paymentMethods/mastercard.svg';
import { ReactComponent as cashOnDeliveryCs } from './assets/icons/paymentMethods/cash-on-delivery-cz.svg';
import { ReactComponent as cashOnDeliveryDe } from './assets/icons/paymentMethods/cash-on-delivery-de.svg';
import { ReactComponent as cashOnDeliveryHu } from './assets/icons/paymentMethods/cash-on-delivery-hu.svg';
import { ReactComponent as cashOnDeliveryRo } from './assets/icons/paymentMethods/cash-on-delivery-ro.svg';
import { ReactComponent as cashOnDeliverySk } from './assets/icons/paymentMethods/cash-on-delivery-sk.svg';
import { ReactComponent as paypal } from './assets/icons/paymentMethods/paypal.svg';
import { ReactComponent as postfinance } from './assets/icons/paymentMethods/postfinance.svg';
import { ReactComponent as installmentDe } from './assets/icons/paymentMethods/installment-de.svg';
import { ReactComponent as sofortueberweisung } from './assets/icons/paymentMethods/sofortueberweisung.svg';
import { ReactComponent as visa } from './assets/icons/paymentMethods/visa.svg';

const paymentIcons = {
    afterpay_invoice: afterpay,
    bancontact,
    cashOnDeliveryCs,
    cashOnDeliveryDe,
    cashOnDeliveryHu,
    cashOnDeliveryRo,
    cashOnDeliverySk,
    ideal_r: ideal,
    installmentDe,
    installment_open_invoice: installmentDe,
    invoiceDe,
    invoiceFr,
    mastercard,
    mistercash_r: bancontact,
    paypal,
    paypal_express: paypal,
    postfinance,
    sofortueberweisung,
    visa,
};

export default {
    AddToCart,
    ArrowLeft,
    ArrowRight,
    ArrowDown,
    ArrowTopRight,
    BurgerMenuClose,
    BurgerMenuOpen,
    FilesEmptyState,
    Pencil,
    Share,
    Trash,
    ArrowUp,
    MagnifyingGlass,
    ...paymentIcons,
};
