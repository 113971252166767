/**
 * Extract product slug from URL
 * @param pathname current path name
 * @returns product slug or undefined
 */
export const getProductSlug = (pathname: string) => {
    const urlParts = pathname.replace(/\/$/, '').split('/');

    const productPathIdentifierIndex = urlParts.indexOf('p');

    if (productPathIdentifierIndex && urlParts.length > productPathIdentifierIndex) {
        return urlParts[productPathIdentifierIndex + 1];
    }

    return undefined;
};
