import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { useEffect, useMemo, useState, type ChangeEvent } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import countryCodes from '@mediashop/base/assets/data/country-code';
import classNames from 'classnames';
import Input from '../Input';
import Select from '../Select';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import examples from 'libphonenumber-js/mobile/examples';
import { isValidPhoneNumber, getExampleNumber, CountryCode } from 'libphonenumber-js';
import { useIntl } from 'react-intl';

const componentName = 'phone-number-input';

export type PhoneNumberInputProps = BaseProps & {
    id?: string;
    value?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
    title?: string;
    name: string;
    label?: string;
    error?: string;
    required?: boolean;
    country?: string;
};

function PhoneNumberInput({
    className,
    id,
    name,
    label,
    value,
    error,
    required,
    country,
    onChange,
    onBlur,
}: PhoneNumberInputProps): JSX.Element {
    const [phoneNumber, setPhoneNumber] = useState(EMPTY_STRING);
    const { country: userCountry } = useShopContext();
    const { formatMessage } = useIntl();

    const phoneNumberWithoutDialCode = useMemo(() => {
        for (const countryCodeLoop of countryCodes) {
            if (phoneNumber?.startsWith(countryCodeLoop.dialCode)) {
                return phoneNumber.replace(countryCodeLoop.dialCode, EMPTY_STRING);
            }
        }
        return phoneNumber;
    }, [phoneNumber]);

    const dialCode = useMemo(() => {
        for (const countryCodeLoop of countryCodes) {
            if (phoneNumber?.startsWith(countryCodeLoop.dialCode)) {
                return countryCodeLoop.dialCode;
            }
        }

        const customerCountry = country || userCountry;
        return countryCodes.find((countryCode) => countryCode.code === customerCountry)!.dialCode;
    }, [phoneNumber, userCountry, country]);

    const phoneNumberError = useMemo(() => {
        const selectedDialCodeCountry = countryCodes.find((obj) => obj.dialCode === dialCode)?.code as CountryCode;
        const examplePhoneNumberObj = getExampleNumber(selectedDialCodeCountry, examples);

        return phoneNumber && !isValidPhoneNumber(phoneNumber)
            ? formatMessage({ id: 'address.phoneErrorText' }, { examplePhoneNumber: examplePhoneNumberObj?.number })
            : undefined;
    }, [dialCode, formatMessage, phoneNumber]);

    useEffect(() => {
        if (value) {
            setPhoneNumber(value);
        }
    }, [value]);

    const onChangePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.replace(/[^0-9]/g, '');
        if (value.startsWith('0')) {
            value = value.substring(1);
        }

        if ((dialCode + value).length > 20) {
            value = (dialCode + value).slice(dialCode.length, 20);
        }

        if (value) {
            setPhoneNumber(`${dialCode}${value}`);
        } else {
            setPhoneNumber(`${dialCode}${EMPTY_STRING}`);
            const event = { target: { id, name, value: EMPTY_STRING } };
            onChange(event as ChangeEvent<HTMLInputElement>);
        }
    };

    const onChangeCountryCode = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setPhoneNumber(`${value}${phoneNumberWithoutDialCode}`);
    };

    useEffect(() => {
        if (dialCode && phoneNumberWithoutDialCode) {
            const event = { target: { id, name, value: `${dialCode}${phoneNumberWithoutDialCode}` } };
            onChange(event as React.ChangeEvent<HTMLInputElement>);
        }
    }, [phoneNumberWithoutDialCode, dialCode]);

    return (
        <div className={classNames(`${componentName}__phone-input-holder`, className)}>
            <Select
                className={`${componentName}__country-code-select`}
                name="phonenumber_country_code"
                value={dialCode}
                onChange={onChangeCountryCode}
                size="small"
            >
                {countryCodes.map((item) => (
                    <option key={item.dialCode} value={item.dialCode}>
                        ({item.code}) {item.dialCode}
                    </option>
                ))}
            </Select>

            <Input
                id={id}
                inputMode="tel"
                name={name}
                type="number"
                label={label}
                value={phoneNumberWithoutDialCode}
                error={error && error !== '' ? error : phoneNumberError}
                required={required}
                onBlur={onBlur}
                onChange={onChangePhoneNumber}
            />
        </div>
    );
}

export default injectComponent('pattern.molecule.DynamicAddressForm.PhoneNumberInput', PhoneNumberInput);
