import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLoader } from '../../store/loader';
import { queries } from '..';
import { useShopContext } from '../../hooks/useShopContext';

export const useCancelSubscription = () => {
    const { customerClient } = useLoader();
    const queryClient = useQueryClient();
    const { locale } = useShopContext();

    const cancelSubscription = (subscriptionId: string) => customerClient.cancelSubscription(subscriptionId);

    const onSuccessInternal = () => {
        queryClient.invalidateQueries({ queryKey: queries.customer.subscriptions(locale).queryKey });
    };

    return useMutation({
        mutationFn: cancelSubscription,
        onSuccess: onSuccessInternal,
    });
};
