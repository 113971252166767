import { CartLineItem } from '@mediashop/app/api/types/ClientCart';
import { Money } from '@mediashop/app/api/types/Money';
import { VariantCustom } from '../types/badges';

/**
 * get variant price object with originalPrice and price from CartLineItem customFields
 *
 * @param lineItemCustom
 * @param price
 */
export const getVariantCustom = (lineItemCustom: CartLineItem['custom'], price: Money): VariantCustom | undefined => {
    const variantCustom: VariantCustom = {} as VariantCustom;

    if (lineItemCustom) {
        const originalPriceObject =
            lineItemCustom.originalPrice?.centAmount !== 0 ? lineItemCustom.originalPrice : undefined;
        const priceType = lineItemCustom.priceType;
        if (originalPriceObject) {
            variantCustom.price = {
                ...price,
                priceType,
            };
            variantCustom.originalPrice = originalPriceObject;
        }
        const numberOfInstallments = lineItemCustom.numberOfInstallments ?? 0;
        if (numberOfInstallments) {
            variantCustom.installments = numberOfInstallments;
        }
    }

    return variantCustom;
};
