import { NostoProvider } from '@nosto/nosto-react';
import { ReactElement } from 'react';
import { useProject } from '../hooks/useProject';
import { useShopContext } from '../hooks/useShopContext';

type NostoProviderWrapperProps = {
    children: ReactElement;
};

const NostoProviderWrapper = ({ children }: NostoProviderWrapperProps) => {
    const { nostoAccounts, searchProvider } = useProject();
    const { country, locale } = useShopContext();

    const nostoAccountId = nostoAccounts ? (nostoAccounts[`${country}|${locale}`] ?? nostoAccounts['*']) : '';

    if (searchProvider === 'Nosto' && nostoAccountId) {
        return <NostoProvider account={nostoAccountId}>{children}</NostoProvider>;
    }

    return children;
};

export default NostoProviderWrapper;
