import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import {
    BaseProps,
    BrxBackground,
    BrxHeadlines,
    BrxImageWithTextProps,
    BrxLink,
} from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { PaymentMeta } from '@mediashop/app/api/types/Payment';

const OnePageCheckoutOrganism = lazy(() => lazyRetry(() => import('./OnePageCheckout')));

export type OnePageCheckoutProps = BaseProps & {
    headline?: BrxHeadlines;
    background: BrxBackground;
    icons: BrxImageWithTextProps[];
    passwordForgotten: BrxLink;
    payment?: PaymentMeta;
};

const OnePageCheckout = (props: OnePageCheckoutProps) => (
    <LazyComponent>
        <OnePageCheckoutOrganism {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.OnePageCheckout', OnePageCheckout, 'catalog-base');
