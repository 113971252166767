import { Cart, CustomLineItem } from '../types/ClientCart';
import AddressMapper from './AddressMapper';
import { CartDto } from '../dtos/cart/cartDto';
import { CustomLineItemDto } from '../dtos/cart/customLineItemDto';
import { Money } from '../types/Money';
import MoneyDomain from '../../domain/Money';

export const mapCustomLineItem = (customLineItem: CustomLineItemDto): CustomLineItem => ({
    ...customLineItem,
    money: customLineItem.money,
    name: customLineItem.name,
});

/**
 * Calculates the product total or resolves to zero money
 */
const calculateProductTotal = (cart: CartDto) => {
    if (cart?.shippingMode === 'Single' && cart?.shippingInfo) {
        return MoneyDomain.subtract(cart.totalPrice, cart.shippingInfo?.taxedPrice?.totalGross);
    }

    if (cart?.shippingMode === 'Multiple') {
        let productTotal: Money = { ...cart.totalPrice };
        cart.shipping?.forEach((shipping) => {
            productTotal = MoneyDomain.subtract(productTotal, shipping.shippingInfo.taxedPrice.totalGross);
        });
        return productTotal;
    }

    return cart.totalPrice;
};

/**
 * Maps cart-data for easier consumption by developer.
 */
const mapCart = (cart: CartDto): Cart => ({
    ...cart,
    customLineItems: cart.customLineItems.map((customLineItem) => mapCustomLineItem(customLineItem)),
    shippingAddress: AddressMapper.mapAddressDto(cart.shippingAddress)!,
    billingAddress: AddressMapper.mapAddressDto(cart.billingAddress),
    productTotal: calculateProductTotal(cart),
});

export default {
    mapCart,
};
