import { lazy } from 'react';
import { BaseProps, BrxLink } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const LoginMolecule = lazy(() => lazyRetry(() => import('./LoginForm')));

export interface LoginFormValues {
    email: string;
    password: string;
    'company-transactions'?: string;
}

export type LoginFormProps = BaseProps & {
    onLoginSuccess: (clubCardRemovedFromCart: boolean) => void;
    passwordForgotten?: BrxLink;
};

const LoginForm = (props: LoginFormProps) => (
    <LazyComponent>
        <LoginMolecule {...props} />
    </LazyComponent>
);

export default LoginForm;
