import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';

const componentName = 'full-screen-loading-indicator';

type FullScreenLoadingIndicatorProps = BaseProps;

/**
 * Display a full-screen spinning loading-indicator
 */
function FullScreenLoadingIndicator({ className }: FullScreenLoadingIndicatorProps) {
    return (
        <div className={classNames(componentName, className)} data-testid="loading-indicator">
            <div className={`${componentName}__loading-indicator`} />
        </div>
    );
}

export default injectComponent('pattern.atom.FullScreenLoadingIndicator', FullScreenLoadingIndicator);
