import { useQuery } from '@tanstack/react-query';
import { useShopContext } from '../../hooks/useShopContext';
import { useLoader } from '../../store/loader';
import { queries } from '..';

type UseSubscriptionsProps = {
    enabled?: boolean;
};

export const useSubscriptions = ({ enabled = true }: UseSubscriptionsProps = {}) => {
    const { customerClient } = useLoader();
    const { locale } = useShopContext();
    const getSubscriptions = () => customerClient.getSubscriptions();

    return useQuery({
        ...queries.customer.subscriptions(locale),
        queryFn: () => getSubscriptions(),
        enabled,
    });
};
