import { Children, ReactNode } from 'react';
import { ContainerAccount } from '../ContainerAccount';
import { useBrxPageContext } from '@mediashop/app/bloomreach/useBrxPageContext';
import { BrxUtils } from '@mediashop/app/bloomreach/utils/BrxUtils';
import classNames from 'classnames';

interface ContainerInlineProps {
    children: ReactNode;
}

const componentClass = 'tv.mediashop.components.AccountNavigationComponent';

/**
 * Display all items in this container inline.
 */
export default function ContainerInline({ children }: Readonly<ContainerInlineProps>): JSX.Element {
    const brxPageContext = useBrxPageContext();
    const accountNavigationComponentClass = BrxUtils.getComponentByClass(brxPageContext, componentClass);
    const isAccount = Boolean(accountNavigationComponentClass);

    return (
        <div className={classNames('container-inline container-wrapper', { 'container-account-wrapper': isAccount })}>
            {isAccount && <ContainerAccount>{children}</ContainerAccount>}

            {!isAccount && Children.map(children, (child) => <div className="container-inline__item">{child}</div>)}
        </div>
    );
}
