import BloomreachImageResponsive from '@mediashop/base/pattern/atom/BloomreachImageResponsive';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import { BaseProps, BrxImageSetImpl, BrxUsp } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { LinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';
import parse from 'html-react-parser';
import TextCompound from '@mediashop/base/pattern/atom/TextCompound';

const componentName = 'top-header';

type TopHeaderProps = BaseProps & {
    phoneText?: string;
    phoneNumber?: string;
    phoneTextMobile?: string;
    phoneIcon: BrxImageSetImpl;
    usps: BrxUsp[];
    actionLinkText?: string;
    actionLinkUrl?: string;
    actionLinkHTMLText?: { contentType: string; value: string };
    backgroundColorHex?: string;
    fontColorHex?: string;
};

/**
 * Shows a Top Header Bar with 3 Areas(Start, Center, End) as a kind of Navigation Bar
 */
function TopHeader({
    phoneText = 'Kostenlose Bestellhotline: ',
    phoneNumber = '800 900 88 90',
    phoneIcon,
    usps,
    actionLinkText = 'Service & Help',
    actionLinkUrl = '/',
    className,
    phoneTextMobile,
    actionLinkHTMLText,
    backgroundColorHex,
    fontColorHex,
}: TopHeaderProps): JSX.Element {
    const deviceType = useDeviceType();
    const [device, setDevice] = useState('desktop');
    const isMobileDevice = device === 'mobile';

    useEffect(() => {
        setDevice(deviceType);
    }, [deviceType]);

    const refTopDiv = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (refTopDiv.current && fontColorHex) {
            refTopDiv.current.style.setProperty('--top-header-text-color', fontColorHex);
            refTopDiv.current.style.setProperty('--top-header-phone-number-color', fontColorHex);
            refTopDiv.current.style.setProperty('--top-header-cta-color', fontColorHex);
            refTopDiv.current.style.setProperty('--top-header-icon-color', fontColorHex);
            refTopDiv.current.style.setProperty('color', fontColorHex);
        }
    }, [fontColorHex]);

    const phoneIconAltText = isMobileDevice && phoneTextMobile?.length ? phoneTextMobile : phoneText;

    return (
        <BackgroundColorWrapper
            hexBackgroundColor={backgroundColorHex}
            backgroundColor="primary"
            className={`background__${componentName}`}
        >
            <ContentWrapper className={classNames(componentName, className)}>
                <div className={`${componentName}__top`} ref={refTopDiv}>
                    <div className={`${componentName}__top-left`}>
                        <div className={`${componentName}__phone-wrapper ${componentName}__wrapper`}>
                            {phoneIcon ? (
                                <span className={`${componentName}__phone-icon`}>
                                    <BloomreachImageResponsive
                                        className={`${componentName}__icon`}
                                        alt={phoneIconAltText?.replace(':', '')}
                                        desktopImage={phoneIcon}
                                        variant={BrXImageVariant.TopHeader}
                                    />
                                </span>
                            ) : (
                                SKIP_RENDER
                            )}
                            <span className={`${componentName}__phone-text`}>
                                {isMobileDevice ? phoneTextMobile : phoneText}
                                <a href={`tel:${phoneNumber}`} className={`${componentName}__phone-link`}>
                                    {' '}
                                    {phoneNumber}
                                </a>
                            </span>
                        </div>
                        {usps.map((usp, key) => (
                            <div key={key} className={`${componentName}__usps-wrapper ${componentName}__wrapper`}>
                                <LinkOpenInTab tab={usp.linkOpenInTabWoText?.tab} link={usp.linkOpenInTabWoText.link}>
                                    {usp.icon ? (
                                        <span className={`${componentName}__usps-icon`} aria-label="Usp-Icon">
                                            <BloomreachImageResponsive
                                                className={`${componentName}__icon`}
                                                alt={usp.text}
                                                desktopImage={usp.icon}
                                                variant={BrXImageVariant.TopHeader}
                                            />
                                        </span>
                                    ) : (
                                        SKIP_RENDER
                                    )}

                                    <TextCompound
                                        desktop={usp.desktopMobileText?.desktop}
                                        mobile={usp.desktopMobileText?.mobile}
                                        className={`${componentName}__usps-text`}
                                        style={fontColorHex ? { color: fontColorHex } : {}}
                                    />
                                </LinkOpenInTab>
                            </div>
                        ))}
                    </div>

                    {actionLinkHTMLText?.value ? (
                        <div className={`${componentName}__html-action-links`}>{parse(actionLinkHTMLText.value)}</div>
                    ) : (
                        <div className={`${componentName}__action-wrapper ${componentName}__wrapper`}>
                            <LinkOpenInTab
                                className={`${componentName}__action-link`}
                                tab="sameTab"
                                link={actionLinkUrl}
                            >
                                {actionLinkText}
                            </LinkOpenInTab>
                        </div>
                    )}
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.TopHeader', TopHeader);
