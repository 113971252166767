import { useEffect } from 'react';
import { usePage } from '../../hooks/usePage';
import useEventTracking from '../../hooks/useEventTracking';
import { useProduct } from '../../hooks/useProduct';

export default function EventTracking(): null {
    const page = usePage();
    const { trackCartViewed, trackHomepageViewed } = useEventTracking();
    const { slugProduct } = useProduct();

    useEffect(() => {
        switch (page.pageType) {
            case 'homepage': {
                trackHomepageViewed();
                break;
            }
            case 'cart': {
                trackCartViewed();
                break;
            }
            default: {
                // Do nothing
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page.pageType, slugProduct.status]);

    return null;
}
