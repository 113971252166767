import { BaseProps } from '@mediashop/app/bloomreach/types';
import { BrxLinkOpenInTabWithFontColorAndHide } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy, ReactElement } from 'react';

const BurgerMenuQuickLinksItemLazy = lazy(() => lazyRetry(() => import('./BurgerMenuQuickLinksItem')));

export type BurgerMenuQuickLinksItemProps = BaseProps & {
    closeBurgerMenu: () => void;
    quickLink: BrxLinkOpenInTabWithFontColorAndHide;
};

const BurgerMenuQuickLinksItem = (props: BurgerMenuQuickLinksItemProps): ReactElement => (
    <LazyComponent>
        <BurgerMenuQuickLinksItemLazy {...props} />
    </LazyComponent>
);

export default BurgerMenuQuickLinksItem;
