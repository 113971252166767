import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CartAddonProductMolecule = lazy(() => lazyRetry(() => import('./CartAddonProduct')));

export type CartAddonProductProps = BaseProps & {
    name: string;
    description?: string;
    variants: Variant[];
    onAddToCart: () => void;
};

const CartAddonProduct = (props: CartAddonProductProps): JSX.Element => (
    <LazyComponent>
        <CartAddonProductMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.CartAddonProduct', CartAddonProduct);
