import { useEffect } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { App, ComponentInjector } from './index';
import { initStore } from './store';
import { MergedTheme } from './Theme';
import { initStoreFromAppProps } from './store/action/root';
import { SessionStorageKeys } from './constants/storageKeys';
import loadDevTools from './dev-tools/load';
import { partnerInitiated } from './store/reducer/partner';
import { clientSideTookOver, userAgentDetected } from './store/reducer/renderContext';
import { viewportDimensionsChangedListener } from './store/action/viewportDimensionChangedListener';
import { BrxPageModel } from './bloomreach/types';

export default function browserBootstrap(theme: MergedTheme): void {
    const $appData = document.getElementById('appData');
    const $app = document.getElementById('app');
    const currentUrl = new URL(window.location.href);
    const isDevelopment = process.env.NODE_ENV === 'development';

    const store = initStore(globalThis.__PRELOADED_STATE__);

    ComponentInjector.setThemePath(theme.path);

    let beforeHydratedHtml;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let afterHydratedHtml;

    if (!$appData || !$app) {
        throw new Error('Element #appData or #app not found');
    }

    if (!$appData.dataset.props) {
        throw new Error(`Element #appData doesn't contain App props.`);
    }

    if (isDevelopment) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeHydratedHtml = $app.outerHTML;
    }

    const ssrSuccess = $appData.dataset.ssrsuccess === 'true';

    const start = performance.now();

    // Allow client to disable hydration/rendering
    if (isDevelopment && currentUrl.searchParams.has('_mediashop_disable_hydration')) {
        return;
    }

    const props = JSON.parse($appData.dataset.props);
    if (!globalThis.__PRELOADED_STATE__) {
        store.dispatch(initStoreFromAppProps(props));
    } else {
        // garbage collect preloaded state
        delete globalThis.__PRELOADED_STATE__;
    }

    const initialCmsPageModel = globalThis.__CMS_PAGE_MODEL__ as BrxPageModel;

    loadDevTools(store, () => {
        function AppWithCallbackAfterRender() {
            useEffect(() => {
                if (isDevelopment) {
                    // eslint-disable-next-line no-console
                    console.log(ssrSuccess ? 'hydrated' : 'rendered', performance.now() - start);
                }

                // Switch context to client-side rendering
                store.dispatch(clientSideTookOver());

                store.dispatch(
                    partnerInitiated({
                        urlParam: currentUrl.searchParams.get('partner') ?? '',
                        referrer: document.referrer ?? '',
                        session: window.sessionStorage.getItem(SessionStorageKeys.PARTNER_ID) ?? '',
                    })
                );

                // Dispatch device-type related action
                store.dispatch(userAgentDetected({ userAgent: navigator.userAgent }));

                store.dispatch(viewportDimensionsChangedListener());

                if (isDevelopment) {
                    afterHydratedHtml = $app!.outerHTML;
                    // Add differ for easier debugging using prettier and a diff-library
                }
            });

            return (
                <App
                    theme={theme}
                    store={store}
                    pageModel={initialCmsPageModel}
                    {...JSON.parse($appData!.dataset.props!)}
                />
            );
        }

        if (ssrSuccess) {
            // SSR
            hydrateRoot($app, <AppWithCallbackAfterRender />);
        } else {
            // Client
            const root = createRoot($app);
            root.render(<AppWithCallbackAfterRender />);
        }
    });
}
