import { ComponentType } from 'react';
export { default as injectComponent } from './injectComponent';

type RegistryType = Record<string, Record<string, ComponentType>>;

export default class ComponentInjector {
    static registry: RegistryType = {};
    static themePath: string[] = ['base'];

    private static initTheme(themeName: string): void {
        if (!this.registry[themeName]) {
            this.registry[themeName] = {};
        }
    }

    static setThemePath(path: string[]): void {
        this.themePath = path;
        this.themePath.forEach((themeName) => this.initTheme(themeName));
    }

    static set(name: string, component: ComponentType, themeName = 'base'): void {
        this.initTheme(themeName);

        if (this.registry[themeName][name]) {
            console.warn(`Component ${name} has already been registered.`);
            return;
        }

        this.registry[themeName][name] = component;
    }

    static tryGet(name: string): ComponentType | undefined {
        const themeWithComponent = this.getThemeNameForComponent(name);
        if (!themeWithComponent) {
            return undefined;
        }
        return this.registry[themeWithComponent][name];
    }

    static get(name: string): ComponentType {
        const component = this.tryGet(name);
        if (!component) {
            throw new Error(`ComponentInjector: Component "${name}" not registered.`);
        }
        return component;
    }

    static getThemeNamesForComponent(name: string): string[] {
        return this.themePath.filter((themeName) => {
            if (!this.registry[themeName]) {
                throw new Error(`ComponentInjector: Theme "${themeName}" not registered.`);
            }
            return this.registry[themeName][name];
        });
    }

    static getThemeNameForComponent(name: string): string | undefined {
        return this.getThemeNamesForComponent(name)[0];
    }
}
