import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order } from '../../api/types/ClientOrder';

type Entity<T> =
    | {
          status: 'unloaded';
          data: undefined;
      }
    | {
          status: 'loading' | 'error';
          data?: T;
      }
    | {
          status: 'succeeded';
          data: T;
      }
    | {
          status: 'succeeded_fake';
          data?: T;
      };

export interface OrderState {
    order: Entity<Order>;
}

const initialState: OrderState = {
    order: {
        status: 'unloaded',
        data: undefined,
    },
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        orderCreated(state, action: PayloadAction<{ order: Order; facebookTrackingId: string }>) {
            const { order } = action.payload;
            return {
                ...state,
                order: {
                    ...state.order,
                    status: 'succeeded',
                    data: order,
                },
            };
        },
        orderStartedLoading(state) {
            return {
                ...state,
                order: {
                    ...state.order,
                    status: 'loading',
                },
            };
        },
        loadingOrderFailed(state) {
            return {
                ...state,
                order: {
                    status: 'error',
                },
            };
        },
        loadingOrderFailedLight(state) {
            return {
                ...state,
                order: {
                    status: 'succeeded_fake',
                },
            };
        },
    },
});

export const { loadingOrderFailed, loadingOrderFailedLight, orderCreated, orderStartedLoading } = orderSlice.actions;

export default orderSlice.reducer;
