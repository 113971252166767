import { BrComponentContext } from '@bloomreach/react-sdk';
import { useContext } from 'react';

export const useBrxComponentContext = () => {
    const componentContext = useContext(BrComponentContext);

    if (!componentContext) {
        throw new Error('useBrxComponentContext must be called inside a bloomreach component or page.');
    }

    return componentContext;
};
