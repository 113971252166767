import { Product } from '@mediashop/app/api/types/ClientProduct';
import { useCancelSubscription } from '@mediashop/app/queries/customer/useCancelSubscription';
import { CustomerSubscription } from '@mediashop/app/types/customer/subscription/customerSubscription';
import { CustomerSubscriptionState } from '@mediashop/app/types/customer/subscription/customerSubscriptionState';
import Button from '@mediashop/base/pattern/atom/Button';
import CroppedImage from '@mediashop/base/pattern/atom/CroppedImage';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';
import { FormattedDate, FormattedMessage } from 'react-intl';

const componentName = 'subscription-management-product';

interface SubscriptionManagementProductProps {
    product?: Product;
    subscription: CustomerSubscription;
}

export function SubscriptionManagementProduct({ product, subscription }: Readonly<SubscriptionManagementProductProps>) {
    const { mutate: cancelSubscription, isPending: isCancellationPending } = useCancelSubscription();

    return (
        <div key={subscription.subscriptionId} className={`${componentName}__wrapper`}>
            <div className={`${componentName}__container`}>
                <h4 className={`${componentName}__name`}>{product?.name}</h4>
                <div className={`${componentName}__details-container`}>
                    <div className={`${componentName}__image`}>
                        <CroppedImage
                            altText={product?.name}
                            src={product?.imageUri}
                            variant={CroppedImageVariant.SubscriptionManagement}
                        />
                    </div>

                    <div className={`${componentName}__details`}>
                        <div>
                            <span>
                                <FormattedMessage id="subscription.deliveryInterval" />
                            </span>
                            <FormattedMessage
                                id={`subscription.intervalUnit.${subscription.interval.unit}`}
                                values={{ amount: subscription.interval.amount }}
                            />
                        </div>
                        <div>
                            <span>
                                <FormattedMessage id="subscription.amount" />
                            </span>
                            {subscription.quantity}
                        </div>
                        <div>
                            <span>
                                <FormattedMessage id="subscription.nextDelivery" />
                            </span>
                            <FormattedDate value={subscription.nextShippingAt} />
                        </div>
                        <div>
                            <span>
                                <FormattedMessage id="subscription.ordered" />
                            </span>
                            <FormattedDate value={subscription.createdAt} />
                        </div>
                        <div>
                            <span>
                                <FormattedMessage id="subscription.status" />
                            </span>
                            <FormattedMessage id={`subscription.state.${subscription.status}`} />
                        </div>
                    </div>
                </div>
            </div>

            <Button
                onClick={() => cancelSubscription(subscription.subscriptionId)}
                className={`${componentName}__cancel-button`}
                disabled={subscription.status === CustomerSubscriptionState.CANCELLED}
                loading={isCancellationPending}
            >
                <FormattedMessage id="subscription.cancelSubscription" />
            </Button>
        </div>
    );
}
