import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { ImageBannerProps } from '@mediashop/base/pattern/molecule/ImageBanner';

const ImageBannerSliderMolecule = lazy(() => lazyRetry(() => import('./ImageBannerSlider')));

export type ImageBannerSliderProps = BaseProps & {
    backgroundColorHex: string;
    displayTime: number;
    imageBanners: ImageBannerProps[];
    style: 'banner' | 'contentBanner' | 'none';
    hideArrows: boolean;
};

const ImageBannerSlider = (props: ImageBannerSliderProps): JSX.Element => (
    <LazyComponent>
        <ImageBannerSliderMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ImageBannerSlider', ImageBannerSlider);
