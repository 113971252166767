import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ReturnTypeGetBadges, VariantCustom } from '../types/badges';
import { getBadgesByCategory } from '../utils';
import { Product, Variant } from '@mediashop/app/api/types/ClientProduct';
import { Money } from '@mediashop/app/api/types/Money';
import { useProject } from '@mediashop/app/hooks/useProject';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';

const DEFAULT_BADGE_OBJECT = {
    topBadges: [],
    bottomBadges: [],
    detailBadges: [],
    hasSaleBadge: false,
};

type UseBadgesProps = {
    product?: Product;
    variant?: Variant;
    installmentPrice?: Money;
    isPDP?: boolean;
    variantCustom?: VariantCustom;
};

export const useBadges = ({
    product,
    variant,
    variantCustom,
    installmentPrice,
    isPDP,
}: UseBadgesProps): ReturnTypeGetBadges => {
    const intl = useIntl();

    const { locale } = useShopContext();
    const { store: storeKey } = useProject();

    const badges = useMemo(
        () =>
            variant
                ? getBadgesByCategory({
                      installmentPrice,
                      intl,
                      isPDP,
                      locale,
                      product,
                      storeKey,
                      variant,
                      variantCustom,
                  })
                : DEFAULT_BADGE_OBJECT,
        [intl, locale, installmentPrice, product, storeKey, variant, variantCustom]
    );

    return badges;
};
