import { useProject } from '@mediashop/app/hooks/useProject';
import { useShopContext } from './useShopContext';

/**
 * A hook that returns the localized name of the site (project).
 *
 */
export default function useSiteName(): string {
    const { shopNames } = useProject();
    const { locale } = useShopContext();

    if (shopNames?.length === 1 && !shopNames[0]?.locale) {
        return shopNames[0].name;
    }

    return shopNames?.find((obj) => obj.locale === locale)?.name ?? '';
}
