import { RefObject, useEffect } from 'react';

export const useOutsideFocused = (
    refs: RefObject<HTMLElement> | RefObject<HTMLElement>[],
    callback: () => void
): void => {
    useEffect(() => {
        const handleFocusedOutside = (event) => {
            const refArray = Array.isArray(refs) ? refs : [refs];
            if (refArray.every((ref) => ref.current && !ref.current.contains(event.target))) {
                callback();
            }
        };

        document.addEventListener('focus', handleFocusedOutside, true);

        return () => {
            document.removeEventListener('focus', handleFocusedOutside, true);
        };
    }, [refs, callback]);
};
