import { useEffect, useRef } from 'react';
import { Product } from '../../api/types/ClientProduct';
import { useShopContext } from '../../hooks/useShopContext';
import { useCategories } from '../../queries/categories/useCategories';
import { dispatchProductListDisplayed } from '../actions/dispatchProductListDisplayed';

/**
 * This is a very simple hashing function for products.
 * It just gets the first part of the product-id and concatenates it for all products.
 * @param products list of products to be "hashed"
 * @returns hash value
 */
const hashProducts = (products: Product[]) =>
    products.reduce((hash, product) => {
        const productIdPart = product.id.split('-')[0] ?? '';
        return `${hash}${productIdPart}`;
    }, '');

/**
 * Hook that handles analytics events for product lists (or category page)
 * @param products products to be sent to analytics
 * @param listName name of the list
 */
export const useProductListAnalytics = (products: Product[], listName: string): void => {
    const productHash = useRef<string>('');

    const { country } = useShopContext();
    const { data: categories = [] } = useCategories();
    const mainCategory = categories[0];

    const useEffectFn = () => {
        if (products.length > 0) {
            const newProductHash = hashProducts(products);
            if (newProductHash !== productHash.current) {
                productHash.current = newProductHash;

                dispatchProductListDisplayed({ products, listName, mainCategory, country });
            }
        }
    };

    useEffect(useEffectFn, [products, listName, mainCategory, country]);
};
