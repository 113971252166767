import * as React from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const componentName = 'checkbox';

type CheckboxProps = BasePropsWithChildren & {
    className?: string;
    error?: string;
    id: string;
    name: string;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onChange: (checked: boolean) => void;
    required?: boolean;
    size?: 'medium' | 'small' | 'large';
    value?: boolean;
    style?: React.CSSProperties;
};

/**
 * A styled check-box. Wrapper around native checkbox-element.
 */
function Checkbox({
    id,
    name,
    value = false,
    size = 'medium',
    className,
    children,
    onChange,
    onBlur,
    error,
    style,
    required = false,
}: CheckboxProps) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof onChange === 'function') {
            onChange(event.target.checked);
        }
    };

    return (
        <label className={classNames(componentName, className, `${componentName}--${size}`)} style={style ?? {}}>
            <input
                type="checkbox"
                name={name}
                checked={value}
                onChange={handleChange}
                onBlur={onBlur}
                className={`${componentName}__input`}
            />

            <div className={`${componentName}__label`}>
                {children}
                {required && '*'}
            </div>

            {error ? <div className={`${componentName}__error-message`}>{error}</div> : SKIP_RENDER}

            <div
                id={id}
                className={classNames(`${componentName}__box`, `${componentName}__box--${size}`, {
                    [`${componentName}__box--error`]: Boolean(error),
                })}
            >
                {value ? <Icon className={`${componentName}__checked`} name="Check" /> : SKIP_RENDER}
            </div>
        </label>
    );
}

export default injectComponent('pattern.atom.Checkbox', Checkbox);
