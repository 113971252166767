import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import ProductAttributeValue from '../../ProductAttributeValue';
import { ProductDetail } from '@mediashop/app/api/types/ClientProductDetailed';

const componentName = 'product-extra-info-table';

type ProductExtraInfoTableProps = BaseProps & {
    productAttributesLabels: Record<string, string>;
    productAttributesList: { name: string; value: ProductDetail }[];
};

/**
 * Render the product extra info table.
 * @param productAttributesLabels
 * @param productAttributesList
 */
function ProductExtraInfoTable({ productAttributesLabels, productAttributesList }: ProductExtraInfoTableProps) {
    return (
        <table className={`${componentName}`}>
            <tbody>
                {productAttributesList.map((attribute) => (
                    <tr key={attribute.name} className={`${componentName}__row`}>
                        <td className={`${componentName}__data-label`}>
                            {productAttributesLabels[attribute.name] ?? ''}
                        </td>
                        <td className={`${componentName}__data-value`}>
                            <ProductAttributeValue value={attribute.value.value} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default injectComponent('pattern.molecule.ProductExtraInfoTable', ProductExtraInfoTable);
