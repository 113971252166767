import { useMappedCountry } from '@mediashop/app/hooks/useMappedCountry';
import { useCategories } from '@mediashop/app/queries/categories/useCategories';
import {
    FlatBreadcrumbIndex,
    NestedBreadcrumbIndex,
    buildFlatBreadcrumbsIndex,
    buildNestedBreadcrumbsIndex,
} from '../utils/buildBreadcrumbsIndex';

export default function useBreadcrumbs(): {
    flatBreadcrumbIndex: FlatBreadcrumbIndex;
    nestedBreadcrumbIndex: NestedBreadcrumbIndex;
} {
    const { data: categories = [] } = useCategories();

    const mainCategory = categories[0];

    const countryCategoryName = useMappedCountry();

    if (mainCategory) {
        const [selectedCountryCategory] = mainCategory.children.filter((cat) => cat.name === countryCategoryName);

        return {
            flatBreadcrumbIndex: buildFlatBreadcrumbsIndex(mainCategory, selectedCountryCategory.children),
            nestedBreadcrumbIndex: buildNestedBreadcrumbsIndex(selectedCountryCategory.children),
        };
    }

    return {
        flatBreadcrumbIndex: {},
        nestedBreadcrumbIndex: {},
    };
}
