import { PageModel } from '@bloomreach/spa-sdk';
import { ContextState } from '../store/reducer/context';

// https://github.com/bloomreach/spa-sdk/blob/main/packages/spa-sdk/src/spa/http.ts
type BrxHttpClientConfig = {
    headers: Record<string, string>;
    method: 'GET' | 'POST';
    url: string;
};

export type BrxHttpClient = (config: BrxHttpClientConfig) => Promise<{ data: PageModel }>;

export default function httpClient(
    context: Pick<ContextState, 'country' | 'isBrX' | 'locale'>,
    takeLanguageFromUrl: boolean
): BrxHttpClient {
    return async (config: BrxHttpClientConfig): Promise<{ data: PageModel }> => {
        const { country, isBrX: hasToken, locale } = context;

        let url = config.url;
        if (takeLanguageFromUrl) {
            url = config.url.replace(`/${country.toLowerCase()}/${locale.split('-')[0].toLowerCase()}`, '');
        }

        const headers = { ...config.headers };

        if (!hasToken) {
            headers.country = country?.toLowerCase();
            headers.language = locale.split('-')[0].toLowerCase();
        }

        const originalPathBase64 = btoa(window.location.pathname);

        const urlBase64 = btoa(encodeURI(url));

        const urlToUse = hasToken ? url : `/bloomreach-data?url=${urlBase64}&originalUrl=${originalPathBase64}`;

        const response = await fetch(urlToUse, {
            method: config.method,
            headers,
            credentials: 'include',
        });

        if (response.status === 404) {
            throw new Error('BrX Page not found');
        }

        const data = await response.json();

        return { data };
    };
}
