import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ProductDetailsHeaderLazy = lazy(() => lazyRetry(() => import('./ProductDetailsHeader')));

export type ProductDetailsHeaderProps = BaseProps & {
    title: string;
    claim: string;
    stars: number;
    reviewCount: number;
};

const ProductDetailsHeader = (props: ProductDetailsHeaderProps): JSX.Element => (
    <LazyComponent>
        <ProductDetailsHeaderLazy {...props} />
    </LazyComponent>
);

export default ProductDetailsHeader;
