import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface NonceState {
    nonce: string;
}

const initialState: NonceState = {
    nonce: '',
};

/**
 * Nonce reducer.
 */
const nonceSlice = createSlice({
    name: 'nonce',
    initialState,
    reducers: {
        nonceChanged(state, action: PayloadAction<{ nonce: string }>) {
            const { nonce } = action.payload;
            return {
                ...state,
                nonce,
            };
        },
    },
});

export const { nonceChanged } = nonceSlice.actions;

export default nonceSlice.reducer;
