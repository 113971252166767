import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import BloomreachImageResponsive from '../../atom/BloomreachImageResponsive';
import Headlines from '../../atom/Headlines';
import Slider from '../Slider';
import BackgroundColorWrapper from '../../atom/BackgroundColorWrapper';
import {
    BackgroundProps,
    BaseProps,
    BrxCTAButton,
    BrxHeadlines,
    BrxImageWithAltText,
} from '@mediashop/app/bloomreach/types';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';
import { SwiperSlide } from 'swiper/react';
import CTAButton from '../../atom/CTAButton';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

type StageSliderItem = {
    headlines: BrxHeadlines;
    image: BrxImageWithAltText;
    callToActionButton: BrxCTAButton;
};

type StageSliderProps = BaseProps & {
    autoplay: boolean;
    background: BackgroundProps;
    items: StageSliderItem[];
};

/**
 * Component with a background of photo including title information and checkout button.
 * Different design for mobile and desktop base on the slider for pictures
 */
function StageSlider({ autoplay, background, items }: StageSliderProps): JSX.Element {
    const componentName = 'stage-slider';
    const navigate = useNavigate();

    function callActionButton(item: StageSliderItem) {
        const ctaLink = item.callToActionButton.reference;
        if (ctaLink.startsWith('/')) {
            navigate(ctaLink);
        } else if (ctaLink.startsWith('#')) {
            const sanitizedId = ctaLink.replace('#', '');
            document?.getElementById(sanitizedId)?.scrollIntoView();
        } else {
            window.location.href = ctaLink;
        }
    }

    return (
        <BackgroundColorWrapper backgroundColor={background}>
            <div
                className={classNames(
                    componentName,
                    `${componentName}--${background}`,
                    `${componentName}--image-position-left`
                )}
            >
                <Slider autoplay={autoplay} slidesPerView={1}>
                    {items.map((item, key) => (
                        <SwiperSlide key={key}>
                            <div className={`${componentName}__content-container`}>
                                <div className={`${componentName}__image-container`}>
                                    <BloomreachImageResponsive
                                        className={`${componentName}__image`}
                                        alt={item.image.altText ?? EMPTY_STRING}
                                        desktopImage={item.image.image}
                                        variant={BrXImageVariant.StageSlider}
                                    />
                                </div>
                                <div className={`${componentName}__wrapper`}>
                                    <Headlines {...item.headlines} />

                                    <CTAButton
                                        onClick={() => callActionButton(item)}
                                        ctaButton={item.callToActionButton}
                                        customStyles={
                                            item.callToActionButton.backgroundColorHex
                                                ? { borderColor: item.callToActionButton.backgroundColorHex }
                                                : {}
                                        }
                                    >
                                        {item.callToActionButton.text}
                                    </CTAButton>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Slider>
            </div>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.StageSlider', StageSlider);
