import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';

const componentName = 'contact-block-item';

export type ContactBlockItemProps = BaseProps & {
    contactLabel: string;
    contactValue: string;
    prefix: string;
    suffix: string;
    phoneNumber: string;
    link: string;
    email: string;
    type: string;
};

function ContactBlockItem({
    contactValue,
    prefix,
    suffix,
    phoneNumber,
    link,
    email,
    type,
}: ContactBlockItemProps): JSX.Element {
    const getLink = () => {
        if (type === 'Telephone') {
            return `tel:${phoneNumber?.replace(/\s+/g, '')}`;
        }
        if (type === 'Email') {
            return `mailto:${email?.replace(/\s+/g, '')}`;
        }
        if (type === 'Chat') {
            return link?.replace(/\s+/g, '');
        }

        return contactValue?.replace(/\s+/g, '');
    };

    return (
        <div className={`${componentName}`}>
            {prefix ? <span className={`${componentName}__prefix`}>{prefix}</span> : null}
            <a className={`${componentName}__value`} href={getLink()}>
                {contactValue}
            </a>
            {suffix ? <span className={`${componentName}__suffix`}>{suffix}</span> : null}
        </div>
    );
}

export default injectComponent('pattern.molecule.Contact.ContactBlockItem', ContactBlockItem);
