import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import Icon from '../Icon';
import './index.scss';

const componentName = 'toggle-play-button';

type Props = BaseProps & {
    onPlay: () => void;
    onPause: () => void;
    isPlaying: boolean;
};

const TogglePlayButton = ({ onPlay, onPause, isPlaying, className }: Props) => {
    const handleClick = () => {
        if (isPlaying) {
            onPause();
        } else {
            onPlay();
        }
    };

    return (
        <div
            className={classNames(componentName, className, {
                [`${componentName}__play-button-color`]: !isPlaying,
            })}
            onClick={handleClick}
        >
            <Icon name={isPlaying ? 'PauseButton' : 'PlayButton'} />
        </div>
    );
};

export default injectComponent('pattern.atom.TogglePlayButton', TogglePlayButton);
