import { ValueOf } from '../../../helper/utility';

export const CustomerSubscriptionState = {
    ACTIVE: 'active',
    PAUSED: 'paused',
    CANCELLED: 'cancelled',
    PROBLEMS: 'problems',
};

export type CustomerSubscriptionStateValue = ValueOf<typeof CustomerSubscriptionState>;
