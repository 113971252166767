import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxAnchor, BrxHeadlines, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const InfoBannerMolecule = lazy(() => lazyRetry(() => import('./InfoBanner')));

export type InfoBannerProps = BaseProps & {
    anchor?: BrxAnchor;
    id: string;
    headlines: BrxHeadlines;
    icon?: BrxImageSetImpl;
    style: 'primary' | 'secondary' | 'tertiary' | 'default' | 'info' | 'warning' | 'error' | 'success';
    showCloseTrigger: boolean;
    dismissalKey: string;
    backgroundColor?: string;
    fontcolor?: string;
};

const InfoBanner = (props: InfoBannerProps): JSX.Element => (
    <LazyComponent>
        <InfoBannerMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.InfoBanner', InfoBanner);
