import { BaseProps } from '@mediashop/app/bloomreach/types';
import BloomreachHtml from '@mediashop/app/components/BloomreachHtml';
import { DetailedProduct } from '@mediashop/app/api/types/ClientProductDetailed';

type Props = BaseProps & {
    product?: DetailedProduct;
};

const componentName = 'product-details-item';

function DetailsItem({ product }: Props): JSX.Element {
    return <BloomreachHtml className={`${componentName}__text-container`} html={product?.description} />;
}
export default DetailsItem;
