import { lazy } from 'react';
import { DownloadWithImageItemProps } from './DownloadWithImageItem';
import { injectComponent } from '@mediashop/app/component-injector';
import { BackgroundProps, BaseProps, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import LazyComponent from '@mediashop/base/pattern/molecule/LazyComponent';

const DownloadWithImageMolecule = lazy(() => import('./DownloadWithImage'));

export type DownloadWithImageProps = BaseProps & {
    headlines: BrxHeadlines;
    downloadWithImageAndTextItem: DownloadWithImageItemProps[];
    background: BackgroundProps;
};

const DownloadWithImage = (props: DownloadWithImageProps): JSX.Element => (
    <LazyComponent>
        <DownloadWithImageMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.DownloadWithImage', DownloadWithImage);
