import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Product } from '@mediashop/app/api/types/ClientProduct';
import { UseProductReviewsReturnProps } from '@mediashop/app/hooks/api/useProductReviews';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const RatingsMolecule = lazy(() => lazyRetry(() => import('./Ratings')));

export type RatingProps = BaseProps &
    UseProductReviewsReturnProps & {
        currentPage: number;
        product?: Product;
        transitionToPage: (page: number) => void;
    };

const RatingsItem = (props: RatingProps): JSX.Element => (
    <LazyComponent>
        <RatingsMolecule {...props} />
    </LazyComponent>
);

export default RatingsItem;
