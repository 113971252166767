import * as React from 'react';
import { BrxMapping } from './bloomreach/types';

interface Theme {
    name: string;
    extends?: Theme;
    brComponents?: BrxMapping;
    icons?: Record<string, React.ComponentType>;
}

export interface MergedTheme {
    name: string;
    path: string[];
    brComponents: BrxMapping;
    icons: Record<string, React.ComponentType>;
}

export const ThemeContext = React.createContext<MergedTheme>({} as MergedTheme);

export default Theme;
