import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Product } from '@mediashop/app/api/types/ClientProduct';

const DownloadsMolecule = lazy(() => lazyRetry(() => import('./Downloads')));

export type DownloadsProps = BaseProps & {
    product?: Product;
};

const Downloads = (props: DownloadsProps): JSX.Element => (
    <LazyComponent>
        <DownloadsMolecule {...props} />
    </LazyComponent>
);
export default Downloads;
