import { useMemo } from 'react';
import { BrxRelationType } from '@mediashop/app/bloomreach/types';
import { useProductRelations } from './useProductRelations';
import { useGetProductBySlug } from '@mediashop/app/hooks/useGetProductBySlug';
import { useCart } from '@mediashop/app/hooks/useCart';
import { StoreEntity } from '@mediashop/app/store/reducer/product';

type HookProps = {
    crosssellAiContainerId: string;
    relationType?: BrxRelationType;
    isCartPage?: boolean;
};

type ReturnType = {
    isLoading: boolean;
    relatedProducts: StoreEntity[];
};

/**
 * Hook that fetches product/cart relations for slider
 * @param object containing the type of the relation and the product slug and if the slider is on cart page
 * @returns loading state and related products
 */
export const useSliderProductRelations = ({
    crosssellAiContainerId,
    relationType,
    isCartPage,
}: HookProps): ReturnType => {
    const { product: slugProduct } = useGetProductBySlug();

    const selectedVariant = useMemo(
        () => slugProduct?.selectedVariant ?? slugProduct?.product?.variants?.at(0),
        [slugProduct]
    );

    const { isLoading, relatedProducts } = useProductRelations({
        crosssellAiContainerId,
        relationType,
        useStandardPrice: true,
        variant: selectedVariant,
        isCartPage,
    });

    const { cart } = useCart();

    const cartProductKey = cart?.lineItems.find((item) => item?.productKey === slugProduct?.product?.key);

    const mappedRelatedProducts: StoreEntity[] = relatedProducts
        .filter((product) => {
            if (cartProductKey?.productKey === slugProduct?.product?.key) {
                return true;
            }

            return product.key !== slugProduct?.product?.key;
        })
        .map((product) => ({
            product,
            status: 'succeeded',
        }));

    return {
        isLoading,
        relatedProducts: mappedRelatedProducts,
    };
};
