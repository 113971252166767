import { useCart } from '@mediashop/app/hooks/useCart';
import bloomify from '@mediashop/app/bloomreach/bloomify';
import SPLPCart from '../pattern/organism/SPLPCart';

const CartBloomreach = (data) => {
    const cartState = useCart();

    return <SPLPCart {...data} isLoading={cartState.status !== 'succeeded'} cart={cartState.cart} />;
};

export default bloomify(CartBloomreach);
