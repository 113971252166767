import * as React from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import InputMask from '@mona-health/react-input-mask';
import classNames from 'classnames';
import Icon from '../../atom/Icon';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { FieldTransform } from '@mediashop/app/api/types/AddressConfiguration';
import { useEffect, useRef } from 'react';

const componentName = 'masked-input';

export type MaskedInputProps = BaseProps & {
    id?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    value?: string;
    placeholder?: string;
    type?: string;
    pattern?: string;
    name: string;
    error?: string;
    mask: string | (string | RegExp)[];
    label: string;
    required?: boolean;
    transform?: FieldTransform;
    maskPlaceholder?: string;
    focus?: boolean;
};

function MaskedInput({
    id,
    className,
    name,
    type,
    pattern,
    mask,
    value,
    error,
    label,
    placeholder,
    required,
    onChange,
    onBlur,
    transform,
    maskPlaceholder,
    focus,
}: MaskedInputProps) {
    const beforeMaskedStateChange = ({ nextState }) => {
        let { value } = nextState;
        value = value.replace(/\)/g, '').replace(/\(/g, '');

        if (transform === 'uppercase') {
            value = value.toUpperCase();
        }

        return {
            ...nextState,
            value,
        };
    };

    const inputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (focus && inputRef.current?.parentElement) {
            inputRef.current?.parentElement?.focus();
        }
    }, [focus]);

    return (
        <div className={classNames(componentName, className)}>
            <label className={`${componentName}__label`}>
                <InputMask
                    ref={inputRef}
                    className={classNames(`${componentName}__input`, { [`${componentName}--error`]: Boolean(error) })}
                    pattern={pattern}
                    type={type}
                    id={id}
                    name={name}
                    placeholder={placeholder ?? '_'}
                    maskPlaceholder={maskPlaceholder ?? '_'}
                    onBlur={onBlur}
                    value={value}
                    mask={mask}
                    onChange={onChange}
                    beforeMaskedStateChange={beforeMaskedStateChange}
                />
                <span className={`${componentName}__title`}>{required ? `${label}*` : label}</span>

                {error ? (
                    <div className={`${componentName}__error`}>{error}</div>
                ) : (
                    <span className={`${componentName}__check`}>
                        <Icon className={`${componentName}__check-icon`} name="Check" />
                    </span>
                )}
            </label>
        </div>
    );
}

export default injectComponent('pattern.atom.MaskedInput', MaskedInput);
