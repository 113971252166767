import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';

type Props = BaseProps & {
    brData: {
        component: {
            getParameters: () => {
                id: string;
            };
        };
    };
};

/**
 * Anchor component within document
 */
function Anchor({ brData }: Props) {
    const anchorId = brData.component.getParameters().id.replace('#', '');

    return <div className="anchor" id={anchorId} />;
}

export default injectComponent('pattern.organism.Anchor', Anchor);
