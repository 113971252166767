import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxAnchor, BrxImageSetImpl, BrxTextCompound } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const InfoBannerCustomMolecule = lazy(() => lazyRetry(() => import('./InfoBannerCustom')));

export type InfoBannerCustomProps = BaseProps & {
    anchor?: BrxAnchor;
    icon?: BrxImageSetImpl;
    id: string;
    style: 'primary' | 'secondary' | 'tertiary' | 'default' | 'info' | 'warning' | 'error' | 'success';
    showCloseTrigger: boolean;
    backgroundColor?: string;
    fontcolor?: string;
    textCompound: BrxTextCompound;
};

const InfoBannerCustom = (props: InfoBannerCustomProps): JSX.Element => (
    <LazyComponent>
        <InfoBannerCustomMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.InfoBannerCustom', InfoBannerCustom);
