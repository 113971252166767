import { useAppSelector } from '../store/hooks/hooks';
import { ProductState } from '../store/reducer/product';
import { shallowEqual } from 'react-redux';

/**
 * Hook that returns product data from store.
 * @returns product
 */
export const useProduct = (equalityCheck = false): ProductState => {
    return useAppSelector((state) => state.product, {
        equalityFn: equalityCheck ? shallowEqual : undefined,
    });
};
