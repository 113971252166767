import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { DetailedVariant } from '@mediashop/app/api/types/ClientProductDetailed';

const DeliveryMolecule = lazy(() => lazyRetry(() => import('./Delivery')));

export type DeliveryProps = BaseProps & {
    productDetails?: DetailedVariant['productDetails'];
};

const DeliveryItem = (props: DeliveryProps): JSX.Element => (
    <LazyComponent>
        <DeliveryMolecule {...props} />
    </LazyComponent>
);

export default DeliveryItem;
