import { useOrder } from '@mediashop/app/hooks/useOrder';
import bloomify from '@mediashop/app/bloomreach/bloomify';
import CheckoutSuccessMessage from '../pattern/molecule/CheckoutSuccessMessage';
import LoadingIndicator from '../pattern/atom/LoadingIndicator';

const SPLPCheckoutSuccessBloomreach = () => {
    const { order: orderState } = useOrder();
    const email =
        orderState.data?.orderedCart?.billingAddress?.email ?? orderState.data?.cart.billingAddress?.email ?? '';

    if (orderState.status === 'loading' || orderState.status === 'unloaded') {
        return <LoadingIndicator />;
    }

    return <CheckoutSuccessMessage order={orderState.data} email={email} isError={orderState.status === 'error'} />;
};

export default bloomify(SPLPCheckoutSuccessBloomreach);
