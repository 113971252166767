import { injectComponent } from '@mediashop/app/component-injector';
import { Helmet } from 'react-helmet-async';

function NoIndex() {
    return (
        <Helmet>
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
    );
}

export default injectComponent('pattern.molecule.NoIndex', NoIndex, 'catalog-base');
