import { EmarsysQueueEvent } from './types';

let isTestMode = false;

export let EmarsysQueue: EmarsysQueueEvent[] = [];

const flushQueue = () => {
    EmarsysQueue = [];
};

export const dispatchQueue = (): void => {
    if (globalThis.ScarabQueue) {
        if (isTestMode) {
            EmarsysQueue.unshift({ command: 'testMode' });
        }

        const transfromedQueue = EmarsysQueue.map(({ command, data }) => (data ? [command, data] : [command]));
        globalThis.ScarabQueue.push(...transfromedQueue);
        flushQueue();
    }
};

export const setTestMode = (): void => {
    isTestMode = true;
};
