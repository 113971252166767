/* eslint-disable id-length */
import { ValueOf } from '../../helper/utility';

export const EmarsysCommand = {
    AVAILABILITY_ZONE: 'availabilityZone',
    CART: 'cart',
    CATEGORY: 'category',
    GO: 'go',
    PURCHASE: 'purchase',
    RECOMMEND: 'recommend',
    SEARCH_TERM: 'searchTerm',
    SET_EMAIL: 'setEmail',
    TEST_MODE: 'testMode',
    VIEW: 'view',
} as const;

export type EmarsysCommands = ValueOf<typeof EmarsysCommand>;
