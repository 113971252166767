import { useEffect, useState } from 'react';
import { FooterColumnProps } from '../types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { injectComponent } from '@mediashop/app/component-injector';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { LinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import useRewriteLinks from '@mediashop/app/hooks/useRewriteLinks';

type FooterTextItemProps = BaseProps & {
    footerItem: FooterColumnProps;
    separatorLineColorHex: string;
};

const componentName = 'footer-text-item';

function FooterTextItem({ footerItem, separatorLineColorHex }: FooterTextItemProps) {
    const deviceType = useDeviceType();
    const isMobileOrTabletDevice = deviceType === 'mobile' || deviceType === 'tablet';
    const { collapsedByDefault, text } = footerItem;

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(collapsedByDefault === false);
    }, [collapsedByDefault]);

    const showContent = isActive || !isMobileOrTabletDevice;

    const textHtml = typeof text === 'object' && text?.value ? text.value : '';
    const rewritten = useRewriteLinks(textHtml) ?? '';

    /*
     * We have to use dangerouslySetInnerHTML here, because the HTML-parser removes inline event handlers.
     * Otherwise, the cookie settings modal set in Bloomreach wouldn't work.
     */
    return (
        <div
            className={`${componentName}__column`}
            style={separatorLineColorHex ? { borderBottomColor: separatorLineColorHex } : { borderBottomColor: '#fff' }}
        >
            {/** Header line */}
            <div className={`${componentName}__heading`} onClick={() => setIsActive(!isActive)}>
                {/** Title */}
                {footerItem.title}

                {/** Icon */}
                {isMobileOrTabletDevice ? (
                    <Icon className={`${componentName}__icon`} name={isActive ? 'ChevronUp' : 'ChevronDown'} />
                ) : (
                    SKIP_RENDER
                )}
            </div>

            {/** Content */}
            {showContent ? (
                <div className={`${componentName}__item`}>
                    {footerItem.links?.map((link, index) => (
                        <p key={index}>
                            <LinkOpenInTab {...link} />
                        </p>
                    ))}
                    {footerItem.text && (
                        <div
                            className={`${componentName}__text-container`}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: rewritten,
                            }}
                        />
                    )}
                </div>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}

export default injectComponent('pattern.organism.FooterText.FooterTextItem', FooterTextItem);
