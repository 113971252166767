import { useProduct } from '@mediashop/app/hooks/useProduct';
import { useMemo } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import Headlines from '@mediashop/base/pattern/atom/Headlines';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import ContentWrapper from '../../../../base/pattern/atom/ContentWrapper';
import ProductExtraInfoTable from './ProductExtraInfoTable';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { DetailedProduct } from '@mediashop/app/api/types/ClientProductDetailed';
import { useGetProductBySlug } from '@mediashop/app/hooks/useGetProductBySlug';

const generateProductAttributeList = (product?: DetailedProduct) => {
    const attributes = product?.variants?.[0]?.productDetails ?? {};

    const productAttributesList = Object.keys(attributes).map((key) => ({ name: key, value: attributes[key] }));

    // Handle description in a special way by adding it to the attribute list (as first item)
    productAttributesList.unshift({ name: 'description', value: { label: '', value: product?.description } });

    return productAttributesList;
};

const componentName = 'product-extra-info';

/**
 * Render the product extra info accordions.
 */
function ProductExtraInfo() {
    const intl = useIntl();

    const { products } = useProduct();
    const { product: foundProduct } = useGetProductBySlug();

    const productBySlug = foundProduct?.product;

    const attributeLabels = Object.entries(productBySlug?.variants[0]?.productDetails ?? {}).reduce(
        (labels, [key, { label }]) => ({ ...labels, [key]: label }),
        {}
    );

    const productAttributes = useMemo(() => generateProductAttributeList(productBySlug), [productBySlug]);

    return (
        <div className={componentName}>
            <BackgroundColorWrapper backgroundColor="default">
                <ContentWrapper>
                    {products && productAttributes.length > 0 && (
                        <>
                            <Headlines
                                className={componentName}
                                headline={intl.formatMessage({ id: 'product.attributes.headline' })}
                                subheadline={intl.formatMessage({ id: 'product.attributes.subheadline' })}
                                textStyle="normalCase"
                            />
                            <div
                                className={classNames(`${componentName}__technical-data-wrapper`, {
                                    [`${componentName}__technical-data-wrapper--two-col`]:
                                        productAttributes.length >= 2,
                                })}
                            >
                                <ProductExtraInfoTable
                                    productAttributesList={productAttributes.slice(
                                        0,
                                        Math.ceil(productAttributes.length / 2)
                                    )}
                                    productAttributesLabels={attributeLabels}
                                />

                                <ProductExtraInfoTable
                                    productAttributesList={productAttributes.slice(
                                        Math.ceil(productAttributes.length / 2)
                                    )}
                                    productAttributesLabels={attributeLabels}
                                />
                            </div>
                        </>
                    )}
                </ContentWrapper>
            </BackgroundColorWrapper>
        </div>
    );
}

export default injectComponent('pattern.molecule.ProductExtraInfo', ProductExtraInfo, 'catalog-base');
