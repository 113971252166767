import { BaseProps } from '@mediashop/app/bloomreach/types';
import Icon from '../../atom/Icon';
import { injectComponent } from '@mediashop/app/component-injector';
import { useIntl } from 'react-intl';

type Props = BaseProps & {
    direction: 'left' | 'right';
    extraClassName?: string;
    onClick?: () => void;
};

function ArrowButton({ direction, className = '', extraClassName = '', onClick }: Props) {
    const intl = useIntl();
    function getArrowIconName() {
        if (direction === 'left') {
            return 'ArrowLeft';
        }

        return 'ArrowRight';
    }

    return (
        <button
            type="button"
            onClick={onClick}
            className={`arrow-button arrow-button--${direction} ${className} ${extraClassName}`}
            aria-label={
                direction === 'left'
                    ? intl.formatMessage({ id: 'slider.prevButton' })
                    : intl.formatMessage({ id: 'slider.nextButton' })
            }
        >
            <Icon name={getArrowIconName()} />
        </button>
    );
}

export default injectComponent('pattern.molecule.ArrowButton', ArrowButton);
