import { injectComponent } from '@mediashop/app/component-injector';
import {
    BaseProps,
    BrxBackground,
    BrxHeadlines,
    BrxImageWithTextProps,
    BrxLink,
} from '@mediashop/app/bloomreach/types';
import MultiPageCheckout from '../MultiPageCheckout';
import OnePageCheckout from '../OnePageCheckout';
import { ExpressPaymentMethods } from '@mediashop/app/payment/constants/expressPaymentMethods';
import ExpressCheckout from '../ExpressCheckout';
import { usePayment } from '@mediashop/app/queries/payment/usePayment';

type CheckoutProps = BaseProps & {
    background: BrxBackground;
    headline?: BrxHeadlines;
    icons: BrxImageWithTextProps[];
    passwordForgotten: BrxLink;
    type: 'multi_page_checkout' | 'one_page_checkout';
};

function Checkout({ type, ...props }: CheckoutProps) {
    const { data: payment } = usePayment();

    const shouldUseExpressCheckout =
        payment?.selectedPaymentMethod && ExpressPaymentMethods.includes(payment.selectedPaymentMethod);

    if (shouldUseExpressCheckout) {
        return <ExpressCheckout {...props} payment={payment} />;
    }

    if (type === 'multi_page_checkout') {
        return <MultiPageCheckout {...props} />;
    }

    return <OnePageCheckout {...props} payment={payment} />;
}

export default injectComponent('pattern.organism.Checkout', Checkout, 'catalog-base');
