import { injectComponent } from '@mediashop/app/component-injector';
import classNames from 'classnames';
import { BaseProps, HeadlineStyleProps, HeadlineTextStyleProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const componentName = 'headlines';

export type HeadlineProps = BaseProps & {
    alignment?: 'left' | 'center' | 'right' | null;
    headline: string;
    headlineStyle?: HeadlineStyleProps;
    subheadline?: string;
    textStyle?: HeadlineTextStyleProps;
    alignStartOnMobile?: boolean;
    alignStartOnTablet?: boolean;
    alignStartOnDesktop?: boolean;
    textColor?: string;
    subTextColor?: string;
    hasPadding?: boolean;
    headlineFontColorHex?: string;
    subheadlineFontColorHex?: string;
    shadowColorHex?: string;
    headlineShadow?: boolean;
};

// eslint-disable-next-line complexity
function Headlines({
    alignment = 'center',
    headline,
    subheadline,
    textStyle = 'upperCase',
    className = '',
    headlineStyle = 'h1',
    alignStartOnMobile = false,
    alignStartOnTablet = false,
    alignStartOnDesktop = false,
    textColor,
    subTextColor,
    hasPadding = true,
    headlineFontColorHex,
    subheadlineFontColorHex,
    shadowColorHex,
    headlineShadow,
}: HeadlineProps) {
    // Headline classes title-h{1,2,3,4} and title-h{1,2,3,4}-sub are defined in style/elements/headlines.scss
    const headlineClass = headlineStyle ? `title-${headlineStyle.toLowerCase()}` : 'title-h1';
    const subHeadlineClass = headlineStyle ? `title-${headlineStyle.toLowerCase()}-sub` : 'title-h1-sub';

    const CustomTag = (headlineStyle ? headlineStyle?.toLowerCase() : 'h1') as keyof JSX.IntrinsicElements;
    const CustomSubheadlineTag = `h${String.fromCharCode(CustomTag.charCodeAt(1) + 1)}` as keyof JSX.IntrinsicElements;

    const headlineTextColor = textColor ? textColor : undefined;
    const subheadlineTextColor = subTextColor ? subTextColor : undefined;

    if (!headline && !subheadline) {
        return null;
    }

    return (
        <div
            className={classNames(
                componentName,
                {
                    [`${componentName}--mobile-start`]: alignStartOnMobile,
                    [`${componentName}--tablet-start`]: alignStartOnTablet,
                    [`${componentName}--desktop-start`]: alignStartOnDesktop,
                    [`${componentName}--padding`]: hasPadding,
                },
                `${componentName}--${textStyle?.toLowerCase()}`,
                `${componentName}--text-align-${alignment ?? 'center'}`,
                className
            )}
            data-testid={componentName}
        >
            {headline ? (
                <CustomTag
                    className={classNames(
                        `${componentName}__headline`,
                        {
                            [`${componentName}__headline--mobile-start`]: alignStartOnMobile,
                            [`${componentName}__headline--tablet-start`]: alignStartOnTablet,
                            [`${componentName}__headline--desktop-start`]: alignStartOnDesktop,
                        },
                        headlineClass
                    )}
                    style={{
                        color: headlineFontColorHex ?? headlineTextColor,
                        textShadow: headlineShadow && shadowColorHex ? `0px 1px 3px ${shadowColorHex}` : undefined,
                    }}
                >
                    {headline}
                </CustomTag>
            ) : (
                SKIP_RENDER
            )}

            {subheadline ? (
                <CustomSubheadlineTag
                    className={classNames(
                        `${componentName}__subheadline`,
                        {
                            [`${componentName}__subheadline--mobile-start`]: alignStartOnMobile,
                            [`${componentName}__subheadline--tablet-start`]: alignStartOnTablet,
                            [`${componentName}__subheadline--desktop-start`]: alignStartOnDesktop,
                        },
                        subHeadlineClass
                    )}
                    style={{
                        color: subheadlineFontColorHex
                            ? subheadlineFontColorHex
                            : (subheadlineTextColor ?? headlineTextColor),
                        textShadow: headlineShadow && shadowColorHex ? `0px 1px 3px ${shadowColorHex}` : undefined,
                    }}
                >
                    {subheadline}
                </CustomSubheadlineTag>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}

export default injectComponent('pattern.atom.Headlines', Headlines);
