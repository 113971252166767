import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxImageWithAltText, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CustomerClubMolecule = lazy(() => lazyRetry(() => import('./CustomerClub')));

export type CustomerClubProps = BaseProps & {
    headlines: BrxHeadlines;
    ctaTextMembership: string;
    ctaTextNoMembership: string;
    image?: BrxImageWithAltText;
    text?: BrxHtml;
};

const CustomerClub = (props: CustomerClubProps): JSX.Element => (
    <LazyComponent>
        <CustomerClubMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.CustomerClub', CustomerClub);
