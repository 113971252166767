import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const LogoSliderBasicSlideMolecule = lazy(() => lazyRetry(() => import('./BasicSlide')));

export type LogoSliderBasicSlideProps = BaseProps & {
    image: BrxImageWithAltText;
};

const LogoSliderBasicSlide = (props: LogoSliderBasicSlideProps): JSX.Element => (
    <LazyComponent>
        <LogoSliderBasicSlideMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.LogoSliderBasicSlide', LogoSliderBasicSlide);
