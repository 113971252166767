import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

type Props = {
    children: React.ReactNode;
    onError?: () => void;
};

export default class AppErrorBoundary extends React.Component<Props> {
    render(): JSX.Element {
        return (
            <ErrorBoundary
                fallbackRender={({ error }) => (
                    <div role="alert">
                        <p>Something went wrong:</p>
                        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{error.stack}</pre>
                    </div>
                )}
                onError={this.props.onError}
            >
                {this.props.children}
            </ErrorBoundary>
        );
    }
}
