import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, HippoIconProps } from '@mediashop/app/bloomreach/types';
import classNames from 'classnames';

type Props = BaseProps & {
    icon: HippoIconProps;
};

/**
 * @deprecated Please use BloomreachImage instead
 */
function HippoIcon({ icon, className }: Props) {
    return (
        <img
            className={classNames(`hippo-icon`, className)}
            src={icon?.original?.model?.links?.site?.href}
            alt={icon?.altText}
        />
    );
}

export default injectComponent('pattern.atom.HippoIcon', HippoIcon);
