import { toast, Toaster, ToastBar } from 'react-hot-toast';

const Notification = (): JSX.Element => {
    const toastOptions = {
        duration: 4500,
        style: {
            padding: '25px',
        },
        className: 'toaster-wrapper',
    };

    const closeToaster = () => {
        toast.dismiss();
    };

    return (
        <span onClick={closeToaster}>
            <Toaster position="bottom-right" toastOptions={toastOptions}>
                {(toasterWrapper) => (
                    <ToastBar toast={toasterWrapper}>
                        {({ icon, message }) => (
                            <>
                                {icon}
                                {message}
                                {toasterWrapper.type !== 'loading' && (
                                    <button
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            toast.dismiss(toasterWrapper.id);
                                        }}
                                    >
                                        X
                                    </button>
                                )}
                            </>
                        )}
                    </ToastBar>
                )}
            </Toaster>
        </span>
    );
};

export default Notification;
