import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import { sanitizeProductIds } from '@mediashop/app/helper/sanitizeProductIds';
import { SpecialDealProps } from '.';
import { Product, ProductWithActiveVariant } from '@mediashop/app/api/types/ClientProduct';
import { StoreProducts } from '@mediashop/app/store/reducer/product';

/**
 * This function extracts all special deal product ids from a string like this:
 * Bionic Burner | 6b0ab4f5-fbb2-42ea-9050-db54594283d8_2ccf5df7-a235-43b1-913c-e438403d0456
 * @param idString string
 * @returns a string array with all special deal product ids in the given idString parameter
 */
export const extractSpecialDealIds = (idString: string): string[] => {
    const startIndex = idString.lastIndexOf('|') + 1;
    const processedInput = idString.slice(startIndex).trim();
    const ids = processedInput.split('_');
    return ids.filter((id) => id.trim() !== '');
};

/**
 * Get the special deal product out from the store products
 * @param storeProducts  Record<string, ProductEntity>
 * @param specialDealData SpecialDealProps
 * @returns the found an available special deal product
 */
export const getSpecialDealProduct = (
    storeProducts: StoreProducts,
    specialDealData?: SpecialDealProps
): ProductWithActiveVariant | undefined => {
    const { productId: id } = specialDealData ?? {};
    const specialDealProductIds = sanitizeProductIds(id);

    return Object.entries(storeProducts).find(
        ([key, _]) => specialDealProductIds.includes(key ?? EMPTY_STRING) && storeProducts[key].status === 'succeeded'
    )?.[1].product;
};

/**
 * Filters the productIds from the given products array which are also included in the given specialDealProduct.
 * @param products Product[]
 * @param specialDealProduct Product
 * @returns the filtered unique products
 */
export const filterDoubleSpecialDealProducts = (products: Product[], specialDealProduct?: Product): Product[] => {
    if (specialDealProduct) {
        const specialDealProductIds = extractSpecialDealIds(specialDealProduct.id);

        return products.filter((prod) =>
            specialDealProductIds.every((specialDealProductId) => prod.id !== specialDealProductId)
        );
    }

    return products;
};

/**
 * ---
 * Format link for provided page. Used by pagination to generate SEO conform href links.
 */
export const getFormattedPageLink = (
    pageNumber: number,
    search: string,
    pathname: string,
    urlParam = 'page'
): string | undefined => {
    // For the break element ("...") we don´t receive a page number, so we have to return nothing
    if (pageNumber) {
        const urlParams = new URLSearchParams(search);

        if (pageNumber > 1) {
            urlParams.set(urlParam, pageNumber.toString());
        } else {
            urlParams.delete(urlParam);
        }

        urlParams.sort();

        return urlParams.toString() ? `${pathname}?${urlParams.toString()}` : pathname;
    }

    return undefined;
};

export const calculatePagination = (page: number, limit: number, hasSpecialDeal: boolean): [number, number] => {
    const offset = (page - 1) * limit;
    let adjustedLimit = limit;
    let adjustedOffset = offset;

    if (hasSpecialDeal) {
        adjustedLimit += page === 1 ? -2 : 0;
        adjustedOffset = Math.max(0, offset - 2);
    }

    return [adjustedOffset, adjustedLimit];
};

export const paginateArray = <T>(array: T[], offset: number, limit: number): T[] => {
    return array.slice(offset, offset + limit);
};
