import { useTestGroup } from '@mediashop/app/hooks/useTestGroup';

export type ABTestingProps = {
    testId: string;
    control: JSX.Element | null;
    variation: JSX.Element | null;
};

function ABTesting({ testId, control, variation }: ABTestingProps): JSX.Element | null {
    const group = useTestGroup(testId);
    return group?.toLowerCase() === 'variation' ? variation : control;
}

export default ABTesting;
