import { RootState } from '../../index';
import analytics from '../../../analytics';

export const handlePageReceivedAction = function (action: { page: { pageType: string } }, state: RootState): void {
    const { pageType } = action.page;

    if (pageType === 'cart') {
        analytics.dispatchCheckoutStep({
            // The type doesn´t matter here as it´s the same for OPC and MPC
            type: 'OnePage',
            cart: state.cart,
            pageType,
            step: 1,
        });
    }
};
