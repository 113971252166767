import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { HeadlineProps } from '@mediashop/base/pattern/atom/Headlines';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const OrderOverviewMolecule = lazy(() => lazyRetry(() => import('./OrderOverview')));

export type OrderOverviewProps = BaseProps & {
    headline: HeadlineProps;
};

const OrderOverview = (props: OrderOverviewProps): JSX.Element => (
    <LazyComponent>
        <OrderOverviewMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.OrderOverview', OrderOverview);
