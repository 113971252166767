import { SessionStorageKeys } from '@mediashop/app/constants/storageKeys';
import * as React from 'react';
import { AddressForm } from '@mediashop/app/api/types/Address';

type CustomerSessionData = {
    formType: 'login' | 'guest' | 'register';
    billingData: { values: AddressForm; validated: boolean };
    shippingData: { values: AddressForm; validated: boolean };
};

export const resetCustomerDataFromSession = () => {
    if (typeof window !== 'undefined') {
        sessionStorage.removeItem(SessionStorageKeys.CUSTOMER_CHECKOUT_DATA);
        sessionStorage.removeItem(SessionStorageKeys.GUEST_EMAIL);
    }
};

const useCustomerDataFromSession = (): [
    CustomerSessionData | undefined,
    React.Dispatch<React.SetStateAction<CustomerSessionData>>,
] => {
    let sessionData;
    if (typeof window !== 'undefined') {
        sessionData = JSON.parse(sessionStorage.getItem(SessionStorageKeys.CUSTOMER_CHECKOUT_DATA) ?? '{}');
    }

    const setSessionData = (customerData: CustomerSessionData): void => {
        if (customerData.formType === 'guest') {
            sessionStorage.setItem(SessionStorageKeys.GUEST_EMAIL, customerData.billingData.values.billing_email!);
        } else {
            sessionStorage.removeItem(SessionStorageKeys.GUEST_EMAIL);
        }
        sessionStorage.setItem(SessionStorageKeys.CUSTOMER_CHECKOUT_DATA, JSON.stringify(customerData));
    };

    return [sessionData, setSessionData];
};

export default useCustomerDataFromSession;
