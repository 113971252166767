import { TrackableLink } from '@mediashop/app/analytics/components/TrackableLink';
import { TrackingKeys } from '@mediashop/app/analytics/constants/trackingKeys';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import BloomreachImageResponsive from '@mediashop/base/pattern/atom/BloomreachImageResponsive';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';
import { CSSProperties, ReactElement, useRef } from 'react';
import { useIntl } from 'react-intl';
import { LogoEffects } from '../../MainNavigation/HeaderLogo';
import { MainNavigationCustomLogoProps } from '../types';
import FlipLogo from './FlipLogo';

const componentName = 'header-logo-custom';

export type HeaderLogoCustomProps = BaseProps &
    MainNavigationCustomLogoProps & {
        onClick?: () => void;
    };

const HeaderLogoCustom = ({
    exchangeLogoDesktop,
    exchangeLogoMobile,
    logoDesktop,
    logoMobile,
    minWidthDesktopLogo,
    minWidthMobileLogo,
}: HeaderLogoCustomProps): ReactElement => {
    const intl = useIntl();
    const { formatLinkBase } = useReactRouterLink();
    const homeLink = formatLinkBase(EMPTY_STRING);
    const deviceType = useDeviceType();
    const isMobile = deviceType === 'mobile';

    const ref = useRef<HTMLDivElement>(null);

    const effect = exchangeLogoDesktop || (isMobile && exchangeLogoMobile) ? LogoEffects.Flip : LogoEffects.None;

    const styles = {
        [`--${componentName}-min-width-desktop-logo`]: minWidthDesktopLogo ? `${minWidthDesktopLogo}px` : undefined,
        [`--${componentName}-min-width-mobile-logo`]: minWidthMobileLogo ? `${minWidthMobileLogo}px` : undefined,
    } as CSSProperties;

    return (
        <div ref={ref} className={componentName} style={styles}>
            <TrackableLink
                to={homeLink}
                aria-label={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                trackingKey={TrackingKeys.HEADER_LOGO}
            >
                {effect === LogoEffects.None && (
                    <BloomreachImageResponsive
                        className={`${componentName}__logo`}
                        alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                        desktopImage={logoDesktop}
                        mobileImage={logoMobile}
                        variant={BrXImageVariant.MainNavigation}
                    />
                )}
                {effect === LogoEffects.Flip && (
                    <FlipLogo
                        logoDesktop={logoDesktop}
                        logoMobile={logoMobile}
                        exchangeLogoDesktop={exchangeLogoDesktop}
                        exchangeLogoMobile={exchangeLogoMobile}
                    />
                )}
            </TrackableLink>
        </div>
    );
};

export default HeaderLogoCustom;
