import { CustomerV2 } from '@mediashop/app/api/types/ClientCustomer';
import { PersonalInfoAddressFormValues } from '.';

export const getInitialFormValues = (
    customer: CustomerV2,
    shippingCountries: string[],
    currentShopCountry: string
): PersonalInfoAddressFormValues => {
    const { firstName, lastName, salutation, defaultBillingAddress } = customer;
    const {
        city,
        country: originalCountry,
        county,
        isCompany,
        phone,
        postalCode,
        region,
        state,
        streetName,
        streetNumber,
        apartment,
        block,
        company,
        floor,
        sector,
        stairway,
        vatId,
    } = defaultBillingAddress;

    const country = shippingCountries.includes(originalCountry) ? originalCountry : currentShopCountry;

    return {
        firstName,
        lastName,
        salutation,

        city,
        country,
        county,
        isCompany: isCompany?.toString(),
        phone,
        postalCode,
        region,
        state,
        streetName,
        streetNumber,
        apartment,
        block,
        company,
        floor,
        sector,
        stairway,
        vatId,
    };
};
