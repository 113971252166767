import { useLoader } from '../store/loader';

const useEventTracking = () => {
    const { eventTrackingClient } = useLoader();

    const trackHomepageViewed = () => {
        eventTrackingClient.trackPageViewedEvent({ name: 'page.viewed', page: 'home' });
    };

    const trackPDPViewed = (sku: string, componentCount: number) => {
        eventTrackingClient.trackPDPViewedEvent({ name: 'productDetailPage.viewed', sku, componentCount });
    };

    const trackCartViewed = () => {
        eventTrackingClient.trackPageViewedEvent({ name: 'page.viewed', page: 'cart' });
    };

    const trackProceededAsGuest = () => {
        eventTrackingClient.trackProceededAsGuestEvent({ name: 'checkout.proceededAsGuest' });
    };

    const trackPaymentOptionsViewed = () => {
        eventTrackingClient.trackPageViewedEvent({ name: 'page.viewed', page: 'checkoutPayment' });
    };

    const trackPaymentOptionSelected = (paymentMethod: string) => {
        eventTrackingClient.trackPaymentOptionSelectedEvent({ name: 'checkout.paymentSelected', paymentMethod });
    };

    const trackOrderSummaryViewed = () => {
        eventTrackingClient.trackPageViewedEvent({ name: 'page.viewed', page: 'checkoutSummary' });
    };

    return {
        trackHomepageViewed,
        trackPDPViewed,
        trackCartViewed,
        trackProceededAsGuest,
        trackPaymentOptionsViewed,
        trackPaymentOptionSelected,
        trackOrderSummaryViewed,
    };
};

export default useEventTracking;
