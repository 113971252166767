/**
 * Several utilities for dealing with baseUrls.
 * A baseUrl is the combination of a hostname and a basePath.
 * E.g. hostname.com/base-path
 */
export default class BaseUrlHelper {
    /**
     * Takes a path and returns it without the given basePath.
     * Does not work on baseUrls.
     */
    static removeBasePathFromPath(path: string, basePath: string): string {
        return path.replace(new RegExp(`^${basePath}`, 'i'), '');
    }

    /**
     * Takes a path and returns it without the given basePath.
     * Does not work on baseUrls.
     */
    static removeBasePathAndLocaleFromPath(path: string, basePath: string, localePath: string): string {
        const pathWithoutBasePath = BaseUrlHelper.removeBasePathFromPath(path, basePath);

        return pathWithoutBasePath.replace(new RegExp(`^${localePath}`, 'i'), '');
    }

    /**
     * Prefix a given path with the correct basePath.
     */
    static addBasePathToPath(path: string, basePath: string): string {
        return basePath + path;
    }

    /**
     * Extract the basePath from a baseUrl.
     * Returns an empty string if baseUrl is only a hostname.
     * Otherwise returns the path with a leading slash and without trailing slash.
     */
    static getBasePathFromBaseUrl(baseUrl?: string): string {
        const basePath = baseUrl
            ?.split('/')
            .slice(1)
            .filter((pathPart) => Boolean(pathPart))
            .join('/');
        return basePath ? `/${basePath}` : '';
    }

    /**
     * Returns a metric indicating the priority of the given url.
     * Useful if you need to determine the most closely matching url.
     */
    static getBaseUrlPriority(baseUrl: string): number {
        return baseUrl.match(/\//g)?.length ?? 0;
    }

    /**
     * Determine whether a given requestUrl matches a given baseUrl.
     */
    static isRequestUrlMatching(requestUrl: string, baseUrl: string): boolean {
        const regex = new RegExp(`^${baseUrl}`, 'i');
        return regex.test(requestUrl);
    }

    /** Returns an URL object for either a pathname or a full url */
    static getUrlObject(url: string): URL {
        if (!url.match(/^(http|https):\/\//)) {
            /** Dummy url in case we only receive a pathname like /test-category */
            url = `http://mediashop.tv${url.startsWith('/') ? '' : '/'}${url}`;
        }
        return new URL(url);
    }
}
