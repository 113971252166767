interface ProductVariantIdParser {
    /**
     * Extract the productId from a productVariantId
     */
    getProductId: (productVariantId: string) => string | null;

    /**
     * Extract the variantId from a productVariantId
     */
    getVariantId: (productVariantId: string) => number | undefined;
}

const PRODUCT_VARIANT_ID_REGEX = /^var(\d+)-(.*)$/;

/**
 * ProductVariantIdParser for uuid-based formats, like:
 * `var<variant-id>-<product-id>`
 * Where the variantId is prefixed to the productId.
 */
const UuidProductVariantIdParser: ProductVariantIdParser = {
    getProductId(productVariantId: string): string {
        return productVariantId.replace(PRODUCT_VARIANT_ID_REGEX, '$2');
    },

    getVariantId(productVariantId: string): number | undefined {
        const index = Number.parseInt(productVariantId.replace(PRODUCT_VARIANT_ID_REGEX, '$1'), 10);
        if (Number.isNaN(index)) {
            console.warn(
                'UuidProductVariantIdParser: Cannot parse variant index from productVariantId',
                productVariantId
            );
            return undefined;
        }
        return index;
    },
};

export default UuidProductVariantIdParser;
