import { injectComponent } from '@mediashop/app/component-injector';
import classNames from 'classnames';
import { BackgroundColor, BasePropsWithChildren } from '@mediashop/app/bloomreach/types';

export type BackgroundColorWrapperProps = BasePropsWithChildren & {
    backgroundColor?: BackgroundColor;
    hexBackgroundColor?: string;
    id?: string;
    position?: 'fixed' | 'relative';
};

/**
 * Helper Component to allow Components to adaptively take the whole page-width on different device-types.
 *
 * @param {any} children - if true the container has full-width on mobile
 * @param {string} className
 * @param {string} backgroundColor - sets the background-color of the component to one
 *                                   of the theme-colors.
 * @param {string} hexBackgroundColor - override backgroundColor and can be set in Bloomreach
 * @param {string} id - sets the id of the component
 * @param {string} position - sets position for the complete BackgroundColorWrapper
 */
function BackgroundColorWrapper({
    backgroundColor = 'none',
    children,
    className,
    hexBackgroundColor,
    id,
    position,
}: BackgroundColorWrapperProps) {
    return (
        <div
            id={id}
            className={classNames(className, `background--${backgroundColor}`)}
            data-testid="background-color-wrapper"
            style={{
                backgroundColor: hexBackgroundColor ? hexBackgroundColor : undefined,
                position: position ? position : undefined,
            }}
        >
            <div>{children}</div>
        </div>
    );
}

export default injectComponent('pattern.atom.BackgroundColorWrapper', BackgroundColorWrapper);
