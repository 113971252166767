import { lazy } from 'react';
import { SlugProductEntity } from '@mediashop/app/store/reducer/product';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { AccordionItemPDP } from '../types';
import { AccordionProps } from '@mediashop/base/pattern/molecule/Accordion';
import { TeaserGridProps } from '../../TeaserGrid';
import { TextProps } from '@mediashop/base/pattern/atom/Text';

const AccordionPDPItemMolecule = lazy(() => lazyRetry(() => import('./AccordionItem')));

export type AccordionItemProps = BaseProps & {
    item: AccordionItemPDP;
    product: SlugProductEntity;
    separatorLineColorHex?: string;
    faq?: AccordionProps;
    specialContent?: TeaserGridProps | TextProps;
};

const AccordionPDPItem = (props: AccordionItemProps): JSX.Element => (
    <LazyComponent>
        <AccordionPDPItemMolecule {...props} />
    </LazyComponent>
);

export default AccordionPDPItem;
