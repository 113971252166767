import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DevToolsSettings } from '../../dev-tools/types';

export type DevToolsState = DevToolsSettings;

const initialState: DevToolsState = {
    disablePaymentDobCheck: false,
};

const devToolsSlice = createSlice({
    name: 'devTools',
    initialState,
    reducers: {
        settingsUpdated(state, action: PayloadAction<{ settings: DevToolsSettings }>) {
            const { settings } = action.payload;

            return {
                ...state,
                ...settings,
            };
        },
    },
});

export const { settingsUpdated } = devToolsSlice.actions;

export default devToolsSlice.reducer;
