import * as React from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';

export type DynamicTextareaProps = BaseProps & {
    id?: string;
    name: string;
    value?: string;
    error?: string;
    labelId?: string;
    placeholder?: string;
    maxLength?: number;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const DynamicTextareaLazy = React.lazy(() => lazyRetry(() => import('./DynamicTextarea')));

const DynamicTextarea = (props: DynamicTextareaProps): JSX.Element => (
    <LazyComponent>
        <DynamicTextareaLazy {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.atom.DynamicTextarea', DynamicTextarea);
