import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { DetailedVariant } from '@mediashop/app/api/types/ClientProductDetailed';
import { FormattedMessage } from 'react-intl';

const componentName = 'variant-selector';

type VariantSelectorTitleProps = {
    index: number;
    variant?: Variant;
    variants?: Variant[];
};

const getVariantAttribute = (attributeName: string, variant?: Variant, variants?: Variant[]): string => {
    let attributeValue = variant?.attributes[attributeName];
    if (!attributeValue && (variant as DetailedVariant)?.attributes.subscriptionBaseVariantSku) {
        const baseVariant = variants?.find(
            (currentVariant) =>
                currentVariant.sku === (variant as DetailedVariant)?.attributes.subscriptionBaseVariantSku
        );
        attributeValue = baseVariant?.attributes[attributeName];
    }

    return attributeValue;
};

const VariantSelectorTitle = ({ index, variant, variants }: VariantSelectorTitleProps) => {
    const title = getVariantAttribute(`variant${index + 1}Title`, variant, variants);
    const subline = getVariantAttribute(`variant${index + 1}Subline`, variant, variants);

    return (
        <div className={`${componentName}__title`}>
            {title ? <>{title}:</> : <FormattedMessage id="product.variantSelectorTitle" />}
            {subline && <div className={`${componentName}__subline`}>{subline}</div>}
        </div>
    );
};

export default VariantSelectorTitle;
