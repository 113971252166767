import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Order } from '@mediashop/app/api/types/ClientOrder';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { TextProps } from '../../atom/Text';

const CheckoutSuccessMessageMolecule = lazy(() => lazyRetry(() => import('./CheckoutSuccessMessage')));

export type CheckoutSuccessMessageProps = BaseProps & {
    order: Order | undefined;
    email: string;
    isError: boolean;
    className?: string;
    text?: TextProps;
};

const CheckoutSuccessMessage = (props: CheckoutSuccessMessageProps): JSX.Element => (
    <LazyComponent>
        <CheckoutSuccessMessageMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.CheckoutSuccessMessage', CheckoutSuccessMessage);
