import { RootStore } from '../index';
import OrderClient from '../../api/OrderClient';
import { v4 as uuidv4 } from 'uuid';
import { loadingOrderFailed, loadingOrderFailedLight, orderCreated, orderStartedLoading } from '../reducer/order';

// See MS-2927/MS-5998
const MISSING_CART_ERROR = 'SESSION_MISSING_CART';

export default class OrderLoader {
    private client: OrderClient;
    private store: RootStore;

    constructor(client: OrderClient, store: RootStore) {
        this.client = client;
        this.store = store;
    }

    async createOrder(): Promise<void> {
        this.store.dispatch(orderStartedLoading());
        const facebookTrackingId = uuidv4();
        try {
            const order = await this.client.createOrder(facebookTrackingId);
            this.store.dispatch(orderCreated({ order, facebookTrackingId }));
        } catch (error) {
            let errorCode;
            if ('error' in error) {
                errorCode = error.error.error;
            } else {
                errorCode = error.errors[0].code;
            }
            if (errorCode === MISSING_CART_ERROR || errorCode === 'CART_MISSING_PAYMENT') {
                this.store.dispatch(loadingOrderFailedLight());
            } else {
                this.store.dispatch(loadingOrderFailed());
            }
        }
    }
}
