import { useEffect, useMemo, useState } from 'react';
import Variants from '@mediashop/app/domain/Variants';
import ProductStageVariants from '../Variants';
import uniq from 'lodash/uniq';
import { Product, Variant } from '@mediashop/app/api/types/ClientProduct';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';

const componentName = 'product-stage-variant-switcher';

const getInitialSelectedCombinations = (variantCombinations: Record<string, string[]>, variant?: Variant) => {
    if (!variant) {
        return {};
    }

    return Object.keys(variantCombinations).reduce(
        (selectedCombinations, dimension) => ({
            ...selectedCombinations,
            [dimension]: variantCombinations[dimension].indexOf(variant?.attributes?.[dimension]),
        }),
        {}
    );
};

type ProductStageVariantSwitcherProps = {
    product: Product;
    isError: boolean;
    onChangeVariant: (variant?: Variant) => void;
    selectedVariant?: Variant;
    showVariantLabel?: boolean;
};

/**
 * Display a list of select-boxes for each variant-dimension (e.g. size & color)
 * @param product {ProductBundle}
 * @param isError {boolean}
 * @param selectedVariant {Variant}
 * @param showVariantLabel {boolean}
 * @param onChangeVariant {function|undefined}
 */
export default function ProductStageVariantSwitcher({
    product,
    isError,
    onChangeVariant,
    selectedVariant,
    showVariantLabel = true,
}: ProductStageVariantSwitcherProps): JSX.Element {
    const variants = Variants.getApplicableVariants(product.variants);
    const variantDimensions = variants[0].attributes.variantDimensions;

    const variantCombinations = useMemo<Record<string, string[]>>(() => {
        /**
         * We need to take into account the order we receive the items so we first need to set the keys
         */
        return variantDimensions.reduce(
            (acc, dimension) => ({
                ...acc,
                [dimension.key]: uniq(product.variants.map((variant) => variant.attributes[dimension.key])),
            }),
            {}
        );
    }, [product]);

    const initialSelectedCombinations = getInitialSelectedCombinations(variantCombinations, selectedVariant);
    const [selectedCombinations, setSelectedCombinations] = useState(initialSelectedCombinations);

    /**
     * Update pre-selected variant combinations when the selected variant changes.
     */
    useEffect(() => {
        setSelectedCombinations(getInitialSelectedCombinations(variantCombinations, selectedVariant));
    }, [selectedVariant, variantCombinations]);

    /**
     * Include the pre-selected variant always in the list, even if it cannot be added to the cart.
     */
    const [includePreselectedVariant, setIncludePreselectedVariant] = useState(Boolean(selectedVariant));

    const handleVariantChange = (selection: string, type: string) => {
        const selected = Object.keys(variantCombinations).indexOf(type) === 0 ? {} : { ...selectedCombinations };

        if (Object.keys(variantCombinations).indexOf(type) === 0) {
            /**
             * disable the active variant when changing the first switcher
             */
            onChangeVariant();
        }

        setSelectedCombinations({
            ...selected,
            ...{ [type]: selection },
        });

        // Don´t offer preselected variant for selection again
        setIncludePreselectedVariant(false);

        /**
         * Only propagate to select the variant if we've selected the last item
         */
        if (Object.keys(variantCombinations).indexOf(type) === Object.keys(variantCombinations).length - 1) {
            /**
             * Filter the variants so  we have a easier way of finding them
             * We always ingore the current working level as it can cause issues when the user changes his mind
             */
            const availableVariants = variants.filter((variant) =>
                Object.entries(selectedCombinations).every(
                    (element) =>
                        element[0] === type ||
                        variant.attributes[element[0]] ===
                            variantCombinations[element[0]][parseInt(element[1] as string, 16)]
                )
            );

            /**
             * At this point only one variant should be available and we set it as the activeVariant
             */
            onChangeVariant(
                availableVariants.length === 1
                    ? availableVariants[0]
                    : availableVariants.find(
                          (variant) => variant.attributes[type] === variantCombinations[type][selection]
                      )
            );
        }
    };

    return (
        <div className={componentName}>
            {variants.length > 0 &&
                Object.keys(variantCombinations).map((combination, index) => (
                    <ProductStageVariants
                        key={index}
                        isError={isError}
                        label={
                            variantDimensions.find((dimension) => dimension?.key === combination)?.key ?? EMPTY_STRING
                        }
                        combination={combination}
                        options={variantCombinations[combination]}
                        /*
                         * First variant picker always has all elements available so everything can be reset
                         * The current working row shows all available combinations even if a user picked something so
                         * he can change his mind
                         */
                        variants={variants.filter((variant) =>
                            Object.entries(selectedCombinations).every(
                                (element) =>
                                    index === 0 ||
                                    element[0] === combination ||
                                    variant.attributes[element[0]] ===
                                        variantCombinations[element[0]][parseInt(element[1] as string, 16)]
                            )
                        )}
                        value={selectedCombinations[combination] ?? EMPTY_STRING}
                        onChange={handleVariantChange}
                        preSelectedVariant={includePreselectedVariant ? selectedVariant : undefined}
                        showLabel={showVariantLabel}
                    />
                ))}
        </div>
    );
}
