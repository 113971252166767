import { FormattedDate } from 'react-intl';
import { GatewayProductReview } from '@mediashop/app/api/types/GatewayReview';
import Stars from '../Stars';

const componentName = 'review';

type ReviewProps = {
    review: GatewayProductReview;
};

export function Review({ review }: ReviewProps): JSX.Element {
    return (
        <>
            <div className={`${componentName}__details`}>
                <Stars starId={review.id} allStarsProduct={review.stars} />
                <span className={`${componentName}__date`}>
                    <FormattedDate value={review.dateTime} day="2-digit" month="2-digit" year="numeric" />
                </span>
            </div>
            <p className={`${componentName}__text`}>{review.review}</p>
        </>
    );
}
