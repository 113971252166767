import { injectComponent } from '@mediashop/app/component-injector';
import classNames from 'classnames';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';

export type TooltipPositionProps = 'top' | 'right' | 'bottom' | 'left';

type Props = BasePropsWithChildren & {
    text: string;
    position?: TooltipPositionProps;
};

/**
 * Display a tooltip.
 * Different positions available.
 */
function Tooltip({ text = '', position = 'right', children, className }: Props) {
    const componentName = 'tooltip';

    return (
        <div className={classNames(componentName, `${componentName}--${position}`, className)}>
            <div className={`${componentName}__text`}>{text}</div>
            {children}
        </div>
    );
}

export default injectComponent('pattern.atom.Tooltip', Tooltip);
