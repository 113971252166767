import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { ProductsPreCheckoutProps } from '../../../bloomreach/ProductsPreCheckout';

const ProductsPreCheckoutMolecule = lazy(() => lazyRetry(() => import('./ProductsPreCheckout')));

const ProductsPreCheckout = (props: ProductsPreCheckoutProps): JSX.Element => (
    <LazyComponent>
        <ProductsPreCheckoutMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ProductsPreCheckout', ProductsPreCheckout);
