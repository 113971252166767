import { useCallback, useEffect } from 'react';

export const useDebounceEffect = (effect: () => void, delay: number | undefined, deps: any[]): void => {
    const callback = useCallback(effect, deps);

    useEffect(() => {
        if (!delay) {
            callback();
            return;
        }

        const handler = setTimeout(() => {
            callback();
        }, delay);

        // eslint-disable-next-line consistent-return
        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
};
