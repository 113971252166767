import { useMemo, type MouseEvent, ReactElement } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import LinkHelper from '@mediashop/app/helper/LinkHelper';
import useLinkBase from '@mediashop/app/hooks/useLinkBase';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import { BasePropsWithChildren, BrxLink } from '@mediashop/app/bloomreach/types';

export type BrxLinkOpenInTab = {
    tab: 'sameTab' | 'newTab' | null | undefined;
    link: BrxLink | string;
};

export type BrxLinkOpenInTabWithColors = BrxLinkOpenInTab & {
    backgroundColorHex?: string;
    fontColorHex?: string;
    shadowColorHex?: string;
};

export type BrxLinkOpenInTabWithFontColorAndHide = Omit<
    BrxLinkOpenInTabWithColors,
    'backgroundColorHex' | 'shadowColorHex'
> & {
    hide?: boolean;
};

export type BrXLinkOpenInTabWithColorsAndBorder = Omit<BrxLinkOpenInTabWithColors, 'shadowColorHex'> & {
    borderColorHex?: string;
};

type Props = BasePropsWithChildren &
    BrxLinkOpenInTabWithColors &
    BrxLinkOpenInTabWithFontColorAndHide & {
        onClick?: (event: MouseEvent) => void;
        isActive?: boolean;
    };

const componentName = 'link-open-in-tab';

export function LinkOpenInTab({
    className,
    isActive = false,
    tab,
    link,
    children,
    backgroundColorHex,
    fontColorHex,
    onClick,
}: Props): ReactElement {
    const linkBase = useLinkBase();
    const { formatLinkBase } = useReactRouterLink();

    const brxLink = link as BrxLink;
    const content = children ? children : brxLink.text;

    const reference = brxLink?.reference ?? link;

    const isAbsoluteUrl = useMemo(() => (reference ? LinkHelper.isAbsoluteUrl(reference) : false), [reference]);
    const isAnchorLink = useMemo(() => (reference ? reference.startsWith('#') : false), [reference]);
    const isNewTab = useMemo(() => tab && tab === 'newTab', [tab]);

    /**
     * External links are all links, that either have an absolute URI or should be opened in a new tab.
     */
    const isInternalLink = !isAbsoluteUrl && !isNewTab && !isAnchorLink;

    const hrefExternal = useMemo(
        () => (isAbsoluteUrl || isAnchorLink ? (reference ?? EMPTY_STRING) : `${linkBase}${reference}`),
        [isAbsoluteUrl, isAnchorLink, linkBase, reference]
    );

    const toInternal = useMemo(
        () => (isAbsoluteUrl ? EMPTY_STRING : formatLinkBase(reference)),
        [isAbsoluteUrl, formatLinkBase, reference]
    );

    if (!link && children) {
        return children as JSX.Element;
    }

    return isInternalLink ? (
        <Link
            className={classNames(componentName, className, {
                [`${componentName}--active`]: isActive,
            })}
            to={toInternal}
            style={{
                background: backgroundColorHex ? backgroundColorHex : undefined,
                color: fontColorHex ? fontColorHex : undefined,
            }}
            onClick={onClick}
        >
            {content}
        </Link>
    ) : (
        <a
            className={classNames(componentName, className)}
            href={hrefExternal}
            target={isNewTab ? '_blank' : undefined}
            rel="noopener noreferrer"
            style={{
                background: backgroundColorHex ? backgroundColorHex : undefined,
                color: fontColorHex ? fontColorHex : undefined,
            }}
            onClick={onClick}
        >
            {content}
        </a>
    );
}
