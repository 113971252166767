import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface CategoryState {
    selectedCategoryId: string;
}

const initialState: CategoryState = {
    selectedCategoryId: '',
};

/**
 * Shop-Context reducer.
 */
const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        selectedCategoryIdChanged(state, action: PayloadAction<{ selectedCategoryId: string }>) {
            const { selectedCategoryId } = action.payload;
            return {
                ...state,
                selectedCategoryId,
            };
        },
    },
});

export const { selectedCategoryIdChanged } = categorySlice.actions;

export default categorySlice.reducer;
