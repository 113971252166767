import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';

type Params = {
    origin: string;
    takeLanguageFromUrl: boolean;
    basePath: string;
    locale: string;
    path: string;
};

/**
 * Format url for hreflang
 * @param params params for url formatting
 * @returns formatted url for hreflang
 */
export const formatHrefLangLink = ({ origin, takeLanguageFromUrl, basePath, locale, path }: Params): string => {
    const [language, country] = locale.split('-');
    const formattedBasePath = basePath.startsWith('/') ? basePath.substring(1) : basePath;
    const localeUrlPath = takeLanguageFromUrl ? `${country.toLowerCase()}/${language.toLowerCase()}` : EMPTY_STRING;

    const concatenatedPath = [formattedBasePath, localeUrlPath, path].filter(Boolean).join('/');

    const formattedPath = concatenatedPath.replace(/\/\//, '/');
    return `${origin}/${formattedPath}`;
};
