import { FormEvent, ForwardedRef, useCallback, useRef, useImperativeHandle } from 'react';
import { object } from 'dot-object';
import { injectComponent } from '@mediashop/app/component-injector';
import { throttle } from 'throttle-debounce';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';

/**
 * Unmanaged form component.
 * onChange will trigger on input changes and return a object of the current values and their validity.
 * formRef can be used to imperatively tell React, that the contents of the form have changed.
 * This is useful, if you change the contents of the form at any time.
 */
export type FormData<T = Record<string, unknown>> = {
    formData: T;
    isValid: boolean;
};
type FormProps<T> = BasePropsWithChildren & {
    onChange: (event: FormEvent, data: FormData<T>) => void;
    formRef: ForwardedRef<FormRefHandle>;
};

export type FormRefHandle = {
    triggerChange: throttle<(event?: FormEvent) => void>;
};

function Form<T>({ onChange, className = '', children, formRef }: FormProps<T>) {
    const ref = useRef<HTMLFormElement | null>(null);
    const handleChange = useCallback(
        throttle(500, (event: FormEvent) => {
            if (typeof onChange !== 'function' || !ref.current) {
                return;
            }
            const formData = object(Object.fromEntries(new FormData(ref.current)));
            const isValid = ref.current.checkValidity();

            onChange(event, {
                formData,
                isValid,
            });
        }),
        [onChange]
    );

    useImperativeHandle<FormRefHandle, FormRefHandle>(formRef, () => ({
        triggerChange: handleChange,
    }));

    return (
        <form onChange={handleChange} ref={ref} className={`form ${className}`}>
            {children}
        </form>
    );
}

export default injectComponent('pattern.molecule.Form', Form);
