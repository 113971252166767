import { Cart, MultipleShippingInfo, ShippingInfo, ShippingKey } from '../api/types/ClientCart';
import { Money } from '@mediashop/app/api/types/Money';

type ReturnType = {
    shippingCost: Money;
    bulkyShippingCost: Money;
    dropShippingCost: Money;
    shippingPrice: Money;
};

export const calculateShippingSingle = (
    shippingInfo?: ShippingInfo,
    custom?: Cart['customFields'],
    hasShippingDiscounts: boolean = false
): ReturnType => {
    let shippingCost: Money = { centAmount: 0, fractionDigits: 2 };
    let bulkyShippingCost: Money = { centAmount: 0, fractionDigits: 2 };
    const dropShippingCost: Money = { centAmount: 0, fractionDigits: 2 };
    let shippingPrice: Money = { centAmount: 0, fractionDigits: 2 };
    const bulkyTotalCost = parseInt(custom?.bulkyTotalCost ?? '0', 10);

    if (shippingInfo) {
        const shippingRate =
            hasShippingDiscounts || bulkyTotalCost
                ? shippingInfo.shippingRate.price
                : shippingInfo.taxedPrice.totalGross;

        const mainShippingPrice = shippingRate.centAmount ?? 0;
        const extraShipping = bulkyTotalCost ? bulkyTotalCost * 100 : 0;
        const cartFractionDigits = shippingRate.fractionDigits || 2;

        shippingPrice = {
            centAmount: Math.max(mainShippingPrice - extraShipping, 0),
            currencyCode: shippingRate.currencyCode,
            fractionDigits: shippingRate.fractionDigits,
        };

        if (extraShipping) {
            bulkyShippingCost = {
                centAmount: extraShipping,
                currencyCode: shippingRate.currencyCode,
                fractionDigits: cartFractionDigits,
            };
            shippingCost = {
                centAmount: Math.max(mainShippingPrice - extraShipping, 0),
                currencyCode: shippingRate.currencyCode,
                fractionDigits: cartFractionDigits,
            };
        }
    }

    return { bulkyShippingCost, shippingCost, shippingPrice, dropShippingCost };
};

export const calculateShippingMultiple = (shipping?: Array<MultipleShippingInfo>): ReturnType => {
    const shippingCost: Money = { centAmount: 0, fractionDigits: 2 };
    const bulkyShippingCost: Money = { centAmount: 0, fractionDigits: 2 };
    const dropShippingCost: Money = { centAmount: 0, fractionDigits: 2 };
    const shippingPrice: Money = { centAmount: 0, fractionDigits: 2 };

    shipping?.forEach((shipping) => {
        if (shipping.shippingKey === ShippingKey.STANDARD) {
            shippingPrice.centAmount = shipping.shippingInfo.taxedPrice.totalGross.centAmount;
        } else if (shipping.shippingKey === ShippingKey.BULKY) {
            bulkyShippingCost.centAmount = shipping.shippingInfo.taxedPrice.totalGross.centAmount;
        } else if (shipping.shippingKey.startsWith(ShippingKey.DROP)) {
            dropShippingCost.centAmount += shipping.shippingInfo.taxedPrice.totalGross.centAmount;
        }
    });

    return { bulkyShippingCost, shippingCost, dropShippingCost, shippingPrice };
};
