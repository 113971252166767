import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { ProductListDisplayedDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';
import { Product } from '../../api/types/ClientProduct';
import { Category } from '../../api/types/ClientCategory';
import { getProductCategories, getProductCategory } from '../utils/categoryUtils';

export type DispatchProductListDisplayedData = {
    products: Product[];
    listName: string;
    mainCategory?: Category;
    country: string;
};

const createGtmDataLayer = (
    event: TagManagerEvent,
    { products, listName: list, mainCategory, country }: DispatchProductListDisplayedData
): ProductListDisplayedDataLayer => ({
    dataLayer: {
        event,
        ecommerce: {
            impressions: products.map((product, index) => ({
                id: product?.id ?? 'unknown',
                sku: (product?.variants?.length ?? 0) > 1 ? 'unknown' : (product?.variants[0].sku ?? 'unknown'),
                groupId: product?.key ?? 'unknown',
                name: product?.name ?? 'unknown',
                brand: product ? product.variants[0].attributes.brandName : 'unknown',
                price: product ? product.variants[0].price?.centAmount / 100 : 'unknown',
                category: getProductCategory(mainCategory, product, country).productCategory ?? '',
                categories: getProductCategories(mainCategory, product, country),
                list,
                position: index + 1,
            })),
        },
    },
});

export const dispatchProductListDisplayed = (data: DispatchProductListDisplayedData): void => {
    triggerGtmEvent(createGtmDataLayer(ANALYTICS_EVENT.PRODUCTLIST_VIEW, data));
};
