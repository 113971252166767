import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ContentNavigationItemLazy = lazy(() => lazyRetry(() => import('./ContentNavigationItem')));

export type ContentNavigationItemProps = BaseProps;

const ContentNavigationItem = (props: ContentNavigationItemProps): JSX.Element => (
    <LazyComponent>
        <ContentNavigationItemLazy {...props} />
    </LazyComponent>
);

export default ContentNavigationItem;
