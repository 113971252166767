import {
    PARTNER_ID_BING,
    PARTNER_ID_FACEBOOK,
    PARTNER_ID_GOOGLE_ORGANIC,
    PARTNER_ID_YOUTUBE,
} from '../../constants/partnerIds';
import { EMPTY_STRING } from '../../constants/semanticConstants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PartnerState {
    partnerId?: string;
}

const initialState: PartnerState = {
    partnerId: undefined,
};

/**
 * Detect partnerId from referrer URL
 * @param referrer
 */
function detectPartnerIdFromReferrer(referrer: string): string {
    if (referrer.includes('google')) {
        return PARTNER_ID_GOOGLE_ORGANIC;
    }

    if (referrer.includes('facebook')) {
        return PARTNER_ID_FACEBOOK;
    }

    if (referrer.includes('bing')) {
        return PARTNER_ID_BING;
    }

    if (referrer.includes('youtube')) {
        return PARTNER_ID_YOUTUBE;
    }

    return EMPTY_STRING;
}

/**
 * Load partnerId from action
 * @param action
 */
export function getPartnerId(action: { urlParam: string; referrer: string; session: string }): string {
    if (action.urlParam) {
        return action.urlParam;
    }

    if (action.referrer) {
        return detectPartnerIdFromReferrer(action.referrer);
    }

    if (action.session) {
        return action.session;
    }

    return EMPTY_STRING;
}

const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        partnerInitiated(state, action: PayloadAction<{ urlParam: string; referrer: string; session: string }>) {
            return { ...state, partnerId: getPartnerId(action.payload) };
        },
        partnerUpdated(state, action: PayloadAction<{ partnerId: string }>) {
            const { partnerId } = action.payload;

            return {
                ...state,
                partnerId,
            };
        },
    },
});

export const { partnerInitiated, partnerUpdated } = partnerSlice.actions;

export default partnerSlice.reducer;
