import { Page, Content, Document, isDocument, isReference } from '@bloomreach/spa-sdk';

declare type ContentModel = ReturnType<Content['getData']>;
declare type DocumentDataModel = ReturnType<Document['getData']>;

/**
 * Determine whether or not the given value is a plain and simple object.
 * @param object
 */
const isBasicObject = (object) =>
    object && typeof object === 'object' && Object.getPrototypeOf(object) === Object.prototype;

/**
 * Takes a data-object and dereferences all references it can find.
 */
export default function dereferenceData(
    data: Content | ContentModel | DocumentDataModel,
    getContent: Page['getContent']
): any {
    if (Array.isArray(data)) {
        return data.map((item) => dereferenceData(item, getContent));
    }
    if (isReference(data)) {
        const content = getContent(data);
        if (!content) {
            return content;
        }
        return dereferenceData(content, getContent);
    }
    if (isDocument(data)) {
        return dereferenceData(data.getData(), getContent);
    }
    if (isBasicObject(data)) {
        const unrefd = {};
        Object.keys(data).forEach((key) => {
            unrefd[key] = dereferenceData(data[key], getContent);
        });
        return unrefd;
    }
    return data;
}
