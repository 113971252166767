import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { DetailedVariant } from '@mediashop/app/api/types/ClientProductDetailed';

const VideoMolecule = lazy(() => lazyRetry(() => import('./Videos')));

export type VideoProps = BaseProps & {
    attributes: DetailedVariant['attributes'];
};

const VideoItem = (props: VideoProps): JSX.Element => (
    <LazyComponent>
        <VideoMolecule {...props} />
    </LazyComponent>
);

export default VideoItem;
