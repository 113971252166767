import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { PaymentProps } from '../../../../types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CheckoutPaymentMolecule = lazy(() => lazyRetry(() => import('./Payment')));

const CheckoutPayment = (props: PaymentProps): JSX.Element => (
    <LazyComponent>
        <CheckoutPaymentMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.Checkout.Payment', CheckoutPayment);
