import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { PurchaseDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';
import { getProductDataLayerFromGatewayCartLineItem } from '../utils/getProductDataLayerFromGatewayCartLineItem';
import { Money } from '../../api/types/Money';
import { triggerEmarsysEvent } from '../emarsys/triggerEmarsysEvent';
import { EmarsysCommand } from '../emarsys/commants';
import { EMPTY_STRING } from '../../constants/semanticConstants';
import { getEmarsysCartByOrder } from '../emarsys/helpers/getEmarsysCart';
import { ExchangeRatesSubset } from '../../types/exchangeRates';
import { Order } from '../../api/types/ClientOrder';
import MoneyDomain from '../../domain/Money';
import { Cart, ShippingKey } from '../../api/types/ClientCart';

const PAYMENT_FEE_KEY = 'payment_fee';

type OrderCreatedData = {
    emarsysAccountCurrency?: string;
    exchangeRates?: ExchangeRatesSubset;
    order: Order;
    facebookTrackingId: string;
};

const getDecimalAmount = (money?: Money) => (money ? money.centAmount / 10 ** money.fractionDigits : 0);

const getEmarsysOrderId = (order: Order, orderIdFromUrl?: string): string => {
    const numericOrderId = order.cart?.customFields?.orderId ?? order.orderNumber;
    if (numericOrderId) {
        return `WEB_${numericOrderId}`;
    }

    if (orderIdFromUrl) {
        return `WEB_${orderIdFromUrl}`;
    }

    return EMPTY_STRING;
};

const createEmarsysPurchaseData = (
    { emarsysAccountCurrency, exchangeRates, order }: OrderCreatedData,
    orderIdFromUrl?: string
) => ({
    orderId: getEmarsysOrderId(order, orderIdFromUrl),
    items: getEmarsysCartByOrder(order.orderedCart ?? order.cart, emarsysAccountCurrency, exchangeRates),
});

const calculateShippingTotal = (cart: Cart, gross: boolean = true): Money => {
    if (cart?.shippingMode === 'Single' && cart?.shippingInfo) {
        return gross ? cart.shippingInfo?.taxedPrice?.totalGross : cart.shippingInfo?.taxedPrice?.totalNet;
    }

    if (cart?.shippingMode === 'Multiple') {
        let productTotal: Money = { ...cart.totalPrice, centAmount: 0 };
        cart.shipping?.forEach((shipping) => {
            if (
                shipping.shippingKey !== ShippingKey.STANDARD ||
                !cart.shipping?.find((shipping) => shipping.shippingKey.startsWith(ShippingKey.DROP))
            ) {
                productTotal = MoneyDomain.add(
                    productTotal,
                    gross ? shipping.shippingInfo.taxedPrice.totalGross : shipping.shippingInfo.taxedPrice.totalNet
                );
            }
        });
        return productTotal;
    }

    return gross ? cart.taxedPrice.totalGross : cart.taxedPrice.totalNet;
};

// eslint-disable-next-line complexity
const createGtmDataLayer = (
    event: TagManagerEvent,
    { order, facebookTrackingId }: OrderCreatedData,
    orderIdFromUrl?: string
): PurchaseDataLayer | null => {
    const cart = order.orderedCart ?? order.cart;
    const paymentFees = cart.customLineItems?.find((cLineItem) => cLineItem.key === PAYMENT_FEE_KEY);
    const transactionFee = paymentFees ? getDecimalAmount(paymentFees.money) : 0;
    const transactionFeeTax =
        (paymentFees?.taxedPrice?.totalGross?.centAmount ?? 0) - (paymentFees?.taxedPrice?.totalNet?.centAmount ?? 0);

    const shippingPrice = calculateShippingTotal(cart);
    const shippingTax = shippingPrice.centAmount - calculateShippingTotal(cart, false).centAmount;

    const totalNet = cart.taxedPrice.totalNet;
    const totalGross = cart.taxedPrice.totalGross;
    const taxCentAmount = totalGross.centAmount - totalNet.centAmount - transactionFeeTax - shippingTax;
    const products = cart.lineItems.map((lineItem) =>
        getProductDataLayerFromGatewayCartLineItem(lineItem, lineItem.quantity)
    );

    const totalGrossWithoutFees =
        totalGross.centAmount - (paymentFees?.money?.centAmount ?? 0) - (shippingPrice?.centAmount ?? 0);

    if (order) {
        return {
            dataLayer: {
                event,
                facebookTrackingId,
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: (order.orderNumber?.length ? order.orderNumber : orderIdFromUrl) ?? EMPTY_STRING,
                            revenue: totalGrossWithoutFees / 10 ** totalGross.fractionDigits,
                            tax: taxCentAmount / 10 ** totalNet.fractionDigits,
                            shipping: getDecimalAmount(shippingPrice),
                            transactionFee,
                            coupon: cart.discountCodes.map(({ discountCode }) => discountCode?.code ?? ''),
                            payment: order.paymentMethod,
                        },
                        products,
                    },
                },
                userEmail: cart.billingAddress!.email,
            },
        };
    }
    return null;
};

export const dispatchOrderCreated = (data: OrderCreatedData): void => {
    const urlParameters = new URLSearchParams(window.location.search);
    const orderId = urlParameters.get('reference') ?? undefined;

    const dataLayer = createGtmDataLayer(ANALYTICS_EVENT.ORDER_CREATE, data, orderId);
    if (dataLayer) {
        triggerGtmEvent(dataLayer);
    }

    // Trigger empty cart before purchase (MS-3311)
    triggerEmarsysEvent({ command: EmarsysCommand.CART, data: [] });
    triggerEmarsysEvent({
        command: EmarsysCommand.SET_EMAIL,
        data: data.order.orderedCart?.billingAddress?.email ?? data.order.cart.billingAddress!.email,
    });
    triggerEmarsysEvent({ command: EmarsysCommand.PURCHASE, data: createEmarsysPurchaseData(data, orderId) });
};
