import { FormattedMessage } from 'react-intl';
import { Money } from '@mediashop/app/api/types/Money';
import Currency from '../../atom/Currency';

interface PriceProps {
    price: Money;
    showFrom?: boolean;
}

export function Price({ price, showFrom = false }: PriceProps): JSX.Element {
    return showFrom ? (
        <FormattedMessage
            id="price.from"
            values={{
                price: <Currency price={price} />,
            }}
        />
    ) : (
        <Currency price={price} />
    );
}
