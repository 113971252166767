import { useEffect } from 'react';
import { useCart } from '../../hooks/useCart';
import { usePartner } from '../../hooks/usePartner';
import { useSetPartnerId } from '../../hooks/api/useSetPartnerId';

/**
 * Update cart partner id when it changed.
 */
export default function CartPartnerConnector(): null {
    const { cart } = useCart();
    const { partnerId } = usePartner();
    const { mutateAsync: setPartnerId } = useSetPartnerId();

    const cartPartnerId = cart?.customFields?.partnerId;

    useEffect(() => {
        if (partnerId && cartPartnerId !== partnerId && cart) {
            setPartnerId(partnerId);
        }
    }, [cart, cartPartnerId, partnerId, setPartnerId]);

    // Return null to please react
    return null;
}
