import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { Configuration as BrConfiguration } from '@bloomreach/spa-sdk';
import PageMetadata from '../../page-metadata';
import ProductDataConnector from '../connector/ProductDataConnector';
import CartDataConnector from '../connector/CartDataConnector';
import OrderCreationConnector from '../connector/OrderCreationConnector';
import PageDataConnector from '../connector/PageDataConnector';
import Notification from '../../components/Notification';
import { BrxMapping, BrxPageModel } from '../types';
import useIsClientSide from '../../hooks/useIsClientSide';
import { SKIP_RENDER } from '../../constants/semanticConstants';

type Props = {
    config: BrConfiguration;
    mapping: BrxMapping;
    page?: BrxPageModel;
};

export const SplpBloomreachApp = ({ config, mapping, page }: Props): JSX.Element => {
    const isClientSide = useIsClientSide();

    return (
        <BrPage configuration={config} mapping={mapping} page={page}>
            <PageMetadata />
            <ProductDataConnector />
            <CartDataConnector />
            {isClientSide ? <OrderCreationConnector /> : SKIP_RENDER}
            <PageDataConnector />
            <Notification />

            <BrComponent path="top" />
            <BrComponent path="main" />
            <BrComponent path="footer" />
        </BrPage>
    );
};
