import parseHtml from 'html-react-parser';
import { FormattedMessage } from 'react-intl';
import { Category } from '@mediashop/app/api/types/ClientCategory';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { SKIP_RENDER, SPACE } from '@mediashop/app/constants/semanticConstants';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { CATEGORY_BELOW_THE_FOLD_CONTAINER_ID } from '../BelowTheFoldText/constants';

const componentName = 'category-above-the-fold-text';

type AboveTheFoldTextType = BaseProps & {
    category?: Category;
};

export function AboveTheFoldText({ category, className }: AboveTheFoldTextType): JSX.Element | null {
    const deviceType = useDeviceType();
    const isMobileDevice = deviceType === 'mobile';

    const aboveTheFoldText = isMobileDevice
        ? category?.custom?.categoryTextAboveTheFoldMobile
        : category?.custom?.categoryTextAboveTheFoldDesktop;

    const scrollToBelowTheFoldText = () => {
        const target = document.getElementById(CATEGORY_BELOW_THE_FOLD_CONTAINER_ID);
        target?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    return aboveTheFoldText ? (
        <p className={className}>
            {parseHtml(aboveTheFoldText)}
            {SPACE}
            <span className={`${componentName}__anchor-link`} onClick={scrollToBelowTheFoldText}>
                <FormattedMessage id="category.showMore" />
            </span>
        </p>
    ) : (
        SKIP_RENDER
    );
}
