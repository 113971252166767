import { Category } from '../../api/types/ClientCategory';

export const findCategoryIdBySlug = (categoryArray: Category[], currentPath: string): string => {
    let categoryId = '';
    categoryArray.forEach((category) => {
        if (categoryId) {
            return;
        }
        if (category.slug === currentPath) {
            categoryId = category.id;
        } else if (category.children?.length) {
            categoryId = findCategoryIdBySlug(category.children, currentPath);
        }
    });

    return categoryId;
};

const findMainCategoryCountry = (categories: Category[], categoryTreeRootId: string, userCountry: string): string => {
    let categoryId = '';
    categories.forEach((category) => {
        if (categoryId) {
            return;
        }

        if (category.id === categoryTreeRootId) {
            const countryCategory = category.children?.find((country) => country.name === userCountry);
            categoryId = countryCategory?.id ?? '';
        } else if (category.children?.length) {
            categoryId = findMainCategoryCountry(category.children, categoryTreeRootId, userCountry);
        }
    });

    return categoryId;
};

/**
 * Extract the category ID based on the slug
 */
export const determineProductsCategory = (
    categoryTreeRootId: string,
    categories: Category[],
    userCountry: string,
    url?: string
): string => {
    let urlObject;
    if (typeof window !== 'undefined') {
        urlObject = new URL(window.location.href);
    } else if (url) {
        let goodUrl = url;
        if (!url.match(/^(http|https):\/\//)) {
            /** Dummy url in case we only receive a pathname like /test-category */
            goodUrl = `http://mediashop.tv${url.startsWith('/') ? '' : '/'}${url}`;
        }
        urlObject = new URL(goodUrl);
    }

    const currentPath = urlObject.pathname.replace(/\/$/, '').split('/').pop();
    const subcategory = urlObject.searchParams.get('subcategory') ?? '';

    const categoryToSearchIn = subcategory || currentPath;

    if (categoryToSearchIn) {
        let categoryId = findCategoryIdBySlug(categories, categoryToSearchIn.toLowerCase());

        /**
         * Get the country Category if we're on the main page
         * or if no category id was found (the main category is set as a fallback)
         */
        if (categoryId === '') {
            categoryId = findMainCategoryCountry(categories, categoryTreeRootId, userCountry);
        }

        return categoryId !== '' ? categoryId : categoryTreeRootId;
    }

    return categoryTreeRootId;
};
