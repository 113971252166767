import { PaymentMethod } from '@mediashop/app/api/types/Payment';
import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const PaymentMethodRequiredFieldsLazy = lazy(() => lazyRetry(() => import('./PaymentMethodRequiredFields')));

export interface RequiredFieldsForm {
    dateOfBirth?: string;
    phone?: string;
}

export type PaymentMethodRequiredFieldsProps = BaseProps & {
    paymentMethod: PaymentMethod;
    fields: Array<keyof RequiredFieldsForm>;
    fieldsDefault?: RequiredFieldsForm;
    goToSummary?: () => void;
};

const PaymentMethodRequiredFields = (props: PaymentMethodRequiredFieldsProps): JSX.Element => (
    <LazyComponent>
        <PaymentMethodRequiredFieldsLazy {...props} />
    </LazyComponent>
);

export default PaymentMethodRequiredFields;
