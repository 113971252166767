import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useShopContext } from '../../hooks/useShopContext';
import { useLoader } from '../../store/loader';
import { queries } from '..';
import { BrxFilter } from '../../bloomreach/types';
import { useFilterWithPriceRange } from './useFilterWithPriceRange';

type Props = {
    filters: Array<BrxFilter>;
    offset: number;
    limit: number;
};

/**
 * Hook to get products depending on provided filters
 */
export const useSearchByAttribute = ({ filters, offset, limit }: Props) => {
    const { productClient } = useLoader();
    const { country, currency, locale } = useShopContext();

    const getSearchResultsByAttribute = () =>
        productClient.searchByAttribute(useFilterWithPriceRange(filters), country, currency, locale, limit, offset);

    return useQuery({
        ...queries.search.searchByAttribute(filters, country, currency, locale, offset, limit),
        queryFn: () => getSearchResultsByAttribute(),
        enabled: filters.length > 0,
        placeholderData: keepPreviousData,
    });
};
