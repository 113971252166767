import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { useIntl, FormattedNumber } from 'react-intl';

type CurrencyProps = {
    price?: {
        centAmount: number;
        fractionDigits: number;
        currencyCode?: string;
    };
};

const formatCurrencyCode = (currencyCode: string): string => {
    switch (currencyCode) {
        case 'EUR':
            return '€';
        case 'CHF':
            return 'CHF';
        case 'HUF':
            return 'Ft';
        case 'CZK':
            return 'Kč';
        case 'RON':
            return 'Lei';
        default:
            return currencyCode;
    }
};

/**
 * We need to handle a special case for HUF where the number of decimals is 0.
 * Otherwise we return the fractionDigits set in the price object or the default of 2
 * Currently `price.fractionDigits` can't be configured from commerce tools so it's not reliable
 * @param price
 *
 * @returns [number, number] of the form [fractionDigits, divideExponent]
 */
const getCurrencyFractionDigits = (price: CurrencyProps['price']): [number, number] => {
    switch (price?.currencyCode) {
        // some currencies needs to be divided by 100 and have no decimals
        case 'HUF':
        case 'CZK':
        case 'RON':
            return [0, 2];
        default:
            return [price?.fractionDigits ?? 2, price?.fractionDigits ?? 2];
    }
};

/**
 * Render a commercetools-price. Uses the current currency.
 */
export default function Currency({
    price = {
        centAmount: 0,
        fractionDigits: 2,
        currencyCode: undefined,
    },
}: CurrencyProps): JSX.Element {
    const { locale: intlContextLocale } = useIntl();
    const { currency } = useShopContext();

    const [fractionDigits, divideExponent] = getCurrencyFractionDigits(price);

    const currencyCode = price.currencyCode ?? currency;
    const priceValue = price.centAmount ? price.centAmount / 10 ** divideExponent : 0;

    const locale = currencyCode === 'CHF' ? 'de-CH' : intlContextLocale;

    const formattedCurrency = formatCurrencyCode(currencyCode);
    // we use the Intl API directly here, as the locale could be different to the one from the intl context
    const formattedPrice = Intl.NumberFormat(locale, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(priceValue);

    // Special case for some locales where we want to display the currency symbol before the price
    if (['de-DE', 'de-CH', 'fr-CH'].includes(locale)) {
        return (
            <>
                {formattedCurrency}
                {'\u00a0'}
                {formattedPrice}
            </>
        );
    }

    return (
        <FormattedNumber
            value={priceValue}
            style="currency"
            currency={currencyCode}
            currencyDisplay="narrowSymbol"
            minimumFractionDigits={fractionDigits}
            maximumFractionDigits={fractionDigits}
        />
    );
}
