/* eslint-disable max-lines-per-function */
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import Icon from '@mediashop/base/pattern/atom/Icon';
import { MainNavigationVerticalBrandNavigationItems } from '.';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import { LinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import { useOutsideClicked } from '@mediashop/app/hooks/useOutsideClicked';
import { getGroupedNavigationItems } from '../functions';
import { useProject } from '@mediashop/app/hooks/useProject';
import { removeLocaleFromPathname } from '@mediashop/app/helper/localeHelper';
import { markChildItemActive } from './functions';

const componentName = 'main-navigation-vertical-menu';

type Props = {
    extendedNavigation: MainNavigationVerticalBrandNavigationItems[];
    activeMainNavigationItem?: MainNavigationVerticalBrandNavigationItems;
    setChildMenuVisible: (val: boolean) => void;
    toggleMobileMenuOpen: () => void;
};

const MobileMenu = ({
    extendedNavigation,
    activeMainNavigationItem,
    setChildMenuVisible,
    toggleMobileMenuOpen,
}: Props): JSX.Element => {
    const location = useLocation();
    const { takeLanguageFromUrl } = useProject();
    const cleanedPathname = takeLanguageFromUrl ? removeLocaleFromPathname(location.pathname) : location.pathname;

    const mobileMenuRef = useRef<HTMLDivElement>(null);
    const [openedChildNavigationMenus, setOpenedChildNavigationMenus] = useState<
        MainNavigationVerticalBrandNavigationItems[]
    >([]);

    const groupedNavigationItems = (navItem: MainNavigationVerticalBrandNavigationItems) => {
        const childNavigationItemsOfHoveredMainNavigationItem = extendedNavigation.filter(
            (extNav) => extNav.link === navItem?.link
        );
        return getGroupedNavigationItems(childNavigationItemsOfHoveredMainNavigationItem);
    };

    const hasGroupedItems = (navItem: MainNavigationVerticalBrandNavigationItems) => {
        return groupedNavigationItems(navItem).some((items) => items.some((item) => item.isHeadline));
    };

    useOutsideClicked(mobileMenuRef, toggleMobileMenuOpen);
    useEffect(() => {
        if (mobileMenuRef.current) {
            document.documentElement.style.setProperty(
                '--mobile-menu-top',
                `${mobileMenuRef.current.getBoundingClientRect().top}px`
            );
        }
    }, [mobileMenuRef]);

    const onClickNavigationItem = () => {
        toggleMobileMenuOpen();
        setChildMenuVisible(false);
    };

    const determineChildNavigationVisibility = (navItem: MainNavigationVerticalBrandNavigationItems) => {
        setOpenedChildNavigationMenus((prevState) => {
            const index = prevState.findIndex((item) => item.link === navItem.link);

            if (index === -1) {
                return [...prevState, navItem];
            }
            return prevState.filter((item) => item.link !== navItem.link);
        });
    };

    useEffect(() => {
        const openedActiveMainNavigationItem = openedChildNavigationMenus.find(
            (openedChildNavItem) => openedChildNavItem.link === activeMainNavigationItem?.link
        );

        if (!openedActiveMainNavigationItem && activeMainNavigationItem) {
            setOpenedChildNavigationMenus([...openedChildNavigationMenus, activeMainNavigationItem]);
        }
    }, [activeMainNavigationItem]);

    return (
        <div className={`${componentName}__mobile-wrapper`}>
            <div ref={mobileMenuRef} className={`${componentName}__mobile-content-wrapper`}>
                <ContentWrapper>
                    {/* Main Navigation */}
                    {extendedNavigation.map((navItem) => (
                        <div key={navItem.link}>
                            <div
                                onClick={() => determineChildNavigationVisibility(navItem)}
                                className={classNames(
                                    {
                                        [`${componentName}__child-navigation-link-active`]:
                                            activeMainNavigationItem?.link === navItem.link,
                                    },
                                    {
                                        [`${componentName}__arrow-icon-rotation-state`]: Boolean(
                                            openedChildNavigationMenus.find(
                                                (openedChildNavItem) => openedChildNavItem.link === navItem.link
                                            )
                                        ),
                                    },
                                    `${componentName}__main-navigation-link`
                                )}
                            >
                                {navItem.childItems.length === 0 ? (
                                    <LinkOpenInTab
                                        onClick={() => onClickNavigationItem()}
                                        tab={navItem.tab}
                                        link={navItem.link ?? ''}
                                    >
                                        {navItem.linkText}
                                    </LinkOpenInTab>
                                ) : (
                                    <span>{navItem.linkText}</span>
                                )}

                                {navItem.childItems.length > 0 ? (
                                    <Icon name="ArrowDown" className={`${componentName}__arrow-down`} />
                                ) : (
                                    SKIP_RENDER
                                )}
                            </div>

                            {/* Child Navigation */}
                            {openedChildNavigationMenus.find(
                                (openedChildNavItem) => openedChildNavItem.link === navItem.link
                            ) && navItem.childItems.length > 0 ? (
                                <div className={`${componentName}__child-navigation`}>
                                    {groupedNavigationItems(navItem).map((group, groupIndex) => (
                                        <ul
                                            key={groupIndex}
                                            className={classNames(`${componentName}__navigation-list`, {
                                                [`${componentName}__navigation-list--with-child-heading`]:
                                                    hasGroupedItems(navItem),
                                            })}
                                        >
                                            {group.map((childItem) => (
                                                <li
                                                    key={childItem.link}
                                                    className={classNames(
                                                        `${componentName}__navigation-list-item`,
                                                        {
                                                            [`${componentName}__child-navigation-link-active`]:
                                                                markChildItemActive(
                                                                    extendedNavigation,
                                                                    childItem,
                                                                    cleanedPathname,
                                                                    location
                                                                ),
                                                        },
                                                        {
                                                            [`${componentName}__navigation-list-item--headline`]:
                                                                childItem.isHeadline,
                                                        },
                                                        {
                                                            [`${componentName}__navigation-list-item--no-headline`]:
                                                                !childItem.isHeadline,
                                                        }
                                                    )}
                                                >
                                                    {childItem.link ? (
                                                        <LinkOpenInTab
                                                            onClick={() => onClickNavigationItem()}
                                                            tab={childItem.tab}
                                                            link={childItem.link}
                                                        >
                                                            {childItem.linkText}
                                                        </LinkOpenInTab>
                                                    ) : (
                                                        <span>{childItem.linkText}</span>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    ))}
                                </div>
                            ) : (
                                SKIP_RENDER
                            )}
                        </div>
                    ))}
                </ContentWrapper>
            </div>
        </div>
    );
};

export default MobileMenu;
