import { RootStore } from '../store';

/**
 * Load the devtools. Code is taken from https://kentcdodds.com/blog/make-your-own-dev-tools
 * and adapted to our usecase.
 * @param callback callback after devtools load
 */
function loadDevTools(store: RootStore, callback: () => void): void {
    // this allows you to explicitly disable it in development for example
    const explicitlyDisabled =
        window.location.search.includes('ms_devtools=false') || window.localStorage.getItem('ms_devtools') === 'false';

    const explicitlyEnabled =
        window.location.search.includes('ms_devtools=true') || window.localStorage.getItem('ms_devtools') === 'true';

    if (!explicitlyDisabled && (process.env.NODE_ENV === 'development' || explicitlyEnabled)) {
        /*
         * use a dynamic import so the dev-tools code isn't bundled with the regular
         * app code so we don't worry about bundle size.
         */
        import('./dev-tools').then((devTools) => devTools.install(store)).finally(callback);
    } else {
        // if we don't need the DevTools, call the callback immediately.
        callback();
    }
}

export default loadDevTools;
