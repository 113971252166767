import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import Icon from '../Icon';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import clamp from 'lodash/clamp';
import { useChannelSettings } from '@mediashop/app/bloomreach/useChannelSettings';

const StarType = {
    FULL: 'full',
    HALF: 'half',
    EMPTY: 'empty',
} as const;

function getStars(numberOfStars: number): string[] {
    numberOfStars = clamp(numberOfStars, 0, 5);
    const numberOfFullStars = Math.trunc(numberOfStars);
    const numberOfHalfStars = Number(numberOfStars % 1 !== 0);
    const numberOfEmptyStars = 5 - numberOfFullStars - numberOfHalfStars;

    const fullStars = Array(numberOfFullStars)
        .fill(0)
        .map(() => StarType.FULL);
    const halfStars = Array(numberOfHalfStars)
        .fill(0)
        .map(() => StarType.HALF);
    const emptyStars = Array(numberOfEmptyStars)
        .fill(0)
        .map(() => StarType.EMPTY);

    return [...fullStars, ...halfStars, ...emptyStars];
}

const componentName = 'star-rating';

type StarRatingProps = BaseProps & {
    rating: number;
    ratingText?: JSX.Element;
};

/**
 * Simple StarRating Component.
 * Provide the rating which should be a number of the Stars active shown on a Scale to 5.
 */
function StarRating({ rating, ratingText, className }: StarRatingProps): JSX.Element | null {
    const { showReviews } = useChannelSettings();

    if (!showReviews) {
        return SKIP_RENDER;
    }

    const stars = getStars(rating);

    return (
        <div className={classNames(componentName, className)}>
            {stars.map((starType, index) => (
                <Icon
                    key={index}
                    name={starType === StarType.HALF ? 'HalfStar' : 'Star'}
                    className={classNames(`${componentName}__star`, `${componentName}__star--${starType}`)}
                />
            ))}
            {ratingText}
        </div>
    );
}

export default injectComponent('pattern.atom.StarRating', StarRating);
