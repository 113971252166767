import { BrxTextCompound } from '@mediashop/app/bloomreach/types';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import { TextCompoundProps } from '.';

/**
 * Check if desktop text or mobile text is set in text compound
 * @param textCompound
 * @returns boolean
 */
export const isTextCompoundAvailable = (textCompound?: TextCompoundProps): boolean =>
    Boolean(
        (textCompound && typeof textCompound.desktop === 'object' && textCompound.desktop.value !== EMPTY_STRING) ||
            (textCompound && typeof textCompound?.mobile === 'object' && textCompound.mobile.value !== EMPTY_STRING) ||
            (textCompound && typeof textCompound.desktop === 'string' && textCompound.desktop !== EMPTY_STRING) ||
            (textCompound && typeof textCompound.mobile === 'string' && textCompound.mobile !== EMPTY_STRING)
    );

/**
 * Check if desktop text is empty in text compound
 * Optionally also check if the old html content is empty
 * @param isMobileDevice
 * @param textCompound
 * @param content
 * @returns boolean
 */
export const emptyDesktopTextCompound = (isMobileDevice: boolean, textCompound: BrxTextCompound): boolean =>
    !isMobileDevice &&
    textCompound &&
    ((typeof textCompound.desktop === 'string' && textCompound.desktop === EMPTY_STRING) ||
        (typeof textCompound.desktop === 'object' && textCompound.desktop.value === EMPTY_STRING));
