import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import { Fragment } from 'react';
import { IntlProvider } from 'react-intl';

import messages from './messages';
import { useAppSelector } from '../store/hooks/hooks';

interface IntlProviderProps extends BasePropsWithChildren {
    useInformalGerman: boolean;
}

/**
 * Provide react-intl context through redux.
 */
const ReduxIntlProvider = ({ children, useInformalGerman }: IntlProviderProps) => {
    const { locale } = useAppSelector((state) => state.context);

    const message = useInformalGerman ? messages[`${locale}_informal`] : messages[locale];

    return (
        <IntlProvider locale={locale} textComponent={Fragment} messages={message}>
            {children}
        </IntlProvider>
    );
};

export default ReduxIntlProvider;
