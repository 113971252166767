import { lazy } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { DetailedVariant } from '@mediashop/app/api/types/ClientProductDetailed';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const AttributesMolecule = lazy(() => lazyRetry(() => import('./Attributes')));

export type AccordionAttributesProps = BaseProps & {
    variant?: DetailedVariant;
};

const AttributesItem = (props: AccordionAttributesProps): JSX.Element => (
    <LazyComponent>
        <AttributesMolecule {...props} />
    </LazyComponent>
);
export default AttributesItem;
