import { lazy } from 'react';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CustomerClubSuggestionMolecule = lazy(() => lazyRetry(() => import('./CustomerClubSuggestion')));

export interface CustomerClubSuggestionProps {
    onClose: () => void;
}

const CustomerClubSuggestion = (props: CustomerClubSuggestionProps): JSX.Element => (
    <LazyComponent>
        <CustomerClubSuggestionMolecule {...props} />
    </LazyComponent>
);

export default CustomerClubSuggestion;
